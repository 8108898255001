import styled from "styled-components"

interface ContainerProps {
  $analyticsperline: number
}

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.colors.black};
  display: grid;
  grid-template-columns: repeat(${(props) => props.$analyticsperline}, 1fr);
  gap: 4px;
  height: 100px;
  width: 160px;
  border-radius: 6px;
  padding: 4px;

  @media (max-width: 1320px) {
    height: 60px;
    width: 100px;
  }
`

export const AnalyticRepresentation = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  grid-column: auto;
  grid-row: auto;
  border-radius: 4px;
`
