import styled from "styled-components"

export const Container = styled.div`
  h3 {
    padding-bottom: 20px;
  }
`

export const Table = styled.table`
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #dddd;
    padding: 15px;
    text-align: left;
  }

  th {
    background-color: #f5f5f5;
  }

  @media (max-width: 600px) {
    th,
    td {
      display: block;
      width: 100%;
    }

    th {
      display: none;
    }

    td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  }
`

export const TextTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1px;
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
`

export const ResetBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #449e48;
  color: white;
  font-weight: 500;
  font-size: medium;
  display: flex;
  margin: auto;
  margin-top: 20px;

  :hover {
    box-shadow: 5px 6px 16px 3px rgba(160, 160, 160, 0.25);
    background-color: #357a38;
  }
`
