import { useContext } from "react"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { Root } from "../../../components/Modal"
import { SensorInfo } from "../components/SensorsInfo"
import { SensorsProvContext } from "../context/SensorsProvContext"
import useSensorsProvController from "../controller/useSensorsProvController"
import { SensorsProvContextDTO } from "../entities/SensorsProvContextDTO"
import * as S from "./styles"
import { Buffer } from "buffer"
import { useAppTranslate } from "../../../translate/useAppTranslate"

// @ts-ignore
window.Buffer = Buffer

export const SensorsProvWrapper = (): JSX.Element => {
  const { handleOpenConfirmModal } = useSensorsProvController()
  const { wsDataProcessed } = useContext(
    SensorsProvContext
  ) as SensorsProvContextDTO
  const { sensorsProv } = useAppTranslate()

  return (
    <S.Wrapper>
      <S.TextInfo>
        <h2>{sensorsProv.msg_distance_from_sensor}</h2>
        <LoadingSpinner size={25} />
      </S.TextInfo>

      {wsDataProcessed.map((sensor) => (
        <SensorInfo
          key={sensor.mac}
          applicationVersion={sensor.applicationVersion}
          dateAndTimeOfLastMsg={sensor.time}
          hardwareVersion={sensor.hardwareVersion}
          protocolVersion={sensor.protocolVersion}
          rssi={sensor.rssi}
          sensorTemperature={sensor.maxTemp}
          serialNumber={sensor.serialNumber}
          openModal={() =>
            handleOpenConfirmModal({
              mac: sensor.mac,
              codeDate: sensor.codeDate,
              adv: sensor.adv,
            })
          }
          mac={sensor.mac}
          codeDate={sensor.codeDate}
          adv={sensor.adv}
          voltage={sensor.voltage}
        />
      ))}
      <Root />
    </S.Wrapper>
  )
}
