import { useEffect, useState } from "react"

function getWindowDimensions(subHeight?: number) {
  const { innerWidth, innerHeight } = window

  //17px for some padding on page
  const width = innerWidth - 17

  //60px for appbar
  const height =
    subHeight !== undefined ? innerHeight - (subHeight + 60) : innerHeight - 60

  return {
    width,
    height,
  }
}

export default function useWindowDimensions(subHeight?: number) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(subHeight)
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
