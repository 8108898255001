import { LogoImg } from "../../styles/Navbar.styled"
import NewHedroLogo from "../../../../assets/logo_hedro.svg"
import { Link } from "react-router-dom"
import styled from "styled-components"

const LogoAPP = styled.div`
  text-align: center;
`

export const Logo = () => {
  return (
    <LogoAPP>
      <Link to='/'>
        <LogoImg src={NewHedroLogo} alt='hedro_logo' />
      </Link>
    </LogoAPP>
  )
}
