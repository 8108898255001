import { Text } from "../../../ui/Text"
import { SPageTitle } from "./styled"

type PageProps = {
  title: string
  subTitle?: string
}

export const PageTitle = ({ title, subTitle }: PageProps) => {
  return (
    <SPageTitle>
      <Text as='h4' className='m-l-10 page-title'>
        {title}
      </Text>
      <Text as='h6' className='m-l-10 page-sub-title'>
        {subTitle}
      </Text>
    </SPageTitle>
  )
}
