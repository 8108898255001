import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  box-sizing: border-box;

  border: 1px solid #cacaca;
  border-radius: 10px;

  margin: 10px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  box-sizing: border-box;

  padding: 10px;
`

export const TextAndIcon = styled.div<{
  $on?: boolean
}>`
  width: 130px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 20px;

  padding: 5px;

  gap: 5px;

  svg {
    filter: ${(props) => {
      if (props.$on === undefined) return "none"

      if (props.$on) {
        return "invert(73%) sepia(35%) saturate(2442%) hue-rotate(63deg) brightness(96%) contrast(75%);"
      } else {
        return "invert(40%) sepia(95%) saturate(3426%) hue-rotate(337deg) brightness(113%) contrast(102%);"
      }
    }};
  }
`

export const Text = styled.h2`
  font-size: small;
`

export const ResetButton = styled.button`
  border: none;
  border-radius: 5px;
  background: #ff7b7b21;
  color: #ff7b7b;
  text-transform: uppercase;
  padding: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  justify-self: center;

  &:hover {
    color: white;
    background: #ff7b7b;
  }
`

export const AlgorithmGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
`

export const DetailGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  flex-flow: row wrap;
  gap: 10px;
`
