/* eslint-disable array-callback-return */
import { getAccRaw } from "hdr-process-data"
import { useProcessApiData } from "./processApiData"
import {
  AnalyticConfigDTO,
  AnalyticConfigSensorDTO,
} from "../entities/analyticConfigDTO"
import { ArrayData } from "../entities/serviceAndMacDTO"
import { ApiDataDTO } from "../../../store/features/reports/reports.interfaces"
import { ProcessData } from "../entities/processDataWithMacDTO"
import { OrbitDataAuxDTO } from "../entities/OrbitarDataAux"
import { useServicesData } from "./processServicesData"

export const useDataAnalytics = () => {
  const { getData, getProcessedFFTData, getProcessedAccRawData } =
    useServicesData()

  const { getSensorDataFromApi } = useProcessApiData()

  const module = (vector: number[]): number => {
    let result = 0
    vector.map((value) => {
      result = value + result
    })
    return result / 3
  }

  const getMacs = (analyticsData: AnalyticConfigDTO): string[] => {
    const macs: string[] = []

    const sensors = analyticsData.sensors as AnalyticConfigSensorDTO
    const keys = Object.keys(sensors)

    keys.forEach((key) => {
      sensors[key].forEach((element) => {
        if (!macs.includes(element.mac)) macs.push(element.mac)
      })
    })

    return macs
  }

  const getMean = (accRawx: number[], accRawy: number[], accRawz: number[]) => {
    let sumx = 0,
      sumy = 0,
      sumz = 0
    let countx = 0,
      county = 0,
      countz = 0

    for (let i = 0; i < accRawx.length; i++) {
      if (!isNaN(accRawx[i])) {
        sumx += accRawx[i]
        countx++
      }

      if (!isNaN(accRawy[i])) {
        sumy += accRawy[i]
        county++
      }

      if (!isNaN(accRawz[i])) {
        sumz += accRawz[i]
        countz++
      }
    }

    const meanX = sumx / countx
    const meanY = sumy / county
    const meanZ = sumz / countz

    return { meanX, meanY, meanZ }
  }

  const getOrbitData = async (
    analyticsData: AnalyticConfigDTO,
    startDate: Date,
    endDate: Date
  ) => {
    const service = "ACCRAW"

    const macs = getMacs(analyticsData)

    const data = await getSensorDataFromApi(
      startDate ? startDate : null,
      endDate ? endDate : null,
      macs,
      service
    )

    const orbitaData = {} as OrbitDataAuxDTO

    data.forEach((msg: ApiDataDTO) => {
      const mac = msg.mac
      const resultAccRaw = getAccRaw(msg.raw, msg.time, {
        applicationVersion: msg.applicationVersion,
        protocolVersion: msg.protocolVersion,
      })

      if (resultAccRaw.isRight()) {
        const { accRaw, axis, xPlot, time } = resultAccRaw.value

        let accRawx: number[] = []
        let accRawy: number[] = []
        let accRawz: number[] = []
        let accRawModule: number[] = []

        for (let i = 0; i < xPlot.length; i++) {
          if (axis === "all") {
            accRawx.push(accRaw[0][i])
            accRawy.push(accRaw[1][i])
            accRawz.push(accRaw[2][i])
            accRawModule.push(
              module([accRaw[0][i], accRaw[1][i], accRaw[2][i]])
            )
          } else {
            if (axis === "x") {
              accRawx.push(accRaw[0][i])
            } else if (axis === "y") {
              accRawy.push(accRaw[0][i])
            } else if (axis === "z") {
              accRawz.push(accRaw[0][i])
            }
          }
        }

        if (!orbitaData[mac]) orbitaData[mac] = []

        const { meanX, meanY, meanZ } = getMean(accRawx, accRawy, accRawz)

        for (let i = 0; i < accRawx.length; i++) {
          if (!isNaN(accRawx[i])) {
            accRawx[i] = accRawx[i] - meanX
            accRawx[i] = accRawx[i] / 9.8
          }

          if (!isNaN(accRawy[i])) {
            accRawy[i] = accRawy[i] - meanY
            accRawy[i] = accRawy[i] / 9.8
          }

          if (!isNaN(accRawz[i])) {
            accRawz[i] = accRawz[i] - meanZ
            accRawz[i] = accRawz[i] / 9.8
          }
        }

        const modelData = {
          accRawx,
          accRawy,
          accRawz,
          accRawModule,
          xPlot,
          time,
        }

        orbitaData[mac].push(modelData)
      }
    })

    if (Object.keys(orbitaData).length === 0) return []
    else return orbitaData[macs[0]]
  }

  async function setLinearGraphData(
    analyticsData: AnalyticConfigDTO,
    startDate: Date,
    endDate: Date
  ): Promise<ArrayData[]> {
    const analyticID = analyticsData.id

    let newSeries: ArrayData[] = []
    newSeries = await getData(analyticsData, analyticID, startDate, endDate)
    return newSeries
  }

  async function setFFTProcessedData(
    analyticsData: AnalyticConfigDTO,
    startDate: Date,
    endDate: Date
  ): Promise<ProcessData[]> {
    const analyticID = analyticsData.id

    const processedData = await getProcessedFFTData(
      analyticsData,
      analyticID,
      startDate,
      endDate
    )
    return processedData
  }

  const setAccRawProcessedData = async (
    analyticsData: AnalyticConfigDTO,
    analyticID: number | undefined,
    startDate: Date,
    endDate: Date
  ): Promise<ProcessData[]> => {
    const processedData = await getProcessedAccRawData(
      analyticsData,
      analyticID,
      startDate,
      endDate
    )
    return processedData
  }

  return {
    setLinearGraphData,
    setFFTProcessedData,
    setAccRawProcessedData,
    getOrbitData,
  }
}
