import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { updateOpenDashboard } from "../../../../../store/features/dashboard/dashboard.slice"
import { openModal } from "../../../../../store/features/modal/modal.slice"

export const useDashDropdownController = () => {
  const userProfile = useAppSelector(
    (state) => state.persistedReducer.user.profile
  )
  const dashboards = useAppSelector((state) => state.persistedReducer.dashboard)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    dashboards.openDashboard?.name
  )

  const dispatch = useAppDispatch()

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (
    dashboardName: string,
    dashboardSelected: number
  ) => {
    if (!userProfile) {
      console.error("Erro ao salvar dados do usuário")
      return
    }

    if (dashboardName === selectedOption) return

    setSelectedOption(dashboardName)

    dispatch(
      updateOpenDashboard({
        dashboardIndex: dashboardSelected,
      })
    )

    toggleDropdown()
  }

  const handleAddDash = () => {
    setIsOpen(!isOpen)

    dispatch(
      openModal({
        modal: "DashboardCreate",
      })
    )
  }

  useEffect(() => {
    setSelectedOption(dashboards.openDashboard?.name)
  }, [dashboards.openDashboard])

  return {
    toggleDropdown,
    selectedOption,
    isOpen,
    dashboards,
    handleOptionClick,
    handleAddDash,
  }
}
