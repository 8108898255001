import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 999 !important;

  background: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`
