import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
`

export const SelectAnalyticContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;

  @media (max-width: 1230px) {
    display: flex;
    flex-direction: column;
  }
`

export const CardContainer = styled.label<{ selected: boolean }>`
  border: ${(props) =>
    props.selected
      ? "1.5px solid" + props.theme.colors.blue
      : "1px solid" + props.theme.colors.grey};
  box-shadow: ${(props) => props.selected && "0 0 12px rgba(0, 0, 0, 0.2)"};

  border-radius: 8px;
  padding: 10px 25px 25px 10px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1100px) {
    min-height: 20px;
    padding: 12px 12px 12px 12px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
`

export const InputCheckbox = styled.input`
  display: none;
`

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

export const CardContentTitle = styled.span`
  margin: auto;
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.bold};

  @media (max-width: 1100px) {
    font-size: 12px;
    font-weight: 500;
  }
`

export const CardContent = styled.div`
  height: 100%;
  text-align: center;

  h4 {
    margin-top: 10px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
  }

  @media (max-width: 1100px) {
    h4 {
      display: none;
    }
  }
`

export const ContentSensorOrEntity = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 25px;
`

export const SelectSensorOrEntity = styled.label`
  cursor: pointer;
  display: flex;
  gap: 5px;
  text-align: center;
  h3 {
    font-size: medium;
    font-weight: 500;
    margin: auto;
  }
  @media (max-width: 600px) {
    margin: auto;
  }
`

export const LabelTitleAnalytic = styled.div`
  margin-bottom: 10px;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`
