import { createSlice } from "@reduxjs/toolkit"
import {
  fetchSensorWithServices,
  fetchProvisionedSensorsRequest,
  reportRequest,
} from "./reports.api"
import { SensorFromStoreDTO } from "./reports.interfaces"
import { SensorWithServicesDTO } from "../../../utils/entities/sensors/SensorsWithServicesDTO"

interface InitialState {
  provisionedSensorsWithDataObject: SensorWithServicesDTO[]
  provisionedSensorsObject: SensorFromStoreDTO
  isLoadingSensors: boolean
  isLoadingData: boolean
}

const initialState: InitialState = {
  provisionedSensorsWithDataObject: [],
  provisionedSensorsObject: {},
  isLoadingSensors: false,
  isLoadingData: false,
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSensorWithServices.pending, (state) => {
      state.isLoadingSensors = true
    })
    builder.addCase(fetchSensorWithServices.fulfilled, (state, action) => {
      state.isLoadingSensors = false
      state.provisionedSensorsWithDataObject = action.payload
    })
    builder.addCase(fetchSensorWithServices.rejected, (state, action) => {
      state.isLoadingSensors = false
      console.error(action.error.message)
    })

    builder.addCase(fetchProvisionedSensorsRequest.pending, (state) => {
      state.isLoadingSensors = true
    })
    builder.addCase(
      fetchProvisionedSensorsRequest.fulfilled,
      (state, action) => {
        state.isLoadingSensors = false
        state.provisionedSensorsObject = action.payload
      }
    )
    builder.addCase(
      fetchProvisionedSensorsRequest.rejected,
      (state, action) => {
        state.isLoadingSensors = false
        console.error(action.error.message)
      }
    )

    builder.addCase(reportRequest.pending, (state) => {
      state.isLoadingData = true
    })
    builder.addCase(reportRequest.fulfilled, (state, action) => {
      state.isLoadingData = false
    })
    builder.addCase(reportRequest.rejected, (state, action) => {
      state.isLoadingData = false
      console.error(action.error.message)
    })
  },
})

export default reportsSlice.reducer
