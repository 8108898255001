import { FC } from "react"
import { HighchartsReact } from "highcharts-react-official"
import { useChartController } from "./controller/useChartController"

import Boost from "highcharts/modules/boost"
import Highcharts from "highcharts"

import { ChartData } from "./entities/ChartData"
import { FftType } from "../../store/features/dashboard/dashboard.interfaces"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/no-data-to-display")(Highcharts)

Boost(Highcharts)

interface ChartProps {
  chart: ChartData
  realtime: boolean
  fftType?: FftType
}

export const Chart: FC<ChartProps> = ({ chart, realtime, fftType }) => {
  const { chartRef, chartOptions } = useChartController({
    chart,
    realtime,
    fftType,
  })

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions()}
      ref={chartRef}
    />
  )
}
