import * as S from "./styles"

interface ConfigButtonProps {
  _onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  text: string
  type: "reconfigure" | "disable"
}

export const ConfigButton = ({ _onClick, text, type }: ConfigButtonProps) => {
  const typeReconfigure = {
    $background: "#daf0ff",
    $color: "#2F90D6",
    $border: "1px solid #2F90D6",
    $bordercolor: "#2F90D6",
  }

  const typeDisable = {
    $background: "#ff7b7b21",
    $color: "#ff7b7b",
    $border: "1px solid #ff7b7b",
    $bordercolor: "#ff7b7b",
  }

  const _type = type === "reconfigure" ? typeReconfigure : typeDisable

  return (
    <S.Container
      onClick={_onClick}
      $background={_type.$background}
      $color={_type.$color}
      $border={_type.$border}
      $bordercolor={_type.$bordercolor}
    >
      {text}
    </S.Container>
  )
}
