/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { MdKeyboardBackspace, MdClose } from "react-icons/md"
import Draggable from "react-draggable"
import Paper from "@mui/material/Paper"

import { ReactComponent as QuestionMarkIcon } from "../../../../assets/questionMark_icon.svg"
import { Tooltip } from "../Tooltip"

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    dialogWrapper: {
      padding: theme.spacing(2),
      position: "absolute",
      top: theme.spacing(5),
      overflowY: "visible",
    },
    center: {
      textAlign: "start",

      [`@media (min-width: ${props.breakpoint})`]: {
        textAlign: "center",
      },
    },
    dialogTitle: {
      paddingRight: "0px",
      paddingLeft: "0px",
      whiteSpace: "nowrap",
    },
    dialogContent: {
      overflowY: "visible",
      height: "100%",
    },
    title: {
      fontSize: "3.5vw",
      [`@media (min-width: ${props.breakpoint})`]: {
        fontSize: "25px",
      },
    },
    buttom: {
      width: "2px",
      [`@media (min-width: ${props.breakpoint})`]: {
        width: "5px",
      },
    },
  }))

const DraggablePaperComponent = (props: any) => {
  return (
    <Draggable
      enableUserSelectHack={false}
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const PaperComponent = (props: any) => {
  return <Paper {...props} />
}

interface Props {
  title: string
  children: any
  openModal: boolean
  handleModalClose: () => void
  back: boolean
  handleModalBack: () => void
  maxWidth: false | "sm" | "md" | "xs" | "lg" | "xl" | undefined
  showCloseButton: boolean
  heightAfterBreakpoint: string
  heightBeforeBreakpoint: string
  breakpoint: string
  showTitleTooltip: boolean
  textForTitleTooltip: string
}

const Modal = (props: any) => {
  const {
    title,
    children,
    openModal,
    handleModalClose,
    back = false,
    handleModalBack,
    maxWidth = "md",
    showCloseButton = true,
    heightAfterBreakpoint = "80vh",
    heightBeforeBreakpoint = "80vh",
    breakpoint = "650px",
    showTitleTooltip = false,
    textForTitleTooltip = "",
  }: Props = props

  const classes = useStyles({
    heightAfterBreakpoint,
    heightBeforeBreakpoint,
    breakpoint,
  })()

  const _newWayOfHandlingOnClose = (reason: string) => {
    if (reason === "backdropClick") {
      return false
    }

    if (reason === "escapeKeyDown") {
      return false
    }
  }

  return (
    <Dialog
      open={openModal}
      onClose={_newWayOfHandlingOnClose}
      fullWidth={true}
      maxWidth={maxWidth}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby='draggable-dialog-title'
      PaperComponent={
        window.innerWidth > 800 ? DraggablePaperComponent : PaperComponent
      }
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id='draggable-dialog-title'
        className={classes.dialogTitle}
      >
        <div style={{ display: "flex" }}>
          {back === true ? (
            <Button variant='contained' onClick={handleModalBack}>
              <MdKeyboardBackspace />
            </Button>
          ) : null}

          <Typography
            variant='h5'
            component='div'
            className={classes.center}
            style={{ flexGrow: 1, fontWeight: "bold" }}
          >
            {showTitleTooltip ? (
              <>
                {title}
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {textForTitleTooltip}
                    </span>
                  }
                  placement='bottom'
                >
                  <QuestionMarkIcon
                    width={"12px"}
                    style={{ marginLeft: "2px" }}
                  />
                </Tooltip>
              </>
            ) : (
              <span className={classes.title}>{title}</span>
            )}
          </Typography>

          {showCloseButton === true ? (
            <Button
              variant='contained'
              color='secondary'
              onClick={handleModalClose}
              className={classes.buttom}
            >
              <MdClose />
            </Button>
          ) : (
            <Button disabled />
          )}
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default Modal
