import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useAppSelector } from "../../../../../store/hooks"
import { SensorWithServicesDTO } from "../../../../../utils/entities/sensors/SensorsWithServicesDTO"
import { ReportsContext, ReportsContextProps } from "../../../context/context"
import { cleanAndPadSerialNumber } from "../../../../../utils/cleanAndPadSerialNumber"

export const SERVICES = [
  "ACCRAW",
  "FFT",
  "NTC",
  "RMMS",
  "RMS2",
  "TEMP",
  "TILT",
  "_4T20",
  "POT",
  "HEALTH",
]

interface PanelSensorController {
  allSensors: boolean
  filterSelected: number
  sensorSelected: string
  filteredSensors: SensorWithServicesDTO[]
  handleSelectAll: () => void
  handleFilterService: (e: ChangeEvent<HTMLSelectElement>) => void
  handleFilterSensor: (e: ChangeEvent<HTMLInputElement>) => void
  clearFilter: (e: React.MouseEvent<HTMLElement>) => void
  submitReport: () => Promise<void>
  startDate: Date
  setStartDate: Dispatch<SetStateAction<Date>>
  endDate: Date
  setEndDate: Dispatch<SetStateAction<Date>>
  disabledSubmitButton: boolean
}

export const useReportsPanelSensor = (): PanelSensorController => {
  const withDataFromStore = useAppSelector(
    (state) => state.persistedReducer.reports.provisionedSensorsWithDataObject
  )

  const {
    submitReport,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    disabledSubmitButton,
  } = useContext(ReportsContext) as ReportsContextProps

  const [filteredSensors, setFilteredSensors] = useState<
    SensorWithServicesDTO[]
  >([])

  useEffect(() => {
    setFilteredSensors(withDataFromStore)
  }, [withDataFromStore])

  const getExibitionName = (sensor: SensorWithServicesDTO): string => {
    return sensor.name
      ? sensor.name
      : `S${cleanAndPadSerialNumber(sensor.serialNumber, sensor.hardwareVersion)}`
  }

  const filter = (serviceIndex: number, sensorInput: string) => {
    const sensors: SensorWithServicesDTO[] = []

    const filteringBySensor = sensorInput !== ""

    for (let i = 0; i < withDataFromStore.length; i++) {
      const sensor = withDataFromStore[i]

      const exhibitionName = getExibitionName(sensor)

      const hasResultName =
        !filteringBySensor ||
        exhibitionName.toLowerCase().includes(sensorInput.toLowerCase())

      const filteringByService = serviceIndex !== 0

      if (hasResultName && !filteringByService) {
        sensors.push(sensor)
        continue
      }

      for (let j = 0; j < sensor.services.length; j++) {
        const { nameService } = sensor.services[j]

        const hasResultService =
          nameService.toUpperCase() === SERVICES[serviceIndex - 1]

        if (hasResultService && hasResultName) {
          sensors.push(sensor)
          break
        }
      }
    }

    setFilteredSensors(sensors)
  }

  const [filterSelected, setFilterSelected] = useState<number>(0)
  const [sensorSelected, setSensorSelected] = useState<string>("")

  const handleFilterService = (e: ChangeEvent<HTMLSelectElement>): void => {
    e.stopPropagation()
    setFilterSelected(Number(e.target.value))

    filter(Number(e.target.value), sensorSelected)
  }

  const handleFilterSensor = (e: ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation()
    setSensorSelected(e.target.value)

    filter(filterSelected, e.target.value)
  }

  const clearFilter = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setFilterSelected(0)
    setSensorSelected("")
    setFilteredSensors(withDataFromStore)
  }

  const [allSensors, setAllSensors] = useState<boolean>(false)

  const handleSelectAll = (): void => {
    setAllSensors(!allSensors)
  }

  return {
    allSensors,
    filterSelected,
    sensorSelected,
    filteredSensors,
    handleSelectAll,
    handleFilterService,
    handleFilterSensor,
    clearFilter,
    submitReport,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    disabledSubmitButton,
  }
}
