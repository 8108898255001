import { useContext, useEffect, useState } from "react"
import Fft3DChart from "../components/Charts/Fft3DChart/FftChart"
import FftChart from "../components/Charts/FftChart/FftChart"
import LineChart from "../components/Charts/Linechart"
import { HistoricalAnalysisContext } from "../context/modelContext"
import { useHourMeterChartController } from "./useHourMeterChart"
import OrbitChart from "../components/Charts/OrbitChart"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { HourmeterController } from "../entities/hourMeterDTO"
import { HourMeterChart } from "../components/Charts/HourMeterChart/HourMeterChart"
import { Loading } from "../../../components/Modal/components/Loading"
import { openModal } from "../../../store/features/modal/modal.slice"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { LastConfigDTO } from "../entities/lastConfigDTO"

export const useHistoricalAnalysis = () => {
  const {
    chartTypeSelect,
    setSelectorIndex,
    selectorIndex,
    selectorMaxIndex,
    isLoadingData,
    setDataToPlot,
    setFormGraphType,
    setSelectedServices,
    setStartDate,
    setEndDate,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica
  const dispatch = useAppDispatch()

  const lastConfig = useAppSelector(
    (state) => state.persistedReducer.historicalAnalysis.lastConfig
  )

  const parseDateStringToDate = (dateStringOrDate: string | Date): Date => {
    if (dateStringOrDate instanceof Date) {
      return dateStringOrDate
    }

    if (typeof dateStringOrDate !== "string") {
      return new Date()
    }

    // Remove 'Z' from the end of the string if it exists
    const dateString = dateStringOrDate.endsWith("Z")
      ? dateStringOrDate.slice(0, -1)
      : dateStringOrDate

    const date = new Date(dateString)

    return date
  }

  useEffect(() => {
    if (!lastConfig || lastConfig.formGraphType === 4) return
    updateChartWithLastConfig(lastConfig)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateChartWithLastConfig = (lastConfig: LastConfigDTO) => {
    setFormGraphType(lastConfig.formGraphType)
    handleSetChartTypeSelect(lastConfig.formGraphType)
    setDataToPlot(lastConfig.data)
    setSelectedServices(lastConfig.selectedServices)
    setEndDate(parseDateStringToDate(lastConfig.endDate))
    setStartDate(parseDateStringToDate(lastConfig.startDate))
  }

  const { graphicOptionsHourMeter, chartTypeToggle, ChangeChartTypeHourMeter } =
    useHourMeterChartController() as HourmeterController

  const processSelectorIndex = (value: number) => {
    if (selectorIndex + value < 0 || selectorIndex + value > selectorMaxIndex)
      return
    setSelectorIndex(selectorIndex + value)
  }

  const [fft3D, setFft3D] = useState<boolean>(false)
  const [chart, setChart] = useState(<LineChart />)

  useEffect(() => {
    if (isLoadingData) {
      setChart(
        <Loading loadingMessage='Carregando dados...'>
          <LoadingSpinner size={50} />
        </Loading>
      )
      return
    }

    handleSetChartTypeSelect(chartTypeSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTypeSelect, fft3D, isLoadingData])

  const handleSetChartTypeSelect = (value: number) => {
    switch (value) {
      case 2:
        if (fft3D === false) setChart(<FftChart />)
        else setChart(<Fft3DChart />)
        break

      case 3:
        setChart(<LineChart />)
        break

      case 4:
        setChart(<HourMeterChart />)
        break

      case 5:
        setChart(<OrbitChart />)
        break

      default:
        setChart(<LineChart />)
        break
    }
  }

  const handleOpenHistoricalAnalysisConfig = () => {
    dispatch(
      openModal({
        modal: "HistoricalAnalysisConfig",
      })
    )
  }

  return {
    chart,
    setFft3D,
    fft3D,
    ChangeChartTypeHourMeter,
    chartTypeToggle,
    graphicOptionsHourMeter,
    processSelectorIndex,
    handleOpenHistoricalAnalysisConfig,
  }
}
