import { useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { AccRaw, FFT, HDR_SERVICES_TYPE } from "hdr-process-data"
import {
  transformDataArrayInPoints,
  transformDataInPoints,
} from "../../../utils/analytic_data/transformDataArrayInPoints"
import {
  linechartOptions,
  lineChartTooltipFormater,
} from "../options/linechart"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { fetchData } from "../../../store/features/data/data.api"
import {
  ExtraConfig,
  FftType,
} from "../../../store/features/dashboard/dashboard.interfaces"
import { barChartTooltipFormater, barChartOptions } from "../options/barchart"
import theme from "../../../styles/theme"
import { ChartData, ChartType, Service } from "../entities/ChartData"
import { MeasureUnit, Serie } from "../entities/Serie"
import { Socket } from "socket.io-client"
import { HighchartsReactRefObject } from "highcharts-react-official"
import { Chart, Point, Series } from "highcharts"
import { Axis } from "../../../utils/entities/Axis"
import { SocketData } from "../../../store/features/socket/socket.interfaces"
import { processData } from "../../../store/features/data/data.utilities"
import { ServiceData } from "../../../store/features/data/data.interfaces"
import { SensorConfig } from "../../../store/features/sensors/sensors.interfaces"
import { AdlSendData } from "../../../entities/adl.types"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"

interface ChartsControllerProps {
  chart: ChartData
  realtime: boolean
  fftType?: FftType
}

export const useChartController = ({
  chart,
  realtime,
  fftType,
}: ChartsControllerProps) => {
  const dispatch = useAppDispatch()

  const chartRef = useRef<HighchartsReactRefObject>(null)

  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)

  const sensors = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const sensorsProv = useAppSelector(
    (state) => state.persistedReducer.sensors.prov
  )
  const translate = useAppTranslate()

  const getYAxisTitle = () => {
    const typesSet = new Set<HDR_SERVICES_TYPE>()
    chart.services.forEach((service) => {
      if (service.type) typesSet.add(service.type)
    })

    //if you have several units mixed
    const typesArray = Array.from(typesSet)
    if (typesArray.length !== 1) return "Valores"

    const singleType = typesArray[0]
    const service = HDR_SERVICES_TYPE[singleType]

    // if it's pot or 4t20 check localstorage for the unit of measurement
    if (service === "pot" || service === "_4t20") {
      const mac = chart.services[0].mac
      const extraConfigKey = `${mac} - ${HDR_SERVICES_TYPE[singleType]} - ExtraConfig`
      const extraConfig = localStorage.getItem(extraConfigKey)
      const objExtraConfig = extraConfig ? JSON.parse(extraConfig) : null
      if (!objExtraConfig) return translate.chart.yAxisTitle[singleType]
      return objExtraConfig.unitOfMeasurement
    }

    // if it's not pot or 4t20 return the default unit of measurement
    return translate.chart.yAxisTitle[singleType]
  }

  const getMeasureUnit = (
    type: HDR_SERVICES_TYPE,
    mac: string
  ): MeasureUnit => {
    switch (type) {
      case HDR_SERVICES_TYPE.health:
      case HDR_SERVICES_TYPE.temp:
      case HDR_SERVICES_TYPE.tempMMM:
      case HDR_SERVICES_TYPE.gps:
      case HDR_SERVICES_TYPE.ntc:
        return {
          y: "°C",
        }

      case HDR_SERVICES_TYPE.rms2:
        return {
          y: "m/s²",
        }
      case HDR_SERVICES_TYPE.fft:
        return {
          x: "Hz",
          y: "m/s²",
        }

      case HDR_SERVICES_TYPE.accRaw:
        return {
          x: "ms",
          y: "m/s²",
        }

      case HDR_SERVICES_TYPE.rmms:
        return {
          y: "mm/s",
        }

      case HDR_SERVICES_TYPE.tilt:
        return {
          y: "°",
        }

      case HDR_SERVICES_TYPE._4t20: {
        const extraConfig = localStorage.getItem(`${mac} - _4t20 - ExtraConfig`)
        const objEctg = JSON.parse(extraConfig!)
        console.log(objEctg)
        if (!objEctg) return { y: "" }
        return {
          y: objEctg.unitOfMeasurement,
        }
      }
      case HDR_SERVICES_TYPE.pot: {
        const extraConfig = localStorage.getItem(`${mac} - pot - ExtraConfig`)
        const objEctg = JSON.parse(extraConfig!)
        if (!objEctg) return { y: "" }
        return {
          y: objEctg.unitOfMeasurement,
        }
      }
    }
  }

  const chartOptions = () => {
    if (chart.type === ChartType.ADL) {
      return barChartOptions({
        loadingMessage: translate.chart.loadingMessage,
        noDataMessage: translate.chart.noDataMessage,
      })
    }

    const xAxisType = chart.type === 1 ? "datetime" : "linear"

    return linechartOptions({
      subtitle: translate.chart.subtitle,
      noDataMessage: translate.chart.noDataMessage,
      loadingMessage: translate.chart.loadingMessage,
      xAxisType: xAxisType,
      xAxisTitle: translate.chart.xAxisTitle[chart.type],
      yAxisTitle: getYAxisTitle(),
    })
  }

  const getSeriesName = ({
    sensors,
    moduleLabel,
    mac,
    serviceName = "",
    axis,
  }: {
    sensors: SensorConfig[]
    moduleLabel: string
    mac: string
    serviceName?: string
    axis?: string | undefined
  }): string => {
    const sensor = sensorsProv.find((e) => e.bleHdrMac === mac)

    if (!sensor || !sensor.sensor?.productionSerialNumber) return "Deleted"

    const { productionSerialNumber, hardwareVersion } = sensor.sensor

    if (sensor.name && sensor?.name?.trim().length !== 0) {
      if (!axis) return `${serviceName} ${sensor.name}`

      if (axis === "module")
        return `${serviceName}(${moduleLabel}) ${sensor.name}`

      return `${serviceName}(${axis.toUpperCase()}) ${sensor.name}`
    }

    if (!axis)
      return `${serviceName} S${cleanAndPadSerialNumber(productionSerialNumber, hardwareVersion)}`

    if (axis === "module")
      return `${serviceName}(${moduleLabel}) ${sensor?.name}`

    return `${serviceName}(${axis.toUpperCase()}) S${cleanAndPadSerialNumber(productionSerialNumber, hardwareVersion)}`
  }

  const getCategories = (services: Service[]): string[] => {
    return services.map((service) => {
      const configuredName = getSeriesName({
        mac: service.mac,
        serviceName: "",
        moduleLabel: translate.chart.moduleAxis,
        sensors,
      })

      if (!configuredName) return service.name

      return configuredName
    })
  }

  const configureChart = ({
    chartData,
    chart,
  }: {
    chartData: ChartData
    chart: Highcharts.Chart
  }): void => {
    if (chartData.type === ChartType.ADL) {
      const MIN = 0
      const MAX = 6

      if (chart.xAxis.length === 0) return
      if (chart.yAxis.length === 0) return

      chart.xAxis[0].setCategories(getCategories(chartData.services))
      chart.yAxis[0].setExtremes(MIN, MAX)

      const initialData = chartData.services.map((service) => {
        return {
          name: service.name,
          y: MIN,
          color: theme.colors.blue,
          id: service.mac,
        }
      })

      chart.addSeries({
        type: "column",
        showInLegend: false,
        data: initialData,
      })
    }

    if (chartData.type === ChartType.ADL) {
      chart.tooltip.update({
        formatter: barChartTooltipFormater,
      })
    } else {
      chart.tooltip.update({
        formatter: lineChartTooltipFormater,
      })
    }
  }

  const updateBar = ({
    points,
    name,
    slow,
    time,
  }: {
    points: Point[]
    name: string
    slow: number
    time: number
  }) => {
    const point = points.find((point) => point.name === name)

    if (!point) return

    point.update(
      {
        y: slow,
        custom: {
          time,
        },
      },
      false
    )
  }

  const verifySideBars = ({
    points,
    distance,
  }: {
    points: Point[]
    distance: number
  }) => {
    for (let i = 0; i < points.length; i++) {
      const bar = points[i]

      let haveActiveBarNext = false

      for (let j = i - distance; j < i; j++) {
        if (j < 0) continue

        const leftBar = points[j]

        if (bar.y === 0 || leftBar.y === 0) continue

        bar.update({ color: theme.colors.red }, false)

        leftBar.update({ color: theme.colors.red }, false)

        haveActiveBarNext = true
      }

      if (!haveActiveBarNext) {
        bar.update({ color: theme.colors.blue }, false)
      }
    }
  }

  const createADLSocketConnection = ({
    chart,
    serviceName,
    socket,
    companyId,
    mac,
  }: {
    chart: Highcharts.Chart
    serviceName: string
    mac: string
    companyId: number
    socket: Socket
  }) => {
    const event = `WS2CLIENT/ADL_DATA_POINTS/${companyId}/${mac}`

    const DISTANCE = 2

    socket.on(event, (data: AdlSendData) => {
      if (chart.series.length === 0) return

      const points = chart.series[0].data

      updateBar({
        name: serviceName,
        points,
        slow: data.points,
        time: data.time,
      })

      verifySideBars({
        points,
        distance: DISTANCE,
      })

      chart.redraw(false)
    })
  }

  const getProcessedData = async ({
    mac,
    period,
    type,
  }: {
    mac: string
    period: number
    type: HDR_SERVICES_TYPE
  }): Promise<ServiceData[]> => {
    let processedData: ServiceData[] = []

    await dispatch(
      fetchData({
        mac,
        period,
        serviceType: type,
      })
    )
      .unwrap()
      .then((data) => (processedData = data))
      .catch((e: Error) => console.error(e.message))

    return processedData
  }

  const getSeriesPoints = ({
    processedData,
    serviceType,
    axis,
    configs,
    mac,
  }: {
    processedData: ServiceData[]
    serviceType: HDR_SERVICES_TYPE
    axis?: Axis
    configs?: ExtraConfig
    mac: string
  }): number[][] => {
    const fftType = getFftTypeInStorage({
      chartId: chart.id,
      type: serviceType,
    })

    const graphicData = transformDataArrayInPoints({
      axis,
      configs,
      data: processedData,
      fftType,
      type: serviceType,
      mac: mac,
    })

    if (graphicData.isLeft()) {
      console.error(graphicData.value.message)
      return []
    }

    return graphicData.value
  }

  const getFftTypeInStorage = ({
    type,
    chartId,
  }: {
    type: HDR_SERVICES_TYPE
    chartId: number
  }): FftType | undefined => {
    if (type !== HDR_SERVICES_TYPE.fft) return undefined

    const key = `FFT-TYPE-${chartId}`

    const fftTypeInLocalStorage = localStorage.getItem(key)

    if (!fftTypeInLocalStorage) {
      console.error(`Invalid FFT type to chart ${chart.id}`)
      return undefined
    }

    return fftTypeInLocalStorage as FftType
  }

  const addSeriesToArray = ({
    series,
    service,
    axis,
    points,
    configs,
    date,
  }: {
    series: Serie[]
    service: Service
    points: number[][]
    axis?: Axis
    configs?: ExtraConfig
    date?: string
  }) => {
    const name = getSeriesName({
      mac: service.mac,
      serviceName: service.name,
      axis,
      moduleLabel: translate.chart.moduleAxis,
      sensors,
    })

    const measureUnit = getMeasureUnit(service.type!, service.mac)
    series.push({
      type: "line",
      name,
      customTooltip: {
        measureUnit,
        date: date ?? undefined,
      },
      data: points,
      mac: service.mac,
      serviceType: service.type,
      axis: axis,
      configs,
    })
  }

  const getTooltipDate = (
    data: ServiceData[],
    type: HDR_SERVICES_TYPE
  ): string | undefined => {
    if (type !== HDR_SERVICES_TYPE.fft && type !== HDR_SERVICES_TYPE.accRaw)
      return

    if (data.length === 0) return

    return (data as FFT[] | AccRaw[])[0].time
  }

  const getChartSeries = async ({ chartData }: { chartData: ChartData }) => {
    const series: Serie[] = []

    for (let index = 0; index < chartData.services.length; index++) {
      const service = chartData.services[index]

      if (!service.type) continue

      const processedData = await getProcessedData({
        mac: service.mac,
        period: chart.period,
        type: service.type,
      })

      if (service.axisData === undefined) {
        const points = getSeriesPoints({
          processedData,
          serviceType: service.type,
          mac: service.mac,
        })

        const date = getTooltipDate(processedData, service.type)

        addSeriesToArray({
          series,
          points,
          service,
          date,
        })

        continue
      }

      for (let index = 0; index < service.axisData.length; index++) {
        const { axis, configs } = service.axisData[index]

        const points = getSeriesPoints({
          processedData,
          serviceType: service.type,
          axis,
          configs,
          mac: service.mac,
        })

        const date = getTooltipDate(processedData, service.type)

        addSeriesToArray({
          series,
          points,
          service,
          axis,
          configs,
          date,
        })
      }
    }

    return series
  }

  const plotADLChart = ({
    chartData,
    chart,
  }: {
    chartData: ChartData
    chart: Chart
  }) => {
    if (!socket) return

    chart.hideLoading()

    for (let index = 0; index < chartData.services.length; index++) {
      const service = chartData.services[index]

      createADLSocketConnection({
        chart: chart,
        companyId: chartData.ws.companyId,
        mac: service.mac,
        serviceName: service.name,
        socket: socket,
      })
    }

    return
  }

  const removePointsOutPeriod = (series: Series, period: number) => {
    for (let index = 0; index < series.points.length; index++) {
      const point = series.points[index]

      const passedTimeInSeconds = (Date.now() - point.x) / 1000

      if (passedTimeInSeconds > period) {
        series.removePoint(index, false)
      } else {
        //Since the points are ordered by date,
        //if a point is within the time period, all data that follows will also be within it
        break
      }
    }
  }

  const createWebSocketConnection = ({
    chart,
    chartData,
    serie,
    index,
  }: {
    chart: Chart
    chartData: ChartData
    serie: Serie
    index: number
  }) => {
    const event = `WS2CLIENT/${chartData.ws.companyId}/${serie.mac}/${serie.serviceType}`

    socket?.on(event, (data: SocketData) => {
      if (
        !data.raw ||
        !data.time ||
        !data.serviceType ||
        !data.applicationVersion ||
        !data.protocolVersion
      )
        return

      const processedData = processData({
        raw: data.raw,
        serviceType: data.serviceType,
        time: data.time,
        applicationVersion: data.applicationVersion,
        protocolVersion: data.protocolVersion,
      })

      if (processedData.isLeft()) {
        console.error("Error on process realtime data!")
        console.error(processedData.value.message)
        return
      }

      let fftType: string | undefined | null = undefined
      const date = getTooltipDate([processedData.value], data.serviceType)

      if (data.serviceType === HDR_SERVICES_TYPE.fft) {
        const key = `FFT-TYPE-${chartData.id}`
        fftType = localStorage.getItem(key)

        if (
          fftType === null ||
          (fftType !== "Velocity" && fftType !== "Acceleration")
        ) {
          console.error("Error on FFT Type!")
          return
        }
      }

      const pointsResult = transformDataInPoints({
        data: processedData.value,
        serviceType: data.serviceType,
        axis: serie.axis,
        fftType: fftType,
        configs: serie.configs,
        mac: serie.mac as string,
      })

      const points = pointsResult.isLeft() ? [] : pointsResult.value

      const chartSerie = chart.series[index]

      removePointsOutPeriod(chartSerie, chartData.period)

      if (points.length !== 0 && chartSerie) {
        if (
          serie.serviceType === HDR_SERVICES_TYPE.accRaw ||
          serie.serviceType === HDR_SERVICES_TYPE.fft
        ) {
          chartSerie.update({
            type: "line",
            data: points,
            custom: {
              ...serie.customTooltip,
              date,
            },
          })
          return
        }

        for (let index = 0; index < points.length; index++) {
          const point = points[index]

          chartSerie.addPoint(point, false, false, false)
        }
      }

      chart.redraw(false)
    })
  }

  const plotChart = async ({
    chartData,
    chart,
    realtime,
  }: {
    chartData: ChartData
    chart: Chart
    realtime: boolean
  }) => {
    // Check if has lost chart ref
    if (Object.keys(chart).length === 0) return

    const series = await getChartSeries({
      chartData,
    })

    // Check if has lost chart ref
    if (Object.keys(chart).length === 0) return

    for (let index = 0; index < series.length; index++) {
      const serie = series[index]

      chart.addSeries(
        {
          type: serie.type,
          name: serie.name,
          data: serie.data,
          custom: serie.customTooltip,
        },
        false
      )

      if (realtime) {
        if (!serie.mac || !serie.serviceType) {
          console.error("Error on config WS")
          continue
        }

        createWebSocketConnection({
          chart,
          chartData,
          index,
          serie,
        })
      }
    }

    chart.hideLoading()

    chart.redraw(true)
  }

  const removeAllSeries = ({
    chartRef,
  }: {
    chartRef: HighchartsReactRefObject | null
  }) => {
    if (chartRef && chartRef.chart) {
      const length = chartRef.chart.series.length

      for (let index = 0; index < length; index++) {
        const serie = chartRef.chart.series[0]
        serie.remove(false)
      }
    }
  }

  const removeWebSocketConnections = ({
    socket,
    chartData,
  }: {
    socket: Socket | null
    chartData: ChartData
  }) => {
    if (!socket) return

    for (let i = 0; i < chartData.services.length; i++) {
      const service = chartData.services[i]

      if (chartData.type === ChartType.ADL) {
        socket.off(
          `WS2CLIENT/ADL_DATA_POINTS/${chartData.ws.companyId}/${service.mac}`
        )
        continue
      }

      if (!service.type) continue

      socket.off(
        `WS2CLIENT/${chartData.ws.companyId}/${service.mac}/${service.type}`
      )
    }
  }

  useEffect(() => {
    if (!chartRef) return
    if (!chartRef.current) return
    if (!chartRef.current.chart) return

    let isCancelled = false

    const createChart = async () => {
      if (!chartRef) return
      if (!chartRef.current) return
      if (!chartRef.current.chart) return

      configureChart({
        chartData: chart,
        chart: chartRef.current.chart,
      })

      if (chart.type === ChartType.ADL) {
        plotADLChart({ chart: chartRef.current.chart, chartData: chart })
      } else {
        if (isCancelled) return

        await plotChart({
          chart: chartRef.current.chart,
          chartData: chart,
          realtime,
        })
      }
    }

    createChart()

    return () => {
      isCancelled = true

      // eslint-disable-next-line react-hooks/exhaustive-deps
      removeAllSeries({ chartRef: chartRef.current })

      if (realtime) {
        removeWebSocketConnections({ socket, chartData: chart })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, fftType])

  return { chartRef, chartOptions }
}
