import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
`

export const Main = styled.main`
  font-size: 14px;

  strong {
    font-size: 16px;
    letter-spacing: 1.15px;
    font-weight: bold;
  }
`

export const HeaderPage = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 100px;
  background: #fff;

  @media (max-width: 600px) {
    height: 150px;
  }
`
export const BodyPage = styled.div`
  height: 70%;
  width: 100%;
`

export const ReportTypes = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px;
  background: #fff;
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`

export const NotReports = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    color: silver;
    font-size: 50px;
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 30px;
      text-align: center;
    }
  }
`

export const ReportLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fefefe;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: black;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
