import styled from "styled-components"

export const Container = styled.div`
  margin: 10px;

  h1 {
    margin-bottom: 10px;
  }

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-around;
  flex-direction: column;

  margin-bottom: 10px;
`

export const Buttons = styled.div`
  width: 100%;

  margin-left: 20px;
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const ButtonRed = styled.button`
  width: 118px;
  height: 34px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #ff0000;
`

export const ButtonGreen = styled.button`
  width: 118px;
  height: 34px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #3cd62f;
`

export const ItemMultiLines = styled.span`
  max-lines: 3;
  word-break: break-all;
`
