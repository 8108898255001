import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MessageContainer = styled.div`
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const SelectWithTooltip = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: help;
  font-size: 20px;
`
