import styled from "styled-components"

export const Container = styled.div`
  margin: 20px;
  text-align: center;
  font-family: arial, sans-serif;
  @media (max-width: 1250px) {
    margin: 20px;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

export const Message = styled.h2`
  font-size: large;
  font-weight: 500;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
`

export const ModalTitle = styled.h2`
  font-size: large;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.red};
`
