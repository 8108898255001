import styled from "styled-components"

export const LoadingContainer = styled.form`
  height: 100%;
  width: 100%;

  h1 {
    padding-top: 5.5rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const ContainerWraper = styled.form`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  button {
    margin-top: 8px;
  }
`

export const Title = styled.div`
  width: 400px;

  margin-bottom: 10px;

  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
`
