export const palette = {
  primary: {
    lightest: "#FEEAE6",
    lighter: "#FEE1DB",
    main: "#E25D3F",
    darker: "#CF4E31",
    darkest: "#AA442D",
  },
  secondary: {
    main: "#E25D3F",
  },
  sucess: {
    lightest: "#F6FEF9",
    lighter: "#ECFDF3",
    main: "#027A48",
    darker: "#05603A",
    darkest: "#054F31",
  },
  info: {
    lightest: "#F5FAFF",
    lighter: "#EFF8FF",
    main: "#175CD3",
    darker: "#1849A9",
    darkest: "#194185",
  },
  alert: {
    lightest: "#FEFDF0",
    lighter: "#FEFBE8",
    main: "#EAAA08",
    darker: "#D28A04",
    darkest: "#B97A04",
  },
  error: {
    lightest: "#FFFBFA",
    lighter: "#FEE4E2",
    main: "#D92D20",
    darker: "#B42318",
    darkest: "#7A271A",
  },
  gray: {
    _50: "#FCFCFD",
    _100: "#F3F4F6",
    _200: "#EFF1F5",
    _300: "#E9ECF1",
    _400: "#D0D5DD",
    _500: "#98A2B3",
    _600: "#3C424C",
    _700: "#282D34",
    _800: "#171A21",
  },
  black: "#000",
  white: "#FFF",
  divider: "",
  text: {
    primary: "#FFF",
    secondary: "",
    disabled: "",
  },
}
