/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyledButton, TextInsideBtn } from "./styles"

import CircularProgress from "@material-ui/core/CircularProgress"
import { MdClose } from "react-icons/md"

export const GetButton = ({
  width = "250px",
  text = "",
  background = "#8d8d8f", // '#3AA508',
  padding = "6px",
  marginBottom = "10px",
  fontSize = "14px",
  onClickFn = (e: any, name: any) => console.log(),
  loading = false,
  disabled = false,
  closebtn = false,
  onCloseClickFn = (e: any, name: any) => console.log(),
  name = "",
}: {
  width?: string
  text?: string
  background?: string // '#3AA508';
  padding?: string
  marginBottom?: string
  fontSize?: string
  onClickFn?: any
  loading?: boolean
  disabled?: boolean
  closebtn?: boolean
  onCloseClickFn?: any
  name?: string
}) => {
  return (
    <StyledButton
      width={width}
      background={background}
      padding={padding}
      marginBottom={marginBottom}
      fontSize={fontSize}
      onClick={(e) => onClickFn(e, name)}
      disabled={disabled}
    >
      <TextInsideBtn>{text}</TextInsideBtn>
      {loading && (
        <CircularProgress
          size={20}
          style={{ marginLeft: "10px", color: "white" }}
        />
      )}
      {closebtn && (
        <MdClose size={24} onClick={(e) => onCloseClickFn(e, name)} />
      )}
    </StyledButton>
  )
}
