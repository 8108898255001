import { createAsyncThunk } from "@reduxjs/toolkit"
import { Product } from "./products.interfaces"
import { apiCall } from "../../../utils/axios"
import { isAxiosError } from "axios"

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (): Promise<Product[]> => {
    const api = apiCall()

    const URL = process.env.REACT_APP_API_PRODUCTS

    if (!URL) throw Error("Erro ao buscar produtos!")

    try {
      const response = await api.get<Product[]>(URL)
      return response.data
    } catch (error) {
      if (!isAxiosError(error)) throw Error("Erro ao buscar produtos!")

      if (error.response?.status === 400)
        throw Error("Erro ao buscar produtos!")

      if (error.response?.status === 401)
        throw Error("Você não tem permissão para completar essa ação!")

      if (error.response?.status === 404)
        throw Error("O recurso não foi encontrado!")

      throw Error("Erro ao buscar produtos!")
    }
  }
)
