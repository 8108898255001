/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import styled from "styled-components"

const SContainer = styled.div`
  padding: 32px 24px 32px 24px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`

type ContainerProps = {
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return <SContainer>{children}</SContainer>
}

export default Container
