import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addDays } from "date-fns"
import IconButton from "../IconButton"
import {
  Header,
  Collectors,
  DatesPicker,
  Selects,
  SelectButton,
  ClearFilterBTN,
  Filters,
  SearchInput,
} from "./styles"
import { SERVICES, useReportsPanelSensor } from "./hooks/useReportsPanelSensor"
import { SensorsTable } from "../SensorsTable"
import { Select } from "../Select"

const ReportPanelSensor = () => {
  const {
    allSensors,
    filterSelected,
    sensorSelected,
    filteredSensors,
    handleSelectAll,
    handleFilterSensor,
    handleFilterService,
    clearFilter,
    submitReport,
    setStartDate,
    setEndDate,
    disabledSubmitButton,
    startDate,
    endDate,
  } = useReportsPanelSensor()

  return (
    <Header>
      <Collectors>
        <Selects>
          <SelectButton
            selected={allSensors}
            type='button'
            onClick={handleSelectAll}
          >
            Todos
          </SelectButton>

          <Filters>
            <SearchInput
              placeholder='Buscar por sensor'
              value={sensorSelected}
              onChange={handleFilterSensor}
            />
            <Select
              values={SERVICES}
              placeholder='Filtrar por serviço'
              value={filterSelected}
              onChange={handleFilterService}
            />
            <ClearFilterBTN onClick={clearFilter}>Limpar Filtro</ClearFilterBTN>
          </Filters>

          <DatesPicker>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date!)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat='dd/MM/yyyy'
              previousYearButtonLabel='Previous Year'
            />
            <span> até </span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date!)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={addDays(startDate, 30)}
              dateFormat='dd/MM/yyyy'
            />
          </DatesPicker>

          <IconButton
            onClick={() => submitReport()}
            disabled={disabledSubmitButton}
          />
        </Selects>

        <SensorsTable
          filteredSensors={filteredSensors}
          selectAll={allSensors}
        />
      </Collectors>
    </Header>
  )
}
export default ReportPanelSensor
