import styled from "styled-components"

export const SPageTitle = styled.div`
  .page-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 265px;
  }
  .page-sub-title {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
