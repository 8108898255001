import * as S from "./styles"
import { PiCircleNotch } from "react-icons/pi"

interface LoadingSpinnerProps {
  size?: number
}

export const LoadingSpinner = ({
  size = 60,
}: LoadingSpinnerProps): JSX.Element => {
  return (
    <S.Container size={size}>
      <S.RotateAnimation>
        <PiCircleNotch size={size} />
      </S.RotateAnimation>
    </S.Container>
  )
}
