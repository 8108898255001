/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container, ContainerBtn } from "./styles"
import { BsPersonPlusFill } from "react-icons/bs"
import { SearchFilter } from "../SearchFilter"
import useManagerUser from "../../controllers/useManagerUser"
import { FormButton } from "../FormButton"
import { UserTable } from "../UserTable"

interface TableUsersProps {
  openFormFcn: () => void
  handleOpenFormEditUser: (user: any) => void
}

const TableUsers = ({
  openFormFcn,
  handleOpenFormEditUser,
}: TableUsersProps) => {
  const { filteredUsers } = useManagerUser()

  return (
    <Container>
      <h1>Tabela de Usuários</h1>
      <ContainerBtn>
        <FormButton
          callBack={() => openFormFcn()}
          text='Novo Usuário'
          $width='180px'
          $background='#3CD62F'
        >
          <BsPersonPlusFill
            size={17}
            color='white'
            style={{ color: "white" }}
          />
        </FormButton>
        <SearchFilter />
      </ContainerBtn>
      <UserTable
        filteredUsers={filteredUsers}
        handleOpenFormEditUser={handleOpenFormEditUser}
      />
    </Container>
  )
}

export default TableUsers
