import { useInstallButtonController } from "./controllers/useInstallButtonController"
import * as S from "./styles"

interface InstallButtonProps {
  onClick: () => void
  /**
   * available: sensor available for installation
   * installed: installation completed
   * installing: sensor being installed
   * error: sensor installation error
   * blocked: sensor installation blocked. Some other sensor being installed
   * provisioned: sensor already has a provisioning
   */
  status:
    | "available"
    | "installed"
    | "installing"
    | "error"
    | "blocked"
    | "provisioned"
    | "configure"
    | "configuring"
    | "configured"
    | "disable"
    | "disabling"
    | "disabled"
    | "reset"
    | "resetting"
    | "reseted"
}

export const InstallButton = ({
  onClick,
  status = "blocked",
}: InstallButtonProps) => {
  const { returnStatus } = useInstallButtonController()

  const { title, background, disabled, icon, color, letterSpacing } =
    returnStatus(status)

  return (
    <S.InstallBTN
      $background={background}
      title={title}
      color={color}
      data-testid='install-button'
      disabled={disabled}
      onClick={onClick}
    >
      <S.TitleAndIcon letterSpacing={letterSpacing}>
        <p>{title}</p>
        {icon}
      </S.TitleAndIcon>
    </S.InstallBTN>
  )
}
