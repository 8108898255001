/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiCall } from "../../../utils/axios"

export class CreateAlarmUsecase {
  async create(sensorsObj: any) {
    const api = apiCall()
    const response = await api.post(
      `${process.env.REACT_APP_API_ALARMS}`,
      sensorsObj
    )

    if (response.status === 201) {
      return true
    }

    return false
  }
}
