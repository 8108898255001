import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import ptBR from "./languages/pt.json"
import enUS from "./languages/en.json"
import esLA from "./languages/spa.json"

import { initReactI18next } from "react-i18next"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: ["translation"],
    fallbackLng: "pt-BR",
    interpolation: {
      escapeValue: false,
    },
    ns: ["translation"],
    resources: {
      "pt-BR": ptBR,
      "en-US": enUS,
      "es-LA": esLA,
    },
  })

export default i18n
