import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
`

export const Main = styled.main`
  font-size: 14px;
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  padding: 0px 0 10px 0;

  strong {
    font-size: 32px;
    letter-spacing: 1.15px;
    font-weight: bold;
  }
  @media (max-width: 736px) {
    padding: 60px 0 10px 0;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  row-gap: 5px;
`

export const UserSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1%;
  margin-bottom: 1%;
`

export const UserSelectionText = styled.div``

export const UserSelectionDropDownAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

export const UserSelectionDropdown = styled.div`
  display: flex;
  width: 100px;
  height: 23px;
  position: relative;
  justify-content: center;
  border: none;
  border-bottom: 1px solid grey;
`

export const UserSelectionDropDownText = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserSelectionDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: blue;
  position: absolute;
  top: 23px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

export const UserSelectionDropdownListItem = styled.button<{ index: number }>`
  background: ${(props) => (props.index % 2 === 0 ? "#f0ebeb" : "#d8d3d3")};
  border: none;
  cursor: pointer;
  padding: 2%;
  border-radius: 0px !important;

  &:hover {
    background: #9ffecb;
  }
`

export const ClearFilterBTN = styled.button`
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #2196f3;
  padding: 5px;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #1273c1;
  }
`
export const CenterLoading = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`
