import { useContext, useEffect, useRef, useState } from "react"
import { linechart } from "../components/Charts/ChartsType/linechart"
import { HistoricalAnalysisContext } from "../context/modelContext"
import HighchartsReact from "highcharts-react-official"
import {
  AnnotationMockPointOptionsObject,
  AnnotationsOptions,
  Point,
  PointLabelObject,
} from "highcharts"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { FftDTO } from "../entities/services/fftDTO"
import { AccrawDTO } from "../entities/services/accraw"
import { AccRawAllAxisDTO } from "../entities/accRawAllAxisDTO"
import { DataPlotDTO } from "../entities/dataSeriesDTO"
import { FftAllAxisDataPlotDTO } from "../entities/fftAllAxisDTO"
import { formatedDate } from "../../../utils/date"

export const useLineChartController = () => {
  const {
    dataToPlot,
    formGraphType,
    sensors,
    selectorIndex,
    setSelectorIndex,
    setSelectorMaxIndex,
    setAxisFft,
    fftAccOrVeloc,
    setThresholdOffHourMeter,
    setThresholdOperingHourMeter,
    initialThresholdOperating,
    initialThresholdOff,
    setSelectorText,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica

  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const [fftData, setFftData] = useState<FftAllAxisDataPlotDTO[] | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  const [graphicOptions]: any = useState({
    ...linechart(),
  })

  let setTimeOutOff: string | number | NodeJS.Timeout | undefined
  let setTimeOutOpering: string | number | NodeJS.Timeout | undefined

  const secundsDragDrop = 1300

  function funSetTimeOutOff(y: number) {
    setTimeOutOff = setTimeout(function () {
      if (initialThresholdOff !== y) {
        setThresholdOffHourMeter(y)
      }
    }, secundsDragDrop)
  }

  function funSetTimeOutOpering(y: number) {
    setTimeOutOpering = setTimeout(function () {
      if (initialThresholdOperating !== y) {
        setThresholdOperingHourMeter(y)
      }
    }, secundsDragDrop)
  }

  function stopSetTimeOutOpering() {
    clearTimeout(setTimeOutOpering)
  }

  function stopSetTimeOutOff() {
    clearTimeout(setTimeOutOff)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeThresholdOff = (point: AnnotationMockPointOptionsObject) => {
    stopSetTimeOutOff()
    funSetTimeOutOff(point.y)
    return point.y
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeThresholdOpering = (point: AnnotationMockPointOptionsObject) => {
    stopSetTimeOutOpering()
    funSetTimeOutOpering(point.y)
    return point.y
  }

  useEffect(() => {
    if (!dataToPlot || dataToPlot.length === 0) return
    // if (chartTypeSelect !== 1) return;
    if (chartRef.current === null) return
    while (chartRef.current.chart.series.length) {
      chartRef.current.chart.series[0].remove()
    }

    //redraw no chart
    chartRef.current.chart.redraw()

    if ([1, 4].includes(formGraphType)) {
      let responseDataSeries: DataPlotDTO[] = []
      let data: number[][] = []
      if (formGraphType === 1) {
        const dataToPlotType1 = dataToPlot as DataPlotDTO[]
        let responseDataSeries: DataPlotDTO[] = []
        dataToPlotType1.forEach((item) => {
          let data: number[][] = []
          item.data.data.forEach((dataInfo) => {
            data.push(dataInfo)
          })
          let auxDataPlot: DataPlotDTO = {
            sensorMac: item.sensorMac,
            data: {
              type: "line",
              name: item.data.name,
              data: data,
            },
          }
          responseDataSeries.push(auxDataPlot)
        })

        // eslint-disable-next-line array-callback-return
        responseDataSeries.map((series: DataPlotDTO) => {
          chartRef.current?.chart.addSeries(
            { data: series.data.data, name: series.data.name, type: "line" },
            true,
            true
          )
        })

        chartRef.current.chart.update({
          tooltip: {
            formatter: function (this: PointLabelObject): string {
              return (
                this.series.name +
                " | " +
                formatedDate(this.x as number) +
                "<br/>" +
                "<b>" +
                (this.y as number).toFixed(2) +
                "<br/>"
              )
            },
          },
        })
      } else {
        const dataToPlotType4 = dataToPlot[3] as DataPlotDTO
        // eslint-disable-next-line array-callback-return
        dataToPlotType4.data.data.map((dataInfo) => {
          data.push(dataInfo)
        })
        let auxDataPlot: DataPlotDTO = {
          sensorMac: dataToPlotType4.sensorMac,
          data: {
            type: "line",
            name: dataToPlotType4?.data?.name?.replace(
              / - rms2VectorModule$/,
              ""
            ),
            data: data,
          },
        }
        responseDataSeries.push(auxDataPlot)

        chartRef.current.chart.update({
          tooltip: {
            formatter: function (this: PointLabelObject): string {
              return (
                formatedDate(this.x as number) +
                " : <b>" +
                (this.y as number).toFixed(2) +
                "<br/>"
              )
            },
          },
          stockTools: {
            gui: {
              enabled: false,
            },
          },
        })

        // eslint-disable-next-line array-callback-return
        responseDataSeries.map((series: DataPlotDTO) => {
          chartRef.current?.chart.addSeries(
            { data: series.data.data, name: series.data.name, type: "line" },
            true,
            true
          )
        })
      }
    } else if (formGraphType === 2) {
      processFftDataToPlot(dataToPlot as FftDTO[])
    } else if (formGraphType === 3) {
      processAccRawDataToPlot(dataToPlot as AccrawDTO[])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPlot, fftAccOrVeloc])

  const _getNameSensor = (
    sensorName: string | null | undefined,
    productionSerialNumber: string
  ) => {
    if (sensorName == null || sensorName === "") {
      return productionSerialNumber.slice(-4)
    } else {
      return sensorName
    }
  }

  const processFftDataToPlot = (dataToPlot: FftDTO[]) => {
    const indexSensor = sensors.findIndex(
      (element) => element.bleHdrMac === dataToPlot[0].mac
    )

    const nameSensor = sensors[indexSensor]?.name

    let dataPlot: FftAllAxisDataPlotDTO[] = []

    for (let index = 0; index < dataToPlot.length; index++) {
      let fftToPlot = dataToPlot[index]

      let auxDataPlot: FftAllAxisDataPlotDTO = {
        time: fftToPlot.time,
        sensorMac: fftToPlot.mac,
        series: [],
      }

      let fftData: number[][] = []

      if (fftAccOrVeloc === "acc") {
        fftData = fftToPlot.fft
      } else {
        fftData = fftToPlot.fftVeloc
      }

      // eslint-disable-next-line array-callback-return
      fftData.map((data, axis) => {
        let name = ""
        let auxdata: number[][] = []

        data.forEach((item, i) => {
          auxdata.push([fftToPlot.freqArray[i], item])
        })
        auxdata.splice(0, 1)
        if (fftToPlot.axis !== "all") {
          name = `${nameSensor}-${fftToPlot.axis}`
          setAxisFft(fftToPlot.axis)
        } else {
          setAxisFft("all")
          switch (axis) {
            case 0: {
              name = `${nameSensor}-X`
              break
            }
            case 1: {
              name = `${nameSensor}-Y`
              break
            }
            case 2: {
              name = `${nameSensor}-Z`
              break
            }
            default: {
              break
            }
          }
        }
        auxDataPlot.series.push({
          name,
          data: auxdata,
        })
      })
      dataPlot.push(auxDataPlot)
    }
    // eslint-disable-next-line array-callback-return
    dataPlot[0].series.map((series) => {
      chartRef.current?.chart.addSeries(
        { data: series.data, name: series.name, type: "line" },
        true,
        true
      )
    })

    setFftData(dataPlot)
    setSelectorMaxIndex(dataPlot.length - 1)

    if (selectorIndex < dataPlot.length)
      setSelectorText(formatedDate(dataPlot[selectorIndex].time))
    else {
      setSelectorText(formatedDate(dataPlot[0].time))
      setSelectorIndex(0)
    }
  }

  const processAccRawDataToPlot = (dataToPlot: AccrawDTO[]) => {
    const sensorsKeys = sensors
    const indexSensor = sensorsKeys.findIndex(
      (element) => element.bleHdrMac === dataToPlot[0].mac
    )

    const nameSensor = _getNameSensor(
      sensors[indexSensor].name,
      sensors[indexSensor].sensor.productionSerialNumber
    )
    let newSeries: AccRawAllAxisDTO[] = []

    for (let index = 0; index < dataToPlot.length; index++) {
      const accRawToPlot = dataToPlot[index]

      let auxDataPlot: AccRawAllAxisDTO = {
        time: accRawToPlot.time,
        sensorMac: accRawToPlot.mac,
        series: [],
      }

      accRawToPlot.accRaw.forEach((data, axis) => {
        let name = ""
        let auxData: number[][] = []

        data.forEach((item, i) => {
          auxData.push([accRawToPlot.xPlot[i], item])
        })

        auxData[1].shift()

        if (accRawToPlot.axis !== "all") {
          name = `${nameSensor}-${accRawToPlot.axis}`
          setAxisFft(accRawToPlot.axis)
        } else {
          setAxisFft("all")

          switch (axis) {
            case 0: {
              name = `${nameSensor}-X`
              break
            }
            case 1: {
              name = `${nameSensor}-Y`
              break
            }
            case 2: {
              name = `${nameSensor}-Z`
              break
            }
            default: {
              break
            }
          }
        }

        auxDataPlot.series.push({
          name,
          data: auxData,
        })
      })
      newSeries.push(auxDataPlot)
    }

    newSeries[0].series.forEach((series) => {
      chartRef.current?.chart.addSeries(
        { data: series.data, name: series.name, type: "line" },
        true,
        true
      )
    })
    setFftData(newSeries)

    setSelectorText(formatedDate(newSeries[0].time))

    setSelectorIndex(0)
    setSelectorMaxIndex(newSeries.length - 1)
  }

  useEffect(() => {
    if (!fftData) return
    if (chartRef.current === null) return

    while (chartRef.current.chart.series.length) {
      chartRef.current.chart.series[0].remove()
    }

    setSelectorText(formatedDate(fftData[selectorIndex].time))

    // eslint-disable-next-line array-callback-return
    fftData[selectorIndex].series.map((series) => {
      chartRef.current?.chart.addSeries(
        { data: series.data, name: series.name, type: "line" },
        true,
        true
      )
    })
  }, [fftData, selectorIndex, setSelectorText])

  useEffect(() => {
    if (formGraphType !== 4) return
    if (!dataToPlot) return
    if (chartRef.current === null) return
    if (!chartRef.current.chart.options.annotations) return
    // @ts-ignore
    chartRef.current.chart.removeAnnotation(1)
    // @ts-ignore
    chartRef.current.chart.removeAnnotation(2)

    let annotations: AnnotationsOptions[] = [
      {
        id: 1,
        draggable: "y",
        shapes: [
          {
            stroke: "red",
            strokeWidth: 3,
            type: "path",

            points: [
              {
                x: 0,
                y: initialThresholdOff,
                yAxis: 0,
                xAxis: 4,
              },
              {
                y: initialThresholdOff,
                x: 10000,
                yAxis: 0,
                xAxis: 4,
              },
            ],
          },
        ],
        labels: [
          {
            style: {
              fontSize: "10px",
            },
            point: {
              x: 3,
              y: initialThresholdOff,
              yAxis: 0,
              xAxis: 4,
            },
            formatter: function (this: Point): string {
              if (
                this.series.chart.options.annotations?.[0].shapes?.[0]
                  .points?.[0]
              ) {
                let pointY = changeThresholdOff(
                  this.series.chart.options.annotations[0].shapes[0]
                    .points[0] as AnnotationMockPointOptionsObject
                ).toFixed(2)
                return `${pointY}`
              }
              return "NULL"
            },
          },
        ],
      },
      {
        draggable: "y",
        id: 2,
        shapes: [
          {
            stroke: "green",
            strokeWidth: 3,
            type: "path",
            points: [
              {
                x: 0,
                y: initialThresholdOperating,
                yAxis: 0,
                xAxis: 4,
              },
              {
                y: initialThresholdOperating,
                x: 10000,
                yAxis: 0,
                xAxis: 4,
              },
            ],
          },
        ],
        labels: [
          {
            style: {
              fontSize: "10px",
            },
            point: {
              x: 3,
              y: initialThresholdOperating,
              yAxis: 0,
              xAxis: 4,
            },
            shape: "rect",

            formatter: function (this: Point): string {
              if (
                this.series.chart.options.annotations?.[1].shapes?.[0]
                  .points?.[0]
              ) {
                return changeThresholdOpering(
                  this.series.chart.options.annotations[1].shapes[0]
                    .points[0] as AnnotationMockPointOptionsObject
                )
                  .toFixed(2)
                  .toString()
              }
              return "NULL"
            },
          },
        ],
      },
    ]
    // eslint-disable-next-line array-callback-return
    annotations.map((annotation: AnnotationsOptions) => {
      // @ts-ignore
      chartRef.current?.chart.addAnnotation(annotation)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialThresholdOperating])

  return {
    chartRef,
    graphicOptions,
  }
}
