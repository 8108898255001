import { breakpoints } from "../../styles/theme/breakpoints"

export const media = {
  /**
   * small devices (portrait phones, less than 600px)
   */
  small: `only screen and (min-width: ${breakpoints.sm})`,
  /**
   * Medium devices (landscape phones, 768px and up)
   */
  medium: `only screen and (min-width: ${breakpoints.md})`,
  /**
   * Large devices (tablets, 992px and up)
   */
  large: `only screen and (min-width: ${breakpoints.lg})`,
  /*
   * Extra large devices (large laptops and desktops, 1200px and up)
   */
  extraLarge: `only screen and (min-width: ${breakpoints.xl})`,
}
