import { DataPlotDTO } from "../../../../../entities/dataSeriesDTO"
import { GeneralDataDTO } from "../../../../../entities/generalData"
import {
  HourMeterTable,
  HourMeterTableInfo,
  HourMeterSection,
  Wrapper,
  Title,
} from "./styles"

interface HourMeterTableComponentProps {
  thresholdOffHourMeter: number
  thresholdOperingHourMeter: number
  startDate: Date
  endDate: Date
  dataToPlot: DataPlotDTO[]
  generalData: React.MutableRefObject<GeneralDataDTO>
}

export const HourMeterTableComponent = ({
  thresholdOffHourMeter,
  thresholdOperingHourMeter,
  startDate,
  endDate,
  dataToPlot,
  generalData,
}: HourMeterTableComponentProps) => {
  // Função para formatar uma data no formato 'DD/MM/AA'
  const formatDate = (date: Date): string => {
    const dia = date.getUTCDate().toString().padStart(2, "0")
    const mes = (date.getUTCMonth() + 1).toString().padStart(2, "0")
    const ano = date.getUTCFullYear() % 100
    return `${dia}/${mes}/${ano}`
  }

  // Função para converter segundos em formato de tempo 'HH:MM:SS'
  const secondsToFormattedTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0")
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0")
    const secondsRemaining = (seconds % 60).toString().padStart(2, "0")
    return ` ${hours}:${minutes}:${secondsRemaining}`
  }

  // Constantes para evitar repetição de strings
  const sensorName = dataToPlot[0]?.data?.name
  const lastDate = generalData.current.lastDate
  const { dataOperating, dataOn, dataOff, dataNoSignal } = generalData.current
  const thresholdOff = thresholdOffHourMeter?.toFixed(2)
  const thresholdOpering = thresholdOperingHourMeter?.toFixed(2)
  const sensorNameFormatted = sensorName?.replace(/ - rms2x$/, "")

  return (
    <Wrapper>
      <Title>Informações gerais:</Title>

      <HourMeterTable>
        <HourMeterTableInfo>
          <HourMeterSection>
            <span>Sensor: {sensorNameFormatted}</span>
            <span>
              Período pesquisado:{" "}
              {`${formatDate(startDate)} até ${formatDate(endDate)}`}
            </span>
            <span>Último dado: {lastDate}</span>
          </HourMeterSection>
        </HourMeterTableInfo>

        <HourMeterTableInfo>
          <h4>Limites configurados:</h4>
          <HourMeterSection>
            <span>Operando: {`> ${thresholdOpering ?? ""}`}</span>
            <span>
              Ligado: {`${thresholdOff ?? ""} até ${thresholdOpering ?? ""}`}
            </span>
            <span>Desligado: {`< ${thresholdOff ?? ""}`}</span>
          </HourMeterSection>
        </HourMeterTableInfo>

        <HourMeterTableInfo title='Tempo formatado em Horas:Minutos:Segundos'>
          <h4>Dados coletados:</h4>
          <HourMeterSection>
            <span>Operando: {` ${secondsToFormattedTime(dataOperating)}`}</span>
            <span>Ligado: {secondsToFormattedTime(dataOn)}</span>
            <span>Desligado: {secondsToFormattedTime(dataOff)}</span>
            <span>Sem sinal: {secondsToFormattedTime(dataNoSignal)}</span>
          </HourMeterSection>
        </HourMeterTableInfo>
      </HourMeterTable>
    </Wrapper>
  )
}
