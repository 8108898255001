import styled from "styled-components"

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  margin-bottom: 5px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`
