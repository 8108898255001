import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  h1 {
    margin-bottom: 10px;
  }

  button {
    margin-top: 10px;
  }
`
