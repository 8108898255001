import { FC } from "react"
import * as S from "./styles"
import theme from "../../styles/theme"

interface TitleProps {
  size: "small" | "medium"
  label: string
}

export const Title: FC<TitleProps> = ({ label, size }) => {
  return <S.Title fontSize={theme.font.sizes[size]}>{label}</S.Title>
}
