import { Dialog } from "@mui/material"
import { Container, Info, Buttons, ButtonRed, ButtonGreen } from "./styles"
import { Text } from "../Text"
import { Company } from "../../entities/company"

interface ModalConfirmCompanyProps {
  open: boolean
  data: Company
  loading: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalConfirmCompany = ({
  open,
  loading,
  onClose,
  data,
  onConfirm,
}: ModalConfirmCompanyProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Container>
        <h3>Confirmar Nova Empresa ?</h3>

        <Info>
          <h4>Informações</h4>
          <Text field='Nome' value={data.name} />
          <Text field='CNPJ' value={data.cnpj} />
          <Text field='Ocupação' value={data.occupation} />
          <Text field='Estado' value={data.state} />
        </Info>

        <Info>
          <h4>Endereço</h4>
          <Text field='CEP' value={data.cep} />
          <Text field='País' value={data.country} />
          <Text field='UF' value={data.adressState} />
          <Text field='Cidade' value={data.city} />
          <Text field='Bairro' value={data.neighborhood} />
          <Text field='Rua' value={data.street} />
          <Text field='Número' value={data.number} />
          <Text field='Complemento' value={data.complement} />
        </Info>

        <Info>
          <h4>Venda</h4>
          <Text field='Produto' value={data.product.name} />
          <Text field='Descrição' value={data.description} />
          <Text field='Quantidade' value={data.product.amount.toString()} />
        </Info>

        <Buttons>
          <ButtonRed onClick={onClose}>Não</ButtonRed>
          <ButtonGreen onClick={onConfirm} disabled={loading}>
            {loading ? "Carregando..." : "Sim"}
          </ButtonGreen>
        </Buttons>
      </Container>
    </Dialog>
  )
}

export default ModalConfirmCompany
