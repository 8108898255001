import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: start;
  min-width: 375px;
  padding: 5px;
  border: 1px solid #aeaeae;
  border-radius: 5px;

  input {
    outline: none;
    padding: 2%;
    border: none;
    border-bottom: 1px dotted #aeaeae;
  }

  @media (max-width: 400px) {
    min-width: 320px;
  }
`
export const ButtonEdit = styled.button`
  text-transform: uppercase;
  background: #171717;
  color: white;
  outline: none;
  border: none;
  padding: 4px;
  margin-top: 2%;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colorsHover.black};
  }
`
