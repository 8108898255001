import styled from "styled-components"

export const Message = styled.div`
  padding: 50px;
  font-size: 0.9em;
  text-align: center;
  text-transform: uppercase;
`

export const Buttons = styled.div`
  bottom: 0;
  width: 100%;
  text-align: end;
  margin-top: 10px;
  width: 100%;
  justify-content: space-around;
  display: flex;
  gap: 10px;
`

export const Container = styled.div`
  margin-top: 10px;
  overflow-y: auto;
  max-height: 300px;

  @media only screen and (min-width: 400px) {
    max-height: 560px;
  }
`
