/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { openModal } from "../../../store/features/modal/modal.slice"
import { useCallback, useContext, useEffect, useRef } from "react"
import { MessageType1DTO } from "../entities/MsgType1DTO"
import { MessageType1ProcessedDTO } from "../entities/MsgType1ProcessedDTO"
import { getProvisioningAvailable } from "hdr-process-data"
import { SensorsProvContext } from "../context/SensorsProvContext"
import { SensorsProvContextDTO } from "../entities/SensorsProvContextDTO"
import { SensorDataToProvisionDTO } from "../entities/SensorDataToProvisionDTO"
import { HDR_RQM_ACTION } from "../../../utils/hdr_rqm_action"
import { HDR_RQM_ACK } from "../../../utils/hdr_rqm_ack"
import { getProvisionedSensorByMacUseCase } from "../../../store/features/sensors/sensors.utilities"
import {
  fetchSensorsConfig,
  fetchSensorsProv,
} from "../../../store/features/sensors/sensors.api"

const useSensorsProvController = () => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector(
    (state) => state.persistedReducer.user.profile?.user_data.x1
  )
  const {
    setWSDataProcessed,
    sensorDataToProvision,
    disablePageRef,
    requestTimeRef,
    setStatusBtn,
    wsDataProcessedRef,
  } = useContext(SensorsProvContext) as SensorsProvContextDTO

  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)
  const sensorAlreadyDisplayed = useRef([] as MessageType1DTO[])

  const wsProvisioningAvailableEvent: string = `WS2CLIENT/PROVISIONING_AVAILABLE/${companyId}`
  const wsAckEvent: string = `WS2CLIENT/ACK/${companyId}`

  const clenupSocket = useCallback(() => {
    socket?.removeListener(wsProvisioningAvailableEvent)
    socket?.removeListener(wsAckEvent)
  }, [wsProvisioningAvailableEvent, wsAckEvent, socket])

  /**
   * Update the variable used to obtain data from the sensor to be provisioned
   * Close confirmation modal
   * @param {SensorDataToProvisionDTO} param0 - Object containing mac, codeDate and adv properties.
   */
  const handleOpenConfirmModal = ({
    mac,
    codeDate,
    adv,
  }: SensorDataToProvisionDTO) => {
    updateSensorToProvision({ mac, codeDate, adv })

    dispatch(
      openModal({
        modal: "Confirmation",
      })
    )
  }

  /**
   * updates the useRef of the sensor data to be provisioned
   * @param {SensorDataToProvisionDTO}
   */
  const updateSensorToProvision = ({
    mac,
    codeDate,
    adv,
  }: SensorDataToProvisionDTO) => {
    sensorDataToProvision.current = {
      mac,
      codeDate,
      adv,
    }
  }

  /**
   * listens to the web socket for type 1 messages
   * checks if the sensor is not being displayed
   * @returns the processed data
   */
  const listenToType1MsgBySocket = useCallback(() => {
    socket?.on(wsProvisioningAvailableEvent, async (data: MessageType1DTO) => {
      // checks if the sensor is already on the screen for provisioning
      const index = sensorAlreadyDisplayed.current.findIndex(
        (sensor) => sensor.mac === data.mac
      )

      //if it is not being displayed
      if (index === -1) {
        const statusProv = await getProvisionedSensorByMacUseCase(data.mac)
        if (statusProv.isRight()) data.statusProv = statusProv.value

        sensorAlreadyDisplayed.current.push(data)
        getDataProcessed(data)
      } else {
        updateSensorAlreadyList(data)
      }
    })
    // eslint-disable-next-line
  }, [wsProvisioningAvailableEvent, socket])

  /**
   * processes the message data
   * checks if the message has already been processed,
   * and updates the array to display the sensor on the screen
   * @param data type 1 message coming from the web socket
   */
  const getDataProcessed = (data: MessageType1DTO) => {
    if (!data) return

    const processedData: MessageType1ProcessedDTO = processesMsgType01(data)
    const wsDataCopy = wsDataProcessedRef.current.filter(
      (item) => item.mac !== data.mac
    )
    const newData: MessageType1ProcessedDTO[] = [...wsDataCopy, processedData]

    wsDataProcessedRef.current = newData
    setWSDataProcessed(newData)
  }

  const updateSensorAlreadyList = (data: MessageType1DTO) => {
    if (!data) return

    const processedData: MessageType1ProcessedDTO = processesMsgType01(data)
    const wsDataCopy = wsDataProcessedRef.current.map((item) =>
      item.mac === data.mac ? processedData : item
    )

    wsDataProcessedRef.current = wsDataCopy
    setWSDataProcessed(wsDataCopy)
  }

  /**
   * uses lib hdr-process-data (getProvisioningAvailable) to process message type 1
   * @param data type 1 message coming from the web socket
   * @returns the processed data
   */
  const processesMsgType01 = (
    data: MessageType1DTO
  ): MessageType1ProcessedDTO => {
    const processType01Msg = getProvisioningAvailable(data.raw, data.time)

    let componentInfos: MessageType1ProcessedDTO

    if (processType01Msg.isRight()) {
      componentInfos = {
        codeDate: processType01Msg.value.codeDate,
        hardwareVersion: processType01Msg.value.hardwareVersion,
        maxTemp: processType01Msg.value.maxTemp,
        productionSerialNumber: processType01Msg.value.serialNumber
          ? processType01Msg.value.serialNumber
          : data.productionSerialNumber,
        selfTest: processType01Msg.value.selfTest,
        serialNumber: processType01Msg.value.serialNumber
          ? processType01Msg.value.serialNumber
          : data.productionSerialNumber,
        statusProv: data.statusProv,
        temp: processType01Msg.value.temp,
        time: processType01Msg.value.time,
        voltage: processType01Msg.value.voltage,
        mac: data.mac,
        rssi: data.rssi,
        adv: data.raw,
        protocolVersion: processType01Msg.value.protocolVersion,
        applicationVersion: processType01Msg.value.applicationVersion,
      }
    } else {
      componentInfos = {
        mac: data.mac,
        rssi: data.rssi,
        protocolVersion: processType01Msg.value.protocolVersion,
        applicationVersion: processType01Msg.value.applicationVersion,
        adv: data.raw,
      }
    }
    return componentInfos
  }

  const handleDispacthConfiguredSensors = async () => {
    await dispatch(fetchSensorsConfig())
    await dispatch(fetchSensorsProv())
  }

  /**
   * Callback function that listens for ACKs coming from the WS.
   * Its function is to check the response coming from the collector regarding provisioning
   */
  const registerAckSocketEvent = useCallback(() => {
    socket?.on(wsAckEvent, (data: { action: number; ack: number }) => {
      const isProvisionAction =
        disablePageRef.current && data.action === HDR_RQM_ACTION.RQM_ACTION_PROV
      const sensorMac = sensorDataToProvision.current?.mac

      if (isProvisionAction && sensorMac) {
        switch (Number(data.ack)) {
          case HDR_RQM_ACK.RQM_ACK_PROVOK:
            handleDispacthConfiguredSensors()
            setStatusBtn((prevState) => ({
              ...prevState,
              Installing: {
                status: false,
                mac: null,
              },
              Installed: {
                mac: [...prevState.Installed.mac, sensorMac],
              },
            }))
            _clearStateAndRefVariables()
            break

          case HDR_RQM_ACK.RQM_ACK_TIMEOUT:
            setStatusBtn((prevState) => ({
              ...prevState,
              Installing: {
                status: false,
                mac: null,
              },
              Error: {
                mac: sensorMac,
              },
            }))
            requestTimeRef.current = null
            disablePageRef.current = false
            break

          default:
            break
        }
      }
    })
  }, [wsAckEvent, socket, sensorDataToProvision])

  const _clearStateAndRefVariables = (): void => {
    requestTimeRef.current = null
    disablePageRef.current = false
  }

  useEffect(() => {
    if (socket === null) return

    listenToType1MsgBySocket()
    registerAckSocketEvent()
    return () => {
      clenupSocket()
    }
  }, [listenToType1MsgBySocket, clenupSocket, registerAckSocketEvent, socket])

  return {
    handleOpenConfirmModal,
  }
}

export default useSensorsProvController
