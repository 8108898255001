import { useCallback, useEffect, useState } from "react"
import { useAuth } from "react-oidc-context"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { resetDashboardState } from "../../../store/features/dashboard/dashboard.slice"
import { resetSensorsConfigState } from "../../../store/features/sensors/sensors.slice"
import { resetUserState } from "../../../store/features/user/user.slice"
import { fetchSensorsConfig } from "../../../store/features/sensors/sensors.api"
import { fetchDashboards } from "../../../store/features/dashboard/dashboard.api"
import { toast } from "react-toastify"
import { AdlAlarmData } from "../../../entities/adl.types"
import { fetchGateways } from "../../../store/features/gateways/gateways.api"
import { formatedDate } from "../../../utils/date"

type OpenPageType =
  | "dashboard"
  | "sensorsconfig"
  | "sensorsprov"
  | "gateways"
  | "alarmslogs"
  | "alarmsconfig"
  | "registeruser"
  | "registercompany"
  | "registersales"
  | "reports"
  | "historicalanalysis"

export const useMenuController = () => {
  const dispatch = useAppDispatch()

  const [openReports, setOpenReports] = useState(false)
  const [openSensor, setOpenSensor] = useState(false)
  const [openAlarms, setOpenAlarms] = useState(false)
  const [openManager, setOpenManager] = useState(false)
  const [pageTitle, setPageTitle] = useState<OpenPageType>("dashboard")

  const userProfile = useAppSelector(
    (state) => state.persistedReducer.user.profile
  )

  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)

  const companyId = useAppSelector(
    (state) => state.persistedReducer.user.profile?.user_data.x1
  )

  const wsAdlAlarmEvent = `WS2CLIENT/ADL_ALARM/${companyId}`

  const alarmSound = new Audio(require("../../../assets/warning.mp3"))

  const registerAlarmLogSocketEvent = useCallback(() => {
    if (!socket) return

    socket.on(wsAdlAlarmEvent, async (data: AdlAlarmData) => {
      if (!data) return
      if (data.intensity === 1) {
        const msg = `Anomalia identificada no ${data.sensorName} às ${formatedDate(data.time)} pelo ${data.serialNumber}, intensidade nível ${
          data.intensity
        }.`
        toast.error(msg, { autoClose: false, theme: "colored" })
      } else {
        const msg = `Aumento da intensidade do alerta às ${formatedDate(data.time)} pelo ${data.serialNumber}, intensidade nível ${
          data.intensity
        }.`
        toast.error(msg, { autoClose: false, theme: "colored" })
      }

      alarmSound.play()
      setTimeout(() => {
        alarmSound.pause()
      }, 3000)
    })
    // eslint-disable-next-line
  }, [socket, wsAdlAlarmEvent])

  const clearSocketListeners = useCallback(() => {
    if (!socket) return

    socket.removeListener(wsAdlAlarmEvent)
  }, [wsAdlAlarmEvent, socket])

  useEffect(() => {
    let page = window.location.href.split("/")
    const pageSelected = page[page.length - 1] as OpenPageType

    setPageTitle(pageSelected)
  }, [])

  useEffect(() => {
    if (socket === null) return
    registerAlarmLogSocketEvent()
    return function cleanup() {
      clearSocketListeners()
    }
    // eslint-disable-next-line
  }, [socket, registerAlarmLogSocketEvent, clearSocketListeners])

  const [isOpen, setIsOpen] = useState(false)
  const { signoutRedirect } = useAuth()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const logout = () => {
    dispatch(resetDashboardState())
    dispatch(resetSensorsConfigState())
    dispatch(resetUserState())
    signoutRedirect()
  }

  const refresh = () => {
    dispatch(fetchGateways())
    dispatch(fetchSensorsConfig())

    dispatch(resetDashboardState())
    dispatch(fetchDashboards())
  }

  const handleOpenSensor = () => {
    setOpenSensor(!openSensor)
  }

  const handleOpenAlarms = () => {
    setOpenAlarms(!openAlarms)
  }

  const handleOpenManager = () => {
    setOpenManager((isOpen) => !isOpen)
  }

  const handleOpenReports = () => {
    setOpenReports(!openReports)
  }

  return {
    isOpen,
    userProfile,
    toggleMenu,
    refresh,
    logout,
    pageTitle,
    openReports,
    openSensor,
    openAlarms,
    handleOpenReports,
    handleOpenSensor,
    handleOpenAlarms,
    openManager,
    handleOpenManager,
  }
}
