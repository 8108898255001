import styled from "styled-components"

export const TextInfo = styled.div`
  font-size: 1.1rem;
  margin-bottom: 25px;
  display: flex;
  padding-left: 10%;
  width: 80%;
  text-align: center;
`

export const ButtonConteiner = styled.div`
  justify-content: center;
  display: flex;
`
