import Modal from "../Modal"
import useManagerUserController from "../../controllers/useManagerUser"
import { Container, Table, ResetBtn } from "./styles"

export const ModalConfirmDeleteUser = () => {
  const {
    openModalDeleteUser,
    handleCloseModalConfirmDeleteUser,
    userToDelete,
    getCompanyNameById,
  } = useManagerUserController()

  return (
    <Modal
      openModal={openModalDeleteUser}
      handleModalClose={() => handleCloseModalConfirmDeleteUser()}
      maxWidth='md'
      title={`Deseja remover o usuário ${userToDelete?.firstName}?`}
    >
      <Container>
        <>
          Ao fazer isso,
          <span style={{ fontWeight: "bold" }}>
            os dados desse usuário serão apagados
          </span>{" "}
          Deseja mesmo continuar?
        </>
        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Sobrenome</th>
              <th>Empresa</th>
              <th>Email</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userToDelete?.firstName}</td>
              <td>{userToDelete?.lastName}</td>
              <td>{getCompanyNameById(userToDelete?.companyId)}</td>
              <td>{userToDelete?.email}</td>
              <td>{userToDelete?.permissionLevel}</td>
            </tr>
          </tbody>
        </Table>
        <ResetBtn onClick={() => handleCloseModalConfirmDeleteUser()}>
          Remover
        </ResetBtn>
      </Container>
    </Modal>
  )
}
