import { ReactNode, createContext, useEffect, useState } from "react"
import { UsersApiDTO } from "../entities/usersApiDTO"
import { callApiGetAllUsers } from "../services/callApiToGetAllUsers"
import {
  CompanyApiDTO,
  callApiGetAllCompanies,
} from "../services/callApiToGetAllCompanies"
import { toast } from "react-toastify"

interface ContextRegisterUsers {
  users: UsersApiDTO[]
  companies: CompanyApiDTO[]
  openModalDeleteUser: boolean
  setOpenModalDeleteUser: React.Dispatch<React.SetStateAction<boolean>>
  userToDelete: UsersApiDTO | null
  setUserToDelete: React.Dispatch<React.SetStateAction<UsersApiDTO | null>>
  openModalEditPermissionUser: boolean
  setOpenModalEditPermissionUser: React.Dispatch<React.SetStateAction<boolean>>
  userToEditPermission: UsersApiDTO | null
  setUserToEditPermission: React.Dispatch<
    React.SetStateAction<UsersApiDTO | null>
  >
  filteredUsers: UsersApiDTO[]
  setFilteredUsers: React.Dispatch<React.SetStateAction<UsersApiDTO[]>>
  filter: string
  setFilter: React.Dispatch<React.SetStateAction<string>>
  getAllUsers: () => Promise<void>
}

export const RegisterUsersContext = createContext<ContextRegisterUsers | null>(
  null
)

export const RegisterUserContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [users, setUsers] = useState<UsersApiDTO[]>([])
  const [filter, setFilter] = useState<string>("name")
  const [filteredUsers, setFilteredUsers] = useState<UsersApiDTO[]>([])
  const [companies, setCompanies] = useState<CompanyApiDTO[]>([])
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false)
  const [openModalEditPermissionUser, setOpenModalEditPermissionUser] =
    useState(false)
  const [userToDelete, setUserToDelete] = useState<UsersApiDTO | null>(null)
  const [userToEditPermission, setUserToEditPermission] =
    useState<UsersApiDTO | null>(null)

  const getAllUsers = async () => {
    const result = await callApiGetAllUsers()

    if (result.isLeft()) {
      toast.error(result.value.message)
      return
    }
    setUsers(result.value)
    setFilteredUsers(result.value)
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  useEffect(() => {
    const getAllCompanies = async () => {
      const result = await callApiGetAllCompanies()

      if (result.isLeft()) {
        toast.error(result.value.message)
        return
      }
      setCompanies(result.value)
    }

    getAllCompanies()
  }, [])

  const defaultContext: ContextRegisterUsers = {
    users,
    companies,
    openModalDeleteUser,
    setOpenModalDeleteUser,
    userToDelete,
    setUserToDelete,
    openModalEditPermissionUser,
    setOpenModalEditPermissionUser,
    userToEditPermission,
    setUserToEditPermission,
    filteredUsers,
    setFilteredUsers,
    filter,
    setFilter,
    getAllUsers,
  }

  return (
    <RegisterUsersContext.Provider value={defaultContext}>
      {children}
    </RegisterUsersContext.Provider>
  )
}
