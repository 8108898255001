import { Tooltip } from "../../Tooltip"
import { IconAndDescriptionContainer } from "../styles"
import { GrStatusGoodSmall } from "react-icons/gr"

const Status = ({
  gatewayStatus,
  name,
  iconSize,
}: {
  gatewayStatus: boolean
  name: string
  iconSize: number
}): JSX.Element => {
  const title = gatewayStatus ? "Online" : "Offline"
  const color = gatewayStatus ? "#1A8828" : "#EF1700"

  return (
    <Tooltip title={title}>
      <IconAndDescriptionContainer>
        <GrStatusGoodSmall size={16} color={color} />
        <p>{name}</p>
      </IconAndDescriptionContainer>
    </Tooltip>
  )
}

export default Status
