import { Container, Header, Info } from "./styles"
import { MdKeyboardArrowDown } from "react-icons/md"
import { GatewayInfoDTO } from "../../entities/gatewayInfoDTO"
import Status from "./components/Status"
import { useGatewayCardController } from "../../controllers/gatewayCardController"
import Ssid from "./components/Ssid"
import Rssi from "./components/Rssi"
import DataRate from "./components/DataRate"
import TimeActive from "./components/ActiveTime"
import TextLine from "./components/TextLine"

export const GatewayCard = ({
  dataRate,
  ewMac,
  id_mqqt,
  ip_eth,
  ip_wifi,
  mac,
  name,
  rssi,
  ssid,
  time,
  lifetime,
  version,
}: GatewayInfoDTO): JSX.Element => {
  const { gatewayStatus, getFormatedLifetime, isCardOpen, openOrCloseCard } =
    useGatewayCardController(time)

  return (
    <Container onClick={() => openOrCloseCard()}>
      <Header>
        <Status gatewayStatus={gatewayStatus} name={name} iconSize={16} />

        {gatewayStatus && (
          <>
            <Ssid ssid={ssid} iconSize={16} />
            <Rssi rssi={rssi} iconSize={16} />
            <DataRate dataRate={dataRate} iconSize={16} />
            <TimeActive
              formatedLifetime={getFormatedLifetime(lifetime)}
              iconSize={16}
            />
          </>
        )}

        <MdKeyboardArrowDown size={24} />
      </Header>

      {isCardOpen && (
        <Info>
          <TextLine title='IP WIFI' description={ip_wifi} />
          <TextLine title='IP ETHERNET' description={ip_eth} />
          <TextLine title='MAC' description={mac} />
          <TextLine title='MAC WIFI' description={ewMac} />
          <TextLine title='Versão de Firmware' description={version} />
          <TextLine title='MQTT ID' description={id_mqqt.toString()} />
        </Info>
      )}
    </Container>
  )
}
