import { CsvDataDTO } from "./reports.interfaces"

export const downloadCSV = (csv: CsvDataDTO[]) => {
  for (let index = 0; index < csv.length; index++) {
    const item = csv[index]

    const blob = new Blob([item.data], { type: "text/csv" })

    const url = URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", item.filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}
