import { useEffect } from "react"
import ReportPanelSensor from "../components/ReportPanelSensor"

import { CircularLoading } from "../components/CircularLoading"

import { Wrapper, BodyPage, ReportLoading, Main } from "./styles"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  fetchProvisionedSensorsRequest,
  fetchSensorWithServices,
} from "../../../store/features/reports/reports.api"
import { useAppTranslate } from "../../../translate/useAppTranslate"

export const ReportsView = () => {
  const { reports } = useAppTranslate()

  const { isLoadingData, isLoadingSensors } = useAppSelector(
    (state) => state.persistedReducer.reports
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchProvisionedSensorsRequest())
    dispatch(fetchSensorWithServices())
  }, [dispatch])

  return (
    <Wrapper>
      <Main>
        {isLoadingData && (
          <ReportLoading>
            <div className='lds-ellipsis'>
              <div />
              <div />
              <div />
              <div />
            </div>
            <h1>{reports.loading}</h1>
          </ReportLoading>
        )}

        {isLoadingSensors && <CircularLoading />}

        {!isLoadingSensors && !isLoadingData && (
          <BodyPage>
            <ReportPanelSensor />
          </BodyPage>
        )}
      </Main>
    </Wrapper>
  )
}
