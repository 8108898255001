import useManagerUserController from "../../controllers/useManagerUser"
import {
  SearchContainer,
  SelectSearchItems,
  InputSearch,
  ContainerSelect,
  Icon,
  OptionItems,
} from "./styles"
import { BsSearch } from "react-icons/bs"

interface SearchFilterProps {}

export const SearchFilter: React.FC<SearchFilterProps> = () => {
  const { changeSearchFilter, handleUpdateInput, inputValue } =
    useManagerUserController()

  return (
    <SearchContainer>
      <ContainerSelect>
        <SelectSearchItems onChange={(e) => changeSearchFilter(e.target.value)}>
          <OptionItems value='name'>Nome</OptionItems>
          <OptionItems value='company'>Empresa</OptionItems>
          <OptionItems value='email'>Email</OptionItems>
        </SelectSearchItems>
      </ContainerSelect>

      <InputSearch
        type='text'
        placeholder='Pesquisar...'
        name='search'
        id='search'
        value={inputValue}
        onChange={(e) => handleUpdateInput(e.target.value)}
      />

      <Icon>
        <BsSearch size={18} />
      </Icon>
    </SearchContainer>
  )
}
