import { createContext, useState, useEffect, useRef, ReactNode } from "react"

import { ApiMappedToVariableMonitored } from "../entities/apiMappedToVariableMonitored"
import { ContextAlarmsConfig } from "../entities/contextDTO"
import { DataFromApiDTO } from "../entities/dataFromApiDTO"
import { DropDownSelectedDTO } from "../entities/dropDownDTO"
import { DropdownOpenBooleanDTO } from "../entities/dropdownOpenDTO"
import { GetAlarmDataDTO } from "../entities/getAlarmDTO"
import { InputSelectedDTO } from "../entities/imputSelectedDTO"
import { InputSelectedRefDTO } from "../entities/inputSelectedRefDTO"
import { SensorNameWithProdSerialNumberDTO } from "../entities/sensorNameWithProdSerialDTO"
import { SensorObjDTO } from "../entities/sensorObjDTO"
import { SecondsToTsampeDTO, TsampeToSecondsDTO } from "../entities/tsampeDTO"
import { VariableMonitoredMappedToApi } from "../entities/variableMonitoredMappedToApi"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"
import { fetchConfiguredSensors } from "../../../store/features/alarms_logs/alarms_logs.api"

export const AlarmsConfigContext = createContext<ContextAlarmsConfig | null>(
  null
)

export const AlarmsConfigContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const dispatch = useAppDispatch()

  const configuredSensorsData = useAppSelector(
    (state) => state.persistedReducer.alarmsLogs.sensorsConfig
  )

  useEffect(() => {
    dispatch(fetchConfiguredSensors())
  }, [dispatch])

  const alarmsFromUserRef = useRef({} as GetAlarmDataDTO)
  const wasModalOpenedFromMain = useRef<boolean>(false)
  const prodSerialNumberPlusAlarm = useRef<string>("")
  const [openAccordionAlarm, setOpenAccordionAlarm] = useState<boolean>(false)

  const [openAlarmsConfigModal, setOpenAlarmsConfigModal] =
    useState<boolean>(false)
  const [
    productionSerialNumbersOfConfiguredAlarms,
    setProductionSerialNumbersOfConfiguredAlarms,
  ] = useState<string[]>([])
  const [sensors, setSensors] = useState<SensorObjDTO[]>([])

  const [dropdownOpen, setDropdownOpen] = useState<DropdownOpenBooleanDTO>({
    sensorProdSerialNumber: false,
    thresholdCondition: false,
    timeBetweenAlarms: false,
    variableToBeMonitored: false,
  })

  const [dropdownSelected, setDropdownSelected] = useState<DropDownSelectedDTO>(
    {
      sensorProdSerialNumber: "",
      thresholdCondition: "",
      timeBetweenAlarms: "",
      variableToBeMonitored: "",
    }
  )

  const [inputSelected, setInputSelected] = useState<InputSelectedDTO>({
    firstThreshold: "",
    threshold: "",
    secondThreshold: "",
    email: "",
  })

  const [loadingModal, setLoadingModal] = useState<boolean>(false)
  const [prodSerialNumberToDropdown, setProdSerialNumberToDropdown] = useState<
    (string | null)[]
  >([])
  const [variableToBeMonitoredDropdown, setVariableToBeMonitoredDropdown] =
    useState<string[]>([])

  const allProdSerialNumbersAndSensorsNameFromConfiguredSensors: (
    | string
    | null
  )[] = configuredSensorsData
    .map((item) => {
      if (item.productionSerialNumber) {
        const prodSerialNum = cleanAndPadSerialNumber(
          item.productionSerialNumber,
          item.hardwareVersion
        ) as string
        return prodSerialNum
          ? `${prodSerialNum.slice(prodSerialNum.length - 5)}${item.name ? `-${item.name}` : ""}`
          : item.mac
      }
      return null
    })
    .filter((item) => item)

  const THRESHOLD_OPTIONS = [
    "Acima",
    "Abaixo",
    "Borda de subida",
    "Borda de descida",
    "Entre valores",
  ]
  const TIME_BETWEEN_ALARMS = ["30min", "1h", "2h", "5h", "12h", "24h"]
  const VARIABLE_TO_BE_MONITORED = [
    "Temperatura",
    "RSSI",
    "Tensão",
    "RMMS",
    "RMS2",
    "4T20",
    "NTC",
    "POT",
  ]
  const VARIABLE_TO_BE_MONITORED_FROM_API = [
    "temp",
    "rssi",
    "voltage",
    "rmms",
    "rms2",
    "_4t20",
    "ntc",
    "pot",
  ]
  const VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS = [
    "temp",
    "rssi",
    "voltage",
    "rmms",
    "rms2",
    "_4t20_a",
    "_4t20_b",
    "ntc_a",
    "ntc_b",
    "pot_a",
    "pot_b",
  ]

  const DROPDOWN_NAMES = [
    "sensorProdSerialNumber",
    "thresholdCondition",
    "timeBetweenAlarms",
    "variableToBeMonitored",
  ]
  const INPUT_NAMES = [
    "firstThreshold",
    "threshold",
    "secondThreshold",
    "email",
  ]

  const dropdownOpenRef = useRef<DropdownOpenBooleanDTO>({})
  const dropdownSelectedRef = useRef<VariableMonitoredMappedToApi>({})
  const inputSelectedRef = useRef<InputSelectedRefDTO>({})
  const sensorsToBeUpdatedRef = useRef<SensorObjDTO[]>([])

  const [dataFromAPI, setDataFromAPI] = useState<DataFromApiDTO[]>([])
  const [sensorsNameWithProdSerialNumber, setSensorsNameWithProdSerialNumber] =
    useState<SensorNameWithProdSerialNumberDTO>({})

  const [updateData, setUpdateData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState<boolean>(false)

  const VARIABLE_MONITORED_MAPPED_TO_API: VariableMonitoredMappedToApi =
    VARIABLE_TO_BE_MONITORED.reduce((prev, curr, index) => {
      let item = {}

      if (curr === "4T20" || curr === "NTC" || curr === "POT") {
        item = {
          ...prev,
          [`${curr}_A`]: `${VARIABLE_TO_BE_MONITORED_FROM_API[index]}_a`,
          [`${curr}_B`]: `${VARIABLE_TO_BE_MONITORED_FROM_API[index]}_b`,
        }
      } else {
        item = {
          ...prev,
          [curr]: VARIABLE_TO_BE_MONITORED_FROM_API[index],
        }
      }
      return item
    }, {})

  const API_MAPPED_TO_VARIABLE_MONITORED: ApiMappedToVariableMonitored =
    VARIABLE_TO_BE_MONITORED_FROM_API.reduce((prev, curr, index) => {
      let item = {}

      if (curr === "_4t20" || curr === "ntc" || curr === "pot") {
        item = {
          ...prev,
          [`${curr}_a`]: `${VARIABLE_TO_BE_MONITORED[index]}_A`,
          [`${curr}_b`]: `${VARIABLE_TO_BE_MONITORED[index]}_B`,
        }
      } else {
        item = {
          ...prev,
          [curr]: VARIABLE_TO_BE_MONITORED[index],
        }
      }
      return item
    }, {})

  const CONVERT_TSAMPLE_TO_SECONDS: TsampeToSecondsDTO = {
    [TIME_BETWEEN_ALARMS[0]]: 1_800,
    [TIME_BETWEEN_ALARMS[1]]: 3_600,
    [TIME_BETWEEN_ALARMS[2]]: 7_200,
    [TIME_BETWEEN_ALARMS[3]]: 18_000,
    [TIME_BETWEEN_ALARMS[4]]: 43_200,
    [TIME_BETWEEN_ALARMS[5]]: 86_400,
  }

  const CONVERT_SECONDS_TO_TSAMPLE: SecondsToTsampeDTO = {
    1_800: TIME_BETWEEN_ALARMS[0],
    3_600: TIME_BETWEEN_ALARMS[1],
    7_200: TIME_BETWEEN_ALARMS[2],
    18_000: TIME_BETWEEN_ALARMS[3],
    43_200: TIME_BETWEEN_ALARMS[4],
    86_400: TIME_BETWEEN_ALARMS[5],
  }

  const validateEmail = (email: string) => {
    const regEx =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    return regEx.test(email)
  }

  const defaultContext: ContextAlarmsConfig = {
    openAlarmsConfigModal,
    setOpenAlarmsConfigModal,
    THRESHOLD_OPTIONS,
    TIME_BETWEEN_ALARMS,
    VARIABLE_TO_BE_MONITORED,
    VARIABLE_MONITORED_MAPPED_TO_API,
    CONVERT_TSAMPLE_TO_SECONDS,
    API_MAPPED_TO_VARIABLE_MONITORED,
    VARIABLE_TO_BE_MONITORED_FROM_API,
    CONVERT_SECONDS_TO_TSAMPLE,
    productionSerialNumbersOfConfiguredAlarms,
    setProductionSerialNumbersOfConfiguredAlarms,
    sensors,
    setSensors,
    DROPDOWN_NAMES,
    INPUT_NAMES,
    allProdSerialNumbersAndSensorsNameFromConfiguredSensors,
    alarmsFromUserRef,
    wasModalOpenedFromMain,
    prodSerialNumberPlusAlarm,
    validateEmail,
    configuredSensorsData,
    VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS,
    dropdownOpen,
    setDropdownOpen,
    dropdownSelected,
    setDropdownSelected,
    inputSelected,
    setInputSelected,
    loadingModal,
    setLoadingModal,
    prodSerialNumberToDropdown,
    setProdSerialNumberToDropdown,
    variableToBeMonitoredDropdown,
    setVariableToBeMonitoredDropdown,
    dropdownOpenRef,
    dropdownSelectedRef,
    inputSelectedRef,
    sensorsToBeUpdatedRef,
    dataFromAPI,
    setDataFromAPI,
    sensorsNameWithProdSerialNumber,
    setSensorsNameWithProdSerialNumber,
    updateData,
    setUpdateData,
    loading,
    setLoading,
    updateBtnDisabled,
    setUpdateBtnDisabled,
    openAccordionAlarm,
    setOpenAccordionAlarm,
  }

  return (
    <AlarmsConfigContext.Provider value={defaultContext}>
      {children}
    </AlarmsConfigContext.Provider>
  )
}
