import { AnalyticConfigDTO } from "../entities/analyticConfigDTO"
import {
  HealthData,
  NTCData,
  POTData,
  ProcessData,
  RMMSData,
  RMS2Data,
  TemperatureData,
  _4T20Data,
} from "../entities/processDataWithMacDTO"
import {
  ArrayData,
  GraphicData,
  ServiceAndMac,
  ServicesAvailable,
} from "../entities/serviceAndMacDTO"
import { useProcessApiData } from "./processApiData"

export const useServicesData = () => {
  const { getProcessData } = useProcessApiData()

  async function getData(
    analyticsData: AnalyticConfigDTO,
    analyticID: number | undefined,
    startDate: Date,
    endDate: Date
  ): Promise<ArrayData[]> {
    const servicesAvailable = _getServicesAvailable(analyticsData)
    const processData = await _getProcessData(
      analyticsData,
      analyticID,
      startDate,
      endDate,
      servicesAvailable
    )
    return processData
  }

  async function getProcessedFFTData(
    analyticsData: AnalyticConfigDTO,
    analyticID: number | undefined,
    startDate: Date,
    endDate: Date
  ): Promise<ProcessData[]> {
    const servicesAvailable = _getServicesAvailable(analyticsData)

    const processedData: ProcessData[] = []

    for (let index = 0; index < servicesAvailable.length; index++) {
      const element = servicesAvailable[index]

      const service = "FFT"
      const serviceArray = element.serviceArray
      const data = await getProcessData(
        service,
        serviceArray,
        analyticID,
        startDate,
        endDate
      )

      data && processedData.push(...data)
    }
    return processedData
  }

  async function getProcessedAccRawData(
    analyticsData: AnalyticConfigDTO,
    analyticID: number | undefined,
    startDate: Date,
    endDate: Date
  ): Promise<ProcessData[]> {
    const servicesAvailable = _getServicesAvailable(analyticsData)
    const processedData: ProcessData[] = []

    for (let index = 0; index < servicesAvailable.length; index++) {
      const element = servicesAvailable[index]

      const service = "ACCRAW"
      const serviceArray = element.serviceArray

      const data = await getProcessData(
        service,
        serviceArray,
        analyticID,
        startDate,
        endDate
      )

      data && processedData.push(...data)
    }

    return processedData
  }

  async function _getProcessData(
    analyticsData: AnalyticConfigDTO,
    analyticID: number | undefined,
    startDate: Date,
    endDate: Date,
    servicesAvailable: ServicesAvailable[]
  ): Promise<ArrayData[]> {
    let processData: ProcessData[] = []
    let graphicData: GraphicData = {}

    for (let index = 0; index < servicesAvailable.length; index++) {
      const element = servicesAvailable[index]

      const service = element.service
      const serviceArray = element.serviceArray
      if (service.includes("temp")) {
        const data = await getProcessData(
          "TEMP",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )

        if (data) processData = [...processData, ...data]
      } else if (service.includes("rms2")) {
        const data = await getProcessData(
          "RMS2",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )

        if (data) processData = [...processData, ...data]
      } else if (service.includes("rmms")) {
        const data = await getProcessData(
          "RMMS",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )
        if (data) processData = [...processData, ...data]
      } else if (service.includes("pot")) {
        const data = await getProcessData(
          "POT",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )

        if (data) processData = [...processData, ...data]
      } else if (service.includes("_4t20")) {
        const data = await getProcessData(
          "_4T20",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )

        if (data) processData = [...processData, ...data]
      } else if (service.includes("ntc")) {
        const data = await getProcessData(
          "NTC",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )

        if (data) processData = [...processData, ...data]
      } else if (service.includes("health")) {
        const data = await getProcessData(
          "HEALTH",
          serviceArray,
          analyticID,
          startDate,
          endDate
        )

        if (data) processData = [...processData, ...data]
      }
    }
    if (processData.length === 0 || !analyticsData.sensors) return []
    const services = Object.keys(analyticsData.sensors)

    services.forEach((service) => {
      const serviceAux = _getServiceType(servicesAvailable, service)

      if (!analyticsData.sensors || !serviceAux) return

      const macsAndNamesOfService = analyticsData.sensors[service]

      macsAndNamesOfService.forEach((macAndName) => {
        let serviceType = serviceAux
        if (serviceAux === "pot") serviceType = "percent"
        else if (serviceAux === "_4t20") serviceType = "current"
        else if (serviceAux === "ntc") serviceType = "temp"
        else if (serviceAux === "health") serviceType = "voltage"
        for (let i = 0; i < processData.length; i++) {
          const process = processData[i]
          if (
            !(
              Object.keys(process).includes(serviceType) &&
              macAndName.mac === process.mac
            )
          ) {
            continue
          }
          let eachData = processData[i]
          if (service.includes("temp")) {
            graphicData = _addEachTempDataIntoXAndY(
              eachData as TemperatureData,
              macAndName,
              service,
              graphicData
            )
          } else if (service.includes("rms2")) {
            graphicData = _addEachRMS2DataIntoXAndY(
              eachData as RMS2Data,
              macAndName,
              service,
              graphicData
            )
          } else if (service.includes("rmms")) {
            graphicData = _addEachRMMSDataIntoXAndY(
              eachData as RMMSData,
              macAndName,
              service,
              graphicData
            )
          } else if (service.includes("pot")) {
            graphicData = _addEachPotDataIntoXAndY(
              eachData as POTData,
              macAndName,
              service,
              graphicData
            )
          } else if (service.includes("_4t20")) {
            graphicData = _addEach_4t20DataIntoXAndY(
              eachData as _4T20Data,
              macAndName,
              service,
              graphicData
            )
          } else if (service.includes("ntc")) {
            graphicData = _addEachNTCDataIntoXAndY(
              eachData as NTCData,
              macAndName,
              service,
              graphicData
            )
          } else if (service.includes("health")) {
            graphicData = _addEachHealthDataIntoXAndY(
              eachData as HealthData,
              macAndName,
              service,
              graphicData
            )
          }
        }
      })
    })
    const arraydata: ArrayData[] = []

    Object.keys(graphicData).forEach((key) => {
      arraydata.push({
        data: graphicData[key].data,
        name: graphicData[key].name,
        mac: graphicData[key].mac,
        service: graphicData[key].service,
      })
    })
    return arraydata
  }

  function _addEachTempDataIntoXAndY(
    foundData: TemperatureData,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}
    for (let index = 0; index < foundData.temp.length; index++) {
      const foundtime = foundData.time[index]

      const name = `${macAndName.name} - ${service}`

      const x = new Date(foundtime).getTime()
      const y = foundData.temp[index]
      if (newData[name]) {
        newData[name].data = [...newData[name].data, [x, y]]
      } else {
        newData = {
          ...newData,
          [name]: {
            data: [[x, y]],
            name: name,
            mac: macAndName.mac,
            service: service,
          },
        }
      }
    }

    const graphicDataNames = Object.keys(graphicData)
    const newGraphicDataName = Object.keys(newData)[0]

    if (graphicDataNames.includes(newGraphicDataName)) {
      graphicData[newGraphicDataName].data = [
        ...graphicData[newGraphicDataName].data,
        ...newData[newGraphicDataName].data,
      ]

      graphicData[newGraphicDataName].name = newData[newGraphicDataName].name
      graphicData[newGraphicDataName].mac = newData[newGraphicDataName].mac
      graphicData[newGraphicDataName].service =
        newData[newGraphicDataName].service
    } else {
      graphicData = {
        ...graphicData,
        ...newData,
      }
    }
    return graphicData
  }

  function _addEachHealthDataIntoXAndY(
    foundData: HealthData,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}

    const foundtime = foundData.time
    const nameVoltage = `${macAndName.name} - ${"voltage"}`
    const nameTemp = `${macAndName.name} - ${"temp"}`
    const nameRSSI = `${macAndName.name} - ${"rssi"}`

    const x = new Date(foundtime).getTime()

    newData = _addPoint(
      x,
      foundData.voltage,
      newData,
      nameVoltage,
      macAndName,
      service
    )
    newData = _addPoint(
      x,
      foundData.temp,
      newData,
      nameTemp,
      macAndName,
      service
    )
    newData = _addPoint(
      x,
      foundData.rssi,
      newData,
      nameRSSI,
      macAndName,
      service
    )

    const graphicDataNames = Object.keys(graphicData)

    for (const key of Object.keys(newData)) {
      if (graphicDataNames.includes(key)) {
        graphicData[key].data = [...graphicData[key].data, ...newData[key].data]
        graphicData[key].name = newData[key].name
        graphicData[key].mac = newData[key].mac
        graphicData[key].service = newData[key].service
      } else {
        graphicData = {
          ...graphicData,
          ...newData,
        }
      }
    }

    return graphicData
  }

  function _addEachPotDataIntoXAndY(
    foundData: POTData,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}

    for (let index = 0; index < foundData.percent.length; index++) {
      const foundtime = foundData.time[index]

      const name = `${macAndName.name} - ${service}`

      const x = new Date(foundtime).getTime()
      const y = foundData.percent[index][0]
      if (newData[name]) {
        newData[name].data = [...newData[name].data, [x, y]]
      } else {
        newData = {
          ...newData,
          [name]: {
            data: [[x, y]],
            name: name,
            mac: macAndName.mac,
            service: service,
          },
        }
      }
    }

    const graphicDataNames = Object.keys(graphicData)
    const newGraphicDataName = Object.keys(newData)[0]

    if (graphicDataNames.includes(newGraphicDataName)) {
      graphicData[newGraphicDataName].data = [
        ...graphicData[newGraphicDataName].data,
        ...newData[newGraphicDataName].data,
      ]
    } else {
      graphicData = {
        ...graphicData,
        ...newData,
      }
    }
    return graphicData
  }

  function _addEachRMS2DataIntoXAndY(
    foundData: RMS2Data,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}

    for (let index = 0; index < foundData.rms2.length; index++) {
      const foundTime = foundData.time[index]
      const x = new Date(foundTime).getTime()

      const name = `${macAndName.name} - ${service}`

      switch (service) {
        case "rms2VectorModule":
          {
            const y = _calculateVectorModule(
              foundData.rms2[index][0],
              foundData.rms2[index][1],
              foundData.rms2[index][2]
            )
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "rms2x":
          {
            const y = foundData.rms2[index][0]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "rms2y":
          {
            const y = foundData.rms2[index][1]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "rms2z":
          {
            const y = foundData.rms2[index][2]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        default:
          break
      }
    }

    const graphicDataNames = Object.keys(graphicData)
    const newGraphicDataName = Object.keys(newData)[0]

    if (graphicDataNames.includes(newGraphicDataName)) {
      graphicData[newGraphicDataName].data = [
        ...graphicData[newGraphicDataName].data,
        ...newData[newGraphicDataName].data,
      ]
      graphicData[newGraphicDataName].name = newData[newGraphicDataName].name
      graphicData[newGraphicDataName].mac = newData[newGraphicDataName].mac
      graphicData[newGraphicDataName].service =
        newData[newGraphicDataName].service
    } else {
      graphicData = {
        ...graphicData,
        ...newData,
      }
    }

    return graphicData
  }

  function _addEach_4t20DataIntoXAndY(
    foundData: _4T20Data,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}

    for (let index = 0; index < foundData.current.length; index++) {
      const foundTime = foundData.time[index]
      const x = new Date(foundTime).getTime()

      const name = `${macAndName.name} - ${service}`

      switch (service) {
        case "_4t20ChanelA":
          {
            const y = foundData.current[index][0]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "_4t20ChanelB":
          {
            const y = foundData.current[index][1]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        default:
          break
      }
    }

    const graphicDataNames = Object.keys(graphicData)
    const newGraphicDataName = Object.keys(newData)[0]

    if (graphicDataNames.includes(newGraphicDataName)) {
      graphicData[newGraphicDataName].data = [
        ...graphicData[newGraphicDataName].data,
        ...newData[newGraphicDataName].data,
      ]
      graphicData[newGraphicDataName].name = newData[newGraphicDataName].name
      graphicData[newGraphicDataName].mac = newData[newGraphicDataName].mac
      graphicData[newGraphicDataName].service =
        newData[newGraphicDataName].service
    } else {
      graphicData = {
        ...graphicData,
        ...newData,
      }
    }
    return graphicData
  }

  function _addEachNTCDataIntoXAndY(
    foundData: NTCData,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}

    for (let index = 0; index < foundData.temp.length; index++) {
      const foundTime = foundData.time[index]
      const x = new Date(foundTime).getTime()

      const name = `${macAndName.name} - ${service}`

      switch (service) {
        case "ntcChanelA":
          {
            const y = foundData.temp[index][0]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "ntcChanelB":
          {
            const y = foundData.temp[index][1]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        default:
          break
      }
    }

    const graphicDataNames = Object.keys(graphicData)
    const newGraphicDataName = Object.keys(newData)[0]

    if (graphicDataNames.includes(newGraphicDataName)) {
      graphicData[newGraphicDataName].data = [
        ...graphicData[newGraphicDataName].data,
        ...newData[newGraphicDataName].data,
      ]
      graphicData[newGraphicDataName].name = newData[newGraphicDataName].name
      graphicData[newGraphicDataName].mac = newData[newGraphicDataName].mac
      graphicData[newGraphicDataName].service =
        newData[newGraphicDataName].service
    } else {
      graphicData = {
        ...graphicData,
        ...newData,
      }
    }
    return graphicData
  }

  function _addEachRMMSDataIntoXAndY(
    foundData: RMMSData,
    macAndName: ServiceAndMac,
    service: string,
    graphicData: GraphicData
  ): GraphicData {
    let newData: GraphicData = {}

    for (let index = 0; index < foundData.rmms.length; index++) {
      const foundTime = foundData.time[index]
      const x = new Date(foundTime).getTime()

      const name = `${macAndName.name} - ${service}`

      switch (service) {
        case "rmmsVectorModule":
          {
            const y = _calculateVectorModule(
              foundData.rmms[index][0],
              foundData.rmms[index][1],
              foundData.rmms[index][2]
            )
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "rmmsx":
          {
            const y = foundData.rmms[index][0]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "rmmsy":
          {
            const y = foundData.rmms[index][1]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        case "rmmsz":
          {
            const y = foundData.rmms[index][2]
            newData = _addPoint(x, y, newData, name, macAndName, service)
          }

          break

        default:
          break
      }
    }
    const graphicDataNames = Object.keys(graphicData)
    const newGraphicDataName = Object.keys(newData)[0]

    if (graphicDataNames.includes(newGraphicDataName)) {
      graphicData[newGraphicDataName].data = [
        ...graphicData[newGraphicDataName].data,
        ...newData[newGraphicDataName].data,
      ]
      graphicData[newGraphicDataName].name = newData[newGraphicDataName].name
      graphicData[newGraphicDataName].mac = newData[newGraphicDataName].mac
      graphicData[newGraphicDataName].service =
        newData[newGraphicDataName].service
    } else {
      graphicData = {
        ...graphicData,
        ...newData,
      }
    }
    return graphicData
  }

  function _addPoint(
    x: number,
    y: number,
    newData: GraphicData,
    name: string,
    macAndName: ServiceAndMac,
    service: string
  ): GraphicData {
    if (newData[name]) {
      newData[name].data = [...newData[name].data, [x, y]]
      newData[name].name = name
      newData[name].mac = macAndName.mac
      newData[name].service = service
    } else {
      newData = {
        ...newData,
        [name]: {
          data: [[x, y]],
          name: name,
          mac: macAndName.mac,
          service: service,
        },
      }
    }
    return newData
  }

  function _calculateVectorModule(x: number, y: number, z: number): number {
    let squareX = Math.pow(x, 2)
    let squareY = Math.pow(y, 2)
    let squareZ = Math.pow(z, 2)
    let module = Math.sqrt(squareX + squareY + squareZ)
    let moduleToFixed = module.toFixed(4)
    return Number(moduleToFixed)
  }

  function _getServiceType(
    servicesAvailables: ServicesAvailable[],
    service: string
  ): string | undefined {
    let serviceType = ""

    for (let index = 0; index < servicesAvailables.length; index++) {
      const available = servicesAvailables[index]

      if (service.includes(available.service)) {
        serviceType = available.service
        return serviceType
      }
    }
  }

  function _getServicesAvailable(
    analyticsData: AnalyticConfigDTO
  ): ServicesAvailable[] {
    const SENSOR_SERVICES = [
      "temp",
      "rms2x",
      "rms2xOneAxis",
      "rms2y",
      "rms2yOneAxis",
      "rms2z",
      "rms2zOneAxis",
      "rms2VectorModule",
      "rmmsx",
      "rmmsxOneAxis",
      "rmmsy",
      "rmmsyOneAxis",
      "rmmsz",
      "rmmszOneAxis",
      "rmmsVectorModule",
      "fft",
      "fftx",
      "ffty",
      "fftz",
      "fftVectorModule",
      "accRaw",
      "accRawx",
      "accRawy",
      "accRawz",
      "accRawVectorModule",
      "pot",
      "_4t20ChanelA",
      "_4t20ChanelB",
      "ntcChanelA",
      "ntcChanelB",
      "health",
    ]

    let servicesAvailable: ServicesAvailable[] = []

    SENSOR_SERVICES.forEach((service) => {
      if (analyticsData.sensors && analyticsData.sensors[service]) {
        if (analyticsData.sensors[service].length > 0) {
          const { serviceArray, startDate, endDate } =
            _fillStartDateAndEndDateAndServiceArray(analyticsData, service)

          if (service.includes("temp")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "temp"
            )
          } else if (service.includes("rms2")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "rms2"
            )
          } else if (service.includes("rmms")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "rmms"
            )
          } else if (service.includes("fft")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "fft"
            )
          } else if (service.includes("accRaw")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "accRaw"
            )
          } else if (service.includes("pot")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "pot"
            )
          } else if (service.includes("_4t20")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "_4t20"
            )
          } else if (service.includes("ntc")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "ntc"
            )
          } else if (service.includes("health")) {
            servicesAvailable = _helperPushToServiceAvailableArray(
              servicesAvailable,
              serviceArray,
              startDate,
              endDate,
              "health"
            )
          }
        }
      }
    })

    return servicesAvailable
  }

  function _helperPushToServiceAvailableArray(
    servicesAvailable: ServicesAvailable[],
    serviceArray: ServiceAndMac[],
    startDate: Date,
    endDate: Date,
    serviceName: string
  ): ServicesAvailable[] {
    let serviceAlreadyInArray = servicesAvailable.findIndex(
      (item) => item?.service === serviceName
    )

    if (serviceAlreadyInArray === -1) {
      servicesAvailable.push({
        service: serviceName,
        serviceArray: serviceArray,
        startDate,
        endDate,
      })
    } else {
      // if the object already exists, verify if serviceArray is complete with the new serviceArray
      serviceArray.forEach((item) => {
        let isThisServiceArrayInTheServicesAvailableYet = servicesAvailable[
          serviceAlreadyInArray
        ].serviceArray.some((servAvailItem) => servAvailItem.mac === item.mac)

        if (!isThisServiceArrayInTheServicesAvailableYet) {
          servicesAvailable[serviceAlreadyInArray].serviceArray = Object.assign(
            [],
            servicesAvailable[serviceAlreadyInArray].serviceArray
          )
          servicesAvailable[serviceAlreadyInArray].serviceArray.push(item)
        }
      })
    }

    return servicesAvailable
  }

  function _fillStartDateAndEndDateAndServiceArray(
    analyticsData: AnalyticConfigDTO,
    service: string
  ) {
    let serviceArray = analyticsData.sensors![service]
    let startDate = analyticsData.startDate!
    let endDate = analyticsData.endDate!

    return {
      serviceArray,
      startDate,
      endDate,
    }
  }

  return {
    getData,
    getProcessedFFTData,
    getProcessedAccRawData,
  }
}
