import { FC } from "react"
import * as S from "./styles"
import { ReactComponent as TempSvg } from "../../../../assets/temp_icon.svg"
import { ReactComponent as RssiSVG } from "../../../../assets/rssi_icon.svg"
import { ReactComponent as SensorSN } from "../../../../assets/sensor_id.svg"
import {
  MdCancel,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdCircle,
} from "react-icons/md"
import { VoltageIcon } from "../../../../components/VoltageIcon"
import { AlgorithmCard } from "../AlgorithmCard"
import { InputSensorName } from "../InputSensorName"
import { InformationCard } from "../InformationCard"
import { SensorProv } from "../../../../store/features/sensors/sensors.interfaces"
import { cleanAndPadSerialNumber } from "../../../../utils/cleanAndPadSerialNumber"
import { useSensorsInfoController } from "../../controllers/useSensorsInfoController"

interface SensorCardProps {
  sensorData: SensorProv
}

export const SensorCard: FC<SensorCardProps> = ({ sensorData }) => {
  const { name, sensor, health, bleHdrMac, bleOriginalMac } = sensorData
  const { temp, rssi, voltage, lastReset, time } = health || {}
  const {
    productionSerialNumber,
    protocolVersion,
    hardwareVersion,
    applicationVersion,
    firmwareVersion,
  } = sensor || {}

  const { getAlgorithm, onArrowClick, openAccordion, onResetBtnClick, status } =
    useSensorsInfoController(bleHdrMac)

  const sensorName =
    name?.trim() ||
    `S${cleanAndPadSerialNumber(productionSerialNumber, hardwareVersion)}`
  const algorithms = getAlgorithm(sensorData)

  const checkStatus = (status: boolean): JSX.Element => {
    return status ? (
      <S.TextAndIcon $on={true}>
        <MdCircle size={20} />
      </S.TextAndIcon>
    ) : (
      <S.TextAndIcon $on={false}>
        <MdCircle size={20} />
      </S.TextAndIcon>
    )
  }

  return (
    <S.Container>
      <S.Header onClick={() => onArrowClick(bleHdrMac)}>
        {checkStatus(status)}

        <S.TextAndIcon>
          <S.Text>{sensorName}</S.Text>
        </S.TextAndIcon>

        <S.TextAndIcon>
          <SensorSN width={"20px"} stroke={"black"} height={"18px"} />
          <S.Text>
            {cleanAndPadSerialNumber(
              sensor?.productionSerialNumber,
              hardwareVersion
            )}
          </S.Text>
        </S.TextAndIcon>

        <S.TextAndIcon>
          <TempSvg
            width={"20px"}
            stroke={temp ? "#1D1D1D" : "grey"}
            height={"18px"}
          />
          <S.Text>{temp && `${temp} °C`} </S.Text>
        </S.TextAndIcon>
        <S.TextAndIcon>
          <VoltageIcon voltage={voltage} />
          <S.Text>{voltage && `${voltage} V`}</S.Text>
        </S.TextAndIcon>
        <S.TextAndIcon>
          <RssiSVG width={"20px"} fill={rssi ? "#1D1D1D" : "grey"} />
          <S.Text>{rssi && `${rssi} dBm`}</S.Text>
        </S.TextAndIcon>

        <S.TextAndIcon>
          <S.ResetButton
            onClick={(e) => onResetBtnClick(e, bleHdrMac, bleOriginalMac)}
          >
            &nbsp;RESET &nbsp;
            <MdCancel size={20} />
          </S.ResetButton>
        </S.TextAndIcon>

        <S.TextAndIcon>
          {openAccordion ? (
            <MdKeyboardArrowUp size={22} />
          ) : (
            <MdKeyboardArrowDown size={22} />
          )}
        </S.TextAndIcon>
      </S.Header>

      {openAccordion && (
        <>
          <S.DetailGroup>
            <InputSensorName bleHdrMac={bleHdrMac} />
            <InformationCard
              mac={bleHdrMac}
              protocolVersion={protocolVersion}
              hardwareVersion={hardwareVersion}
              applicationVersion={applicationVersion}
              lastReset={lastReset}
              time={time}
              firmwareVersion={firmwareVersion}
            />
          </S.DetailGroup>
          <S.AlgorithmGroup>
            {Object.keys(algorithms).map((algorithm: string, index) => (
              <AlgorithmCard
                key={index}
                bleHdrMac={bleHdrMac}
                algorithm={algorithms}
                serviceName={
                  algorithm as
                    | "temp"
                    | "rms2"
                    | "rmms"
                    | "tilt"
                    | "fft"
                    | "accRaw"
                    | "_4t20"
                    | "ntc"
                    | "pot"
                }
              />
            ))}
          </S.AlgorithmGroup>
        </>
      )}
    </S.Container>
  )
}
