import { TypedUseSelectorHook, useDispatch } from "react-redux"
import { AppDispatch, RootState } from "./store"
import { useSelector } from "react-redux"
/**
 * This is a abstraction, to use react redux, with typescript on code
 * useDispatch > useAppDispatch
 */
export const useAppDispatch: () => AppDispatch = useDispatch

/**
 * This is a abstraction, to use react redux, with typescript on code
 * useSelector > useAppSelector
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
