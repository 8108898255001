import styled from "styled-components"

export const HourMeterTable = styled.div`
  margin-top: 5px;
  width: 100%;
  padding: 5px 0 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: small;
`
export const Title = styled.h3`
  font-size: small;
`

export const HourMeterTableInfo = styled.div``

export const HourMeterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  span {
    margin-bottom: 3px;
  }
`

export const Wrapper = styled.div`
  border-radius: 2px;
  border: 1px solid #c9c8c8;
  padding: 5px 0px 0 5px;
`
