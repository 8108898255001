import styled from "styled-components"
import { BsGear } from "react-icons/bs"

export const ConfigIconWrapper = styled.div`
  text-align: right;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-height: 40px;
  padding-left: 5px;
`

export const GearIcon = styled(BsGear)`
  cursor: pointer;
`

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
`

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: medium;
  cursor: pointer;
  text-transform: uppercase;
  gap: 5px;
  color: #201c1c;
  &:hover {
    transition: background-color 0.3s;
    background-color: #2684da;
    color: #fff;
  }
`
