import {
  SensorAvailablesServices,
  services,
} from "../../../store/features/reports/reports.interfaces"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"
import { SensorWithServicesDTO } from "../../../utils/entities/sensors/SensorsWithServicesDTO"

export const useFilterSensorsWithData = () => {
  const getAllAvailablesServices = (
    sensorsWithData: SensorWithServicesDTO[]
  ) => {
    const allServices = [
      "temp",
      "rms2",
      "rmms",
      "fft",
      "accRaw",
      "ntc",
      "pot",
      "_4t20",
      "health",
    ]
    const newAvailablesServices: SensorAvailablesServices[] = []
    if (!sensorsWithData.length) {
      return newAvailablesServices
    }
    sensorsWithData?.forEach((element) => {
      const auxService: services[] = []

      allServices.forEach((service) => {
        let lastData: string = "Sem dados"
        let hasData: boolean = false
        element.services.forEach((serviceNameAndLastData) => {
          if (serviceNameAndLastData.nameService === service) {
            lastData = serviceNameAndLastData.lastData
            hasData = true
          }
        })
        const graphicType: number[] | null = _getGraphicType(service)
        if (graphicType === null) {
          return
        }
        const newService: services = {
          nameService: service,
          lastData: lastData,
          type: graphicType,
          hasData: hasData,
        }
        auxService.push(newService)
      })

      const auxSensorWithData: SensorAvailablesServices = {
        serialNumber: cleanAndPadSerialNumber(
          element.serialNumber,
          element.hardwareVersion
        ),
        mac: element.mac,
        name: element.name || null,
        id: element.id,
        services: auxService,
      }
      newAvailablesServices.push(auxSensorWithData)
    })

    return newAvailablesServices
  }

  function _getGraphicType(service: string): number[] | null {
    const type1Services = ["TEMP", "RMMS", "NTC", "POT", "_4T20", "HEALTH"]
    const type2Services = ["FFT"]
    const type3Services = ["ACCRAW"]
    const type4Services = ["RMS2"]

    const serviceName = service.toUpperCase()
    if (type1Services.includes(serviceName)) {
      return [1]
    } else if (type2Services.includes(serviceName)) {
      return [2]
    } else if (type3Services.includes(serviceName)) {
      return [3]
    } else if (type4Services.includes(serviceName)) {
      return [1, 4]
    }
    return null
  }

  return { getAllAvailablesServices }
}
