import ReactDOM from "react-dom/client"
// import reportWebVitals from "./reportWebVitals";
import App from "./App"
import { Provider } from "react-redux"
import { persisted, store } from "./store/store"
import { PersistGate } from "redux-persist/integration/react"

import { ThemeProvider } from "styled-components"
import theme from "./styles/theme"
import GlobalStyles from "./styles/global"
import "./translate/i18n"
import { LoadingSpinner } from "./components/LoadingSpinner"
import { AuthenticationProvider } from "./utils/authentication/Provider"
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<LoadingSpinner />} persistor={persisted}>
      <AuthenticationProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </AuthenticationProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
