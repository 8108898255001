import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`

export const ContainerHourMeterRMS2 = styled.div`
  width: 50%;
  align-items: center;
`
export const ContainerHourMeter = styled.div`
  width: 50%;
  align-items: center;
`

export const HourMeterTable = styled.div`
  height: 50px;
  border-radius: 2px;
  border: 1px solid #c9c8c8;
  padding: 10px;
`

export const HourMeterTableInfo = styled.div`
  height: 50px;
`
