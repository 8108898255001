import styled from "styled-components"

export const SensorsInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  margin: 10px;
`
export const TextAndIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  width: 130px;
  gap: 5px;
`
export const Text = styled.h2`
  font-size: small;
`
