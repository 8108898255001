import { createSlice } from "@reduxjs/toolkit"
import { Socket, io } from "socket.io-client"

export interface InitialState {
  socket: Socket | null
  events: string[]
}

const createSocket = (): Socket => {
  const accessToken = sessionStorage.getItem(
    sessionStorage.key(0) as string
  ) as string

  return io(process.env.REACT_APP_WS_SERVER_URL ?? "", {
    autoConnect: true,
    transports: ["websocket"],
    query: {
      accessToken: accessToken,
    },
  })
}

const initialState: InitialState = {
  events: [],
  socket: createSocket(),
}

const socketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    disconnectSocket: (state) => {
      if (state.socket) state.socket.close()
    },
  },
})

export const { disconnectSocket } = socketSlice.actions

export default socketSlice.reducer
