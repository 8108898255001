import { combineReducers } from "@reduxjs/toolkit"
import dashboardReducer from "./features/dashboard/dashboard.slice"
import datadReducer from "./features/data/data.slice"
import socketReducer from "./features/socket/socket.slice"
import sensorsReducer from "./features/sensors/sensors.slice"
import userReducer from "./features/user/user.slice"
import gatewaysReducer from "./features/gateways/gateways.slice"
import companiesReducer from "./features/companies/companies.slice"
import productsReducer from "./features/products/products.slice"
import salesReducer from "./features/sales/sales.slice"
import reportsReducer from "./features/reports/reports.slice"
import historicalAnalysisReducer from "./features/historicalAnalysis/historicalAnalysis.slice"
import alarmsLogsReducer from "./features/alarms_logs/alarms_logs.slice"

export const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  data: datadReducer,
  socket: socketReducer,
  sensors: sensorsReducer,
  user: userReducer,
  gateways: gatewaysReducer,
  companies: companiesReducer,
  products: productsReducer,
  sales: salesReducer,
  reports: reportsReducer,
  historicalAnalysis: historicalAnalysisReducer,
  alarmsLogs: alarmsLogsReducer,
})
