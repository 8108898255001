import { FC } from "react"
import * as S from "./styles"
import { useDeleButtonController } from "./hooks/useDeleteButtonController"

interface DeleteButtonProps {
  label: string
  waitingLabel: string
  onClick: () => void
  disabled?: boolean
  disableTimeInSeconds: number
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  label,
  waitingLabel,
  onClick,
  disabled = false,
  disableTimeInSeconds,
}) => {
  const { isDisabled, counter } = useDeleButtonController({
    disableTimeInSeconds,
  })

  return (
    <S.Button
      data-testid='delete-button'
      name='save'
      onClick={onClick}
      disabled={disabled || isDisabled}
    >
      {disabled || isDisabled ? `${waitingLabel} (${counter})` : label}
    </S.Button>
  )
}
