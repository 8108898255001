import {
  SensorConfig,
  SensorProv,
  ServiceApi,
} from "../../../store/features/sensors/sensors.interfaces"
import { getHealthApi } from "../../../store/features/sensors/sensors.utilities"
import { HealthResponseApiDTO } from "../../../utils/entities/sensors/HealthResponseApiDTO"
import { HealthDTO } from "../../../utils/entities/sensors/services/healthDTO"
import { getHealth } from "hdr-process-data"
import {
  MetadataNewNameDTO,
  ObjectMetadataNewNameDTO,
} from "../entities/MetadataNewNameDTO"
import { SensorsFormattedDTO } from "../entities/SensorsFormattedObjDTO"
import { HealthFormattedDTO } from "../../../utils/entities/sensors/HealthFormattedDTO"
const HDR_SERVICE_TYPE = [
  "health",
  "temp",
  "tempMMM",
  "rms2",
  "rmms",
  "tilt",
  "fft",
  "accRaw",
  "gps",
  "_4t20",
  "ntc",
  "pot",
]

const METADATA_NEW_NAMES: MetadataNewNameDTO = {
  axis: "axis",
  sen: "sens",
  res: "res",
  freq: "freq",
  nsCalc: "nsCalc",
  tSample: "tsa",
  nSample: "nse",
  bwLow: "freqLow",
  bwHigh: "freqHigh",
  channel: "channel",
  header: "header",
  payload: "payload",
  msgType: "msgType",
  incrementalNumber: "incrementalNumber",
}
export const useFormattedSensorsController = () => {
  const processHealthData = (data: HealthResponseApiDTO[]) => {
    const health = [] as HealthDTO[]
    data.forEach((element) => {
      const resultHeath = getHealth(element.raw, element.time)
      if (resultHeath.isRight())
        health.push({
          mac: element.mac,
          rssi: element.rssi,
          ...resultHeath.value,
        })
    })
    return health
  }

  const getHealthProcessed = async (sensorsProvisioned: SensorProv[]) => {
    let macs = sensorsProvisioned
      .map((sensor) => sensor.bleHdrMac)
      .filter(Boolean)
    const allMacs = macs.toString().replace("[", "").replace("]", "")
    const health: HealthResponseApiDTO[] = await getHealthApi(allMacs)
    const healthProcessed = processHealthData(health)
    return healthProcessed
  }

  const getSensorsAndServicesFormatted = (
    sensorsProvisioned: SensorProv[],
    sensorsConfigured: SensorConfig[],
    healths: HealthDTO[]
  ) => {
    const provSensorWithConfiguredServicesObject =
      getProvSensorWithConfiguredServices(sensorsProvisioned, sensorsConfigured)
    return _transformObjectInArrayAndAddHealth(
      provSensorWithConfiguredServicesObject,
      healths
    )
  }

  const getProvSensorWithConfiguredServices = (
    sensorsProvisioned: SensorProv[],
    sensorsConfigured: SensorConfig[]
  ) => {
    const obj: SensorsFormattedDTO = {}

    sensorsProvisioned.forEach((elementSensorProv, index) => {
      obj[elementSensorProv.bleHdrMac] = elementSensorProv
      sensorsConfigured.forEach((elementSensorConfig) => {
        if (elementSensorConfig.mac === elementSensorProv.bleHdrMac) {
          elementSensorConfig.services.forEach((elementService) => {
            if (HDR_SERVICE_TYPE.includes(elementService.name)) {
              obj[elementSensorProv.bleHdrMac] = {
                ...obj[elementSensorProv.bleHdrMac],
                [elementService.name]: _changeAlgorithmMetadataNames(
                  elementService.config
                ),
              }
            }
          })
        }
      })
    })
    return obj
  }

  const _transformObjectInArrayAndAddHealth = (
    provSensorWithConfiguredServicesObject: SensorsFormattedDTO,
    healths: HealthDTO[]
  ) => {
    const array = [] as SensorProv[]

    Object.keys(provSensorWithConfiguredServicesObject).forEach((key) => {
      array.push({
        ...provSensorWithConfiguredServicesObject[key],
        health: healths.find(
          (health) => health.mac === key
        ) as HealthFormattedDTO,
      })
    })

    return array
  }

  const _changeAlgorithmMetadataNames = (metadataObj: ServiceApi) => {
    if (!metadataObj) return {}
    const obj: ObjectMetadataNewNameDTO = {}

    Object.keys(metadataObj).forEach((key) => {
      let newKey = METADATA_NEW_NAMES[key as keyof MetadataNewNameDTO]
      obj[newKey as keyof ObjectMetadataNewNameDTO] =
        metadataObj[
          key as
            | "axis"
            | "incrementalNumber"
            | "channel"
            | "freq"
            | "nsCalc"
            | "tSample"
            | "nSample"
            | "channel"
            | "header"
            | "payload"
            | "incrementalNumber"
        ]
    })
    return obj
  }

  return { getHealthProcessed, getSensorsAndServicesFormatted }
}
