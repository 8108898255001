import React, { createContext, ReactNode, useRef, useState } from "react"
import {
  AnalyticPeriod,
  DashboardContextDTO,
} from "../entities/DashboardContextDTO"
import { Analytics } from "../../../store/features/dashboard/dashboard.interfaces"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { SensorAndService } from "../../../components/Modal/components/SensorAndServices/entities/SensorAndService"
import { closeModal } from "../../../store/features/modal/modal.slice"
import { resetDashboardError } from "../../../store/features/dashboard/dashboard.slice"
import { CheckboxState } from "../../../components/Modal/components/SensorAndServices/entities/CheckboxServiceStateDTO"

interface DashboardProviderProps {
  children: ReactNode
}

export const DashboardContext = createContext<DashboardContextDTO | null>(null)

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch()

  const [selectedTypeChart, setSelectedTypeChart] = useState<1 | 2 | 3 | null>(
    null
  )
  const [selectedPeriod, setSelectedPeriod] = useState<number>(
    AnalyticPeriod.NO_SELECTED
  )
  const [graphicName, setGraphicName] = useState<string>("")
  const [orientedBy, setOrientedBy] = useState<"sensor" | "entity">("sensor")

  const sensorAndServices = useRef<SensorAndService[]>([])

  const analyticIdToDelete = useRef<number | null>(null)
  const dashboardIdToDelete = useRef<number | null>(null)

  const sensors = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const getCheckboxStateInitialState = () => {
    const state: CheckboxState = {}

    sensors.forEach((sensor) => {
      sensor.services.forEach((service) => {
        if (!service.axis) {
          state[`${sensor.mac}/${service.name}`] = false
        } else {
          service.axis.forEach((ax) => {
            state[`${sensor.mac}/${service.name}/${ax}`] = false
          })
        }
      })
    })

    return state
  }
  const [checkboxState, setCheckboxState] = useState<CheckboxState>(
    getCheckboxStateInitialState()
  )

  const analyticToPlot = useRef<Analytics | null>(null)

  const handleCloseDashboardModal = (): void => {
    dispatch(closeModal())
    setCheckboxState(getCheckboxStateInitialState())
    sensorAndServices.current = []
    dispatch(resetDashboardError())
    setSelectedPeriod(AnalyticPeriod.NO_SELECTED)
    setSelectedTypeChart(1)
    setGraphicName("")
    setOrientedBy("sensor")
  }

  const defaultContext: DashboardContextDTO = {
    getCheckboxStateInitialState,
    sensorAndServices,
    analyticToPlot,
    selectedTypeChart,
    setSelectedTypeChart,
    setSelectedPeriod,
    selectedPeriod,
    graphicName,
    setGraphicName,
    analyticIdToDelete,
    setOrientedBy,
    orientedBy,
    dashboardIdToDelete,
    handleCloseDashboardModal,
    checkboxState,
    setCheckboxState,
  }

  return (
    <DashboardContext.Provider value={defaultContext}>
      {children}
    </DashboardContext.Provider>
  )
}
