import { useContext } from "react"
import { SensorAndService } from "../entities/SensorAndService"
import { FormattedSensorsAndServices } from "../entities/FormattedSensorsAndServices"
import { DashboardContext } from "../../../../../pages/Dashboard/context/DashboardContext"
import {
  DashboardContextDTO,
  AnalyticPeriod,
} from "../../../../../pages/Dashboard/entities/DashboardContextDTO"
import { postAnalytics } from "../../../../../store/features/dashboard/dashboard.api"
import { resetDashboardError } from "../../../../../store/features/dashboard/dashboard.slice"
import {
  closeModal,
  openModal,
} from "../../../../../store/features/modal/modal.slice"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { CheckboxState } from "../entities/CheckboxServiceStateDTO"

export const useSensorAndServicesController = () => {
  const {
    sensorAndServices,
    analyticToPlot,
    setGraphicName,
    setOrientedBy,
    setSelectedTypeChart,
    setSelectedPeriod,
    setCheckboxState,
    checkboxState,
  } = useContext(DashboardContext) as DashboardContextDTO

  const dispatch = useAppDispatch()

  const dashboard = useAppSelector((state) => state.persistedReducer.dashboard)

  const sensorsConfig = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const isLoading = dashboard.status === "loading"
  const hasError = dashboard.status === "failed"

  const cleanVariables = (): void => {
    setCheckboxState({} as CheckboxState)
    setSelectedPeriod(AnalyticPeriod.NO_SELECTED)
    setSelectedTypeChart(1)
    setGraphicName("")
    setOrientedBy("sensor")
  }

  /**
   * Function that formats services and sensors to send to the request
   * @param {SensorAndService[]} sensorAndServices - Selected sensors and services
   * @returns {FormattedSensorsAndServices} - Returns the formatted sensors and services
   */
  const createsObject = (
    sensorAndServices: SensorAndService[]
  ): FormattedSensorsAndServices => {
    let temporarySensors: FormattedSensorsAndServices = {}

    sensorAndServices.forEach((item) => {
      const sensor = item.configs
        ? { mac: item.mac, name: item.name, configs: item.configs }
        : { mac: item.mac, name: item.name }

      let service =
        item.axis && item.axis === "module"
          ? item.name + "VectorModule"
          : item.service

      if (
        temporarySensors[service] !== undefined &&
        temporarySensors[service] !== null
      ) {
        temporarySensors[service].push(sensor)
      } else {
        temporarySensors[service] = [sensor]
      }
    })
    return temporarySensors
  }

  const handleCloseModal = (): void => {
    cleanVariables()
    sensorAndServices.current = []
    dispatch(resetDashboardError())
    dispatch(closeModal())
  }

  /**
   * Check if you have services selected to enable the finish button
   * @returns {boolean} - Returns true if the button should be disabled
   */
  const buttonIsDisabled = () => {
    let disabled = true

    Object.keys(checkboxState).forEach((key) => {
      if (checkboxState[key]) {
        disabled = false
      }
    })

    return disabled
  }

  const handleBackModal = (): void => {
    cleanVariables()
    sensorAndServices.current = []
    dispatch(closeModal())
    dispatch(
      openModal({
        modal: "AnalyticConfig",
      })
    )
  }

  const handleCreateAnalytic = (): void => {
    if (!analyticToPlot.current) {
      console.error("No data to create analytic!")
      return
    }

    analyticToPlot.current = {
      ...analyticToPlot.current,
      sensors: createsObject(sensorAndServices.current),
    }

    dispatch(postAnalytics(analyticToPlot.current)).then((e) => {
      if (e.meta.requestStatus === "fulfilled") {
        analyticToPlot.current = null
        sensorAndServices.current = []

        cleanVariables()

        dispatch(closeModal())
      }
    })
  }

  return {
    isLoading,
    hasError,
    sensorsConfig,
    handleCloseModal,
    handleBackModal,
    handleCreateAnalytic,
    buttonIsDisabled,
  }
}
