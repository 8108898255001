import { HDR_SERVICES_TYPE } from "hdr-process-data"
import {
  SensorApiResponse,
  SensorConfig,
  Service,
  ServiceApi,
} from "./sensors.interfaces"
import { Axis } from "../../../utils/entities/Axis"
import { ErrorTranslatorDTO } from "../../../pages/SensorsProv/entities/ErrorTranslator"
import { apiCall } from "../../../utils/axios"
import { Result, left, right } from "../../../utils/either/result"
import { ErrorDTO } from "../../../utils/entities/Error"
import { HealthResponseApiDTO } from "../../../utils/entities/sensors/HealthResponseApiDTO"
import { ErroTranslatorConfigDTO } from "../../../pages/SensorsConfig/entities/ErrorTranslatorConfigDTO"
import { ConfigObjectDTO } from "../../../pages/SensorsConfig/entities/ConfigObjectDTO"
import { ResetResquestDTO } from "../../../pages/SensorsConfig/entities/ResetRequestDTO"
import { ErrorTranslatorResetDTO } from "../../../pages/SensorsConfig/entities/ErrorTranslatorResetDTO"

interface SensorsObject {
  [mac: string]: {
    serialNumber: string
    name: string
    id: number
    services: {
      [type: number]: {
        axis?: string[]
        config: ServiceApi
      }
    }
  }
}

export const buildSensorsConfig = (
  sensorsApi: SensorApiResponse[]
): SensorConfig[] => {
  const sensorsObject: SensorsObject = {}
  for (let i = 0; i < sensorsApi.length; i++) {
    const sensorApi = sensorsApi[i]
    const { mac, serviceType, productionSerialNumber, name, id } = sensorApi

    const serviceName = getServiceName(serviceType)

    if (!sensorsObject[mac]) {
      sensorsObject[mac] = {
        serialNumber: productionSerialNumber,
        name,
        id,
        services: {},
      }
    }

    if (!sensorsObject[mac].services[serviceType]) {
      if (sensorApi[serviceName]) {
        const serviceApi = sensorApi[serviceName] as ServiceApi

        sensorsObject[mac].services[serviceType] = {
          axis: getAxisList(serviceApi.axis, serviceApi.channel, serviceType),
          config: serviceApi,
        }
      }
    }
  }

  const sensors: SensorConfig[] = []

  Object.keys(sensorsObject).forEach((mac) => {
    sensors.push({
      mac,
      id: sensorsObject[mac].id,
      name: sensorsObject[mac].name,
      serialNumber: sensorsObject[mac].serialNumber,
      services: Object.keys(sensorsObject[mac].services).map((type) => {
        return {
          name: getServiceName(Number(type)),
          axis: sensorsObject[mac].services[Number(type)].axis,
          type: Number(type),
          config: sensorsObject[mac].services[Number(type)].config,
        }
      }) as Service[],
    })
  })

  return sensors
}

const getAxisList = (
  axis: string | undefined,
  channel: string | undefined,
  type: HDR_SERVICES_TYPE
): Axis[] | undefined => {
  if (type === HDR_SERVICES_TYPE.tilt) return ["pitch", "roll"]

  if (!axis && !channel) return undefined

  if (axis) {
    if (axis === "all") return ["x", "y", "z", "module"]

    return [axis] as Axis[]
  }

  if (channel) {
    if (channel === "ab") return ["a", "b"]

    return [channel] as Axis[]
  }
}

const getServiceName = (type: HDR_SERVICES_TYPE): string => {
  switch (type) {
    case HDR_SERVICES_TYPE.temp:
      return "temp"
    case HDR_SERVICES_TYPE.rms2:
      return "rms2"
    case HDR_SERVICES_TYPE._4t20:
      return "_4t20"
    case HDR_SERVICES_TYPE.accRaw:
      return "accRaw"
    case HDR_SERVICES_TYPE.fft:
      return "fft"
    case HDR_SERVICES_TYPE.gps:
      return "gps"
    case HDR_SERVICES_TYPE.health:
      return "health"
    case HDR_SERVICES_TYPE.ntc:
      return "ntc"
    case HDR_SERVICES_TYPE.pot:
      return "pot"
    case HDR_SERVICES_TYPE.rmms:
      return "rmms"
    case HDR_SERVICES_TYPE.tempMMM:
      return "tempmmm"
    case HDR_SERVICES_TYPE.tilt:
      return "tilt"
  }
}

export const getProvisionedSensorByMacUseCase = async (
  mac: string
): Promise<Result<string, "provisioned" | "available">> => {
  try {
    const api = apiCall()
    const URL = `${process.env.REACT_APP_API_PROVISIONING_UPDATE}/${mac}`
    const headers = {
      usemac: "true",
    }

    const result = await api.get(URL, { headers: headers })
    const { provStatus }: { provStatus: "provisioned" | "available" } =
      result.data

    return right(provStatus)
  } catch {
    return left("error")
  }
}

export const requestProv = async (
  mac: string,
  adv: string,
  ERROR_TRANSLATOR: ErrorTranslatorDTO
) => {
  try {
    const api = apiCall()
    const URL = `${process.env.REACT_APP_API_PROVISIONING_REQUEST}`
    const body = {
      bleOriginalMac: mac,
      adv,
    }

    await api.post(URL, body)

    return {
      success: true,
      message: "Sensor está sendo provisionado...",
    }
  } catch (error) {
    console.table(error)
    const message = (error as ErrorDTO).response?.data?.message
    return {
      success: false,
      message:
        ERROR_TRANSLATOR[message as keyof typeof ERROR_TRANSLATOR] ||
        "Erro desconhecido",
    }
  }
}

export const getHealthApi = async (
  sensors: string
): Promise<HealthResponseApiDTO[]> => {
  const api = apiCall()
  const { data } = await api.get(`${process.env.REACT_APP_API_HEALTH}`, {
    headers: {
      macs: sensors.toUpperCase(),
      limit: 1,
    },
  })
  return data as HealthResponseApiDTO[]
}

export const sendConfig = async (
  configObj: ConfigObjectDTO,
  ERROR_TRANSLATOR: ErroTranslatorConfigDTO
) => {
  const api = apiCall()

  try {
    await api.post(
      `${process.env.REACT_APP_API_CONFIGURATION_REQUEST}`,
      configObj
    )
    return {
      success: true,
      message: "Sensor está sendo configurado...",
    }
  } catch (err) {
    const message = (err as ErrorDTO)?.response?.data?.message
    return {
      success: false,
      message:
        ERROR_TRANSLATOR[message as keyof typeof ERROR_TRANSLATOR] ||
        "Erro desconhecido",
    }
  }
}

export const sendResetRequest = async (
  resetRequest: ResetResquestDTO,
  ERROR_TRANSLATOR: ErrorTranslatorResetDTO
) => {
  const api = apiCall()

  try {
    await api.post(`${process.env.REACT_APP_API_RESET_REQUEST}`, resetRequest)
    return {
      success: true,
      message: "Sensor está sendo resetado...",
    }
  } catch (err) {
    const message = (err as ErrorDTO)?.response?.data?.message
    return {
      success: false,
      message:
        ERROR_TRANSLATOR[message as keyof typeof ERROR_TRANSLATOR] ||
        "Erro desconhecido",
    }
  }
}
