export const formatedDate = (value: string | number): string => {
  if (typeof value === "string") {
    return displayStringDate(value)
  }

  return isDateInMilliseconds(value)
    ? displayMillisecondsDate(value)
    : displaySecondsDate(value)
}

export const displayStringDate = (value: string): string => {
  const date = convertStringToDate(value)
  return displayDateToLocaleString(date)
}

export const isDateInMilliseconds = (value: number): boolean => {
  return value > 1e12
}

export const displayMillisecondsDate = (value: number): string => {
  const date = convertMillisecondsToDate(value)
  return displayDateToLocaleString(date)
}

export const displaySecondsDate = (value: number): string => {
  const date = convertSecondsToDate(value)
  return displayDateToLocaleString(date)
}

const convertStringToDate = (value: string): Date => {
  return new Date(value)
}

const convertMillisecondsToDate = (value: number): Date => {
  return new Date(value)
}

const convertSecondsToDate = (value: number): Date => {
  return new Date(value * 1000)
}

const displayDateToLocaleString = (date: Date): string => {
  return date.toLocaleString()
}
