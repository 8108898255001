import * as S from "./styles"
import { Title } from "../../../Title"

interface LoadingProps {
  loadingMessage: string
  children?: JSX.Element
}

export const Loading = ({
  loadingMessage,
  children,
}: LoadingProps): JSX.Element => {
  return (
    <S.Container>
      <Title size={"medium"} label={loadingMessage} />
      {children}
    </S.Container>
  )
}
