import React from "react"
import { Buttons, ClearButton, Container, Title } from "./styles"
import { CompanyApiDTO } from "../../services/callApiToGetAllCompanies"
import { UserFormDTO } from "../../entities/userForm"
import Select from "../Select"
import Input from "../Input"
import { FormButton } from "../FormButton"

export interface PermissionLevelDTO {
  name: "Usuário" | "Visualizador" | "Administrador"
  value: "user" | "viewer" | "admin"
}

export const PermissionLevel: PermissionLevelDTO[] = [
  { name: "Usuário", value: "user" },
  { name: "Visualizador", value: "viewer" },
  { name: "Administrador", value: "admin" },
]

export interface FormUserProps {
  register: (data: UserFormDTO, createOrEdit: "cadastrar" | "editar") => void
  changeCompany: (selectedIndex: number) => void
  changePermissionLevel: (selectPermission: "user" | "viewer" | "admin") => void
  changeData: (key: string, value: string) => void
  clearForm: () => void
  handleBackFcn: () => void
  data: UserFormDTO
  companies: CompanyApiDTO[]
  createOrEdit: "cadastrar" | "editar"
}

export const FormUser: React.FC<FormUserProps> = ({
  register,
  changeCompany,
  changeData,
  clearForm,
  data,
  companies,
  changePermissionLevel,
  handleBackFcn,
  createOrEdit,
}) => {
  return (
    <Container>
      <Title>
        <h1>Informações</h1>
      </Title>

      {createOrEdit === "cadastrar" && (
        <Select
          items={companies}
          type='company'
          change={(selectedIndex: number) => changeCompany(selectedIndex)}
          defaultValue={data.company?.id}
        />
      )}

      {createOrEdit === "cadastrar" && (
        <Select
          items={PermissionLevel}
          type='permissionLevel'
          change={(selectedIndex) =>
            changePermissionLevel(PermissionLevel[selectedIndex].value)
          }
        />
      )}

      <form>
        <Input
          onChange={(e) => changeData("position", e.target.value)}
          value={data.position}
          label={"Posição"}
          type='text'
        />
        <Input
          onChange={(e) => changeData("name", e.target.value)}
          value={data.name}
          label={"Nome"}
          type='text'
        />
        <Input
          onChange={(e) => changeData("lastName", e.target.value)}
          value={data.lastName}
          label={"Sobrenome"}
          type='text'
        />
        <Input
          onChange={(e) => changeData("phone", e.target.value)}
          value={data.phone}
          label={"Telefone"}
          type='tel'
        />
        {createOrEdit === "cadastrar" && (
          <Input
            onChange={(e) => changeData("email", e.target.value)}
            value={data.email}
            label={"Email"}
            type='text'
          />
        )}
        {createOrEdit === "cadastrar" && (
          <Input
            onChange={(e) => changeData("password", e.target.value)}
            value={data.password}
            label={"Senha"}
            type='password'
          />
        )}
        {createOrEdit === "cadastrar" && (
          <Input
            onChange={(e) => changeData("confirmPassword", e.target.value)}
            value={data.confirmPassword}
            label={"Confirmar senha"}
            type='password'
          />
        )}
      </form>

      <Buttons>
        <FormButton
          callBack={() => register(data, createOrEdit)}
          text={createOrEdit.toUpperCase()}
        />
        <FormButton
          callBack={() => handleBackFcn()}
          text='VOLTAR'
          $background='#a1a1a1'
        />
        <ClearButton onClick={() => clearForm()}>Limpar Formulário</ClearButton>
      </Buttons>
    </Container>
  )
}
