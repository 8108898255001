import { FC } from "react"
import { useModalDeleteController } from "./hooks/useModalDeleteController"
import { BsTrash } from "react-icons/bs"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { BackButton, DeleteButton } from "../../../Buttons"
import { Loading } from "../Loading"

import * as S from "./styles"
import { Error } from "../Error"
import theme from "../../../../styles/theme"

interface DeleteProps {
  type: "DashboardDelete" | "AnalyticDelete"
}

export const Delete: FC<DeleteProps> = ({ type }) => {
  const {
    isLoading,
    hasError,
    timeToActivateTheButton,
    subtitle,
    loadingMessage,
    errorMessage,
    deleteMessage,
    onClose,
    onDelete,
    onBack,
  } = useModalDeleteController({ type })

  const { buttons } = useAppTranslate()

  if (hasError) {
    return (
      <Error
        errorMessage={errorMessage}
        labelBackButton={buttons.back}
        onBack={() => onBack()}
      />
    )
  }

  if (isLoading) {
    return <Loading loadingMessage={loadingMessage} />
  }

  return (
    <>
      <S.ModalBody>
        <S.ModalTitle>{subtitle}</S.ModalTitle>
        <BsTrash color={theme.colors.red} size={150} />
        <S.DeleteMessage>{deleteMessage}</S.DeleteMessage>
      </S.ModalBody>

      <S.ModalFooter>
        <BackButton label={buttons.back} onClick={() => onClose()} />

        <DeleteButton
          label={buttons.delete}
          waitingLabel={buttons.wait}
          onClick={() => onDelete(type)}
          disableTimeInSeconds={timeToActivateTheButton}
        />
      </S.ModalFooter>
    </>
  )
}
