import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { closeModal } from "../../../store/features/modal/modal.slice"
import { useContext } from "react"
import { DashboardContext } from "../../../pages/Dashboard/context/DashboardContext"
import { DashboardContextDTO } from "../../../pages/Dashboard/entities/DashboardContextDTO"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { SensorsProvContext } from "../../../pages/SensorsProv/context/SensorsProvContext"
import { SensorsProvContextDTO } from "../../../pages/SensorsProv/entities/SensorsProvContextDTO"
import { SensorsConfigContext } from "../../../pages/SensorsConfig/context/SensorConfigContext"
import { SensorsConfigContextDTO } from "../../../pages/SensorsConfig/entities/SensorsConfigContextDTO"
import { HistoricalAnalysisContext } from "../../../pages/HistoricalAnalysis/context/modelContext"
import { ContextAnaliseHistorica } from "../../../pages/HistoricalAnalysis/entities/contextDTO"

export interface RootProps {
  title: string
  width: string
  height: string
  minWidth: string
  minHeight: string
  maxWidthOverflow: string
  maxWidth: string
  maxHeight: string
  draggable?: boolean
  onClose: () => void
}

export const useModalController = () => {
  const dispatch = useAppDispatch()

  const { activeModal } = useAppSelector((state) => state.modalReducer)

  const dashboardContext = useContext(
    DashboardContext
  ) as DashboardContextDTO | null
  const sensorProvContext = useContext(
    SensorsProvContext
  ) as SensorsProvContextDTO | null
  const sensorsConfigContext = useContext(
    SensorsConfigContext
  ) as SensorsConfigContextDTO | null
  const historicalAnalysisContext = useContext(
    HistoricalAnalysisContext
  ) as ContextAnaliseHistorica | null

  const {
    modalAnalyticConfig,
    modalEditAnalytic,
    modalEditDash,
    modalDeleteAnalytic,
    modalDeleteDash,
    modalConfirmation,
  } = useAppTranslate()

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const getProps = (): RootProps => {
    const defaultProps: RootProps = {
      width: "60%",
      height: "auto",
      minWidth: "none",
      minHeight: "none",
      maxWidthOverflow: "820px",
      maxWidth: "100%",
      maxHeight: "95%",
      title: "",
      draggable: false,
      onClose: handleCloseModal,
    }

    if (!activeModal) return defaultProps
    switch (activeModal) {
      case "AnalyticConfig":
        return {
          ...defaultProps,
          title: modalAnalyticConfig.modal_title,
          maxWidth: "1250px",
          onClose:
            dashboardContext !== null
              ? dashboardContext.handleCloseDashboardModal
              : handleCloseModal,
        }

      case "AnalyticEdit":
        return {
          ...defaultProps,
          title: modalEditAnalytic.modal_title,
          width: "45%",
          minHeight: "250px",
          maxWidth: "800px",
        }

      case "DashboardCreate":
        return {
          ...defaultProps,
          title: modalEditDash.modal_title_create,
          width: "50%",
          maxWidth: "800px",
        }

      case "DashboardEdit":
        return {
          ...defaultProps,
          title: modalEditDash.modal_title_edit,
          width: "50%",
          maxWidth: "800px",
        }

      case "SelectSensorsAndServices":
        return {
          ...defaultProps,
          title: modalAnalyticConfig.modal_title,
          maxWidth: "1250px",
          maxWidthOverflow: "700px",
          onClose:
            dashboardContext !== null
              ? dashboardContext.handleCloseDashboardModal
              : handleCloseModal,
        }

      case "DashboardDelete": {
        return {
          ...defaultProps,
          title: modalDeleteDash.title,
          minHeight: "350px",
          width: "50%",
          maxWidth: "800px",
        }
      }

      case "Confirmation": {
        return {
          ...defaultProps,
          title: modalConfirmation.title,
          minHeight: "200px",
          width: "50%",
          maxWidth: "750px",
          onClose: sensorProvContext
            ? sensorProvContext.handleCloseModalConfirmation
            : handleCloseModal,
        }
      }

      case "AnalyticDelete": {
        return {
          ...defaultProps,
          title: modalDeleteAnalytic.title,
          minHeight: "350px",
          width: "50%",
          maxWidth: "800px",
        }
      }

      case "ServiceConfig": {
        return {
          ...defaultProps,
          title: `Configuração de ${sensorsConfigContext!.typeOfServiceSelected.current?.toLocaleUpperCase()}`,
          width: "50%",
          maxWidth: "500px",
          onClose:
            sensorsConfigContext !== null
              ? sensorsConfigContext.handleCloseConfigServiceModal
              : handleCloseModal,
          draggable: true,
        }
      }

      case "DisableOrReset": {
        return {
          ...defaultProps,
          title: `${sensorsConfigContext!.titleModalDisableOrReset.current}`,
          minHeight: "350px",
          width: "50%",
          maxWidth: "800px",
          onClose:
            sensorsConfigContext !== null
              ? sensorsConfigContext.handleCloseDisableOrResetModal
              : handleCloseModal,
        }
      }

      case "HistoricalAnalysisConfig": {
        return {
          ...defaultProps,
          title: "Configurações",
          width: "55%",
          maxWidth: "850px",
          onClose:
            historicalAnalysisContext !== null
              ? historicalAnalysisContext.handleCloseHistoricalAnalysisConfig
              : handleCloseModal,
        }
      }

      default:
        break
    }

    return defaultProps
  }

  return {
    activeModal,
    ...getProps(),
  }
}
