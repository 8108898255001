import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { SensorWithServicesDTO } from "../../../../utils/entities/sensors/SensorsWithServicesDTO"
import { PropsWithChildren } from "react"
import { SERVICES, useSensorsTable } from "./hooks/useSensorsTable"

const BIGGER_NUMBER_OF_SERVICES = 10

interface SensorsTableProps {
  filteredSensors: SensorWithServicesDTO[]
  selectAll: boolean
}

export const SensorsTable = ({
  filteredSensors,
  selectAll,
}: SensorsTableProps): JSX.Element => {
  const {
    getCheckboxState,
    getDisableState,
    getLastDate,
    getRowStyle,
    getSensorName,
    onChange,
  } = useSensorsTable({ filteredSensors, selectAll })

  if (filteredSensors.length === 0)
    return <h1>Não foram encontrados sensores para essa filtragem.</h1>

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer>
        <Table style={{}} aria-label='macs and services' stickyHeader>
          <TableHeader />
          <TableBody>
            {filteredSensors.map((sensor, index) => (
              <TableRow
                key={`${index}#${sensor.mac}`}
                style={getRowStyle(index)}
              >
                <NameCell>{getSensorName(sensor)}</NameCell>
                {SERVICES.map((service) => {
                  return (
                    <TableCell
                      key={`${sensor.mac}#${service}`}
                      title={getLastDate(sensor.mac, service)}
                    >
                      <RenderCheckbox
                        checked={getCheckboxState(sensor.mac, service)}
                        disabled={getDisableState(sensor.mac, service)}
                        service={service}
                        onChange={() => onChange(sensor.mac, service)}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export const TableHeader = (): JSX.Element => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          }}
          align='center'
          colSpan={2}
        >
          <strong>Dados do Sensor</strong>
        </TableCell>
        <TableCell colSpan={BIGGER_NUMBER_OF_SERVICES} align='center'>
          <strong>Algoritmos</strong>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export const NameCell = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <TableCell
      scope='row'
      style={{
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      }}
      align='center'
      colSpan={2}
    >
      {children}
    </TableCell>
  )
}

export const RenderCheckbox = ({
  disabled,
  checked,
  service,
  onChange,
}: {
  disabled: boolean
  checked: boolean
  service: string
  onChange: () => void
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          className='checkbox'
          color='primary'
          size='medium'
          value={service}
          checked={checked && !disabled}
          onChange={(e) => onChange()}
        />
      }
      label={service.toUpperCase()}
    />
  )
}
