/* eslint-disable jsx-a11y/label-has-associated-control */
import { Main, Title } from "./styles"

interface RadioButtonItemProps {
  value: number | string
  checked: boolean
  onChange: () => void
  title: string
}

const RadioButtonItem = ({
  value,
  checked,
  onChange,
  title,
}: RadioButtonItemProps) => {
  return (
    <Main>
      <label>
        <input
          type='radio'
          value={value}
          checked={checked}
          onChange={(value) => {
            onChange()
          }}
        />
        <Title>
          <span>{title}</span>
        </Title>
      </label>
    </Main>
  )
}

export default RadioButtonItem
