import { DataFromApiDTO } from "../../entities/dataFromApiDTO"
import { AlarmsInfo } from "../../views/alarmsInfo.tsx"

import { Accordion } from "./styles"

export const AccordionComponentAlarm = ({
  options,
}: {
  options: DataFromApiDTO
}) => {
  const { productionSerialNumber, configs, sensorName } = options
  return (
    <Accordion>
      {configs.map((config, index) => {
        return (
          <AlarmsInfo
            productionSerialNumber={productionSerialNumber}
            config={config}
            key={index}
            sensorName={sensorName}
          />
        )
      })}
    </Accordion>
  )
}
