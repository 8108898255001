import { InputHTMLAttributes, forwardRef } from "react"
import "./styles.css"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, ...props }, ref) => {
    return (
      <div>
        <label className='pure-material-textfield-outlined'>
          <input {...props} ref={ref} />
          <span>{label}</span>
        </label>
      </div>
    )
  }
)

Input.displayName = "Input"
