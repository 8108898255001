import styled from "styled-components"

export const Button = styled.button`
  border: none;
  outline: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: ${(props) => props.theme.border.radius};

  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.red};

  text-transform: uppercase;
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 16px;

  width: 220px;
  height: 52px;

  ${(props) =>
    props.disabled && {
      opacity: "70%",
      cursor: "not-allowed",
    }}

  &:hover {
    opacity: 70%;
  }
`
