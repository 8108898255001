import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"

//import { useLineChartController } from "./useLineChartController";
import Boost from "highcharts/modules/boost"
import { useOrbitChartController } from "../../../controllers/useOrbitChartController"
import { Chart } from "./styles"

Boost(Highcharts)
require("highcharts/modules/no-data-to-display")(Highcharts)

const OrbitChart = () => {
  const { chartRef, graphicOptions } = useOrbitChartController()

  return (
    <Chart>
      <HighchartsReact
        highcharts={Highcharts}
        ref={chartRef}
        options={{
          ...graphicOptions,
        }}
      />
    </Chart>
  )
}

export default OrbitChart
