import { FormUser } from "../FormUser"
import TableUsers from "../TableUser"
import { ModalConfirmDeleteUser } from "../ModalConfirmDelet"
import { Container, ContainerTable } from "./styles"
import { ModalEditPermissionUser } from "../ModalEditPermission"
import useManagerUserController from "../../controllers/useManagerUser"
import ConfirmationMessage from "../ConfirmationMessage"

export const WrapperRegisterUser = () => {
  const {
    isRegisterCompleted,
    registerAndUpdateUser,
    handleNewRegister,
    changeCompany,
    changePermissionLevel,
    changeData,
    clearForm,
    data,
    companies,
    openForm,
    handleOpenForm,
    handleOpenFormEditUser,
    handleBackForm,
    createOrEdit,
  } = useManagerUserController()

  return (
    <Container>
      {!openForm ? (
        <ContainerTable>
          <TableUsers
            openFormFcn={() => handleOpenForm()}
            handleOpenFormEditUser={handleOpenFormEditUser}
          />
        </ContainerTable>
      ) : !isRegisterCompleted ? (
        <FormUser
          data={data}
          register={registerAndUpdateUser}
          changeCompany={changeCompany}
          changeData={changeData}
          changePermissionLevel={changePermissionLevel}
          clearForm={clearForm}
          companies={companies}
          handleBackFcn={() => handleBackForm()}
          createOrEdit={createOrEdit}
        />
      ) : (
        <ConfirmationMessage page='user' onNewRegister={handleNewRegister} />
      )}
      <ModalConfirmDeleteUser />
      <ModalEditPermissionUser />
    </Container>
  )
}
