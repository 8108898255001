import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 375px;
  padding: 5px;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  font-size: small;
  @media (max-width: 400px) {
    min-width: 320px;
  }
`

export const TypeAndValue = styled.div`
  display: flex;
  @media (max-width: 400px) {
    font-size: smaller;
  }
`

export const TypeSpan = styled.span`
  font-weight: bold;
  line-height: 18px;
  color: #000000;
`

export const ValueSpan = styled.span`
  font-weight: normal;
  line-height: 18px;
  color: #000000;
`
