/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyledInput } from "./styles"

export const GetInput = ({
  width = "70px",
  name = "",
  onChangeFn = (e: any, name: any) => console.log("on change in"),
  defaultValue = "",
  value = "",
  disabled = false,
  inputType = "text",
}: {
  width?: string
  name?: string
  onChangeFn?: any
  defaultValue?: string
  value?: string
  disabled?: boolean
  inputType?: string
}) => {
  let initialValue = value ? value : defaultValue ? defaultValue : ""
  return (
    <StyledInput
      width={width}
      type={inputType}
      step={inputType === "number" ? ".01" : "false"}
      onChange={(e) => {
        onChangeFn(e, name)

        let value = e.target.value
        const [integerValue, decimalPoints] = value.toString().split(".")

        if (
          inputType === "number" &&
          decimalPoints &&
          decimalPoints.length > 2
        ) {
          value = `${integerValue}.${decimalPoints.slice(0, 2)}`
        }
        initialValue = value
      }}
      value={initialValue}
      disabled={disabled}
    />
  )
}
