import styled from "styled-components"

export const AlarmsLogsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 1%;
  border: 1px solid #cacaca;
  border-radius: 10px;

  &:hover {
    border-color: #3cd62f;
  }

  @media (max-width: 1076px) {
    justify-content: center;
    flex-direction: column;
  }
`

export const IconAndDescriptionContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 680px) and (max-width: 1076px) {
    width: unset !important;
  }

  @media (max-width: 680px) {
    width: 100% !important;
  }
`

export const TextContainer = styled.span`
  margin-left: 10px;
  font-weight: bold;
`
