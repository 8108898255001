/* eslint-disable array-callback-return */
import { useRef, useEffect, useState, useContext } from "react"
import { accumulatedChart } from "../components/Charts/ChartsType/accumulatedchart"
import { detailedChart } from "../components/Charts/ChartsType/detailedchart"
import { HistoricalAnalysisContext } from "../context/modelContext"
import { dataDetailedChart } from "../entities/dataDetailedChartDTO"
import { dataSerieDetailedChart } from "../entities/dataSeriesDetaledChartDTO"
import { dataSerie } from "../entities/dataSeriesAccumulatedHourMeterDTO"
import { dataAccumulated } from "../entities/dataAcummulatedDTO"
import HighchartsReact from "highcharts-react-official"
import { toast } from "react-toastify"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { DataPlotDTO } from "../entities/dataSeriesDTO"
import { HourmeterController } from "../entities/hourMeterDTO"
import { countDataAccumulatedDTO } from "../entities/countDataAccumulatedDTO"
import { DateAndHoursDTO } from "../entities/dateAndHoursDTO"
import { DataHM } from "../entities/dataFormatHMDTO"
import { DataHoursStatusPeriodOfTwoData } from "../entities/dataHoursAndStatusDTO"
import { datesWithDataDTO } from "../entities/datesWithDataDTO"

export const useHourMeterChartController = () => {
  const {
    dataToPlot,
    thresholdOffHourMeter,
    thresholdOperingHourMeter,
    hmDetailedOrAccumuled,
    dataDateInFormatDDMM,
    ataDateInFormatDDMM,
    setAtaDateInFormatDDMM,
    setThresholdOperingHourMeter,
    setInitialThresholdOperating,
    setInitialThresholdOff,
    startDate,
    endDate,
    generalData,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica

  const chartHourMeterRef = useRef<HighchartsReact.RefObject>(null)

  const chartTypeToggle = useRef("acumulado")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [graphicOptionsHourMeter, setGraphicOptionsHourMeter]: any = useState({
    ...accumulatedChart(),
  })

  let hoursWithDate: datesWithDataDTO[] = []

  let dataSeries: dataSerieDetailedChart[] = [
    {
      name: "Operando",
      color: "green",
      data: [],
    },
    {
      name: "Ligado",
      color: "yellow",
      data: [],
    },
    {
      name: "Desligado",
      color: "red",
      data: [],
    },
    {
      name: "Sem dados",
      color: "#d3d3d3",
      data: [],
    },
  ]

  let index: number = 0
  let hoursAux = -1
  let countData: countDataAccumulatedDTO = {
    dataOff: [],
    dataOn: [],
    dataOperating: [],
    dataNoSignal: [],
  }

  let auxDataDetailed = {
    x: 0,
    y: 0,
    value: "",
    on: 0,
    off: 0,
    operating: 0,
    noSigal: 3600,
    color: "",
  }

  useEffect(() => {
    if (!dataToPlot) return
    if (chartHourMeterRef.current === null) return
    if (!dataToPlot[3]) return

    const dataToPlotHourMeter = dataToPlot[3] as DataPlotDTO
    let soma: number = 0
    let media: number = dataToPlotHourMeter.data.data[0][1]
    let initialThresholdOff = dataToPlotHourMeter.data.data[0][1]
    let maiorValor: number = dataToPlotHourMeter.data.data[0][1]

    dataToPlotHourMeter.data.data.forEach((data, index) => {
      if (data[1] > maiorValor) maiorValor = data[1]
      else if (data[1] < initialThresholdOff && data[1] !== 0)
        initialThresholdOff = data[1]
      if (data[1] > 0) {
        soma = soma + data[1]
        media = soma / index
      }
      if (index === dataToPlotHourMeter.data.data.length - 1)
        generalData.current.lastDate = timestampToDate(data[0])
    })
    setInitialThresholdOperating(parseFloat((media * 1.3).toFixed(2)))
    setInitialThresholdOff(parseFloat((media * 0.7).toFixed(2)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!dataToPlot) return
    if (chartHourMeterRef.current === null) return
    if (!dataToPlot[3]) return

    if (thresholdOffHourMeter > thresholdOperingHourMeter) {
      toast.warning("Error threshold's")
      return
    }

    while (chartHourMeterRef.current.chart.series.length) {
      chartHourMeterRef.current.chart.series[0].remove()
    }

    const rms2ModuleData = dataToPlot[3] as DataPlotDTO

    if (hmDetailedOrAccumuled === "acumulado") {
      setAtaDateInFormatDDMM([])
      setGraphicOptionsHourMeter({ ...accumulatedChart() })
      for (
        let index = 0;
        index < rms2ModuleData.data.data.length - 2;
        index++
      ) {
        const data1: DataHM = {
          time: rms2ModuleData.data.data[index][0],
          data: rms2ModuleData.data.data[index][1],
        }

        const data2: DataHM = {
          time: rms2ModuleData.data.data[index + 1][0],
          data: rms2ModuleData.data.data[index + 1][1],
        }

        let dataFromBothVerifiedPeriods: [number, string, number] | null =
          _checkTimeAndStateOfTwoData(data1, data2)
        if (dataFromBothVerifiedPeriods)
          _feedArraysOfDataToPlot(dataFromBothVerifiedPeriods)
      }
      createGeneralData(countData)
      let formattedDataToPlot: dataSerie[] = [
        {
          name: "Operando",
          data: _convertSegundsInHHMMSS(countData.dataOperating),
          color: "green",
        },
        {
          name: "Ligado",
          data: _convertSegundsInHHMMSS(countData.dataOn),
          color: "yellow",
        },
        {
          name: "Desligado",
          data: _convertSegundsInHHMMSS(countData.dataOff),
          color: "red",
        },
        {
          name: "Sem dados",
          data: _convertSegundsInHHMMSS(countData.dataNoSignal),
          color: "#d3d3d3",
        },
      ]
      formattedDataToPlot.map((series: dataSerie) =>
        chartHourMeterRef.current?.chart.addSeries(
          {
            color: series.color,
            data: series.data,
            name: series.name,
            type: "column",
          },
          true,
          true
        )
      )
      chartHourMeterRef.current.chart.xAxis[0].setCategories(
        dataDateInFormatDDMM as string[]
      )
      chartHourMeterRef.current.chart.yAxis[0].update({
        categories: undefined,
        allowDecimals: false,
        min: 0,
        title: {
          text: "%",
        },
      })
    } else {
      /*tratamento de dados para o grafico detalhado*/
      setGraphicOptionsHourMeter({ ...detailedChart() })
      for (
        let index = 0;
        index < rms2ModuleData.data.data.length - 2;
        index++
      ) {
        const data1: DataHM = {
          time: rms2ModuleData.data.data[index][0],
          data: rms2ModuleData.data.data[index][1],
        }

        const data2: DataHM = {
          time: rms2ModuleData.data.data[index + 1][0],
          data: rms2ModuleData.data.data[index + 1][1],
        }

        const dataFromBothVerifiedPeriods: DataHoursStatusPeriodOfTwoData | null =
          _checkDataHoursAndStatusOfTwoData(data1, data2)
        if (dataFromBothVerifiedPeriods) {
          _feedArraysOfDataToPlotDetailed(dataFromBothVerifiedPeriods)
        }
      }

      _setOneHoursDataInSeriesData()
      _addNoSignalInformation()

      dataSeries.map((series: dataSerieDetailedChart) =>
        chartHourMeterRef.current?.chart.addSeries(
          {
            color: series.color,
            data: series.data,
            name: series.name,
            type: "heatmap",
          },
          true,
          true
        )
      )
      chartHourMeterRef.current.chart.yAxis[0].update({
        categories: [
          "00:00:00",
          "01:00:00",
          "02:00:00",
          "03:00:00",
          "04:00:00",
          "05:00:00",
          "06:00:00",
          "07:00:00",
          "08:00:00",
          "09:00:00",
          "10:00:00",
          "11:00:00",
          "12:00:00",
          "13:00:00",
          "14:00:00",
          "15:00:00",
          "16:00:00",
          "17:00:00",
          "18:00:00",
          "19:00:00",
          "20:00:00",
          "21:00:00",
          "22:00:00",
          "23:00:00",
        ],
        title: {
          text: "Horas",
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hmDetailedOrAccumuled, thresholdOperingHourMeter, thresholdOffHourMeter])

  const calculateDaysWithoutData = (
    startDate: Date,
    endDate: Date,
    daysWithData: number
  ): number => {
    const start = new Date(startDate)
    const end = new Date(endDate)

    const differenceMs = end.getTime() - start.getTime()

    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24))

    return differenceDays - daysWithData
  }

  const timestampToDate = (timestamp: number): string => {
    const dateTime = new Date(timestamp)
    const day = dateTime.getDate().toString().padStart(2, "0")
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0")
    const year = dateTime.getFullYear()
    const hours = dateTime.getHours().toString().padStart(2, "0")
    const minutes = dateTime.getMinutes().toString().padStart(2, "0")
    const seconds = dateTime.getSeconds().toString().padStart(2, "0")

    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
  }

  const createGeneralData = (data: countDataAccumulatedDTO): void => {
    const dataOff = data.dataOff.reduce((a, b) => a + b, 0)
    const dataOn = data.dataOn.reduce((a, b) => a + b, 0)
    const dataOperating = data.dataOperating.reduce((a, b) => a + b, 0)
    const dataNoSignal = data.dataNoSignal.reduce((a, b) => a + b, 0)
    const daysWithoutData = calculateDaysWithoutData(
      startDate,
      endDate,
      data.dataNoSignal.length - 1
    )
    generalData.current.dataOff = dataOff
    generalData.current.dataOn = dataOn
    generalData.current.dataOperating = dataOperating
    generalData.current.dataNoSignal = dataNoSignal + daysWithoutData * 86400 // Calculo o dia sem dados em segundos
  }

  const _addNoSignalInformation = (): void => {
    hoursWithDate.map((element) => {
      ;[
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ].forEach((hours) => {
        if (!element.hours.includes(hours)) {
          let data: dataDetailedChart = {
            x: element.day,
            y: hours,
            on: fmtMSS(auxDataDetailed.on),
            off: fmtMSS(auxDataDetailed.off),
            operating: fmtMSS(auxDataDetailed.operating),
            noSignal: fmtMSS(auxDataDetailed.noSigal),
          }
          dataSeries[3].data.push(data)
        }
      })
    })
  }

  const _checkDataHoursAndStatusOfTwoData = (
    data1: DataHM,
    data2: DataHM
  ): DataHoursStatusPeriodOfTwoData | null => {
    let statusData1: number = _checkDataAndReturnStatus(data1.data)
    let statusData2: number = _checkDataAndReturnStatus(data2.data)
    if (statusData1 !== statusData2) return null

    let hoursData1: number = _checkDataAndReturnDateAndHours(data1.time).hours
    let hoursData2: number = _checkDataAndReturnDateAndHours(data2.time).hours
    if (hoursData1 !== hoursData2) return null

    const periodBetweenDataInSeconds: number = (data2.time - data1.time) / 1000
    if (periodBetweenDataInSeconds > 10 * 60) return null

    let dateData: string = _checkDataAndReturnDateAndHours(data1.time).date
    return {
      statusData: statusData1,
      hoursData: hoursData1,
      dateData: dateData,
      periodBetweenDataInSeconds: periodBetweenDataInSeconds,
    }
  }

  const _checkTimeAndStateOfTwoData = (
    data1: DataHM,
    data2: DataHM
  ): [number, string, number] | null => {
    let statusData1: number = _checkDataAndReturnStatus(data1.data)
    let statusData2: number = _checkDataAndReturnStatus(data2.data)
    if (statusData1 !== statusData2) return null

    let dateData1: string = _checkDataAndReturnDateAndHours(data1.time).date
    let dateData2: string = _checkDataAndReturnDateAndHours(data2.time).date
    if (dateData2 !== dateData1) return null

    const periodBetweenDataInSeconds: number = (data2.time - data1.time) / 1000
    if (periodBetweenDataInSeconds > 10 * 60) return null

    return [statusData1, dateData1, periodBetweenDataInSeconds]
  }

  const compareDates = (date: Date, formatoDM: string): number => {
    const currentDate = new Date()
    const dateProvided = new Date(date)

    // Verifica se a data fornecida é igual à data atual
    if (
      currentDate.getDate() === dateProvided.getDate() &&
      currentDate.getMonth() === dateProvided.getMonth()
    ) {
      // Verifica se o formato DD/MM da data fornecida é igual ao formato fornecido
      const formatDateProvided = `${dateProvided
        .getDate()
        .toString()
        .padStart(2, "0")}/${(dateProvided.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`
      if (formatDateProvided === formatoDM) {
        // Se for a mesma data e formato, calcula a quantidade de segundos desde o início do dia
        const hours = currentDate.getHours()
        const minutes = currentDate.getMinutes()
        const seconds = currentDate.getSeconds()
        return hours * 3600 + minutes * 60 + seconds
      }
    }
    return 86400 // 24 horas * 60 minutos * 60 segundos
  }

  const _feedArraysOfDataToPlot = (data: [number, string, number]): void => {
    const status = data[0]
    const date = data[1]
    const period = data[2]

    if (!dataDateInFormatDDMM || !dataDateInFormatDDMM.includes(date)) {
      dataDateInFormatDDMM!.push(date)
      setAtaDateInFormatDDMM((oldArray) => [...oldArray, date])
      index = dataDateInFormatDDMM!.length - 1
      countData.dataOff[index] = 0
      countData.dataOn[index] = 0
      countData.dataOperating[index] = 0
      countData.dataNoSignal[index] = compareDates(endDate, date)
    }

    if (status === 0) countData.dataOff[index] += period
    else if (status === 1) countData.dataOn[index] += period
    else if (status === 2) countData.dataOperating[index] += period
    countData.dataNoSignal[index] = countData.dataNoSignal[index] - period
  }

  const _feedArraysOfDataToPlotDetailed = (
    statusHoursDateAndPeriod: DataHoursStatusPeriodOfTwoData
  ): void => {
    if (hoursAux === -1) {
      hoursAux = statusHoursDateAndPeriod.hoursData
      auxDataDetailed.y = statusHoursDateAndPeriod.hoursData
      auxDataDetailed.x = ataDateInFormatDDMM.findIndex(
        (date) => date === statusHoursDateAndPeriod.dateData
      )
      _setDataDTOAux(
        statusHoursDateAndPeriod.periodBetweenDataInSeconds,
        statusHoursDateAndPeriod.statusData
      )
    } else if (hoursAux === statusHoursDateAndPeriod.hoursData) {
      _setDataDTOAux(
        statusHoursDateAndPeriod.periodBetweenDataInSeconds,
        statusHoursDateAndPeriod.statusData
      )
    } else {
      _setOneHoursDataInSeriesData()
      hoursAux = statusHoursDateAndPeriod.hoursData
      auxDataDetailed.y = statusHoursDateAndPeriod.hoursData
      auxDataDetailed.x = ataDateInFormatDDMM.findIndex(
        (date) => date === statusHoursDateAndPeriod.dateData
      )
      _setDataDTOAux(
        statusHoursDateAndPeriod.periodBetweenDataInSeconds,
        statusHoursDateAndPeriod.statusData
      )
    }
  }

  const fmtMSS = (s: number): string => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s
  }

  const _setOneHoursDataInSeriesData = (): void => {
    let data: dataDetailedChart = {
      x: auxDataDetailed.x,
      y: auxDataDetailed.y,
      on: fmtMSS(auxDataDetailed.on),
      off: fmtMSS(auxDataDetailed.off),
      operating: fmtMSS(auxDataDetailed.operating),
      noSignal: fmtMSS(auxDataDetailed.noSigal),
    }

    /* Armazena as horas em que contém dados para auxiliar na informação de "sem dados" no gráfico */

    if (!hoursWithDate[auxDataDetailed.x]) {
      let dayAux: datesWithDataDTO = {
        day: auxDataDetailed.x,
        hours: [auxDataDetailed.y],
      }
      hoursWithDate.push(dayAux)
    } else {
      hoursWithDate[auxDataDetailed.x] = {
        day: auxDataDetailed.x,
        hours: [...hoursWithDate[auxDataDetailed.x].hours, auxDataDetailed.y],
      }
    }

    if (
      auxDataDetailed.off > auxDataDetailed.on &&
      auxDataDetailed.off > auxDataDetailed.operating
    )
      dataSeries[2].data.push(data) //  desligado
    else if (
      auxDataDetailed.on > auxDataDetailed.off &&
      auxDataDetailed.on > auxDataDetailed.operating
    )
      dataSeries[1].data.push(data) // ligado
    else if (
      auxDataDetailed.operating > auxDataDetailed.off &&
      auxDataDetailed.operating > auxDataDetailed.on
    )
      dataSeries[0].data.push(data) // operando
    _cleanDataDTOAux()
  }

  const _setDataDTOAux = (period: number, status: number): void => {
    if (status === 0) auxDataDetailed.off += period
    else if (status === 1) auxDataDetailed.on += period
    else auxDataDetailed.operating += period
    auxDataDetailed.noSigal = auxDataDetailed.noSigal - period
  }

  const _cleanDataDTOAux = (): void => {
    auxDataDetailed = {
      x: 0,
      y: 0,
      value: "",
      on: 0,
      off: 0,
      operating: 0,
      noSigal: 3600,
      color: "",
    }
  }

  const _checkDataAndReturnStatus = (valueData: number): number => {
    if (valueData <= thresholdOffHourMeter)
      return 0 //Desligado
    else if (valueData > thresholdOperingHourMeter)
      return 2 // Operando
    else return 1 // Ligado
  }

  const _checkDataAndReturnDateAndHours = (data: number): DateAndHoursDTO => {
    let dd: string = ""
    let mm: string = ""

    let date = new Date(data)
    let _mm: number = date.getMonth() + 1
    let _dd: number = date.getDate()
    let hours: number = date.getHours()

    if (_dd < 10) dd = "0" + _dd.toString()
    else dd = _dd.toString()
    if (_mm < 10) mm = "0" + _mm.toString()
    else mm = _mm.toString()

    return {
      date: dd + "/" + mm,
      hours: hours,
    }
  }

  const _convertSegundsInHHMMSS = (values: number[]): dataAccumulated[] => {
    let segundsFormated: dataAccumulated[] = []
    let _hh: string
    let _mm: string
    let _sec: string

    values.forEach((element, index) => {
      let hours = Math.floor(element / 3600) // get hours
      _hh = hours.toString()
      let minutes = Math.floor((element - hours * 3600) / 60) // get minutes
      _mm = minutes.toString()
      let seconds = element - hours * 3600 - minutes * 60 //  get seconds
      _sec = seconds.toString()

      if (hours < 10) {
        _hh = "0" + hours
      }
      if (minutes < 10) {
        _mm = "0" + minutes
      }
      if (seconds < 10) {
        _sec = "0" + seconds
      }
      const hoursFormated = _hh + ":" + _mm + ":" + _sec

      let newData = {
        y: values[index],
        hours: hoursFormated,
      }

      segundsFormated.push(newData) // Return is HH : MM : SS
    })

    return segundsFormated
  }

  const ChangeChartTypeHourMeter = (): void => {
    if (chartTypeToggle.current === "acumulado") {
      chartTypeToggle.current = "detalhado"
      setGraphicOptionsHourMeter({ ...detailedChart() })
    } else {
      chartTypeToggle.current = "acumulado"
      setGraphicOptionsHourMeter({ ...accumulatedChart() })
    }
  }

  return {
    chartHourMeterRef,
    graphicOptionsHourMeter,
    chartTypeToggle,
    ChangeChartTypeHourMeter,
    thresholdOperingHourMeter,
    thresholdOffHourMeter,
    setThresholdOperingHourMeter,
    startDate,
    endDate,
    dataToPlot,
    generalData,
  } as HourmeterController
}
