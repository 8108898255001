import styled from "styled-components"

export const Container = styled.div`
  flex-direction: column;
  gap: 5px;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const ServiceAndOptionsContainer = styled.div`
  height: 2.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const ServiceAndOptionsContainerCopyConfig = styled.div`
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  @media (min-width: 650px) {
    flex-direction: row;
    align-items: baseline;
  }
`

export const ServiceStyled = styled.div`
  justify-content: center;
  font-size: medium;
  text-align: center;
  font-weight: bold;
  width: 250px;
  text-align: start;
`
export const NSampleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2%;
  width: 130px;
  border: none;
  background: none;
  outline: none;
`
export const ExtraInformationPOT = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2%;
  width: 130px;
  border: none;
  background: none;
  outline: none;
`
export const NSampleInput = styled.input`
  cursor: text;
  width: 100%;
  text-align: center;
  border: none;
  background: none;
  outline: none;
  border-bottom: 1px solid grey;
`
export const DropdownContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2%;
  width: 130px;
  border: none;
  background: none;
  outline: none;
  border-bottom: 1px solid grey;
`

export const DropDownFilled = styled.div`
  position: absolute;
  width: 100%;
`
export const DropdownAndInfoStyled = styled.div`
  display: flex;
  position: relative;
`

export const DropDownListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 126px;
  background: #f4f4f4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  top: 13px;
  z-index: 1;
  box-shadow:
    0.125rem 0px 0.25rem rgb(0 0 0 / 25%),
    0px 0.125rem 0.25rem rgb(0 0 0 / 25%),
    0px 0.0625rem 0.625rem rgb(0 0 0 / 12%);
`

export const ContainerCopyConfig = styled.div`
  font-size: small;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 160px;
  background: #f4f4f4;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  top: 13px;
  z-index: 1;
  box-shadow:
    0.125rem 0px 0.25rem rgb(0 0 0 / 25%),
    0px 0.125rem 0.25rem rgb(0 0 0 / 25%),
    0px 0.0625rem 0.625rem rgb(0 0 0 / 12%);
`

export const DropDownListItem = styled.option`
  border: none;
  background: none;
  outline: none;
  padding: 5px;
  &:hover {
    background: #e4e4e4;
  }
  width: 100%;
  display: flex;
  padding: 2px;
  padding-left: 10px;
`

export const CopyConfig = styled.div`
  margin-top: 10px;
  flex-direction: column;
  display: flex;
`
export const CopyConfigTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: medium;
  margin-bottom: 5px;
  @media (min-width: 650px) {
    text-align: start;
  }
`
export const DropdownContainerCopyConfig = styled.button`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2%;
  width: 180px;
  border: none;
  background: none;
  outline: none;
  border-bottom: 1px solid grey;
`

export const FooterContainer = styled.div`
  margin: auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 40px;
  flex-wrap: wrap;
  @media (min-width: 650px) {
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }
`

export const SelectContainer = styled.select<{ $width: string }>`
  font-size: small;
  text-align: center;
  cursor: pointer;
  justify-content: flex-end;
  width: ${(props) => props.$width};
  border: none;
  background-color: white;
  border-bottom: 1px solid black;
  option {
    text-align: center;
    font-size: 1.1em;
    display: flex;
  }
`
