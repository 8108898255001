import styled from "styled-components"

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: left;
    width: 20rem;
  }

  th {
    background-color: #f2f2f2;
  }
`

export const ActionIcons = styled.div`
  justify-content: center;
  gap: 15px;
  display: flex;
`

export const EditIcon = styled.div`
  cursor: pointer;
  color: #2c2c2c;
  &:hover {
    color: grey;
    box-shadow: 0px 6px 16px 3px rgba(160, 160, 160, 0.25);
  }
`
export const PermissionsIcon = styled.div`
  cursor: pointer;
  color: #2c2c2c;
  &:hover {
    color: grey;
    box-shadow: 0px 6px 16px 3px rgba(160, 160, 160, 0.25);
  }
`

export const DeleteIcon = styled.div`
  color: #c90606dc;
  cursor: pointer;
  &:hover {
    color: grey;
    box-shadow: 0px 6px 16px 3px rgba(160, 160, 160, 0.25);
  }
`

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    margin-left: 10px;
  }

  select {
    margin-left: 10px;
  }
`
