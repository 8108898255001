import { Logo } from "./components/Logo"
import { NavBarContent, NavbarWrapper } from "./styles/Navbar.styled"

import { ROUTES_MAP } from "../../routes/route-map"

import { DropdownMenu } from "./components/DopdownMenu"
import { NavFooter } from "./components/NavBarFooter"

type NewMenuProps = {
  open: boolean
}

const arrRoutes = ROUTES_MAP

export const NewMenu = ({ open }: NewMenuProps) => {
  return (
    <NavbarWrapper $open={open}>
      <Logo key='logo' />
      <NavBarContent>
        {arrRoutes.map((route, key) => (
          <DropdownMenu {...route} key={key} />
        ))}
      </NavBarContent>
      <NavFooter />
    </NavbarWrapper>
  )
}
