import "./styles.css"

interface InputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  type: "email" | "password" | "text" | "tel" | "date" | "number"
  label: string
  value: string
}

export default function Input({ onChange, type, label, value }: InputProps) {
  return (
    <div>
      <label className='pure-material-textfield-outlined'>
        <input
          value={value}
          onChange={onChange}
          placeholder={" "}
          type={type}
          autoComplete='off'
        />
        <span>{label}</span>
      </label>
    </div>
  )
}
