import React from "react"
import * as S from "./styles"
import { useServiceConfigController } from "../controllers/useServiceConfigController"

interface ExtraInformationComponentProps {
  limits: string[]
  btnStatus: string
  serviceName: "pot" | "_4t20"
  mac: string
}

export const ExtraInformationComponent: React.FC<
  ExtraInformationComponentProps
> = ({ limits, btnStatus, serviceName, mac }) => {
  const { handleUpdateLimitExtraInformation, configServiceExtraInformation } =
    useServiceConfigController()

  interface InputFieldProps {
    label: string
    type: string
    disabled: boolean
    placeholder: string
  }

  const extraConfigFromLS = localStorage.getItem(
    `${mac} - ${serviceName} - ExtraConfig`
  )
  const extraConfig = JSON.parse(extraConfigFromLS as string)

  if (extraConfig !== null) {
    configServiceExtraInformation.current = {
      upperLimit: extraConfig.upperLimit,
      inferiorLimit: extraConfig.inferiorLimit,
      unitOfMeasurement: extraConfig.unitOfMeasurement,
    }
  }

  const InputField: React.FC<InputFieldProps> = ({
    label,
    type,
    disabled,
    placeholder,
  }) => {
    const TRANSFORM_VARIABLE_INTO_TITLE = {
      upperLimit: "Limite superior",
      inferiorLimit: "Limite inferior",
      unitOfMeasurement: "Unidade de medida",
    }

    const DEFAULT_VALUE = {
      upperLimit: 100,
      inferiorLimit: 0,
      unitOfMeasurement: "%",
    }

    return (
      <S.ServiceAndOptionsContainer>
        <S.ServiceStyled>{`${TRANSFORM_VARIABLE_INTO_TITLE[label as keyof typeof TRANSFORM_VARIABLE_INTO_TITLE]}:`}</S.ServiceStyled>
        <S.DropdownAndInfoStyled>
          <S.ExtraInformationPOT>
            <S.NSampleInput
              disabled={disabled}
              type={type}
              min='0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateLimitExtraInformation(
                  e,
                  label as keyof typeof TRANSFORM_VARIABLE_INTO_TITLE,
                  e.target.value
                )
              }
              placeholder={placeholder}
              defaultValue={
                configServiceExtraInformation?.current[
                  label as keyof typeof DEFAULT_VALUE
                ]
              }
            />
          </S.ExtraInformationPOT>
        </S.DropdownAndInfoStyled>
      </S.ServiceAndOptionsContainer>
    )
  }

  interface LimitInputsProps {
    limits: string[]
    btnStatus: string
  }

  const LimitInputs: React.FC<LimitInputsProps> = ({ limits, btnStatus }) => {
    return limits.map((limit, index) => (
      <InputField
        key={index}
        label={limit}
        type='number'
        disabled={btnStatus === "configuring"}
        placeholder='Select'
      />
    ))
  }

  interface UnitOfMeasureInputProps {
    btnStatus: string
  }

  const UnitOfMeasureInput: React.FC<UnitOfMeasureInputProps> = ({
    btnStatus,
  }) => {
    return (
      <InputField
        label='unitOfMeasurement'
        type='text'
        disabled={btnStatus === "configuring"}
        placeholder='Select'
      />
    )
  }

  return (
    <>
      <LimitInputs limits={limits} btnStatus={btnStatus} />
      <UnitOfMeasureInput btnStatus={btnStatus} />
    </>
  )
}
