import styled from "styled-components"

export const Container = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

export const InputError = styled.div`
  color: red;
  font-size: 12px;
`
