import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiCall } from "../../../utils/axios"
import { Gateway } from "./gateways.interfaces"

enum API_ROUTES {
  fetchGateways = "/v1/proteu/collectorsProv",
}

export const fetchGateways = createAsyncThunk(
  "gateways/fetchGateways",
  async (): Promise<Gateway[]> => {
    const api = apiCall()
    const response = await api.get(API_ROUTES.fetchGateways)
    return response.data
  }
)
