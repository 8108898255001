import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { rootReducer } from "./reducers"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["socket"],
}
export const persistedReducer = persistReducer(persistConfig, rootReducer)
