import { apiCall } from "../../../utils/axios"

export class GetSensorNameFromProductionSerialNumberUseCase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSensorByProdSerialNumber(prodSerialNumber: any) {
    try {
      const api = apiCall()
      const URL = `${process.env.REACT_APP_API_SENSORS_SHOW}/byprodserialnumber/${prodSerialNumber}`
      const response = await api.get(URL)

      if (response.data && response.data.bleOriginalMac) {
        return await this._getSensorNameFromBleOriginalMac(
          response.data.bleOriginalMac
        )
      }

      return null
    } catch (error) {
      console.error(
        "Error at GetSensorNameFromProductionSerialNumberUseCase.getSensorByProdSerialNumber: " +
          error
      )
      return null
    }
  }

  async _getSensorNameFromBleOriginalMac(bleOriginalMac: string) {
    try {
      const api = apiCall()
      const URL = `${process.env.REACT_APP_API_PROVISIONING_UPDATE}/${bleOriginalMac}`
      const headers = {
        usemac: "true",
      }

      const response = await api.get(URL, {
        headers: headers,
      })

      if (response.data) {
        return response.data.name ?? response.data.bleHdrMac
      }

      return null
    } catch (error) {
      console.error(
        "Error at GetSensorNameFromProductionSerialNumberUseCase._getSensorNameFromBleOriginalMac: " +
          error
      )
      return null
    }
  }
}
