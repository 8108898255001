import { FC } from "react"

import * as S from "./styles"
import { BackButton, Button } from "../../../Buttons"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { useModalConfirmationController } from "./controllers/useModalConfirmationController"

export const Confirmation: FC = () => {
  const { modalConfirmation, buttons } = useAppTranslate()
  const { handleRequestProv, handleCloseModal, messageError } =
    useModalConfirmationController()

  return (
    <S.Container>
      <S.Message>
        {messageError ? messageError : modalConfirmation.message}
      </S.Message>
      <S.Buttons>
        <BackButton label={buttons.back} onClick={() => handleCloseModal()} />
        <Button label={buttons.yes} onClick={() => handleRequestProv()} />
      </S.Buttons>
    </S.Container>
  )
}
