import { ITypography } from "../../../default/typography.interface"

export const typography: ITypography = {
  fontFamily: "'Inter', 'sans-serif'",
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemibold: 600,
  fontWeightBold: 700,
  display: {
    xs: {
      fontFamily: "'Inter', 'sans-serif'",
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  text: {
    lg: {
      fontFamily: "'Inter', 'sans-serif'",
      fontSize: "20px",
      lineHeight: "30px",
    },
    md: {
      fontFamily: "'Inter', 'sans-serif'",
      fontSize: "16px",
      lineHeight: "24px",
    },
    sm: {
      fontFamily: "'Inter', 'sans-serif'",
      fontSize: "14px",
      lineHeight: "20px",
    },
    xs: {
      fontFamily: "'Inter', 'sans-serif'",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
}
