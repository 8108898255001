import { FC } from "react"
import * as S from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { formatedDate } from "../../../../utils/date"

interface InformationCardProps {
  mac: string
  protocolVersion: string
  hardwareVersion: string
  applicationVersion: string
  lastReset: string | undefined
  time: string | undefined
  firmwareVersion: string | undefined
}

export const InformationCard: FC<InformationCardProps> = ({
  mac,
  protocolVersion,
  hardwareVersion,
  applicationVersion,
  lastReset,
  time,
  firmwareVersion,
}) => {
  const { sensorsConfig } = useAppTranslate()

  const version = firmwareVersion
    ? ` PRTV: ${protocolVersion} | HWV: ${hardwareVersion} | APP: ${applicationVersion} | CD: ${firmwareVersion}`
    : ` PRTV: ${protocolVersion} | HWV: ${hardwareVersion} | APP: ${applicationVersion}`

  return (
    <S.Container>
      <S.TypeAndValue>
        <S.TypeSpan>MAC: </S.TypeSpan>
        <S.ValueSpan>{mac}</S.ValueSpan>
      </S.TypeAndValue>

      {time && (
        <S.TypeAndValue>
          <S.TypeSpan>{sensorsConfig.last_seen}: </S.TypeSpan>
          <S.ValueSpan>{formatedDate(time)}</S.ValueSpan>
        </S.TypeAndValue>
      )}

      {lastReset && (
        <S.TypeAndValue>
          <S.TypeSpan>{sensorsConfig.last_reset}: </S.TypeSpan>
          <S.ValueSpan>{formatedDate(lastReset)}</S.ValueSpan>
        </S.TypeAndValue>
      )}

      <S.TypeAndValue>
        <S.TypeSpan>{sensorsConfig.versions}: </S.TypeSpan>
        <S.ValueSpan>{version}</S.ValueSpan>
      </S.TypeAndValue>
    </S.Container>
  )
}
