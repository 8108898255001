import CircularProgress from "@material-ui/core/CircularProgress"

import { Container } from "./styles"
import { ReactNode } from "react"

export default function CircularIndeterminate({
  children,
}: {
  children: ReactNode
}) {
  return (
    <Container>
      <CircularProgress size={100} />
      {children}
    </Container>
  )
}
