import { useContext, useEffect } from "react"
import { SensorWithServicesDTO } from "../../../../../utils/entities/sensors/SensorsWithServicesDTO"
import { SelectedServices } from "../../../entities/allSensorSelected"
import { formatedDate } from "../../../../../utils/date"
import { cleanAndPadSerialNumber } from "../../../../../utils/cleanAndPadSerialNumber"
import { ReportsContext, ReportsContextProps } from "../../../context/context"

export const SERVICES = [
  "accraw",
  "fft",
  "ntc",
  "rmms",
  "rms2",
  "temp",
  "tilt",
  "_4t20",
  "pot",
  "health",
]

export const useSensorsTable = ({
  filteredSensors,
  selectAll,
}: {
  filteredSensors: SensorWithServicesDTO[]
  selectAll: boolean
}) => {
  const { selectedServices, setSelectedServices } = useContext(
    ReportsContext
  ) as ReportsContextProps

  const initialState = (sensors: SensorWithServicesDTO[], value: boolean) => {
    let initial: SelectedServices = {}

    sensors.forEach((sensor) => {
      initial = {
        ...initial,
        [sensor.mac]: {},
      }

      SERVICES.forEach((service) => {
        initial[sensor.mac] = {
          ...initial[sensor.mac],
          [service]: value,
        }
      })
    })

    return initial
  }

  useEffect(() => {
    setSelectedServices(initialState(filteredSensors, selectAll))
  }, [filteredSensors, selectAll, setSelectedServices])

  const getCheckboxState = (mac: string, service: string) => {
    if (!selectedServices[mac]) return false
    if (!selectedServices[mac][service]) return false

    return selectedServices[mac][service]
  }

  const getDisableState = (mac: string, service: string) => {
    const sensor = filteredSensors.find((sensor) => sensor.mac === mac)

    if (!sensor) return true

    const result = sensor.services.find(
      ({ nameService }) => nameService.toLowerCase() === service.toLowerCase()
    )

    return !result
  }

  const getLastDate = (mac: string, service: string): string => {
    const sensor = filteredSensors.find((sensor) => sensor.mac === mac)

    if (!sensor) return ""

    const result = sensor.services.find(
      ({ nameService }) => nameService === service
    )

    if (!result) return ""

    return formatedDate(result.lastData)
  }

  const getSensorName = (sensor: SensorWithServicesDTO): string => {
    return sensor.name
      ? sensor.name
      : `S${cleanAndPadSerialNumber(sensor.serialNumber, sensor.hardwareVersion)}`
  }

  const onChange = (mac: string, service: string) => {
    setSelectedServices((state) => {
      return {
        ...state,
        [mac]: {
          ...state[mac],
          [service]: !state[mac][service],
        },
      }
    })
  }

  const getRowStyle = (index: number) => {
    return index % 2 === 0 ? { background: "white" } : { background: "	#F5F5F5" }
  }

  return {
    getCheckboxState,
    getDisableState,
    getLastDate,
    getSensorName,
    onChange,
    getRowStyle,
  }
}
