import styled from "styled-components"

export const StyledInput = styled.input`
  border-radius: 5px;
  outline: none;
  background: none;
  border: 0.5px solid grey;
  padding: 5px;
  width: ${(props) => props.width ?? "70px"};

  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
