import {
  SquaresFour,
  Crosshair,
  FileText,
  Broadcast,
  Article,
  Siren,
} from "@phosphor-icons/react"
import { PermissionsProteu } from "../store/features/user/user.interfaces"

export const ROUTES_MAP = [
  {
    id: 0,
    page: "Dashboard",
    href: "/dashboard",
    icon: <SquaresFour size={24} />,
    permissions: PermissionsProteu.DashboardEnable,
  },
  {
    id: 1,
    page: "Gateways",
    href: "/gateways",
    icon: <Crosshair size={24} />,
    permissions: PermissionsProteu.GatewaysEnable,
  },
  {
    id: 2,
    page: "Sensores",
    href: null,
    icon: <Broadcast size={24} />,
    permissions: [
      PermissionsProteu.SensorsConfigEnable,
      PermissionsProteu.SensorsProvEnable,
    ],
    tree: [
      {
        id: 21,
        name: "Instalação",
        href: "/sensorsprov",
        permission: PermissionsProteu.SensorsProvEnable,
      },
      {
        id: 22,
        name: "Configuração",
        href: "/sensorsconfig",
        permission: PermissionsProteu.SensorsConfigEnable,
      },
    ],
  },
  {
    id: 3,
    page: "Relatórios",
    href: null,
    icon: <FileText size={24} />,
    permissions: [
      PermissionsProteu.ReportsEnable,
      PermissionsProteu.HistoricalAnalysisEnable,
    ],
    tree: [
      {
        id: 31,
        name: "Baixar Relatórios",
        href: "/reports",
        permission: PermissionsProteu.ReportsEnable,
      },
      {
        id: 32,
        name: "Análise Histórica",
        href: "/historicalanalysis",
        permission: PermissionsProteu.HistoricalAnalysisEnable,
      },
    ],
  },
  {
    id: 4,
    page: "Alarmes",
    href: null,
    icon: <Siren size={24} />,
    permissions: [
      PermissionsProteu.AlarmsConfigEnable,
      PermissionsProteu.AlarmsLogsEnable,
    ],
    tree: [
      {
        id: 51,
        name: "Logs",
        href: "/alarmslogs",
        permission: PermissionsProteu.AlarmsLogsEnable,
      },
      {
        id: 52,
        name: "Configuração",
        href: "/alarmsconfig",
        permission: PermissionsProteu.AlarmsConfigEnable,
      },
    ],
  },
  {
    id: 5,
    page: "Cadastro",
    href: null,
    icon: <Article size={24} />,
    permissions: [
      PermissionsProteu.ManagerCompaniesEnable,
      PermissionsProteu.ManagerSalesEnable,
      PermissionsProteu.ManagerUsersEnable,
    ],
    tree: [
      {
        id: 41,
        name: "Empresa",
        href: "/registercompany",
        permission: PermissionsProteu.ManagerCompaniesEnable,
      },
      {
        id: 42,
        name: "Usuários",
        href: "/registeruser",
        permission: PermissionsProteu.ManagerUsersEnable,
      },
      {
        id: 43,
        name: "Vendas",
        href: "/registersales",
        permission: PermissionsProteu.ManagerSalesEnable,
      },
    ],
  },
]
