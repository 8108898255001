import {
  Auth0DecodedToken,
  KeycloakDecodedToken,
} from "./authentication/jwtDecoded"

export interface DecodedToken {
  permissions: string[]
}

const base64UrlDecode = (base64Url: string): string => {
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  while (base64.length % 4) {
    base64 += "="
  }
  return atob(base64)
}

const decodeJWT = (
  token: string
): KeycloakDecodedToken | Auth0DecodedToken | null => {
  const [headerBase64, payloadBase64, signature] = token.split(".")

  if (!headerBase64 || !payloadBase64 || !signature) {
    console.error("Token JWT inválido")
    return null
  }

  try {
    const header = JSON.parse(base64UrlDecode(headerBase64))
    const payload = JSON.parse(base64UrlDecode(payloadBase64))
    return { ...header, ...payload }
  } catch (error) {
    console.error("Erro ao decodificar o token JWT:")
    return null
  }
}

export default decodeJWT
