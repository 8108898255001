/* eslint-disable react/prop-types */
import { ChangeEvent, ReactNode, createContext, useRef, useState } from "react"
import { SensorsConfigContextDTO } from "../entities/SensorsConfigContextDTO"
import { HealthDTO } from "../../../utils/entities/sensors/services/healthDTO"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { SensorProv } from "../../../store/features/sensors/sensors.interfaces"
import { RightPositionOfSensorsServicesConfigsDTO } from "../entities/RightPositionOfSensorsServicesConfigsDTO"
import { AlgorithmsDTO } from "../entities/AlgorithmsFormattedDTO"
import { ArrayTransformSensReturn } from "../entities/ArrayTransformSensReturnDTO"
import { closeModal } from "../../../store/features/modal/modal.slice"
import { GetTooltipTextByAlgorithmDTO } from "../entities/GetTooltipTextByAlgorithmDTO"
import { ResetResquestDTO } from "../entities/ResetRequestDTO"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { ConfigExtraDTO } from "../entities/ConfigExtraDTO"
import { LastPotDataSavedDTO } from "../entities/LastPotDataSavedDTO"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"

interface SensorsConfigProviderProps {
  children: ReactNode
}

export const SensorsConfigContext =
  createContext<SensorsConfigContextDTO | null>(null)

export const SensorsConfigProvider: React.FC<SensorsConfigProviderProps> = ({
  children,
}) => {
  const [healths, setHealths] = useState([] as HealthDTO[])
  const sensorsConfigured = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )
  const sensorsProvisioned = useAppSelector(
    (state) => state.persistedReducer.sensors.prov
  )
  const [sensorsToBeDisplayed, setSensorToBeDisplayed] = useState(
    [] as SensorProv[]
  )
  const typeOfServiceSelected = useRef<
    | "rmms"
    | "rms2"
    | "ntc"
    | "_4t20"
    | "pot"
    | "temp"
    | "fft"
    | "accRaw"
    | "tilt"
    | null
  >(null)
  const configServiceSelected = useRef<AlgorithmsDTO>(null)
  const copyLastConfigServiceSelected = useRef<AlgorithmsDTO>(null)
  const [localStorageUpdated, setLocalStorageUpdated] = useState<boolean>(false)

  const updateReferencePOT = () => {
    setLocalStorageUpdated((prevState) => !prevState)
  }
  const configServiceExtraInformation = useRef<ConfigExtraDTO>({
    upperLimit: 100,
    inferiorLimit: 0,
    unitOfMeasurement: "%",
  })

  const [modalType, setModalType] = useState<"configure" | "reconfigure">(
    "configure"
  )
  const [bleHdrMac, setBleHdrMac] = useState<string | null>(null)
  const disablePageRef = useRef<boolean>(false)
  const requestTimeRef = useRef<number | null>(null)
  const [updatedSensorInfoSelected, setUpdatedSensorInfoSelected] =
    useState(false)
  const [btnStatus, setBtnStatus] = useState<
    "configured" | "configure" | "configuring" | "error"
  >("configure")
  const [btnStatusDisable, setBtnStatusDisable] = useState<
    "disable" | "disabling" | "disabled" | "error"
  >("disable")
  const [btnStatusReset, setBtnStatusReset] = useState<
    "reset" | "resetting" | "reseted" | "error"
  >("reset")
  const [openModalConfigService, setOpenModalConfigService] = useState(false)
  const titleModalDisableOrReset = useRef<string>("")

  const dispatch = useAppDispatch()
  const [resetOnlyAlgorithm, setResetOnlyAlgorithm] = useState(false)
  const [resetRequest, setResetRequest] = useState({} as ResetResquestDTO)
  const [lastPOTDataSavedFromWS, setLastPOtDataSavedFromWS] = useState(
    {} as LastPotDataSavedDTO
  )
  const {
    sensorsConfig: { services },
  } = useAppTranslate()

  const RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS: RightPositionOfSensorsServicesConfigsDTO =
    {
      rmms: [
        "axis",
        "res",
        "sens",
        "freqHigh",
        "freqLow",
        "nsCalc",
        "nse",
        "tsa",
      ],
      rms2: ["axis", "res", "sens", "freq", "nsCalc", "nse", "tsa"],
      ntc: ["channel", "res", "nse", "tsa"],
      _4t20: ["channel", "res", "nse", "tsa"],
      pot: ["channel", "res", "nse", "tsa"],
      temp: ["res", "nse", "tsa"],
      fft: ["axis", "res", "sens", "freq", "nsCalc", "tsa"],
      accRaw: ["axis", "res", "sens", "freq", "nsCalc", "tsa"],
      tilt: ["axis", "res", "sens", "freq", "nsCalc", "nse", "tsa"],
    }

  const TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE: GetTooltipTextByAlgorithmDTO =
    {
      temp: services.temperature,
      rms2: services.rms2,
      rmms: services.rmms,
      tilt: services.tilt,
      fft: services.fft,
      accRaw: services.accRaw,
      _4t20: services._420mA,
      ntc: services.ntc,
      pot: services.pot,
    }

  const arrayTransformSensReturn: ArrayTransformSensReturn = {
    "2": "2g",
    "4": "4g",
    "8": "8g",
  }

  const _capitalize = (str: string): string => {
    if (str?.length === 1) {
      return str?.toUpperCase()
    }

    const lower = str?.toLowerCase()
    return str?.charAt(0).toUpperCase() + lower?.slice(1)
  }

  const _transformSens = (value: number | string) => {
    if (typeof value === "string") return value

    if (isNaN(value)) return value
    else
      return arrayTransformSensReturn[value as keyof ArrayTransformSensReturn]
  }

  const _transformTSA = (value: number | string): string | number => {
    if (typeof value === "string") return value

    if (isNaN(value) || value === 0) return value
    else if (value < 60) {
      return `${value}s`
    } else if (value >= 60 && value < 3600) {
      return `${value / 60}m`
    } else {
      return `${value / 3600}h`
    }
  }

  const _transformFreq = (value: number | string): string | number => {
    if (typeof value === "string") return value
    if (!value || isNaN(value)) return value
    return `${value}Hz`
  }

  const transformHowTextWillBePresented = (
    config: string,
    value: null | number | undefined | string
  ): string | number => {
    if (!value) return " "
    switch (true) {
      case config === "axis":
        return _capitalize(String(value))
      case config === "channel":
        return String(value)?.toUpperCase()
      case config === "sens":
        return _transformSens(value)
      case config === "tsa":
        return _transformTSA(value)
      case config === "freq" || config === "freqHigh" || config === "freqLow":
        return _transformFreq(value)
      default:
        return value
    }
  }

  const handleCloseConfigServiceModal = () => {
    if (btnStatus !== "configuring") {
      dispatch(closeModal())
      setBtnStatus("configure")
      setBtnStatusDisable("disable")
      setOpenModalConfigService(false)
      configServiceExtraInformation.current = {
        upperLimit: 100,
        inferiorLimit: 0,
        unitOfMeasurement: "%",
      }
    }
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
    setBtnStatusDisable("disable")
    setBtnStatusReset("reset")
    setBtnStatus("configure")
    setOpenModalConfigService(false)
  }

  const handleCloseDisableOrResetModal = () => {
    if (resetOnlyAlgorithm && btnStatusDisable !== "disabling") {
      handleCloseModal()
    }

    if (!resetOnlyAlgorithm && btnStatusReset !== "resetting") {
      handleCloseModal()
    }
  }

  const removeLastDataPOTSalved = (mac: string) => {
    setLastPOtDataSavedFromWS((prevState) => {
      const newState = { ...prevState }
      if (prevState[mac]) {
        delete newState[mac]
      }
      return newState
    })
    localStorage.removeItem(`${mac} - pot - Reference`)
    updateReferencePOT()
  }
  const [orderOption, setOrderOption] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>("")
  const [searchOption, setSearchOption] = useState<number>(0)

  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleChangeSearchOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setSearchOption(Number(e.target.value))
  }

  const compare = (a: number | string, b: number | string) => {
    if (!a && b) return 1
    if (a && !b) return -1

    if (!a || !b) return 0

    return a <= b ? -1 : 1
  }

  const compareDates = (a: string, b: string) => {
    if (!a && b) return 1
    if (a && !b) return -1
    if (!a || !b) return 0

    const firstDate = new Date(a)
    const secondDate = new Date(b)

    return firstDate <= secondDate ? -1 : 1
  }

  const orderDisplayedSensors = (sensors: SensorProv[]): SensorProv[] => {
    if (orderOption === 0) return sensors

    return sensors.sort((a, b) => {
      switch (orderOption) {
        case 1:
          if (!a.name && b.name) return 1
          if (a.name && !b.name) return -1
          if (!a.name || !b.name) return compare(a.bleHdrMac, b.bleHdrMac)
          return compare(a.name, b.name)

        case 2:
          return compare(a.bleHdrMac, b.bleHdrMac)

        case 3:
          return compare(
            a.sensor.productionSerialNumber,
            b.sensor.productionSerialNumber
          )

        case 4:
          if (!a.health && b.health) return 1
          if (a.health && !b.health) return -1
          if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
          return compare(a.health.temp, b.health.temp)

        case 5:
          if (!a.health && b.health) return 1
          if (a.health && !b.health) return -1
          if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
          return compare(a.health.voltage, b.health.voltage)

        case 6:
          if (!a.health && b.health) return 1
          if (a.health && !b.health) return -1
          if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
          return compare(a.health.rssi, b.health.rssi)

        case 7:
          if (!a.health && b.health) return 1
          if (a.health && !b.health) return -1
          if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
          return compareDates(a.health.time, b.health.time)

        case 8:
          if (!a.health && b.health) return 1
          if (a.health && !b.health) return -1
          if (!a.health || !b.health) return compare(a.bleHdrMac, b.bleHdrMac)
          return compareDates(a.health.lastReset, b.health.lastReset)

        default:
          return -1
      }
    })
  }

  const getExibitionName = (sensor: SensorProv): string => {
    return sensor.name
      ? sensor.name
      : `S${cleanAndPadSerialNumber(sensor.sensor.productionSerialNumber, sensor.sensor.hardwareVersion)}`
  }

  const match = (text: string, find: string) => {
    return text.toLowerCase().includes(find.toLowerCase())
  }

  const matchResult = (input: string, sensor: SensorProv, option: number) => {
    switch (option) {
      case 1: {
        const exhibitionName = getExibitionName(sensor)
        return match(exhibitionName, input)
      }

      case 2: {
        return match(sensor.bleHdrMac, input)
      }

      case 3:
        return match(sensor.sensor.productionSerialNumber.toString(), input)

      default:
        return false
    }
  }

  const filter = (
    allSensors: SensorProv[],
    serviceIndex: number,
    sensorInput: string
  ) => {
    const sensors: SensorProv[] = []

    const filteringBySensor = sensorInput !== ""

    for (let i = 0; i < allSensors.length; i++) {
      const sensor = allSensors[i]

      const hasResultName =
        !filteringBySensor || matchResult(sensorInput, sensor, serviceIndex)

      if (hasResultName) {
        sensors.push(sensor)
        continue
      }
    }

    return sensors
  }

  const getSensorsToDisplay = (sensors: SensorProv[]) => {
    const filtered = filter(sensors, searchOption, searchValue)
    const ordered = orderDisplayedSensors(filtered)

    return ordered
  }

  const handleClearFilter = () => {
    setSearchValue("")
    setSearchOption(0)
  }

  const defaultContext: SensorsConfigContextDTO = {
    healths,
    setHealths,
    sensorsProvisioned,
    sensorsConfigured,
    sensorsToBeDisplayed,
    filteredSensors: getSensorsToDisplay(sensorsToBeDisplayed),
    setSensorToBeDisplayed,
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    typeOfServiceSelected,
    configServiceSelected,
    copyLastConfigServiceSelected,
    transformHowTextWillBePresented,
    modalType,
    setModalType,
    _capitalize,
    bleHdrMac,
    setBleHdrMac,
    disablePageRef,
    requestTimeRef,
    btnStatus,
    setBtnStatus,
    handleCloseConfigServiceModal,
    updatedSensorInfoSelected,
    setUpdatedSensorInfoSelected,
    TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE,
    resetOnlyAlgorithm,
    setResetOnlyAlgorithm,
    resetRequest,
    setResetRequest,
    btnStatusDisable,
    setBtnStatusDisable,
    handleCloseDisableOrResetModal,
    titleModalDisableOrReset,
    btnStatusReset,
    setBtnStatusReset,
    openModalConfigService,
    setOpenModalConfigService,
    configServiceExtraInformation,
    lastPOTDataSavedFromWS,
    setLastPOtDataSavedFromWS,
    removeLastDataPOTSalved,
    localStorageUpdated,
    updateReferencePOT,
    setOrderOption,
    orderOption,
    searchValue,
    searchOption,
    handleChangeSearchValue,
    handleChangeSearchOption,
    handleClearFilter,
  }

  return (
    <SensorsConfigContext.Provider value={defaultContext}>
      {children}
    </SensorsConfigContext.Provider>
  )
}
