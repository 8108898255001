/**
 * @file hdr_rqm_action.ts
 * @description Define as constantes de ação de requisição de dados
 * RQM_ACTION_IDLE: Nenhuma ação solicitada
 * RQM_ACTION_PROV: Ação de provisionamento de sensor solicitado
 * RQM_ACTION_CONFIG: Ação de configuração de sensor solicitado
 * RQM_ACTION_RESET: Ação de reset solicitada
 * RQM_ACTION_PROV_LIST: Ação de lista de provisionamento solicitada
 */
export const HDR_RQM_ACTION = {
  RQM_ACTION_IDLE: 0,
  RQM_ACTION_PROV: 1,
  RQM_ACTION_CONFIG: 2,
  RQM_ACTION_RESET: 3,
  RQM_ACTION_PROV_LIST: 4,
}
