import { PermissionsProteu } from "../store/features/user/user.interfaces"
import { useAppSelector } from "../store/hooks"

interface Props {
  children: JSX.Element
  permission: PermissionsProteu
}

export const ValidatePermission = ({
  children,
  permission,
}: Props): JSX.Element => {
  const permissionsFromUser = useAppSelector(
    (state) => state.persistedReducer.user.profile?.permissions
  )

  if (!permissionsFromUser) return <></>

  if (
    permissionsFromUser.some(
      (permissions) => permissions === PermissionsProteu.UserAll
    )
  ) {
    return children
  }

  switch (permission) {
    case PermissionsProteu.DashboardWrite:
      if (
        permissionsFromUser.some(
          (permission) => permission === PermissionsProteu.DashboardWrite
        )
      ) {
        return children
      }
      break
    case PermissionsProteu.DashboardRead:
      if (
        permissionsFromUser.some(
          (permission) => permission === PermissionsProteu.DashboardRead
        )
      ) {
        return children
      }
      break
    case PermissionsProteu.ManagerAll:
      if (
        permissionsFromUser.some(
          (permission) => permission === PermissionsProteu.ManagerAll
        )
      ) {
        return children
      }
      break
    default:
      return <></>
  }

  return <></>
}
