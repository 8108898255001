import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { Button } from "../../../Buttons"
import { TitleInputText } from "../../../TitleInputText"
import { Error } from "../Error"
import { Loading } from "../Loading"
import { SelectAnalyticGridOption } from "./components/SelectAnalyticGridOption"
import { useDashboardConfigController } from "./hooks/useDashboardConfigController"
import { FC } from "react"

import * as S from "./styles"

interface DashboardConfigProps {
  type: "DashboardCreate" | "DashboardEdit"
}

export const DashboardConfig: FC<DashboardConfigProps> = ({
  type,
}): JSX.Element => {
  const {
    isLoading,
    hasError,
    titleInput,
    selectedTypeLayout,
    handleSelectTypeLayout,
    handleUpdateTitle,
    handleSubmit,
    handleCloseModal,
    handleDisabledButton,
    errTitleDash,
  } = useDashboardConfigController(type)

  const { modalEditDash, buttons, dashboard } = useAppTranslate()

  if (hasError) {
    return (
      <Error
        errorMessage={
          type === "DashboardCreate"
            ? modalEditDash.error_create
            : modalEditDash.error_edit
        }
        labelBackButton={buttons.back}
        onBack={handleCloseModal}
      />
    )
  }

  if (isLoading) {
    return <Loading loadingMessage={modalEditDash.loading} />
  }

  return (
    <>
      <S.Container>
        <TitleInputText
          name='titleAnalytic'
          type='text'
          title={modalEditDash.dash_title}
          placeholder={modalEditDash.dash_title_text}
          onChange={handleUpdateTitle}
          value={titleInput}
        />
        {errTitleDash && (
          <S.InputError>{dashboard.maximum_title_size}</S.InputError>
        )}
        <SelectAnalyticGridOption
          onChange={handleSelectTypeLayout}
          selectedIndex={selectedTypeLayout}
          typeLayoutLabel={modalEditDash.type_layout}
          oneAnalyticLabel={modalEditDash.one_graphs_per_line}
          twoAnalyticLabel={modalEditDash.two_graphs_per_line}
          threeAnalyticLabel={modalEditDash.three_graphs_per_line}
        />
        <S.ButtonGroup>
          <Button
            label={buttons.finish}
            onClick={() => handleSubmit(type)}
            disabled={handleDisabledButton()}
          />
        </S.ButtonGroup>
      </S.Container>
    </>
  )
}
