import { Btn } from "./styles"

type ButtonBlueProps = {
  text: string
  callBack: () => void
  $width?: string
  $background?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const FormButton = ({
  text,
  callBack,
  $width = "400px",
  $background = "#3CD62F",
  ...rest
}: ButtonBlueProps): JSX.Element => {
  return (
    <Btn {...rest} onClick={callBack} $width={$width} $background={$background}>
      {text}
      {rest.children}
    </Btn>
  )
}
