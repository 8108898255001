export const detailedChart = () => ({
  credits: {
    enabled: false,
  },
  lang: {
    noData: "Não há dados para serem mostrados para os sensores selecionados.",
    loading: "Carregando...",
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#303030",
    },
  },
  chart: {
    height: "650px",
    type: "heatmap",
    plotBorderWidth: 0.5,
  },
  title: {
    text: "",
  },

  yAxis: {},
  stockTools: {
    gui: {
      enable: false,
    },
  },

  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
      },
    },
  },

  tooltip: {
    pointFormat:
      '<span style="color: black">Operando</span>: <b>{point.operating} minutos</b><br/><span style="color: black">Ligado</span>: <b>{point.on} minutos</b><br/><span style="color: black">Sem Sinal</span>: <b>{point.noSignal} minutos</b><br/><span style="color: black">Desligado</span>: <b>{point.off} minutos</b>',
    shared: true,
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "printChart",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
  accessibility: {
    enabled: false,
  },
})
