import { HTMLProps } from "react"
import styled from "styled-components"

export const Container = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(1, 0, 0, 0.5);
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: auto;
  font-family: arial, sans-serif;
  box-sizing: border-box;
`

export const TitleModal = styled.span`
  font-size: 2.3rem;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.dark};
  @media (max-width: 1300px) {
    font-size: 2rem;
  }
`

interface ContentProps extends HTMLProps<HTMLDivElement> {
  $widthModal: string
  $heightModal: string
  $minWidthModal: string
  $minHeightModal: string
  $maxWidthOverflow: string
  $maxHeight: string
  $maxWidth: string
}

export const Content = styled.div<ContentProps>`
  width: ${(props) => props.$widthModal};
  min-width: ${(props) => props.$minWidthModal};
  min-height: ${(props) => props.$minHeightModal};
  height: ${(props) => props.$heightModal};
  max-height: ${(props) => props.$maxHeight};
  max-width: ${(props) => props.$maxWidth};
  background-color: #ffffff;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
  position: fixed;

  overflow: auto;

  @media (max-width: ${(props) => props.$maxWidthOverflow}) {
    overflow: auto;
    min-width: 95%;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 64px;

  @media (max-width: 500px) {
    justify-content: start;
    padding-left: 10px;
  }
`

export const ModalBody = styled.div`
  padding: 10px;
`
