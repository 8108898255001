import { apiCall } from "../../../utils/axios"

export class GetAlarmsLogsUseCase {
  async getAll() {
    try {
      const api = apiCall()

      const URL = `${process.env.REACT_APP_API_ALARMS_LOGS}`
      const response = await api.get(URL)

      if (response.data && response.data.length > 0) {
        return response.data
      }

      return []
    } catch (error) {
      return []
    }
  }
}
