import styled from "styled-components"

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 10px;
  }
`

export const Title = styled.div`
  margin-left: 5px;

  span {
    margin-left: 5px;
  }

  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
`
