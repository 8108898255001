import { useTranslation } from "react-i18next"
import { AppTranslate } from "./entities/AppTranslate"

export const useAppTranslate = (): AppTranslate => {
  const { t } = useTranslation()

  return {
    buttons: {
      next: t("buttons.next"),
      back: t("buttons.back"),
      finish: t("buttons.finish"),
      save: t("buttons.save"),
      delete: t("buttons.delete"),
      new_dashboard: t("buttons.new dashboard"),
      cancel: t("buttons.cancel"),
      wait: t("buttons.wait"),
      edit: t("buttons.edit"),
      access: t("buttons.access"),
      yes: t("buttons.yes"),
      no: t("buttons.no"),
      already_installed: t("buttons.already installed"),
      error: t("buttons.error"),
      install: t("buttons.install"),
      installed: t("buttons.installed"),
      installing: t("buttons.installing"),
      reconfigure: t("buttons.reconfigure"),
      disable: t("buttons.disable"),
      disabling: t("buttons.disabling"),
      disabled: t("buttons.disabled"),
      configure: t("buttons.configure"),
      configuring: t("buttons.configuring"),
      configured: t("buttons.configured"),
      reset: t("buttons.reset"),
      resetting: t("buttons.resetting"),
      resetted: t("buttons.resetted"),
    },
    login: {
      welcome: t("login.welcome"),
    },
    error: {
      message: t("error.message"),
      button: t("error.button"),
    },
    sensorsProv: {
      application_version: t("sensorsProv.application version"),
      protocol_version: t("sensorsProv.protocol version"),
      hardware_version: t("sensorsProv.hardware version"),
      serial_number: t("sensorsProv.serial number"),
      sensor_temperature: t("sensorsProv.sensor temperature"),
      date_and_time_of_last_msg: t("sensorsProv.date and time of last msg"),
      rssi: t("sensorsProv.rssi"),
      msg_distance_from_sensor: t("sensorsProv.msg distance from sensor"),
      sensors: t("sensorsProv.sensors"),
      installation: t("sensorsProv.installation"),
      voltage: t("sensorsProv.voltage"),
    },
    sensorsConfig: {
      searchEmpty: t("sensorsConfig.searchEmpty"),
      clearSearchLabel: t("sensorsConfig.clearSearchLabel"),
      optionLabel: t("sensorsConfig.optionLabel"),
      searchLabel: t("sensorsConfig.searchLabel"),
      searchOptions: t("sensorsConfig.searchOptions", { returnObjects: true }),
      orderPlaceholder: t("sensorsConfig.orderPlaceholder"),
      orderOptions: t("sensorsConfig.orderOptions", { returnObjects: true }),
      sensors: t("sensorsConfig.sensors"),
      configuration: t("sensorsConfig.configuration"),
      last_seen: t("sensorsConfig.last seen"),
      last_reset: t("sensorsConfig.last reset"),
      versions: t("sensorsConfig.versions"),
      edit_sensor_name: t("sensorsConfig.edit sensor name"),
      axle: t("sensorsConfig.axle"),
      resolution: t("sensorsConfig.resolution"),
      sensitivity: t("sensorsConfig.sensitivity"),
      frequency: t("sensorsConfig.frequency"),
      freqHigh: t("sensorsConfig.freqHigh"),
      freqLow: t("sensorsConfig.freqLow"),
      sample_number: t("sensorsConfig.sample number"),
      shipping_acquisitions: t("sensorsConfig.shipping acquisitions"),
      time_between_acquisitions: t("sensorsConfig.time between acquisitions"),
      copy_configuration_from: t("sensorsConfig.copy configuration from"),
      channel: t("sensorsConfig.channel"),
      reset_sensor: t("sensorsConfig.reset sensor"),
      reset_message: t("sensorsConfig.reset message"),
      disable_message_1: t("sensorsConfig.disable message 1"),
      disable_message_2: t("sensorsConfig.disable message 2"),
      are_you_sure: t("sensorsConfig.are you sure"),
      services: {
        temperature: t("sensorsConfig.services.temperature"),
        rms2: t("sensorsConfig.services.rms2"),
        rmms: t("sensorsConfig.services.rmms"),
        tilt: t("sensorsConfig.services.tilt"),
        fft: t("sensorsConfig.services.fft"),
        accRaw: t("sensorsConfig.services.accRaw"),
        _420mA: t("sensorsConfig.services.420mA"),
        ntc: t("sensorsConfig.services.ntc"),
        pot: t("sensorsConfig.services.pot"),
      },
    },
    dashboard: {
      no_graphics_configured: t("dashboard.no graphics configured"),
      information_to_add_graph: t("dashboard.information to add graph"),
      configure_your_first_dashboard: t(
        "dashboard.configure your first dashboard"
      ),
      analytic: {
        no_data: t("dashboard.analytic.no data"),
        amplitude: t("dashboard.analytic.amplitude"),
        time_s: t("dashboard.analytic.time_s"),
        loading: t("dashboard.analytic.loading"),
        subtitle: t("dashboard.analytic.subtitle"),
      },
      maximum_title_size: t("dashboard.maximum title size"),
    },
    reports: {
      reportsTitle: t("reports.reportsTitle"),
      saveReports: t("reports.saveReports"),
      loading: t("reports.loading"),
      historical_analysis: t("reports.historical analysis"),
    },
    modalExtraConfig: {
      title: t("modalExtraConfig.title"),
    },
    modalConfirmation: {
      title: t("modalConfirmation.title"),
      message: t("modalConfirmation.message"),
    },
    modalAnalyticConfig: {
      error: t("modalAnalyticConfig.error"),
      loading: t("modalAnalyticConfig.loading"),
      modal_title: t("modalAnalyticConfig.modal title"),
      preference: t("modalAnalyticConfig.preference"),
      sensor_services: t("modalAnalyticConfig.sensor/services"),
      graphic_title: t("modalAnalyticConfig.graphic title"),
      type_graphic: t("modalAnalyticConfig.type graphic"),
      type_layout: t("modalAnalyticConfig.type layout"),
      period: t("modalAnalyticConfig.period"),
      graphic_title_text: t("modalAnalyticConfig.graphic_title_text"),
      entity: t("modalAnalyticConfig.entity"),
      oriented_graph: t("modalAnalyticConfig.oriented graph"),
      sensors: t("modalAnalyticConfig.sensors"),
      sensor: t("modalAnalyticConfig.sensor"),
      services: t("modalAnalyticConfig.services"),
      period_text: t("modalAnalyticConfig.period_text"),
      amplitude_time_s: t("modalAnalyticConfig.amplitude/time_s"),
      amplitude_time_ms: t("modalAnalyticConfig.amplitude/time_ms"),
      amplitude_frequency: t("modalAnalyticConfig.amplitude/frequency"),
      amplitude_time_s_text: t("modalAnalyticConfig.amplitude/time_s_text"),
      amplitude_time_ms_text: t("modalAnalyticConfig.amplitude/time_ms_text"),
      amplitude_frequency_text: t(
        "modalAnalyticConfig.amplitude/frequency_text"
      ),
      last_minute: t("modalAnalyticConfig.last minute"),
      last_10_minutes: t("modalAnalyticConfig.last 10 minutes"),
      last_30_minutes: t("modalAnalyticConfig.last 30 minutes"),
      last_hour: t("modalAnalyticConfig.last hour"),
      last_5_hours: t("modalAnalyticConfig.last 5 hours"),
      last_12_hours: t("modalAnalyticConfig.last 12 hours"),
      last_day: t("modalAnalyticConfig.last day"),
      last_week: t("modalAnalyticConfig.last week"),
      no_sensor_with_this_algorithm_available: t(
        "modalAnalyticConfig.no sensor with this algorithm available"
      ),
      all_fields_are_required: t("modalAnalyticConfig.all fields are required"),
      enter_upper_limit: t("modalAnalyticConfig.enter upper limit"),
      enter_the_lower_limit: t("modalAnalyticConfig.enter the lower limit"),
      enter_the_unit_of_measurement: t(
        "modalAnalyticConfig.enter the unit of measurement"
      ),
    },
    modalEditDash: {
      modal_title_edit: t("modalEditDash.modal title edit"),
      modal_title_create: t("modalEditDash.modal title create"),
      dash_title: t("modalEditDash.dash title"),
      type_layout: t("modalEditDash.type layout"),
      two_graphs_per_line: t("modalEditDash.two graphs per line"),
      one_graphs_per_line: t("modalEditDash.one graphs per line"),
      three_graphs_per_line: t("modalEditDash.three graphs per line"),
      dash_title_text: t("modalEditDash.dash_title_text"),
      error_create: t("modalEditDash.error_create"),
      error_edit: t("modalEditDash.error_edit"),
      loading: t("modalEditDash.loading"),
    },
    modalEditAnalytic: {
      modal_title: t("modalEditAnalytic.modal_title"),
      graphic_title: t("modalEditAnalytic.graphic_title"),
      period: t("modalEditAnalytic.period"),
      period_text: t("modalEditAnalytic.period_text"),
      graphic_title_text: t("modalEditAnalytic.graphic_title_text"),
    },
    modalDeleteAnalytic: {
      title: t("modalDeleteAnalytic.title"),
      subtitle: t("modalDeleteAnalytic.subtitle"),
      info: t("modalDeleteAnalytic.info"),
      error: t("modalDeleteAnalytic.error"),
      loading: t("modalDeleteAnalytic.loading"),
    },
    modalDeleteDash: {
      title: t("modalDeleteDash.title"),
      subtitle: t("modalDeleteDash.subtitle"),
      info: t("modalDeleteDash.info"),
      error: t("modalDeleteDash.error"),
      loading: t("modalDeleteDash.loading"),
    },
    lateralMenu: {
      user: t("lateralMenu.user"),
      logout: t("lateralMenu.logout"),
      refresh: t("lateralMenu.refresh"),
    },
    errorTranslation: {
      some_error_occurred: t("errorTranslation.some error occurred"),
      not_hdr_ble_network_registered: t(
        "errorTranslation.not hdr ble network registered"
      ),
      sensor_is_not_produced_by_Hedro: t(
        "errorTranslation.sensor is not produced by Hedro"
      ),
      already_provisioned: t("errorTranslation.already provisioned"),
      process_of_being_provisioned: t(
        "errorTranslation.process of being provisioned"
      ),
      not_sensor_to_be_provisioned_for_this_company: t(
        "errorTranslation.not sensor to be provisioned for this company"
      ),
      sale_not_made: t("errorTranslation.sale not made"),
    },
    chart: {
      subtitle: t("chart.subtitle"),
      moduleAxis: t("chart.moduleAxis"),
      noDataMessage: t("chart.noDataMessage"),
      loadingMessage: t("chart.loadingMessage"),
      fftVelocity: t("chart.fftVelocity"),
      fftAcceleration: t("chart.fftAcceleration"),
      adlTitle: t("chart.adlTitle"),
      xAxisTitle: {
        1: t("chart.xAxisTitle.type 1"),
        2: t("chart.xAxisTitle.type 2"),
        3: t("chart.xAxisTitle.type 3"),
      },
      yAxisTitle: {
        default: t("chart.yAxisTitle.default"),
        0: t("chart.yAxisTitle.0"),
        1: t("chart.yAxisTitle.1"),
        2: t("chart.yAxisTitle.2"),
        3: t("chart.yAxisTitle.3"),
        4: t("chart.yAxisTitle.4"),
        5: t("chart.yAxisTitle.5"),
        6: t("chart.yAxisTitle.6"),
        7: t("chart.yAxisTitle.7"),
        8: t("chart.yAxisTitle.8"),
        9: t("chart.yAxisTitle.9"),
        10: t("chart.yAxisTitle.10"),
        11: t("chart.yAxisTitle.11"),
      },
    },
    alarms: {
      pageName: t("alarms.pageName"),
      subPageConfiguration: t("alarms.subPageConfiguration"),
      subPageLogs: t("alarms.subPageLogs"),
    },
    gateways: {
      pageName: t("gateways.pageName"),
    },
    register: {
      companyPageName: t("register.companyPageName"),
      userPageName: t("register.userPageName"),
      salesPageName: t("register.salesPageName"),
    },
    historicalAnalysis: {
      fft3d: {
        title: t("historicalAnalysis.fft3d.title"),
        selectAxis: t("historicalAnalysis.fft3d.selectAxis"),
      },
    },
  }
}
