import React from "react"
import {
  DestructiveButton,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from "./styles"

type VariantType = "primary" | "secondary" | "link" | "destructive"

export type ButtonOwnProps<E extends React.ElementType> = {
  as?: E
  variant?: VariantType
  children?: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
}

export type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ButtonOwnProps<E>>

const getStyleVariant = ({ variant }: { variant: VariantType }) => {
  switch (variant) {
    case "primary":
      return PrimaryButton
    case "secondary":
      return SecondaryButton
    case "link":
      return LinkButton
    case "destructive":
      return DestructiveButton
  }
}

export const Button = <E extends React.ElementType = "button">({
  children,
  as,
  variant = "primary",
  leftIcon,
  rightIcon,
  ...props
}: ButtonProps<E>): React.ReactNode => {
  const Component = as || "button"

  const Variant = getStyleVariant({ variant })

  return (
    <Variant as={Component} {...props}>
      {leftIcon}
      {children}
      {rightIcon}
    </Variant>
  )
}
