import styled from "styled-components"

interface BtnProps {
  $width?: string
  $background?: string
}

export const Btn = styled.button<BtnProps>`
  background: ${(props) => props.$background};
  gap: 5px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
  text-transform: uppercase;
  margin-bottom: 10px;
  width: ${(props) => props.$width};
  height: 40px;
`
