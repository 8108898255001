import theme from "../../../../styles/theme"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { MdDoneAll } from "react-icons/md"
import { LoadingSpinner } from "../../../LoadingSpinner"

export const useInstallButtonController = () => {
  const { buttons } = useAppTranslate()

  const returnStatus = (
    status?:
      | "available"
      | "installed"
      | "installing"
      | "error"
      | "blocked"
      | "provisioned"
      | "configure"
      | "configuring"
      | "configured"
      | "disable"
      | "disabling"
      | "disabled"
      | "reset"
      | "resetting"
      | "reseted"
  ) => {
    const available = {
      title: buttons.install,
      background: "#00c04b",
      disabled: false,
      letterSpacing: "1.1px",
      color: theme.colors.white,
      icon: <></>,
    }

    const configure = {
      title: buttons.configure,
      background: "#00c04b",
      disabled: false,
      letterSpacing: "1.1px",
      color: theme.colors.white,
      icon: <></>,
    }

    const provisioned = {
      title: buttons.already_installed,
      background: "#FEE12B",
      color: theme.colors.black,
      disabled: true,
      letterSpacing: "0.2px",
      icon: <></>,
    }

    const blocked = {
      title: buttons.install,
      background: "#A9A9A9",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1.1px",
      icon: <></>,
    }

    const installed = {
      title: buttons.installed,
      background: "#00c04b",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1px",
      icon: <MdDoneAll size={17} />,
    }

    const configured = {
      title: buttons.configured,
      background: "#00c04b",
      disabled: false,
      color: theme.colors.white,
      letterSpacing: "1px",
      icon: <MdDoneAll size={17} />,
    }

    const installing = {
      title: buttons.installing,
      background: "#7fff7f",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1.1px",
      icon: <LoadingSpinner size={18} />,
    }

    const configuring = {
      title: buttons.configuring,
      background: "#7fff7f",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1.1px",
      icon: <LoadingSpinner size={18} />,
    }

    const error = {
      title: buttons.error,
      background: "#FF0000",
      disabled: false,
      color: theme.colors.white,
      letterSpacing: "1.1px",
      icon: <></>,
    }

    const disable = {
      title: buttons.disable,
      background: "#00c04b",
      disabled: false,
      letterSpacing: "1.1px",
      color: theme.colors.white,
      icon: <></>,
    }

    const disabling = {
      title: buttons.disabling,
      background: "#7fff7f",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1.1px",
      icon: <LoadingSpinner size={18} />,
    }

    const disabled = {
      title: buttons.disabled,
      background: "#00c04b",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1px",
      icon: <MdDoneAll size={17} />,
    }

    const reset = {
      title: buttons.reset,
      background: "#00c04b",
      disabled: false,
      letterSpacing: "1.1px",
      color: theme.colors.white,
      icon: <></>,
    }

    const resetting = {
      title: buttons.resetting,
      background: "#7fff7f",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1.1px",
      icon: <LoadingSpinner size={18} />,
    }

    const reseted = {
      title: buttons.resetted,
      background: "#00c04b",
      disabled: true,
      color: theme.colors.white,
      letterSpacing: "1px",
      icon: <MdDoneAll size={17} />,
    }

    switch (status) {
      case "available":
        return available
      case "installed":
        return installed
      case "installing":
        return installing
      case "blocked":
        return blocked
      case "provisioned":
        return provisioned
      case "error":
        return error
      case "configure":
        return configure
      case "configuring":
        return configuring
      case "configured":
        return configured
      case "disable":
        return disable
      case "disabling":
        return disabling
      case "disabled":
        return disabled
      case "reset":
        return reset
      case "resetting":
        return resetting
      case "reseted":
        return reseted
      default:
        return blocked
    }
  }

  return { returnStatus }
}
