import styled from "styled-components"

export const TopNavBar = styled.div`
  border-bottom: 1px solid #afacac;
  /* width: 100%; */
  text-align: center;
  margin-top: 10px;
  display: flex;

  gap: 10px;
`

export const ItemTopNavBar = styled.h2<{ selected?: boolean }>`
  margin-left: 10px;
  font-size: ${(props) => props.theme.font.sizes.small};
  color: #afacac;
  border-bottom: ${(props) => props.selected && "4px solid #2684DA"};
  color: ${(props) => props.selected && "#3E3D3D"};
`
