import { FC } from "react"
import * as S from "./styles"

interface ButtonProps {
  label: string
  onClick: () => void
  disabled?: boolean
}

export const Button: FC<ButtonProps> = ({
  label,
  onClick,
  disabled = false,
}) => {
  return (
    <S.Button data-testid='simple-button' onClick={onClick} disabled={disabled}>
      {label}
    </S.Button>
  )
}
