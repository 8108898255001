import { apiCall } from "../../../utils/axios"

export class GetAlarmUsecase {
  async get() {
    try {
      const api = apiCall()
      const response = await api.get(`${process.env.REACT_APP_API_ALARMS}`)

      if (response.status === 200) {
        return {
          error: false,
          data: response.data,
          status: 200,
        }
      }

      return {
        error: true,
        data: "Some error occurred @ GetAlarmsUsecase!",
        status: response?.status,
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const status = err?.response?.status
      if (status !== 404) {
        return {
          error: true,
          data: err,
          status: err.response.status,
        }
      }

      return {
        error: false,
        data: {},
        status: err.response.status,
      }
    }
  }
}
