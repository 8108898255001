import { decodeKeycloak } from "./decodeKeycloak"
import { decodeAuth0 } from "./decodeAuth0"

export const GetPayloadFromAccessToken = (token: string) => {
  const authProvider = process.env.REACT_APP_AUTH

  if (authProvider === "keycloak") return decodeKeycloak(token)

  return decodeAuth0(token)
}
