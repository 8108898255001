import styled from "styled-components"

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
`

export const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.black};
  transition: 0.4s;
  border-radius: 34px;
`

export const SliderThumb = styled.span`
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
`

export const StyledSwitch = styled(HiddenInput)`
  &:checked + ${Slider} {
    background-color: ${(props) => props.theme.colors.black};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${(props) => props.theme.colors.black};
  }

  &:checked + ${Slider} + ${SliderThumb} {
    transform: translateX(26px);
  }
`
