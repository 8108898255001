import { ConfirmBtn, TextAndIcon } from "./styles"
import { ButtonConfirmController } from "./controllers/buttonConfirmController"

interface ButtonConfirmProps {
  handleOnClick: (permission: "admin" | "user" | "viewer") => void
  permission: "admin" | "user" | "viewer"
  text: string
  loading: boolean
}

export const ButtonConfirm = ({
  handleOnClick,
  permission,
  text,
  loading,
}: ButtonConfirmProps) => {
  const { returnStatus } = ButtonConfirmController()

  const { cursor, disabled, icon } = returnStatus(loading, permission)

  return (
    <ConfirmBtn
      onClick={() => handleOnClick(permission)}
      disabled={disabled}
      $cursor={cursor}
    >
      <TextAndIcon>
        {text}
        {icon}
      </TextAndIcon>
    </ConfirmBtn>
  )
}
