import { UsersApiDTO } from "../entities/usersApiDTO"
import { Result, left, right } from "../../../utils/either/result"
import { apiCall } from "../../../utils/axios"
import axios from "axios"

export const callApiGetAllUsers = async (): Promise<
  Result<Error, UsersApiDTO[]>
> => {
  const api = apiCall()

  const URL = `${process.env.REACT_APP_API_GET_ALL_USERS}`

  try {
    const result = await api.get<UsersApiDTO[]>(URL)
    return right(result.data)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400)
        return left(Error("Erro ao buscar usuários!"))

      if (error.response?.status === 401)
        return left(Error("Você não tem permissão para completar essa ação!"))

      if (error.response?.status === 404)
        return left(Error("O recurso não foi encontrado!"))

      return left(Error("Erro ao buscar usuários!"))
    }

    return left(Error("Erros ao buscar usuários!"))
  }
}
