import styled from "styled-components"

export const SearchContainer = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 400px;
  height: 40px;
`

export const ContainerSelect = styled.div`
  background-color: #ddd;
  height: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  padding: 2px;
`

export const SelectSearchItems = styled.select`
  border: none;
  border-radius: 3px;
  background-color: #ddd;
  text-align: center;
  width: 100px;
  color: #474747;
  height: 100%;
  &:hover {
    color: #0f0f0f;
    cursor: pointer;
    background-color: #d4d4d4;
  }
`
export const InputSearch = styled.input`
  width: 300px;
  padding: 2px;
  border: none;
  padding-left: 5px;
`
export const Icon = styled.div`
  padding-right: 5px;
`
export const OptionItems = styled.option`
  border: none;
`
