import { PropsWithChildren } from "react"
import { useAppSelector } from "../../store/hooks"
import { PermissionsProteu } from "../../store/features/user/user.interfaces"

interface ProtectedComponentProps {
  permissions: PermissionsProteu[] | PermissionsProteu
}

export const ProtectedComponent = ({
  permissions,
  children,
}: PropsWithChildren<ProtectedComponentProps>) => {
  const profile = useAppSelector((state) => state.persistedReducer.user.profile)

  if (!profile) return <></>
  if (profile.permissions.length === 0) return <></>

  if (Array.isArray(permissions)) {
    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i]
      if (!profile.permissions.includes(permission)) return <></>
    }

    return children
  }

  if (!profile.permissions.includes(permissions)) return <></>

  return children
}
