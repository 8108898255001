import { FC } from "react"
import * as S from "./styles"
import { SensorCard } from "../components/SensorCard"
import { Root } from "../../../components/Modal"
import { useSensorsInfoController } from "../controllers/useSensorsInfoController"
import { Select } from "../components/Select"
import { useAppTranslate } from "../../../translate/useAppTranslate"

export const SensorsConfigWrapper: FC = () => {
  const {
    filteredSensors,
    orderOption,
    handleOnChangeOrderBy,
    searchValue,
    searchOption,
    handleChangeSearchValue,
    handleChangeSearchOption,
    handleClearFilter,
  } = useSensorsInfoController()

  const { sensorsConfig } = useAppTranslate()

  return (
    <S.Wrapper>
      <S.FilterContainer>
        <S.Filters>
          <S.SearchInput
            placeholder={sensorsConfig.searchLabel}
            value={searchValue}
            onChange={handleChangeSearchValue}
            disabled={searchOption === 0}
          />
          <Select
            values={sensorsConfig.searchOptions}
            placeholder={sensorsConfig.optionLabel}
            value={searchOption}
            onChange={handleChangeSearchOption}
          />
          <S.FilterButton onClick={handleClearFilter}>
            {sensorsConfig.clearSearchLabel}
          </S.FilterButton>
        </S.Filters>

        <Select
          values={sensorsConfig.orderOptions}
          placeholder={sensorsConfig.orderPlaceholder}
          value={orderOption}
          onChange={handleOnChangeOrderBy}
        />
      </S.FilterContainer>

      {filteredSensors.map((sensor) => (
        <SensorCard key={sensor.bleHdrMac} sensorData={sensor} />
      ))}

      {filteredSensors.length === 0 && <h3>{sensorsConfig.searchEmpty}</h3>}
      <Root />
    </S.Wrapper>
  )
}
