import { Btn } from "./styles"

type FormButtonProps = {
  text: string
  $width?: string
  $background?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const FormButton = ({
  text,
  $width = "400px",
  $background = "#3CD62F",
  ...rest
}: FormButtonProps): JSX.Element => {
  return (
    <Btn {...rest} $width={$width} $background={$background}>
      {text}
      {rest.children}
    </Btn>
  )
}

export default FormButton
