/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState, useRef, useEffect, ReactNode } from "react"
import { AnalyticConfigDTO } from "../entities/analyticConfigDTO"
import { ServiceDTO } from "../entities/serviceDTO"
import { useFilterSensorsWithData } from "../controllers/filterSensorsWithData"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { OrbitData, OrbitItem } from "../entities/Orbit"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { ToolsMenuItemDTO } from "../entities/toolsMenuItemDTO"
import { DataPlotDTO } from "../entities/dataSeriesDTO"
import { FftDTO } from "../entities/services/fftDTO"
import { AccrawDTO } from "../entities/services/accraw"
import { SensorWithServicesDTO } from "../../../utils/entities/sensors/SensorsWithServicesDTO"
import { SensorAvailablesServices } from "../../../store/features/reports/reports.interfaces"
import { SensorProv } from "../../../store/features/sensors/sensors.interfaces"
import { GeneralDataDTO } from "../entities/generalData"
import { closeModal } from "../../../store/features/modal/modal.slice"

interface HistoricalAnalysisProviderProps {
  children: ReactNode
}

export const HistoricalAnalysisContext =
  createContext<ContextAnaliseHistorica | null>(null)

export const ModalContextProvider: React.FC<
  HistoricalAnalysisProviderProps
> = ({ children }) => {
  const dispatch = useAppDispatch()

  const [axisFft, setAxisFft] = useState<string>("")
  const [axisFftSelected, setAxisFftSelected] = useState<number>(0)
  const [initialThresholdOperating, setInitialThresholdOperating] =
    useState<number>(0)
  const [initialThresholdOff, setInitialThresholdOff] = useState<number>(0)
  const [filteredSensors, setFilteredSensors] = useState<
    SensorAvailablesServices[]
  >([])
  const FILTER_SENSOR_OPTIONS = ["Nome", "MAC", "Serviço"]
  const [searchInput, setSearchInput] = useState("")
  const SERVICES = [
    "TEMP",
    "RMMS",
    "RMS2",
    "NTC",
    "_4T20",
    "POT",
    "ACCRAW",
    "FFT",
  ]
  const [filterSelected, setFilterSelected] = useState<string>("")
  const [filterServiceSelected, setFilterServiceSelected] = useState<string>("")

  const [openFilterServices, setOpenFilterServices] = useState<boolean>(false)
  const [selectorIndex, setSelectorIndex] = useState<number>(0)
  const [selectorMaxIndex, setSelectorMaxIndex] = useState<number>(0)
  const [selectorText, setSelectorText] = useState<string>("")
  const [openFilterSelection, setOpenFilterSelection] = useState<boolean>(false)

  const setOrbitOptions = () => {
    const initialOptions = [] as OrbitItem[]

    const axis = [
      ["X", "Y"],
      ["X", "Z"],
      ["Y", "Z"],
    ]

    axis.forEach((item) => {
      initialOptions.push({
        name: `Órbita ${item[0]} vs. ${item[1]}`,
        axis: item,
        checked: false,
      } as OrbitItem)
    })

    initialOptions[0].checked = true

    return initialOptions
  }
  const generalData = useRef<GeneralDataDTO>({
    lastDate: "",
    dataOff: 0,
    dataOn: 0,
    dataOperating: 0,
    dataNoSignal: 0,
  })
  const [selectedOrbitItems, setSelectedOrbitItems] =
    useState<OrbitItem[]>(setOrbitOptions())

  const [thresholdOperingHourMeter, setThresholdOperingHourMeter] =
    useState(2.15)
  const [thresholdOffHourMeter, setThresholdOffHourMeter] = useState(0.3)

  const [hmDetailedOrAccumuled, setHmDetailedOrAccumuled] =
    useState<string>("acumulado")
  const dataDateInFormatDDMM: string[] | null = []
  const [ataDateInFormatDDMM, setAtaDateInFormatDDMM] = useState<string[]>([])

  const [dataToPlot, setDataToPlot] = useState(
    [] as DataPlotDTO[] | FftDTO[] | AccrawDTO[] | OrbitData[]
  ) // arrumar esse nome, para dadosApiget ou algo do tipo

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

  const [chartTypeSelect, setChartTypeSelected] = useState<number>(1)

  const [fftAccOrVeloc, setFftAccOrVeloc] = useState<string>("acc")

  const [processedData, setProcessedData] = useState([])

  function cleanProcessData() {
    setProcessedData([])
  }

  const [graphicAccRawData, setGraphicAccRawData] = useState([])
  const [formGraphType, setFormGraphType] = useState(1)
  const [plotGraph, setPlotGraph] = useState(false)
  const [selectedServices, setSelectedServices] = useState([] as ServiceDTO[])
  const [selectedFFTAxis, setSelectedFFTAxis] = useState("VectorModule")
  const [averages, setAverages] = useState({})

  const [show3DFFTGraph, setShow3DFFTGraph] = useState(false)
  const [isLoadingGraph, setIsLoadingGraph] = useState(null)

  const [sensors, setSensors] = useState<SensorProv[]>({} as SensorProv[])

  const [availablesServices, setAvailablesServices] = useState(
    [] as SensorAvailablesServices[]
  )
  const [isLoadingProvisionedSensors, setIsLoadingProvisionedSensors] =
    useState(true)

  const { getAllAvailablesServices } = useFilterSensorsWithData()

  const sensorsFromStore = useAppSelector(
    (state) => state.persistedReducer.sensors.prov
  )

  const sensorsWithDataFromStore = useAppSelector(
    (state) => state.persistedReducer.reports.provisionedSensorsWithDataObject
  )
  const updateAvailablesServices = (
    sensorsWithData: SensorWithServicesDTO[]
  ) => {
    const data = getAllAvailablesServices(sensorsWithData)

    setAvailablesServices(data)
  }

  useEffect(() => {
    const sensors = sensorsFromStore
    const sensorsWithData = sensorsWithDataFromStore
    const sensorsLength = Object.keys(sensors).length
    const availablesServicesLength = availablesServices.length
    setIsLoadingProvisionedSensors(true)

    if (sensorsLength !== 0 && availablesServicesLength === 0) {
      setSensors(sensors)
      updateAvailablesServices(sensorsWithData)
    }

    setIsLoadingProvisionedSensors(false)
  }, [sensorsFromStore])

  const [openConfigModal, setOpenConfigModal] = useState(false)

  const [potMacWithChannel, setPOTMacWithChannel] = useState("")
  const potArrayConfig = useRef(null)

  const analyticFullConfig = useRef({} as AnalyticConfigDTO)
  function getActualStartDate() {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }

  const [startDate, setStartDate] = useState<Date>(getActualStartDate())
  const [endDate, setEndDate] = useState<Date>(new Date())

  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useState<ToolsMenuItemDTO[]>([])

  const changeChart3dOrLinear = () => {
    setShow3DFFTGraph(!show3DFFTGraph)
  }

  const onChangeFftAccOrVeloc = () => {
    if (fftAccOrVeloc === "acc") {
      setFftAccOrVeloc("veloc")
    } else {
      setFftAccOrVeloc("acc")
    }
  }

  const onChangeHmDetailedOrAccumulated = () => {
    if (hmDetailedOrAccumuled === "acumulado") {
      setHmDetailedOrAccumuled("detalhado")
    } else {
      setHmDetailedOrAccumuled("acumulado")
    }
  }

  const handleCloseHistoricalAnalysisConfig = () => {
    dispatch(closeModal())
    setSearchInput("")
    setFilteredSensors([])
    setFilterSelected("")
    setFilterServiceSelected("")
  }

  const defaultContext: ContextAnaliseHistorica = {
    selectorText,
    setSelectorText,
    setSelectorIndex,
    selectorIndex,
    setSelectorMaxIndex,
    selectorMaxIndex,
    axisFftSelected,
    setAxisFftSelected,
    axisFft,
    setAxisFft,
    chartTypeSelect,
    setChartTypeSelected,
    openConfigModal,
    plotGraph,
    setPlotGraph,
    setOpenConfigModal,
    analyticFullConfig,
    potMacWithChannel,
    setPOTMacWithChannel,
    potArrayConfig,
    sensors,
    setSensors,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    items,
    setItems,
    isOpen,
    setIsOpen,
    averages,
    setAverages,
    selectedServices,
    setSelectedServices,
    show3DFFTGraph,
    changeChart3dOrLinear,
    formGraphType,
    setFormGraphType,
    selectedFFTAxis,
    setSelectedFFTAxis,
    availablesServices,
    isLoadingGraph,
    setIsLoadingGraph,
    setAvailablesServices,
    graphicAccRawData,
    setGraphicAccRawData,
    processedData,
    setProcessedData,
    cleanProcessData,
    setDataToPlot,
    dataToPlot,
    onChangeFftAccOrVeloc,
    fftAccOrVeloc,
    isLoadingData,
    setIsLoadingData,
    thresholdOffHourMeter,
    setThresholdOffHourMeter,
    thresholdOperingHourMeter,
    setThresholdOperingHourMeter,
    onChangeHmDetailedOrAccumulated,
    hmDetailedOrAccumuled,
    ataDateInFormatDDMM,
    setAtaDateInFormatDDMM,
    dataDateInFormatDDMM,
    selectedOrbitItems,
    setSelectedOrbitItems,
    isLoadingProvisionedSensors,
    setIsLoadingProvisionedSensors,
    initialThresholdOperating,
    setInitialThresholdOperating,
    initialThresholdOff,
    setInitialThresholdOff,
    sensorsFromStore,
    openFilterSelection,
    filteredSensors,
    setOpenFilterSelection,
    setFilteredSensors,
    FILTER_SENSOR_OPTIONS,
    searchInput,
    setSearchInput,
    SERVICES,
    openFilterServices,
    setOpenFilterServices,
    filterSelected,
    setFilterSelected,
    filterServiceSelected,
    setFilterServiceSelected,
    generalData,
    handleCloseHistoricalAnalysisConfig,
  }

  return (
    <HistoricalAnalysisContext.Provider value={defaultContext}>
      {children}
    </HistoricalAnalysisContext.Provider>
  )
}
