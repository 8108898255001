import React from "react"
import styled from "styled-components"

const SRow = styled.div`
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  display: flex;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
`

type RowProps = {
  children: React.ReactNode
}

const Row: React.FC<RowProps> = ({ children, ...props }) => {
  return <SRow>{children}</SRow>
}

export default Row
