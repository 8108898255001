import { NewHeader } from "../../components/NewHeader"
import { DashboardProvider } from "./context/DashboardContext"
import { DashboardWrapper } from "./view"
import Container from "../../components/ui/Container"
import Row from "../../components/ui/Row"
import Column from "../../components/ui/Column"
import { DashDropdown } from "./components/DashDropdown"
import { DashTools } from "./components/DashTools"
import { DashMenu, DashboardHeader } from "./view/styles"

export const Dashboard = () => {
  const objPage = {
    page: {
      title: "Dashboard",
    },
  }
  return (
    <DashboardProvider>
      <Container>
        <Row>
          <DashboardHeader>
            <NewHeader page={objPage.page} />
            <DashMenu>
              <DashDropdown />
              <DashTools />
            </DashMenu>
          </DashboardHeader>
        </Row>
        <Row>
          <Column md='24' lg='24' xl='24'>
            <DashboardWrapper />
          </Column>
        </Row>
      </Container>
    </DashboardProvider>
  )
}
