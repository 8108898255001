import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 20px;

  margin-right: 10px;
  margin-left: 10px;
`

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const FilterButton = styled.button`
  border: 2px solid transparent;
  outline: none;
  border-radius: 5px;
  background-color: #2196f3;
  color: white;
  font-weight: bold;

  height: 42px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #1273c1;
  }

  &:focus {
    outline: none;
    border: 2px solid black;
  }
`

export const SearchInput = styled.input`
  height: 40px;

  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;

  padding-left: 10px;
`
