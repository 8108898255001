import * as S from "./styles"
import { useSensorsInfoController } from "../../controllers/useSensorsInfoController"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

export const InputSensorName = ({ bleHdrMac }: { bleHdrMac: string }) => {
  const { editSensorName, handleEditSensorName, onEditSaveBtnClick } =
    useSensorsInfoController(bleHdrMac)
  const { sensorsConfig, buttons } = useAppTranslate()

  return (
    <S.Container>
      <input
        type='text'
        value={editSensorName}
        placeholder={sensorsConfig.edit_sensor_name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleEditSensorName(e)
        }
      />
      <S.ButtonEdit onClick={(e) => onEditSaveBtnClick(e)}>
        {buttons.save}
      </S.ButtonEdit>
    </S.Container>
  )
}
