/* eslint-disable react/prop-types */
import { ReactNode, createContext, useRef, useState } from "react"
import { SensorsProvContextDTO } from "../entities/SensorsProvContextDTO"
import { MessageType1ProcessedDTO } from "../entities/MsgType1ProcessedDTO"
import { SensorDataToProvisionDTO } from "../entities/SensorDataToProvisionDTO"
import { ButtonStatus } from "../entities/ButtonsStatus"
import { useAppDispatch } from "../../../store/hooks"
import { closeModal } from "../../../store/features/modal/modal.slice"

interface SensorsProvProviderProps {
  children: ReactNode
}

export const SensorsProvContext = createContext<SensorsProvContextDTO | null>(
  null
)

export const SensorsProvProvider: React.FC<SensorsProvProviderProps> = ({
  children,
}) => {
  const [wsDataProcessed, setWSDataProcessed] = useState<
    MessageType1ProcessedDTO[]
  >([])
  const wsDataProcessedRef = useRef([] as MessageType1ProcessedDTO[])
  const sensorDataToProvision = useRef<SensorDataToProvisionDTO>()
  const disablePageRef = useRef<boolean>(false)
  const requestTimeRef = useRef<number | null>(null)
  const [messageError, setMessageError] = useState<string | null>(null)

  const [statusBtn, setStatusBtn] = useState<ButtonStatus>({
    Installed: {
      mac: [],
    },
    Installing: {
      mac: null,
      status: false,
    },
    Error: {
      mac: null,
    },
    AlreadyInstalled: {
      mac: [],
    },
  })

  const dispatch = useAppDispatch()

  const handleCloseModalConfirmation = () => {
    dispatch(closeModal())
    setMessageError(null)
  }

  const defaultContext: SensorsProvContextDTO = {
    wsDataProcessed,
    setWSDataProcessed,
    sensorDataToProvision,
    disablePageRef,
    requestTimeRef,
    statusBtn,
    setStatusBtn,
    wsDataProcessedRef,
    messageError,
    setMessageError,
    handleCloseModalConfirmation,
  }

  return (
    <SensorsProvContext.Provider value={defaultContext}>
      {children}
    </SensorsProvContext.Provider>
  )
}
