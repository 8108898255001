import { InfoLine } from "../styles"

const TextLine = ({
  title,
  description,
}: {
  title: string
  description: string | null
}): JSX.Element => {
  if (!description) return <></>

  return (
    <InfoLine>
      <p>{title + ":"}</p>
      <span>{description}</span>
    </InfoLine>
  )
}

export default TextLine
