import styled from "styled-components"

export const ConfirmBtn = styled.button<{ $cursor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : props.$cursor)};
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? "#ddd" : "#449e48")};
  color: white;
  font-weight: 500;
  font-size: medium;
  display: flex;
  margin: auto;
  margin-top: 20px;

  :hover {
    box-shadow: 5px 6px 16px 3px rgba(160, 160, 160, 0.25);
    background-color: ${(props) => !props.disabled && "#357a38"};
  }
`

export const TextAndIcon = styled.div`
  gap: 10px;
  display: flex;
`
