import { FC } from "react"
import { AiOutlinePlus } from "react-icons/ai"
import * as S from "./styles"
import theme from "../../../styles/theme"

interface FloatingButtonProps {
  onClick: () => void
}

export const FloatingButton: FC<FloatingButtonProps> = ({ onClick }) => {
  return (
    <S.FloatingButtonWrapper data-testid='button-add' onClick={onClick}>
      <AiOutlinePlus
        data-testid='button-add__icon'
        size={20}
        color={theme.colors.white}
      />
    </S.FloatingButtonWrapper>
  )
}
