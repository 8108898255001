import { SubmitHandler, useForm } from "react-hook-form"
import { CompanySchema, companySchema, defaultValues } from "../schemas/company"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useEffect, useRef, useState } from "react"
import { fetchProducts } from "../../../store/features/products/produtcs.api"
import { toast } from "react-toastify"
import { Company } from "../entities/company"
import { createCompany } from "../../../store/features/companies/companies.api"
import { createSale } from "../../../store/features/sales/sales.api"

export const useRegisterCompany = () => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const profile = useAppSelector((state) => state.persistedReducer.user.profile)

  const companies = useAppSelector((state) => state.persistedReducer.companies)
  const products = useAppSelector((state) => state.persistedReducer.products)

  const company = useRef<null | Company>(null)

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<CompanySchema>({
    resolver: zodResolver(companySchema),
    defaultValues,
  })

  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])

  useEffect(() => {
    for (let key in errors) {
      const error = errors[key as keyof CompanySchema]

      if (!error) continue
      if (!error.message) continue

      toast.error(error.message)

      break
    }
  }, [errors])

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onSubmit: SubmitHandler<CompanySchema> = (data) => {
    const product_index = data.product - 1

    if (product_index >= products.products.length) return

    const product = products.products[product_index]

    company.current = {
      product: {
        id: product.id,
        name: product.name,
        amount: data.amount,
      },
      name: data.name,
      cnpj: data.cnpj,
      occupation: data.occupation,
      state: data.state,
      cep: data.cep,
      country: data.country,
      adressState: data.adressState,
      city: data.city,
      neighborhood: data.neighborhood,
      street: data.street,
      number: data.number.toString(),
      complement: data.complement,
      description: data.description,
    }

    openModal()
  }

  const handleCreateCompany = async () => {
    if (!company.current) return

    const resultCompany = await dispatch(
      createCompany({
        avatarUrl: "-",
        address: {
          city: company.current.city,
          complement: company.current.complement,
          country: company.current.country,
          neighborhood: company.current.neighborhood,
          number: company.current.number,
          street: company.current.street,
          uf: company.current.adressState,
          zipCode: company.current.cep,
        },
        documentNumber: company.current.cnpj,
        name: company.current.name,
        occupationArea: company.current.occupation,
        stateRegistration: company.current.state,
      })
    ).unwrap()

    if (resultCompany.isLeft()) {
      toast.error(resultCompany.value.message)
      return
    }

    toast.success("Empresa cadastrada com sucesso!")

    if (!profile) {
      toast.error("Erro ao cadastrar venda!")
      console.error("Dados de usuário invalidos!")
      return
    }

    const resultSale = await dispatch(
      createSale({
        companyId: resultCompany.value.dataValues.id,
        description: company.current.description,
        productId: company.current.product.id,
        quantity: company.current.product.amount,
        userId: profile.user_data.y1,
      })
    ).unwrap()

    if (resultSale.isLeft()) {
      toast.error(resultSale.value.message)
      return
    }

    toast.success("Venda cadastrada com sucesso!")

    setTimeout(() => {
      closeModal()
      reset()
    }, 5000)
  }

  return {
    isOpen,
    loadingPage: products.loading,
    control,
    products: products.products,
    company: company.current,
    closeModal,
    handleSubmit: handleSubmit(onSubmit),
    handleCreateCompany,
    loadingRequest: companies.loading,
  }
}
