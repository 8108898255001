/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetDropdown } from "../Dropdown"
import { GetInput } from "../Input"
import { GetButton } from "../Button"

import {
  Container,
  SideContainer,
  FieldTitle,
  FieldAndInputContainer,
  CentralizeButton,
} from "./styles"

import { useAlarmConfigModalViewModel } from "../../controllers/useAlarmConfigModalController"
import Modal from "../Modal"
import { Tooltip } from "../Tooltip"

export const AlarmConfigModal = () => {
  const {
    DROPDOWN_NAMES,
    dropdownOpen,
    dropdownSelected,
    handleDropdownOpenClick,
    handleDropdownOptionSelect,
    prodSerialNumberToDropdown,
    THRESHOLD_OPTIONS,
    INPUT_NAMES,
    handleInputOnChange,
    inputSelected,
    TIME_BETWEEN_ALARMS,
    variableToBeMonitoredDropdown,
    openAlarmsConfigModal,
    handleCloseModal,
    onClickSaveBtn,
    loadingModal,
  } = useAlarmConfigModalViewModel()

  const getFieldAndInput = (
    title: any,
    input: any,
    index: any,
    { disabled = false, hasTooltip = false, tooltipMessage = "" }
  ) => {
    if (hasTooltip) {
      return (
        <Tooltip title={tooltipMessage} key={index}>
          <FieldAndInputContainer key={index}>
            <FieldTitle disabled={disabled}>{title}</FieldTitle>
            {input}
          </FieldAndInputContainer>
        </Tooltip>
      )
    } else {
      return (
        <FieldAndInputContainer key={index}>
          <FieldTitle disabled={disabled}>{title}</FieldTitle>
          {input}
        </FieldAndInputContainer>
      )
    }
  }

  const leftComponents = [
    {
      isDropdown: true,
      title: "Selecione um sensor",
      fontWeight: "unset",
      name: DROPDOWN_NAMES[0],
      openDropdown: dropdownOpen[DROPDOWN_NAMES[0]],
      optionSelected: (dropdownSelected as any)[DROPDOWN_NAMES[0]],
      handleDropdownOpenClick: handleDropdownOpenClick,
      handleDropdownOptionSelect: handleDropdownOptionSelect,
      dropdownOptions: prodSerialNumberToDropdown,
    },
    {
      isDropdown: true,
      fontWeight: "unset",
      title: "Selecione a condição",
      name: DROPDOWN_NAMES[1],
      openDropdown: dropdownOpen[DROPDOWN_NAMES[1]],
      optionSelected: (dropdownSelected as any)[DROPDOWN_NAMES[1]],
      handleDropdownOpenClick: handleDropdownOpenClick,
      handleDropdownOptionSelect: handleDropdownOptionSelect,
      dropdownOptions: THRESHOLD_OPTIONS,
    },
    {
      isDropdown: false,
      inputType: "number",
      disabled:
        (dropdownSelected as any)[DROPDOWN_NAMES[1]] !== THRESHOLD_OPTIONS[4],
      title: "Primeiro threshold",
      name: INPUT_NAMES[0],
      onChangeFn: handleInputOnChange,
      value: (inputSelected as any)[INPUT_NAMES[0]],
    },
    {
      isDropdown: true,
      fontWeight: "unset",
      title: "Tempo entre alarmes",
      name: DROPDOWN_NAMES[2],
      openDropdown: dropdownOpen[DROPDOWN_NAMES[2]],
      optionSelected: (dropdownSelected as any)[DROPDOWN_NAMES[2]],
      handleDropdownOpenClick: handleDropdownOpenClick,
      handleDropdownOptionSelect: handleDropdownOptionSelect,
      dropdownOptions: TIME_BETWEEN_ALARMS,
    },
  ]

  const rightComponents = [
    {
      isDropdown: true,
      fontWeight: "unset",
      title: "Variável a ser monitorada",
      name: DROPDOWN_NAMES[3],
      openDropdown: dropdownOpen[DROPDOWN_NAMES[3]],
      optionSelected: (dropdownSelected as any)[DROPDOWN_NAMES[3]],
      handleDropdownOpenClick: handleDropdownOpenClick,
      handleDropdownOptionSelect: handleDropdownOptionSelect,
      dropdownOptions: variableToBeMonitoredDropdown,
    },
    {
      isDropdown: false,
      inputType: "number",
      title: "Threshold",
      disabled:
        (dropdownSelected as any)[DROPDOWN_NAMES[1]] === THRESHOLD_OPTIONS[4],
      name: INPUT_NAMES[1],
      onChangeFn: handleInputOnChange,
      value: (inputSelected as any)[INPUT_NAMES[1]],
    },
    {
      isDropdown: false,
      inputType: "number",
      disabled:
        (dropdownSelected as any)[DROPDOWN_NAMES[1]] !== THRESHOLD_OPTIONS[4],
      title: "Segundo threshold",
      name: INPUT_NAMES[2],
      onChangeFn: handleInputOnChange,
      value: (inputSelected as any)[INPUT_NAMES[2]],
    },
    {
      isDropdown: false,
      hasTooltip: true,
      tooltipMessage:
        "Para colocar mais de um email, separe-os por ponto e vírgula.",
      inputType: "email",
      title: "Email para notificação",
      name: INPUT_NAMES[3],
      onChangeFn: handleInputOnChange,
      value: (inputSelected as any)[INPUT_NAMES[3]],
    },
  ]

  const getSideContainer = (isLeft = false) => {
    return (
      <SideContainer>
        {isLeft
          ? leftComponents.map((opts, index) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const { isDropdown, title, ...configs }: any = opts

              const optionals = {
                disabled: configs.disabled,
                hasTooltip: configs.hasTooltip,
                tooltipMessage: configs.tooltipMessage,
              }

              if (isDropdown) {
                return getFieldAndInput(
                  title,
                  GetDropdown({ ...configs, isSensor: true }),
                  index,
                  optionals
                )
              } else {
                return getFieldAndInput(
                  title,
                  GetInput({ width: "98%", ...configs }),
                  index,
                  optionals
                )
              }
            })
          : rightComponents.map((opts, index) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const { isDropdown, title, ...configs }: any = opts

              const optionals = {
                disabled: configs.disabled,
                hasTooltip: configs.hasTooltip,
                tooltipMessage: configs.tooltipMessage,
              }

              if (isDropdown) {
                return getFieldAndInput(
                  title,
                  GetDropdown(configs),
                  index,
                  optionals
                )
              } else {
                return getFieldAndInput(
                  title,
                  GetInput({ width: "98%", ...configs }),
                  index,
                  optionals
                )
              }
            })}
      </SideContainer>
    )
  }

  return (
    <Modal
      openModal={openAlarmsConfigModal}
      handleModalClose={handleCloseModal}
      title='Configuração De Novo Alarme'
    >
      <Container>
        <SideContainer>{getSideContainer(true)}</SideContainer>
        <SideContainer>{getSideContainer()}</SideContainer>
      </Container>
      <CentralizeButton>
        {GetButton({
          text: "Salvar",
          onClickFn: onClickSaveBtn,
          loading: loadingModal,
        })}
      </CentralizeButton>
    </Modal>
  )
}
