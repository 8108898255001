import styled from "styled-components"

export const ModalHeader = styled.div`
  justify-content: center;
  padding: 10px;
  background-color: #4d4d4d;
  display: flex;
`

export const ModalBody = styled.div`
  margin-bottom: 40px;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`
