import { TextLine } from "./styles"

interface TextProps {
  field: string
  value: string
}

export const Text = ({ field, value }: TextProps): JSX.Element => {
  return (
    <TextLine>
      <p>{`${field}: `}</p>
      <span>{value}</span>
    </TextLine>
  )
}
