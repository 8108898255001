import styled from "styled-components"

export const Container = styled.div`
  //height: 100%;
  //width: 100%;
  //  background-color: red;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`

export const Chart = styled.div`
  //padding: 50px;
  //box-shadow: 0px 6px 16px 3px rgba(0, 0, 0, 0.25);
  //border: 1px solid black;
`
