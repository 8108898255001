import { useEffect, useState } from "react"

interface DeleteButtonControllerProps {
  disableTimeInSeconds: number
}

export const useDeleButtonController = ({
  disableTimeInSeconds,
}: DeleteButtonControllerProps) => {
  const [isDisabled, setIsDisabled] = useState(disableTimeInSeconds > 0)
  const [counter, setCounter] = useState(disableTimeInSeconds || 0)

  useEffect(() => {
    if (disableTimeInSeconds <= 0) return

    setIsDisabled(true)
    setCounter(disableTimeInSeconds)

    const startTime = Date.now()

    const interval = setInterval(() => {
      const currentTime = Date.now()
      const elapsedTime = Math.floor((currentTime - startTime) / 1000)
      const remainingTime = disableTimeInSeconds - elapsedTime

      if (remainingTime <= 0) {
        clearInterval(interval)
        setIsDisabled(false)
      } else {
        setCounter(remainingTime)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [disableTimeInSeconds])

  return { isDisabled, counter }
}
