import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiCall } from "../../../utils/axios"
import {
  Analytics,
  CreateOrEditDashboard,
  Dashboard,
} from "./dashboard.interfaces"

const ApiRoutes = {
  GetAnalytics: "v1/proteu/analytics",
  PostAnalytics: "v1/proteu/analytics",
  DeleteAnalytics: "v1/proteu/analytics/",
  GetDashboards: "v1/proteu/dashboard",
  PostDashboards: "v1/proteu/dashboard",
  DeleteDashboards: "v1/proteu/dashboard/",
  EditDashboards: "v1/proteu/dashboard/",
}

export const fetchDashboards = createAsyncThunk(
  "dashboard/fetchDashboards",
  async (): Promise<Dashboard[]> => {
    const api = apiCall()
    const response = await api.get(ApiRoutes.GetDashboards)
    formatTheSensorsToObject(response.data)
    return response.data
  }
)

export const postAnalytics = createAsyncThunk(
  "dashboard/postAnalytics",
  async (analytic: Analytics): Promise<Analytics> => {
    const api = apiCall()
    const response = await api.post(ApiRoutes.PostAnalytics, analytic)
    return response.data
  }
)

export const postDashboard = createAsyncThunk(
  "dashboard/postDashboards",
  async (dashboard: CreateOrEditDashboard): Promise<Dashboard> => {
    const api = apiCall()

    const response = await api.post(ApiRoutes.PostDashboards, dashboard)

    return response.data
  }
)

export const deleteDashboards = createAsyncThunk(
  "analytics/deleteDashboards",
  async ({
    dashboardId,
  }: {
    dashboardId: number
  }): Promise<{
    dashboardId: number
  }> => {
    const api = apiCall()

    await api.delete(`${ApiRoutes.DeleteDashboards}${dashboardId}`)

    return {
      dashboardId: dashboardId,
    }
  }
)

export const editDashboards = createAsyncThunk(
  "dashboard/editDashboards",
  async ({
    dashboardId,
    dashboardEdit,
  }: {
    dashboardId: number
    dashboardEdit: CreateOrEditDashboard
  }): Promise<Dashboard> => {
    const api = apiCall()
    const response = await api.put(
      `${ApiRoutes.EditDashboards}${dashboardId}`,
      dashboardEdit
    )
    return response.data
  }
)

export const deleteAnalytics = createAsyncThunk(
  "dashboard/deleteAnalytics",
  async ({
    analyticId,
    dashboardId,
  }: {
    analyticId: number
    dashboardId: number
  }): Promise<{
    analyticId: number
    dashboardId: number
  }> => {
    const api = apiCall()

    await api.delete(`${ApiRoutes.DeleteAnalytics}${analyticId}`)
    return {
      analyticId: analyticId,
      dashboardId: dashboardId,
    }
  }
)

/**
 * Function to format the sensors from string to object
 * @param dashboards
 */

const formatTheSensorsToObject = (dashboards: Dashboard[]): void => {
  dashboards.forEach((dashboard) => {
    dashboard.analytics.forEach((analytic) => {
      if (typeof analytic.sensors === "string")
        analytic.sensors = JSON.parse(analytic.sensors)
    })
  })
}
