import { createSlice } from "@reduxjs/toolkit"
import { ResponseCompanyApi } from "./companies.interface"
import { createCompany, fetchCompanies } from "./companies.api"

interface InitialState {
  loading: boolean
  error: string | null
  companies: ResponseCompanyApi[]
}

const initialState: InitialState = {
  loading: false,
  error: null,
  companies: [],
}

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchCompanies.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? ""
    })
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.loading = false
      state.companies = action.payload
    })
    builder.addCase(createCompany.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createCompany.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? ""
    })
    builder.addCase(createCompany.fulfilled, (state) => {
      state.loading = false
    })
  },
})

export default companiesSlice.reducer
