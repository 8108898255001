import { useContext, useState } from "react"
import { toast } from "react-toastify"
import { UserFormDTO } from "../entities/userForm"
import axios, { AxiosResponse } from "axios"
import { UsersApiDTO } from "../entities/usersApiDTO"
import { RegisterUsersContext } from "../context/registerUserContext"
import { CompanyApiDTO } from "../services/callApiToGetAllCompanies"
import { Result, left, right } from "../../../utils/either/result"
import { apiCall } from "../../../utils/axios"

interface ContextRegisterUsers {
  users: UsersApiDTO[]
  companies: CompanyApiDTO[]
  openModalDeleteUser: boolean
  setOpenModalDeleteUser: React.Dispatch<React.SetStateAction<boolean>>
  userToDelete: UsersApiDTO | null
  setUserToDelete: React.Dispatch<React.SetStateAction<UsersApiDTO | null>>
  openModalEditPermissionUser: boolean
  setOpenModalEditPermissionUser: React.Dispatch<React.SetStateAction<boolean>>
  userToEditPermission: UsersApiDTO | null
  setUserToEditPermission: React.Dispatch<
    React.SetStateAction<UsersApiDTO | null>
  >
  filteredUsers: UsersApiDTO[]
  setFilteredUsers: React.Dispatch<React.SetStateAction<UsersApiDTO[]>>
  filter: string
  setFilter: React.Dispatch<React.SetStateAction<string>>
  getAllUsers: () => Promise<void>
}

const EmptyForm: UserFormDTO = {
  company: null,
  name: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  position: "",
  permissionLevel: "user",
}

const useManagerUserController = () => {
  const {
    users,
    companies,
    openModalDeleteUser,
    setOpenModalDeleteUser,
    userToDelete,
    setUserToDelete,
    openModalEditPermissionUser,
    setOpenModalEditPermissionUser,
    userToEditPermission,
    setUserToEditPermission,
    setFilteredUsers,
    filteredUsers,
    setFilter,
    filter,
    getAllUsers,
  } = useContext(RegisterUsersContext) as ContextRegisterUsers

  const [inputValue, setInputValue] = useState<string>("")

  const [data, setData] = useState<UserFormDTO>(EmptyForm)
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [isRegisterCompleted, setIsRegisterCompleted] = useState(false)
  const [createOrEdit, setCreateOrEdit] = useState<"cadastrar" | "editar">(
    "cadastrar"
  )
  const [permission, setPermissions] = useState<"admin" | "user" | "viewer">(
    "user"
  )
  const [loadingEditPermission, setLoadingEditPermission] =
    useState<boolean>(false)

  const informativeTextAboutPermissions = `
    Existem três níveis de permissão: \n
    - Usuário: Tem acesso as páginas de Dashboard, Coletores, Sensores (Provisionamento e Configuração), Alarmes (Logs e Configuração), Reports (Análise Histórica e Baixar Relatórios)\n
    - Visualizador: Tem acesso as páginas de Dashboard, Alarmes (Logs), Reports (Análise Histórica e Baixar Relatórios)\n
    - Administrador: Tem acesso as mesmas páginas que o usuário tem, incluindo as páginas de Cadastro (Usuário, Empresa, Vendas).
  `

  const clearForm = (): void => {
    setData(EmptyForm)
  }

  const handleOpenForm = (): void => {
    setOpenForm(true)
  }

  const handleOpenModalConfirmDeleteUser = (user: UsersApiDTO): void => {
    setUserToDelete(user)
    setOpenModalDeleteUser(true)
  }

  const handleCloseModalConfirmDeleteUser = (): void => {
    setOpenModalDeleteUser(false)
  }

  const handleOpenModalEditPermissionUser = (user: UsersApiDTO): void => {
    setUserToEditPermission(user)
    setOpenModalEditPermissionUser(true)
  }

  const handleCloseModalEditPermissionUser = (): void => {
    setPermissions("user")
    setOpenModalEditPermissionUser(false)
    setLoadingEditPermission(false)
  }

  const handleBackForm = (): void => {
    setOpenForm(false)
    setCreateOrEdit("cadastrar")
    setData(EmptyForm)
  }

  const changeData = (key: string, value: string): void => {
    setData((state) => {
      return {
        ...state,
        [key]: value,
      }
    })
  }

  const handleUpdateInput = (value: string) => {
    setInputValue(value)
    searchUserByFilter(value, filter)
  }

  const searchUserByFilter = (query: string, filter: string) => {
    if (!query) {
      setFilteredUsers(users)
      return
    }

    const filtered = users.filter((user) => {
      switch (filter) {
        case "name": {
          const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
          return fullName.includes(query.toLowerCase())
        }
        case "company":
          return (
            getCompanyNameById(user.companyId)
              .toLowerCase()
              .includes(query.toLowerCase()) && user
          )
        case "email":
          return user.email.toLowerCase().includes(query.toLowerCase())
        default:
          return false
      }
    })
    setFilteredUsers(filtered)
  }

  const getProfile = (
    permission: "admin" | "user" | "viewer" | null
  ): string => {
    if (!permission) return "-"

    switch (permission) {
      case "admin":
        return "Administrador"
      case "user":
        return "Usuário"
      case "viewer":
        return "Visualizador"
      default:
        return "Usuário"
    }
  }

  const changeSearchFilter = (value: string): void => {
    setFilter(value)
    searchUserByFilter(inputValue, value)
  }

  const editFormWithUserDataToBeEdited = (user: UsersApiDTO): void => {
    const userToBeEdited: UserFormDTO = {
      company: companies.find(
        (company) => company.id === user.companyId
      ) as CompanyApiDTO,
      name: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      position: user.position,
      password: "",
      confirmPassword: "",
      permissionLevel: user.permissionLevel,
    }

    setData(userToBeEdited)
  }

  const handleOpenFormEditUser = (user: UsersApiDTO) => {
    editFormWithUserDataToBeEdited(user)
    setCreateOrEdit("editar")
    handleOpenForm()
  }

  const getCompanyNameById = (id?: number): string => {
    if (id === undefined) return "Empresa não encontrada!"
    const company = companies.find((company) => company.id === id)

    if (company === undefined) return "Empresa não encontrada!"

    return company.name
  }

  const changeCompany = (selectedIndex: number): void => {
    setData((state) => {
      return {
        ...state,
        company: companies[selectedIndex],
      }
    })
  }

  const changePermissionLevel = (
    selectPermission: "user" | "viewer" | "admin"
  ): void => {
    setData((state) => {
      return {
        ...state,
        permissionLevel: selectPermission,
      }
    })
  }

  const handleNewRegister = (): void => {
    setIsRegisterCompleted(false)
  }

  const validateForm = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    createOrEdit: "cadastrar" | "editar"
  ): boolean => {
    const requiredFields: Record<string, string[]> = {
      cadastrar: ["company", "email", "password", "confirmPassword"],
      editar: ["name", "lastName", "phone", "position"],
    }

    const requiredFieldsMessages: Record<string, string> = {
      company: "Erro ao selecionar empresa!",
      email: "Campo obrigatório: Email!",
      password: "Mínimo de caracteres não atingido: Senha!",
      confirmPassword: "As senhas não coincidem!",
      name: "Campo obrigatório: Primeiro Nome!",
      lastName: "Campo obrigatório: Último Nome!",
      phone: "Campo obrigatório: Telefone!",
      position: "Campo obrigatório: Cargo!",
    }

    const keysToCheck = requiredFields[createOrEdit]

    for (const key of keysToCheck) {
      const value = data[key]

      if (!value || value === "") {
        toast.error(requiredFieldsMessages[key])
        return false
      }

      if (key === "password" && value.length < 8) {
        toast.error(requiredFieldsMessages[key])
        return false
      }
    }

    if (
      createOrEdit === "cadastrar" &&
      data["password"] !== data["confirmPassword"]
    ) {
      toast.error(requiredFieldsMessages["confirmPassword"])
      return false
    }

    return true
  }

  const callApiEditUserPermission = async (
    permission: "admin" | "user" | "viewer"
  ): Promise<Result<Error, AxiosResponse>> => {
    const URL = `${process.env.REACT_APP_API_GET_ALL_USERS}`
    if (URL === undefined) return left(Error("Erro editar permissão!"))

    const api = apiCall()

    try {
      const result = await api.put(
        `${URL}/${userToEditPermission?.id}/permission`,
        {
          permissionLevel: permission,
        }
      )
      setLoadingEditPermission(false)
      getAllUsers()
      return right(result)
    } catch (error) {
      setLoadingEditPermission(false)
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400)
          return left(Error("Erro ao editar permissão!"))

        if (error.response?.status === 401)
          return left(Error("Você não tem permissão para completar essa ação!"))

        if (error.response?.status === 404)
          return left(Error("O recurso não foi encontrado!"))

        return left(Error("Erro ao editar permissão!"))
      }
      return left(Error("Erro ao editar permissão"))
    }
  }

  const callApiRegisterUser = async (
    data: UserFormDTO
  ): Promise<Result<Error, AxiosResponse>> => {
    const URL = `${process.env.REACT_APP_API_USERS}`

    if (URL === undefined)
      return left(Error("Erro ao buscar rota de cadastro!"))

    const api = apiCall()

    try {
      const result = await api.post(URL, {
        companyId: data.company?.id,
        companyGuid: data.company?.guid,
        firstName: data.name,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        registerNumber: "-",
        position: data.position,
        phone: data.phone,
        permissionLevel: data.permissionLevel,
      })
      getAllUsers()
      return right(result)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400)
          return left(Error("Erro ao cadastrar usuário!"))

        if (error.response?.status === 401)
          return left(Error("Você não tem permissão para completar essa ação!"))

        if (error.response?.status === 404)
          return left(Error("O recurso não foi encontrado!"))

        if (error.response?.status === 409)
          return left(Error("Usuário já existente!"))

        return left(Error("Erro ao cadastrar usuário!"))
      }

      return left(Error("Erro ao buscar produtos!"))
    }
  }

  const callApiUpdateUser = async ({
    firstName,
    lastName,
    position,
    userId,
    phone,
  }: {
    firstName: string
    lastName: string
    position: string
    userId: number
    phone: string
  }): Promise<Result<Error, AxiosResponse>> => {
    const URL = `${process.env.REACT_APP_API_GET_ALL_USERS}/${userId}`
    if (URL === undefined)
      return left(Error("Erro ao buscar rota de editar usuário!"))

    const api = apiCall()

    try {
      const result = await api.put(URL, {
        firstName: firstName,
        lastName: lastName,
        position: position,
        phone: phone,
      })
      getAllUsers()
      return right(result)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400)
          return left(Error("Erro ao editar usuário!"))

        if (error.response?.status === 401)
          return left(Error("Você não tem permissão para completar essa ação!"))

        if (error.response?.status === 404)
          return left(Error("O recurso não foi encontrado!"))

        return left(Error("Erro ao editar usuário!"))
      }
      return left(Error("Erro ao buscar usuários!"))
    }
  }

  const registerAndUpdateUser = async (
    data: UserFormDTO,
    createOrEdit: "cadastrar" | "editar"
  ): Promise<void> => {
    try {
      let result
      if (createOrEdit === "cadastrar") {
        if (!validateForm(data, createOrEdit)) return

        result = await callApiRegisterUser(data)
        toast.success("Usuário cadastrado com sucesso!")
      } else if (createOrEdit === "editar") {
        if (!validateForm(data, createOrEdit)) return
        const userToUpdate = users.find((user) => user.email === data.email)

        if (!userToUpdate) {
          toast.error("Usuário não encontrado para edição.")
          return
        }

        result = await callApiUpdateUser({
          firstName: data.name,
          lastName: data.lastName,
          position: data.position,
          userId: users.find((user) => user.email === data.email)?.id as number,
          phone: data.phone,
        })
        toast.success("Usuário editado com sucesso!")
      }
      if (result && result.isLeft()) {
        toast.error(result.value.message)
        return
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao processar a operação.")
    }
  }

  const editUserPermission = async (
    permission: "admin" | "user" | "viewer"
  ): Promise<void> => {
    if (permission) {
      setLoadingEditPermission(true)
      const result = await callApiEditUserPermission(permission)

      if (result.isLeft()) {
        toast.error(result.value.message)
        setLoadingEditPermission(false)
        return
      }

      toast.success("Permissão editada com sucesso!")
      handleCloseModalEditPermissionUser()
    }
  }

  return {
    registerAndUpdateUser,
    isRegisterCompleted,
    handleNewRegister,
    data,
    changePermissionLevel,
    changeCompany,
    changeData,
    clearForm,
    companies,
    users,
    handleOpenForm,
    handleBackForm,
    openForm,
    getCompanyNameById,
    handleOpenFormEditUser,
    handleOpenModalConfirmDeleteUser,
    handleCloseModalConfirmDeleteUser,
    createOrEdit,
    openModalDeleteUser,
    userToDelete,
    openModalEditPermissionUser,
    handleCloseModalEditPermissionUser,
    handleOpenModalEditPermissionUser,
    userToEditPermission,
    informativeTextAboutPermissions,
    changeSearchFilter,
    handleUpdateInput,
    filteredUsers,
    editUserPermission,
    permission,
    setPermissions,
    loadingEditPermission,
    setLoadingEditPermission,
    getProfile,
    inputValue,
  }
}

export default useManagerUserController
