import Container from "../../components/ui/Container"
import GatewaysList from "./views/GatewaysList"
import styled from "styled-components"
import { NewHeader } from "../../components/NewHeader"
import Row from "../../components/ui/Row"
import Column from "../../components/ui/Column"
import { useAppTranslate } from "../../translate/useAppTranslate"

const Card = styled.div`
  height: auto;
  border: solid 1px ${(props) => props.theme.palette.gray._400};
  border-radius: 4px 4px 4px 4px;
  background-color: ${(props) => props.theme.palette.white};
  padding: 20px 16px;
`

export const Gateways = () => {
  const { gateways } = useAppTranslate()
  const objPage = {
    page: {
      title: `${gateways.pageName}`,
    },
  }
  return (
    <Container>
      <Row>
        <NewHeader page={objPage.page} />
      </Row>
      <Row>
        <Column md='24' lg='24' xl='24'>
          <Card>
            <GatewaysList />
          </Card>
        </Column>
      </Row>
    </Container>
  )
}
