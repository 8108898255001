import styled from "styled-components"

export const Container = styled.div<{ $width: string }>`
  position: relative;
  font-family: Arial;

  width: 400px;
  height: 56px;
  border: 1px solid #757575;
  border-radius: 4px;

  margin-top: 6px;
  margin-bottom: 2px;

  select {
    margin-left: 10px;
    margin-right: 10px;

    font-size: 16px;

    width: 380px;
    height: 56px;

    outline: none;
    border: none;
    background-color: transparent;
  }
`
