import { CheckboxState } from "../../../components/Modal/components/SensorAndServices/entities/CheckboxServiceStateDTO"
import { SensorAndService } from "../../../components/Modal/components/SensorAndServices/entities/SensorAndService"
import { Analytics } from "../../../store/features/dashboard/dashboard.interfaces"

export enum AnalyticPeriod {
  NO_SELECTED = 0,
  LAST_MINUTE = 60,
  LAST_10_MINUTES = 600,
  LAST_30_MINUTES = 1800,
  LAST_HOUR = 3600,
  LAST_5_HOURS = 18000,
  LAST_12_HOURS = 43200,
  LAST_DAY = 86400,
  LAST_WEEK = 604800,
}

export interface DashboardContextDTO {
  /**Checkbox initial state */
  getCheckboxStateInitialState: () => CheckboxState
  /**A list with the selected sensors to create a new chart */
  sensorAndServices: React.MutableRefObject<SensorAndService[]>
  /**A object that represent a new analytic that should be created */
  analyticToPlot: React.MutableRefObject<Analytics | null>
  /**Variable to control the type of analytic selected
   * 1: amplitude/time [sec]
   * 2: amplitude / frequency
   * 3: amplitude / time [msec]
   * null: no selected
   */
  selectedTypeChart: 1 | 2 | 3 | null
  /**Edit the selected analytic   */
  setSelectedTypeChart: React.Dispatch<React.SetStateAction<1 | 2 | 3 | null>>
  /**Variable to control the period of time selected*/
  selectedPeriod: AnalyticPeriod
  /**Edit the period of time selected*/
  setSelectedPeriod: React.Dispatch<React.SetStateAction<AnalyticPeriod>>
  /**Variable to control the name of the analytic selected */
  graphicName: string
  /**Edit the name of the analytic selected */
  setGraphicName: React.Dispatch<React.SetStateAction<string>>
  /** Variable that saves the analytic to be deleted
   * Saves the id of the analytic in which the delete modal was opened
   */
  analyticIdToDelete: React.MutableRefObject<number | null>
  /**Variable that saves the dashboard to be deleted */
  dashboardIdToDelete: React.MutableRefObject<number | null>
  /**edit the analytic guidance */
  setOrientedBy: React.Dispatch<React.SetStateAction<"sensor" | "entity">>
  /**Variable that controls the analytic orientation */
  orientedBy: "sensor" | "entity"
  /**Default behavior to close a modal in Dashoboard Page */
  handleCloseDashboardModal: () => void
  /** Controls the states of the service checkboxes*/
  checkboxState: CheckboxState
  /**Edit the control variable for the states of the service checkboxes */
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>
}
