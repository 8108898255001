import { FC } from "react"
import * as S from "./styles"
import { Button } from "../../components/Buttons"
import { useErrorController } from "./controller/useErrorController"
import { useAppTranslate } from "../../translate/useAppTranslate"

export const ErrorPage: FC = () => {
  const { handleTryAgain } = useErrorController()

  const { error } = useAppTranslate()

  return (
    <S.Container>
      <p>{error.message}</p>
      <Button label={error.button} onClick={handleTryAgain} />
    </S.Container>
  )
}
