import styled from "styled-components"

export const Container = styled.div`
  padding-bottom: 40px;
`

export const ContainerBtn = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;

  /* Adicionando media query para responsividade */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start; /* Alinhar itens à esquerda */
  }
`
