import { useCallback, useEffect, useState } from "react"
import {
  postDashboard,
  editDashboards,
} from "../../../../../store/features/dashboard/dashboard.api"
import { closeModal } from "../../../../../store/features/modal/modal.slice"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { resetDashboardError } from "../../../../../store/features/dashboard/dashboard.slice"

export const useDashboardConfigController = (
  type: "DashboardCreate" | "DashboardEdit"
) => {
  const dispatch = useAppDispatch()

  const dashboard = useAppSelector((state) => state.persistedReducer.dashboard)

  const isLoading = dashboard.status === "loading"
  const hasError = dashboard.status === "failed"
  const [errTitleDash, setErrTitleDash] = useState(false)

  const getDashbordName = useCallback((): string => {
    if (!dashboard.openDashboard || type === "DashboardCreate") return ""
    return dashboard.openDashboard.name
  }, [dashboard.openDashboard, type])

  const getDashbordLayout = useCallback((): number => {
    if (!dashboard.openDashboard || type === "DashboardCreate") return 1
    return dashboard.openDashboard.layout
  }, [dashboard.openDashboard, type])

  useEffect(() => {
    if (!dashboard.openDashboard) return
    setTitleInput(getDashbordName())
    setSelectedTypeLayout(getDashbordLayout())
  }, [getDashbordLayout, getDashbordName, dashboard.openDashboard])

  const [titleInput, setTitleInput] = useState<string>(getDashbordName())

  const [selectedTypeLayout, setSelectedTypeLayout] =
    useState<number>(getDashbordLayout())

  const handleSelectTypeLayout = (index: number): void => {
    setSelectedTypeLayout(index)
  }

  const handleUpdateTitle = (title: string) => {
    setTitleInput(title)
  }

  useEffect(() => {
    if (titleInput) {
      return titleInput.length > 20
        ? setErrTitleDash(true)
        : setErrTitleDash(false)
    }
  }, [titleInput])

  const handleDisabledButton = (): boolean => {
    return titleInput?.trim() === "" || titleInput.length > 20
  }

  const handleSubmit = (type: "DashboardCreate" | "DashboardEdit") => {
    switch (type) {
      case "DashboardCreate":
        createDashboard(titleInput, selectedTypeLayout)
        break

      case "DashboardEdit":
        if (!dashboard.openDashboard) return
        editDashboard(
          titleInput,
          selectedTypeLayout,
          dashboard.openDashboard.id
        )
        break
    }
  }

  const handleCloseModal = () => {
    dispatch(resetDashboardError())
    dispatch(closeModal())
  }

  const createDashboard = (title: string, selectedAnalyticGrid: number) => {
    dispatch(
      postDashboard({
        name: title,
        layout: selectedAnalyticGrid,
      })
    ).then((e) => {
      if (e.meta.requestStatus === "fulfilled") dispatch(closeModal())
    })
  }

  const editDashboard = (
    title: string,
    selectedAnalyticGrid: number,
    dashboardId: number
  ) => {
    dispatch(
      editDashboards({
        dashboardId: dashboardId,
        dashboardEdit: {
          name: title,
          layout: selectedAnalyticGrid,
        },
      })
    ).then((e) => {
      if (e.meta.requestStatus === "fulfilled") dispatch(closeModal())
    })
  }

  return {
    isLoading,
    hasError,
    openDashboardTitle: getDashbordName(),
    openDashboardLayout: getDashbordLayout(),
    titleInput,
    selectedTypeLayout,
    handleSelectTypeLayout,
    handleCloseModal,
    handleUpdateTitle,
    handleSubmit,
    handleDisabledButton,
    errTitleDash,
  }
}
