import styled from "styled-components"

export const Container = styled.div`
  margin-top: 10px;
`

export const OptionsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
  }
  margin-top: 10px;
`

export const Option = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  padding: 12px;
  border-radius: 8px;
  box-shadow: ${(props) => props.selected && "0 0 8px rgba(0, 0, 0, 0.2)"};
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.theme.colors.blue}`
      : `0.5px solid ${props.theme.colors.grey}`};
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1300px) {
    margin-top: 20px;
    min-width: 160px;
    justify-content: center;
  }
`

export const OptionAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Text = styled.span`
  margin-top: 10px;
  font-size: 16px;
`

export const InputOption = styled.input`
  display: none;
`
