import { ApiDataDTO } from "../reports/reports.interfaces"
import { apiCall } from "../../../utils/axios"

const formatedDateToString = (date: Date | string): string => {
  return typeof date === "string"
    ? date
    : date.toISOString() || new Date().toISOString()
}

export const getSensorDataFromApi = async (
  startDate: Date | null,
  endDate: Date | null,
  macs: string[],
  service: string
): Promise<ApiDataDTO[]> => {
  const CONVERT_SENSOR_SERVICE_TO_API_CALL = {
    TEMP: `${process.env.REACT_APP_API_TEMP}`,
    HEALTH: `${process.env.REACT_APP_API_HEALTH}`,
    TEMPMMM: `${process.env.REACT_APP_API_TEMP_MMM}`,
    RMS2: `${process.env.REACT_APP_API_RMS2}`,
    RMMS: `${process.env.REACT_APP_API_RMMS}`,
    TILT: `${process.env.REACT_APP_API_TILT}`,
    FFT: `${process.env.REACT_APP_API_FFT}`,
    ACCRAW: `${process.env.REACT_APP_API_ACC_RAW}`,
    _4T20: `${process.env.REACT_APP_API_4T20}`,
    NTC: `${process.env.REACT_APP_API_NTC}`,
    POT: `${process.env.REACT_APP_API_POT}`,
  }

  let serviceCall =
    CONVERT_SENSOR_SERVICE_TO_API_CALL[
      service as keyof typeof CONVERT_SENSOR_SERVICE_TO_API_CALL
    ]

  let headers = {}
  const api = apiCall()

  if (startDate !== null && endDate !== null) {
    headers = {
      macs,
      startDate: formatedDateToString(startDate),
      endDate: formatedDateToString(endDate),
    }
  } else {
    headers = {
      macs,
    }
  }
  const { data } = await api.get(serviceCall, {
    headers: headers,
  })

  return data
}
