import { FC } from "react"
import { BsTrash } from "react-icons/bs"
import { BiSolidPencil } from "react-icons/bi"
import * as S from "./styles"
import { useDashToolsController } from "./controller/useDashToolsController"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { IconButton } from "../../../../components/ui/IconButton"
import { GearSix } from "@phosphor-icons/react"

export const DashTools: FC = () => {
  const { buttons } = useAppTranslate()

  const {
    toggleDropdown,
    isOpen,
    dropdownRef,
    handleEditClick,
    handleDeleteClick,
  } = useDashToolsController()

  return (
    <S.ConfigIconWrapper data-testid='tools-dashboard'>
      <IconButton
        variant='secondary'
        icon={<GearSix size={24} />}
        onClick={toggleDropdown}
      />
      {isOpen && (
        <S.Dropdown data-testid='tools-dashboard__dropdown' ref={dropdownRef}>
          <S.DropdownItem
            data-testid='tools-dashboard__edit-btn'
            onClick={handleEditClick}
          >
            <BiSolidPencil /> {buttons.edit}
          </S.DropdownItem>
          <S.DropdownItem
            data-testid='tools-dashboard__delete-btn'
            onClick={handleDeleteClick}
          >
            <BsTrash /> {buttons.delete}
          </S.DropdownItem>
        </S.Dropdown>
      )}
    </S.ConfigIconWrapper>
  )
}
