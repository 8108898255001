import { createSlice } from "@reduxjs/toolkit"
import { Profile } from "./user.interfaces"

interface InitialState {
  profile: Profile | null
}

interface UserProfileAction {
  type: string
  payload: Profile
}

const initialState: InitialState = {
  profile: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: (state) => {
      state.profile = null
    },
    setUserProfile: (state, action: UserProfileAction) => {
      state.profile = action.payload
    },
  },
})

export const { resetUserState, setUserProfile } = userSlice.actions

export default userSlice.reducer
