/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { SensorsConfigContext } from "../context/SensorConfigContext"
import { SensorsConfigContextDTO } from "../entities/SensorsConfigContextDTO"
import { ResetResquestDTO } from "../entities/ResetRequestDTO"
import { openModal } from "../../../store/features/modal/modal.slice"
import { AlgorithmsFormattedDTO } from "../entities/AlgorithmsFormattedDTO"
import { SensorProv } from "../../../store/features/sensors/sensors.interfaces"
import { MsgType2DTO, MsgType3DTO } from "../entities/MsgTypeDTO"
import { updateSensorName } from "../../../store/features/sensors/sensors.api"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { useFormattedSensorsController } from "./useFormattedSensorsController"
import { toast } from "react-toastify"
import useSensorsConfigController from "./useSensorsConfigController"
import { HDR_SERVICES_TYPE, getPot } from "hdr-process-data"

type HDRServiceType =
  | "temp"
  | "rms2"
  | "rmms"
  | "tilt"
  | "fft"
  | "accRaw"
  | "_4t20"
  | "ntc"
  | "pot"

export const useSensorsInfoController = (bleHdrMac?: string) => {
  let expanderStateFromLocalStorage: string | null = localStorage.getItem(
    `${bleHdrMac} - Expander`
  )
  const [openAccordion, setOpenAccordion] = useState<boolean>(
    JSON.parse(expanderStateFromLocalStorage as string) ?? false
  )
  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)
  const companyId = useAppSelector(
    (state) => state.persistedReducer.user.profile?.user_data.x1
  )

  const [status, setStatus] = useState<boolean>(false)
  const wsAckEvent = {
    MsgType2: `WS2CLIENT/REQUISITION_AVAILABLE/${companyId}`,
  }

  const {
    sensorsConfigured,
    setResetOnlyAlgorithm,
    titleModalDisableOrReset,
    setResetRequest,
    setBtnStatusReset,
    sensorsToBeDisplayed,
    setSensorToBeDisplayed,
    openModalConfigService,
    setHealths,
    healths,
    sensorsProvisioned,
    setLastPOtDataSavedFromWS,
    setOrderOption,
    orderOption,
    searchValue,
    searchOption,
    handleChangeSearchValue,
    handleChangeSearchOption,
    handleClearFilter,
    filteredSensors,
  } = useContext(SensorsConfigContext) as SensorsConfigContextDTO

  const sensor = sensorsToBeDisplayed.find(
    (sensor) => sensor.bleHdrMac === bleHdrMac
  ) as SensorProv
  const { sensorsConfig } = useAppTranslate()
  const [editSensorName, setEditSensorName] = useState<string>(
    sensor?.name ? sensor.name : ""
  )
  const resetTimeout = useRef<NodeJS.Timeout | null>(null)
  const { getHealthProcessed, getSensorsAndServicesFormatted } =
    useFormattedSensorsController()

  const dispatch = useAppDispatch()
  useSensorsConfigController()
  /** When entering the page, all sensors are considered offline */
  useEffect(() => {
    const macs = Object.keys(sensorsProvisioned)
    macs.forEach((mac) => {
      localStorage.setItem(`${mac} - status`, "offline")
    })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    abortController.abort()
    ;(async () => {
      if (sensorsProvisioned.length) {
        try {
          const healthProcessed = await getHealthProcessed(sensorsProvisioned)
          setHealths(healthProcessed)
        } catch (error) {
          toast.error("Erro no processamento das informações dos sensores", {
            autoClose: 3500,
            style: { fontSize: "1.5rem" },
          })
        }
      }
    })()
  }, [sensorsProvisioned])

  useEffect(() => {
    const abortController = new AbortController()
    abortController.abort()

    if (sensorsProvisioned.length !== 0) {
      const result = getSensorsAndServicesFormatted(
        sensorsProvisioned,
        sensorsConfigured,
        healths
      )

      setSensorToBeDisplayed(result)
    } else {
      setSensorToBeDisplayed([])
    }
  }, [sensorsConfigured, sensorsProvisioned, healths])

  /** Checks ID sensor and makes name change request in redux */
  const onEditSaveBtnClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation()
    if (!sensor?.id) return
    await dispatch(
      updateSensorName({ id: sensor?.id, sensorName: editSensorName })
    )
  }

  /** Clears the websocket that listens for type 2 messages*/
  const clearSocketListeners = useCallback(() => {
    Object.values(wsAckEvent).forEach((eventName) => {
      socket?.removeListener(eventName)
    })
  }, [wsAckEvent, socket])

  const clearSocketListenersPOT = useCallback(() => {
    socket?.removeListener(
      `WS2CLIENT/${companyId}/${bleHdrMac}/${HDR_SERVICES_TYPE.pot}`
    )
  }, [socket])

  /** Creates the object for the reset request and opens the confirmation modal to reset the sensor*/
  const onResetBtnClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    bleHdrMac: string,
    _bleOriginalMac: string
  ): void => {
    e.stopPropagation()
    setResetOnlyAlgorithm(false)
    setBtnStatusReset("reset")
    titleModalDisableOrReset.current = sensorsConfig.reset_sensor.toUpperCase()
    const request: ResetResquestDTO = {
      mac: bleHdrMac,
      bleOriginalMac: _bleOriginalMac,
    }
    setResetRequest(request)
    dispatch(openModal({ modal: "DisableOrReset" }))
  }

  /** Checks on local storage if the Sensor Card accordion is open*/
  const onArrowClick = (mac: string): void => {
    localStorage.setItem(`${mac} - Expander`, String(!openAccordion))
    setOpenAccordion(!openAccordion)
  }

  /** Returns the service configurations. If there is no service, it returns null */
  const getAlgorithm = (sensorData: SensorProv) => {
    const HDR_SERVICE_TYPE: HDRServiceType[] = [
      "temp",
      "rms2",
      "rmms",
      "tilt",
      "fft",
      "accRaw",
      "_4t20",
      "ntc",
      "pot",
    ]

    let obj: AlgorithmsFormattedDTO = {} as AlgorithmsFormattedDTO
    const { sensor, bleHdrMac } = sensorData
    const { algorithms } = sensor || {}
    const sensoConfig = sensorsConfigured.filter(
      (sensor) => sensor.mac === bleHdrMac
    )[0]

    HDR_SERVICE_TYPE.forEach((service) => {
      const servicesConfigured = sensoConfig?.services
      if (algorithms[service]) {
        const algorithmConfigured = servicesConfigured?.filter(
          (element) => element?.name === service
        )[0]

        if (algorithmConfigured)
          obj = {
            ...obj,
            [service]: sensorData[service],
          }
        else
          obj = {
            ...obj,
            [service]: null,
          }
      }
    })
    return obj
  }

  /** Function that updates sensor data with type 2 message data */
  const updateLastMsgReceived = (data: MsgType2DTO) => {
    const index = sensorsToBeDisplayed.findIndex(
      (sensor) => sensor?.bleHdrMac === data?.mac
    )
    if (index !== -1) {
      const date = new Date(data.time * 1000)
      const newProvSensorWithConfiguredServices = [...sensorsToBeDisplayed]
      const modifiedSensor = newProvSensorWithConfiguredServices[index]
      if (modifiedSensor.health && !openModalConfigService) {
        modifiedSensor.health.time = date.toUTCString()
        modifiedSensor.health.rssi = data.rssi
        modifiedSensor.health.protocolVersion = data.protocolVersion
        modifiedSensor.health.applicationVersion = data.applicationVersion
        setSensorToBeDisplayed(newProvSensorWithConfiguredServices)
      }
    }
  }

  const resetInterval = () => {
    if (resetTimeout.current) {
      clearTimeout(resetTimeout.current)
    }
    resetTimeout.current = setTimeout(() => {
      setStatus(false)
    }, 60000) // 60 segundos
  }

  /** Receives type 2 message from WebSocket and updates sensor data with the information received */
  const registerMsgType2SocketEvent = useCallback(() => {
    /** Received from message type 2 by Websocket */
    socket?.on(wsAckEvent.MsgType2, (data: MsgType2DTO) => {
      if (data.mac !== bleHdrMac) return

      updateLastMsgReceived(data)
      setStatus(true)

      resetInterval()
    })
  }, [socket, sensorsToBeDisplayed, openModalConfigService])

  /** Changes the variable that stores the name to be changed of the sensor */
  const handleEditSensorName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditSensorName(e.target.value)
  }

  const createWebSocketConnectionPOT = () => {
    sensorsConfigured.forEach((sensor) => {
      if (sensor.mac === bleHdrMac) {
        sensor.services.forEach((service) => {
          if (service.name === "pot") {
            const event = `WS2CLIENT/${companyId}/${sensor.mac}/${HDR_SERVICES_TYPE.pot}`
            socket?.on(event, (data: MsgType3DTO) => {
              if (!openModalConfigService) processesPOTMessage(data, sensor.mac)
            })
          }
        })
      }
    })
  }

  const processesPOTMessage = (data: MsgType3DTO, mac: string) => {
    const result = getPot(data.raw, data.time.toFixed(0), {
      applicationVersion: data.applicationVersion,
      protocolVersion: data.protocolVersion,
    })

    if (result.isRight()) {
      const newValue = {
        [mac]: result.value.percent,
      }
      setLastPOtDataSavedFromWS((prevState) => ({
        ...prevState,
        ...newValue,
      }))
    }
  }

  useEffect(() => {
    if (socket === null) return
    createWebSocketConnectionPOT()

    return () => {
      clearSocketListenersPOT()
    }
  }, [
    socket,
    clearSocketListenersPOT,
    openModalConfigService,
    sensorsConfigured,
  ])

  useEffect(() => {
    if (socket === null) return
    registerMsgType2SocketEvent()

    return () => {
      clearSocketListeners()
    }
  }, [socket, registerMsgType2SocketEvent, clearSocketListeners])

  const handleOnChangeOrderBy = (e: ChangeEvent<HTMLSelectElement>) => {
    setOrderOption(Number(e.target.value))
  }

  return {
    getAlgorithm,
    onArrowClick,
    openAccordion,
    onResetBtnClick,
    status,
    editSensorName,
    handleEditSensorName,
    onEditSaveBtnClick,
    filteredSensors,
    setSensorToBeDisplayed,
    orderOption,
    handleOnChangeOrderBy,
    searchValue,
    searchOption,
    handleChangeSearchValue,
    handleChangeSearchOption,
    handleClearFilter,
  }
}
