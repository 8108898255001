import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`
export const TextInfo = styled.section`
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
