import { createSlice } from "@reduxjs/toolkit"
import { createSale } from "./sales.api"

interface InitialState {
  loading: boolean
  error: string | null
}

const initialState: InitialState = {
  loading: false,
  error: null,
}

export const salesSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createSale.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createSale.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? ""
    })
    builder.addCase(createSale.fulfilled, (state, action) => {
      state.loading = false
    })
  },
})

export default salesSlice.reducer
