import { useContext } from "react"
import { SensorsProvContext } from "../../../../../pages/SensorsProv/context/SensorsProvContext"
import { SensorsProvContextDTO } from "../../../../../pages/SensorsProv/entities/SensorsProvContextDTO"
import { useAppDispatch } from "../../../../../store/hooks"
import { closeModal } from "../../../../../store/features/modal/modal.slice"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { ErrorTranslatorDTO } from "../../../../../pages/SensorsProv/entities/ErrorTranslator"
import { requestProv } from "../../../../../store/features/sensors/sensors.utilities"

export const useModalConfirmationController = () => {
  const {
    sensorDataToProvision,
    disablePageRef,
    requestTimeRef,
    setStatusBtn,
    setMessageError,
    messageError,
  } = useContext(SensorsProvContext) as SensorsProvContextDTO

  const dispatch = useAppDispatch()
  const { errorTranslation } = useAppTranslate()

  const handleRequestProv = async (): Promise<void> => {
    if (!sensorDataToProvision.current)
      return console.error("No sensor data to provision")

    const { mac, adv } = sensorDataToProvision.current

    const ERROR_TRANSLATOR: ErrorTranslatorDTO = {
      "Some error occurred!": errorTranslation.some_error_occurred,
      "This company doesn't have hdr ble network registered":
        errorTranslation.not_hdr_ble_network_registered,
      "Sensor is not produced by Hedro.":
        errorTranslation.sensor_is_not_produced_by_Hedro,
      "This sensor is already provisioned.":
        errorTranslation.already_provisioned,
      "This sensor is already in process of being provisioned.":
        errorTranslation.process_of_being_provisioned,
      "There is no sensor to be provisioned for this company.":
        errorTranslation.not_sensor_to_be_provisioned_for_this_company,
      SaleNotMade: errorTranslation.sale_not_made,
    }

    const result = await requestProv(mac, adv, ERROR_TRANSLATOR)

    if (result.success) {
      handleCloseModal()
      setStatusBtn((prevState) => ({
        ...prevState,
        Installing: {
          status: true,
          mac: mac,
        },
        Error: {
          mac: null,
        },
      }))

      disablePageRef.current = true
      requestTimeRef.current = new Date().getTime() as number

      setTimeout(() => {
        verifyTimeOut()
      }, 60 * 1000)
    } else {
      console.error(result.message)
      setMessageError(result.message)
    }
  }
  const handleCloseModal = () => {
    dispatch(closeModal())
    setMessageError(null)
  }

  const verifyTimeOut = () => {
    if (disablePageRef.current) {
      const now = new Date().getTime()
      const sensorMac = sensorDataToProvision.current?.mac
      const time = requestTimeRef.current as number
      if (now - time > 50000 && sensorMac) {
        disablePageRef.current = false
        requestTimeRef.current = null

        setStatusBtn((prevState) => ({
          ...prevState,
          Installing: {
            status: false,
            mac: null,
          },
          Error: {
            mac: sensorMac,
          },
        }))
      }
    }
  }

  return {
    handleRequestProv,
    handleCloseModal,
    messageError,
  }
}
