import styled from "styled-components"

export const SensorName = styled.span`
  flex-wrap: wrap;
  width: 150px;
`

export const SensorsContainer = styled.div`
  margin-top: 10.5px;
  max-width: 500p;
  padding-left: 10.5px;
  /* border: 1px solid #c4c4c4; */
  /* border-radius: 4px; */

  height: 200px;
  overflow: auto;

  background-color: #fff;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`

export const EmptySensorMessage = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  h1 {
    margin: 0 auto;
  }
`

export const SensorAndServicesGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 10.5px;
  padding-bottom: 10.5px;
  padding-left: 10.5px;

  margin-top: 10.5px;
  margin-bottom: 10.5px;

  border: 1px solid #c4c4c4;
  border-radius: 4px;

  overflow: auto;

  background-color: #fff;
`

export const CheckBoxButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 10.5px;
  padding-bottom: 10.5px;
  padding-left: 10.5px;

  margin-left: 10.5px;
  overflow: auto;

  background-color: #fff;

  .checkbox {
    margin-right: 10.5px;
    display: flex;

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    span {
      margin-left: 10.5px;
    }
  }
`

export const Title = styled.div`
  width: 100%;
  margin-top: 10.5px;

  display: flex;
  align-items: center;
  justify-content: center;
`
