import {
  Wrapper,
  Main,
  Title,
  Subtitle,
  Header,
  FloatingAddButton,
} from "./styles"

import Fab from "@material-ui/core/Fab"
import { AlarmConfigModal } from "../components/AlarmConfigModal"
import { useAlarmConfigViewModel } from "../controllers/useAlarmConfigController"
import SensorsWithAlarms from "./sensorWithAlarms"
import { DataFromApiDTO } from "../entities/dataFromApiDTO"
import Loading from "../components/Loading"
import { AiOutlinePlus } from "react-icons/ai"

export const AlarmsConfigView = () => {
  const { handleOpenModal, loading, dataFromAPI } = useAlarmConfigViewModel()

  return (
    <Wrapper>
      <Header />
      {loading ? (
        <Loading>Aguarde...</Loading>
      ) : (
        <Main>
          <Title>Configuração de alarme de sensores:</Title>
          <Subtitle>
            Pode demorar até 1h para que o sensor comece a alarmar...
          </Subtitle>
          {dataFromAPI.length > 0 ? (
            dataFromAPI.map((opts: DataFromApiDTO, index: number) => {
              return <SensorsWithAlarms key={index} options={opts} />
            })
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              Não há alarmes configurados. Para configurar um, clique no ícone
              de &quot;+ &quot; no canto inferior direito.
            </span>
          )}

          <FloatingAddButton>
            <Fab
              aria-label='add'
              onClick={() => handleOpenModal(true)}
              style={{
                backgroundColor: "#65bcda",
                color: "white",
                fontSize: "16px",
              }}
            >
              <AiOutlinePlus />
            </Fab>
          </FloatingAddButton>
        </Main>
      )}
      <AlarmConfigModal />
    </Wrapper>
  )
}
