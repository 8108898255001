import * as z from "zod"

export const companySchema = z.object({
  name: z.string().trim().min(1, "Obrigatório adicionar um nome!"),
  cnpj: z
    .string()
    .trim()
    .min(1, "Obrigatório adicionar um CNPJ!")
    .min(14, "Digite um CNPJ válido!"),
  occupation: z.string().trim().min(1, "Obrigatório adicionar uma ocupação!"),
  state: z.string().trim().min(1, "Obrigatório adicionar um estado!"),
  cep: z
    .string()
    .trim()
    .min(1, "Obrigatório adicionar um CEP!")
    .min(8, "Digite um CEP válido!"),
  country: z.string().trim().min(1, "Obrigatório adicionar um país!"),
  adressState: z.string().trim().min(1, "Obrigatório adicionar um estado!"),
  city: z.string().trim().min(1, "Obrigatório adicionar uma cidade!"),
  neighborhood: z.string().trim().min(1, "Obrigatório adicionar um bairro!"),
  street: z.string().trim().min(1, "Obrigatório adicionar uma rua!"),
  number: z.coerce
    .number()
    .positive("Digite um número válido!")
    .min(1, "Obrigatório adicionar um número!"),
  complement: z.string().trim().min(1, "Obrigatório adicionar um complemento!"),
  product: z.number().min(1, "Selecione um produto!"),
  description: z.string().trim().min(1, "Obrigatório adicionar uma descrição!"),
  amount: z.coerce.number().positive("Selecione uma quantidade valida!"),
})

export type CompanySchema = z.infer<typeof companySchema>

export const defaultValues: CompanySchema = {
  name: "",
  cnpj: "",
  occupation: "",
  state: "",
  cep: "",
  country: "",
  adressState: "",
  city: "",
  neighborhood: "",
  street: "",
  number: 0,
  complement: "",
  product: 0,
  description: "",
  amount: 0,
}
