import { Profile } from "../../store/features/user/user.interfaces"
import decodeJWT from "../decodeJWT"
import { Result, left, right } from "../either/result"
import { Auth0DecodedToken } from "./jwtDecoded"

export const decodeAuth0 = (token: string): Result<Error, Profile> => {
  const result = decodeJWT(token)

  if (!result) return left(Error("Invalid Token"))

  const userMetaData = auth0Metadata(result as Auth0DecodedToken)
  if (userMetaData.isRight()) return right(userMetaData.value)

  return left(userMetaData.value)
}

const auth0Metadata = (userData: Auth0DecodedToken): Result<Error, Profile> => {
  if (!userData) return left(Error("Invalid Token"))

  try {
    var profile: Profile = {
      email: userData.user_data.email,
      name: userData.user_data.name,
      permissions: userData.permissions,
      user_data: userData.user_data.user_metadata,
      exp: userData.exp,
    }
  } catch (error) {
    return left(Error("Parse Error on User_Data"))
  }
  if (
    !profile.user_data.x1 ||
    !profile.user_data.x2 ||
    !profile.user_data.y1 ||
    !profile.user_data.y2
  )
    return left(Error("user_Data not a valid object"))

  return right(profile)
}
