import styled from "styled-components"

export const ModalBody = styled.div`
  margin: 20px;
  text-align: center;
  font-family: arial, sans-serif;
  @media (max-width: 1250px) {
    margin: 20px;
  }
`

export const ModalTitle = styled.h2`
  font-size: large;
  margin-bottom: 20px;
`

export const DeleteMessage = styled.span`
  display: flex;
  margin: auto;
  font-size: large;
`
export const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
`
