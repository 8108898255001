import { Container, MessageContainer, SelectWithTooltip } from "./styles"
import { Tooltip } from "@material-ui/core"
import { ReactComponent as QuestionMarkIcon } from "../../../../assets/questionMark_icon.svg"
import { ButtonConfirm } from "../ButtonConfirm"
import useManagerUserController from "../../controllers/useManagerUser"
import Modal from "../Modal"
import Select from "../Select"
import { PermissionLevel } from "../FormUser"

export const ModalEditPermissionUser = () => {
  const {
    openModalEditPermissionUser,
    handleCloseModalEditPermissionUser,
    userToEditPermission,
    informativeTextAboutPermissions,
    editUserPermission,
    permission,
    setPermissions,
    loadingEditPermission,
  } = useManagerUserController()

  return (
    <Modal
      title={`Editar permissão de ${userToEditPermission?.firstName}`}
      openModal={openModalEditPermissionUser}
      handleModalClose={() => handleCloseModalEditPermissionUser()}
      maxWidth='sm'
      showCloseButton={!loadingEditPermission}
    >
      <Container>
        <MessageContainer>
          Ao fazer isso, o usuário{" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            terá acesso a todas as funcionalidades do sistema pertencente ao
            tipo de permissão atribuída.
          </span>{" "}
        </MessageContainer>

        <SelectWithTooltip>
          <Select
            type='permissionLevel'
            items={PermissionLevel}
            change={(e) => setPermissions(PermissionLevel[e].value)}
            width='300px'
          />
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-line" }}>
                {informativeTextAboutPermissions}
              </span>
            }
            placement='bottom'
          >
            <QuestionMarkIcon width={"16px"} style={{ marginLeft: "2px" }} />
          </Tooltip>
        </SelectWithTooltip>

        <ButtonConfirm
          handleOnClick={editUserPermission}
          permission={permission}
          text='Editar'
          loading={loadingEditPermission}
        />
      </Container>
    </Modal>
  )
}
