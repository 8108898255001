import styled from "styled-components"

interface TitleProps {
  fontSize: string
}

export const Title = styled.span<TitleProps>`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.theme.font.bold};
`
