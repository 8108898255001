import { FC } from "react"
import {
  MdBatteryCharging20,
  MdBatteryCharging50,
  MdBatteryCharging60,
  MdBatteryCharging90,
  MdBatteryChargingFull,
} from "react-icons/md"

interface VoltageIconProps {
  voltage: number | undefined
}

export const VoltageIcon: FC<VoltageIconProps> = ({ voltage }) => {
  if (!voltage) return <MdBatteryCharging20 size={20} color='#1D1D1D' />
  if (voltage >= 3.15) {
    return <MdBatteryChargingFull size={20} color='#379944' />
  } else if (voltage >= 2.85 && voltage < 3.15) {
    return <MdBatteryCharging90 size={20} color='#379944' />
  } else if (voltage >= 2.65 && voltage < 2.85) {
    return <MdBatteryCharging60 size={20} color='#3eac4c' />
  } else if (voltage >= 2.5 && voltage < 2.65) {
    return <MdBatteryCharging50 size={20} color='#EE8434' />
  } else {
    return <MdBatteryCharging20 size={20} color='#D72638' />
  }
}
