import { useEffect, useState } from "react"

interface GatewayCardControllerDTO {
  gatewayStatus: boolean
  getFormatedLifetime: (lifetime: number | null) => string
  isCardOpen: boolean
  openOrCloseCard: () => void
}

export const useGatewayCardController = (
  messageTime: number | null
): GatewayCardControllerDTO => {
  const [gatewayStatus, setGatewayStatus] = useState<boolean>(false)

  useEffect(() => {
    const refreshClock = (): void => {
      const actualTimeInSeconds = new Date().getTime() / 1000

      if (!messageTime) return

      const timeSinceLastMessage = Math.round(actualTimeInSeconds - messageTime)

      setGatewayStatus(timeSinceLastMessage < 15)
    }

    const timerId = setInterval(refreshClock, 1000)

    return () => clearInterval(timerId)
  }, [messageTime])

  const [isCardOpen, setIsCardOpen] = useState<boolean>(false)

  const openOrCloseCard = (): void => {
    setIsCardOpen(!isCardOpen)
  }

  const getFormatedLifetime = (lifetime: number | null): string => {
    if (!lifetime) return ""

    const hours = Math.floor(lifetime / 3600)
    const minutes = Math.floor((lifetime % 3600) / 60)
    const seconds = Math.floor((lifetime % 3600) % 60)

    return `${hours}:${minutes}:${seconds}`
  }

  return {
    gatewayStatus,
    getFormatedLifetime,
    isCardOpen,
    openOrCloseCard,
  }
}
