import { DefaultTheme } from "styled-components/dist/types"
import { palette } from "./theme/proteu/palette"
import { typography } from "./theme/proteu/typography"

const theme: DefaultTheme = {
  border: {
    radius: "6px",
  },
  box: {
    shadow: "0 8px 8px rgba(0, 0, 0, 0.08)",
  },
  font: {
    family: "'Exo 2',sans-serif",
    light: 300,
    normal: 400,
    bold: 600,

    sizes: {
      small: "16px",
      medium: "28px",
      large: "40px",
    },
  },
  icons: {
    sizes: {
      small: 20,
      medium: 24,
      large: 30,
    },
  },
  colors: {
    black: "#4D4D4D",
    red: "#FF4646",
    white: "#FFFFFF",
    blue: "#2684DA",
    green: "#3CD62F",
    grey: "#AEB2B7",
    dark: "#201c1c",
  },
  colorsHover: {
    black: "#4D4D4D",
    red: "#FF0000",
    white: "#FFFFFF",
    blue: "#2F9BFF",
    green: "#3CD62F",
    grey: "#959698",
  },
  palette: palette,
  typography: typography,
}

export default theme
