/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useContext, useState, useEffect } from "react"
import { HistoricalAnalysisContext } from "../context/modelContext"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { plotlyDataDTO } from "../entities/fft3DChartOptionsDTO"
import { FftDTO } from "../entities/services/fftDTO"
import { useAppTranslate } from "../../../translate/useAppTranslate"

export const useFFT3DChartController = () => {
  const { dataToPlot, axisFftSelected } = useContext(
    HistoricalAnalysisContext
  ) as ContextAnaliseHistorica

  const { historicalAnalysis } = useAppTranslate()

  const [title, setTitle] = useState("")

  const [dataX, setDataX] = useState<number[][]>([])
  const [dataY, setDataY] = useState<number[][]>([])
  const [dataZ, setDataZ] = useState<number[][]>([])
  const [data, setData] = useState<plotlyDataDTO>({
    x: dataX,
    y: dataY,
    z: dataZ,
    marker: { color: "red" },
    mode: "lines",
    type: "surface",
  })

  const getIndex = (dataToPlotFFT: FftDTO, axisFftSelected: number) => {
    if (dataToPlotFFT.axis === "all") return axisFftSelected

    if (dataToPlotFFT.axis === "x") {
      return axisFftSelected === 0 ? 0 : NaN
    }

    if (dataToPlotFFT.axis === "y") {
      return axisFftSelected === 1 ? 0 : NaN
    }

    if (dataToPlotFFT.axis === "x") {
      return axisFftSelected === 2 ? 0 : NaN
    }

    return NaN
  }

  useEffect(() => {
    const dataToPlotFFT = dataToPlot as FftDTO[]

    const x: number[][] = []
    const y: number[][] = []
    const z: number[][] = []

    for (let i = 0; i < dataToPlotFFT.length; i++) {
      let yAppend = dataToPlotFFT[i].freqArray.slice(1)
      y.push(yAppend)

      x.push(Array(y[0].length).fill(dataToPlotFFT[i].time))

      const idx = getIndex(dataToPlotFFT[i], axisFftSelected)
      if (isNaN(idx)) continue

      const zAppend = dataToPlotFFT[i].fft[idx].slice(1)
      z.push(zAppend)
    }

    setDataX(x)
    setDataY(y)
    setDataZ(z)
  }, [dataToPlot, axisFftSelected])

  useEffect(() => {
    if (axisFftSelected === 0) {
      setTitle(`${historicalAnalysis.fft3d.title} X`)
    } else if (axisFftSelected === 1) {
      setTitle(`${historicalAnalysis.fft3d.title} Y`)
    } else if (axisFftSelected === 2) {
      setTitle(`${historicalAnalysis.fft3d.title} Z`)
    }

    setData({
      x: dataX,
      y: dataY,
      z: dataZ,
      marker: { color: "red" },
      mode: "lines",
      type: "surface",
    })
  }, [dataZ])

  return { data, title }
}
