import IconButton from "../IconButton"
import Modal from "../Modal"
import { TextInfo, ButtonConteiner } from "./styles"

export const ModalConfirmDelet = ({
  nameSensor,
  monitoredVariableTitle,
  handleModalClose,
  onClickBtn,
}: {
  nameSensor: string
  monitoredVariableTitle: string
  handleModalClose: () => void
  onClickBtn: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productionSerialNumber: string,
    monitoredVariableTitle: string
  ) => void
}) => {
  return (
    <Modal
      title='Excluir Alarme'
      openModal={true}
      maxWidth='xs'
      handleModalClose={handleModalClose}
    >
      <TextInfo>
        Você deseja excluir o alarme de {monitoredVariableTitle} do sensor{" "}
        {nameSensor} ?
      </TextInfo>

      <ButtonConteiner>
        <IconButton
          name='Confirmar'
          widthButton='120px'
          heightButton='30px'
          fontSize='1em'
          onClick={onClickBtn}
        />
      </ButtonConteiner>
    </Modal>
  )
}
