import { Container } from "./styles"
import { GatewayCard } from "../../components/GatewayCard"
import { useGatewaysListController } from "../../controllers/gatewaysListController"

const GatewaysList = (): JSX.Element => {
  const { gateways } = useGatewaysListController()

  return (
    <Container>
      {gateways.length === 0 ? (
        <h2>Não há coletores configurados para essa empresa!</h2>
      ) : (
        gateways.map((info) => <GatewayCard {...info} key={info.mac} />)
      )}
    </Container>
  )
}

export default GatewaysList
