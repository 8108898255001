import { useContext } from "react"
import { SensorsProvContext } from "../context/SensorsProvContext"
import { SensorsProvContextDTO } from "../entities/SensorsProvContextDTO"
import { formatedDate } from "../../../utils/date"

const useSensorsInfoController = () => {
  const { statusBtn } = useContext(SensorsProvContext) as SensorsProvContextDTO

  /**
   * checks the sensor status from the mac in the 'statusBtn' variable
   * @param mac
   * @returns "installed" | "installing" | "blocked" | "error" | "provisioned" | "available"
   */
  const getStatusButton = (mac: string) => {
    if (statusBtn.Installed.mac.includes(mac)) return "installed"
    if (statusBtn.Installing.status)
      return statusBtn.Installing.mac === mac ? "installing" : "blocked"
    if (statusBtn.Error.mac === mac) return "error"
    if (statusBtn.AlreadyInstalled.mac.includes(mac)) return "provisioned"
    return "available"
  }

  /**
   * Formatting date to be displayed
   * @param date to be displayed
   * @returns date formatted
   */
  const converterDateTime = (date: string | undefined) => {
    if (!date) return "Invalid Date"

    return formatedDate(date)
  }

  return {
    getStatusButton,
    converterDateTime,
  }
}

export default useSensorsInfoController
