import {
  Main,
  Header,
  Container,
  UserSelectionContainer,
  UserSelectionText,
  UserSelectionDropDownAndIcon,
  UserSelectionDropdown,
  UserSelectionDropDownText,
  UserSelectionDropdownList,
  UserSelectionDropdownListItem,
  ClearFilterBTN,
  CenterLoading,
} from "./styles"

import { MdKeyboardArrowDown } from "react-icons/md"

import { useAlarmsLogsController } from "../controllers/useAlarmsLogsController"
import AlarmsInfo from "../components/alarmsinfo"
import { AlarmsLogsPaginated } from "../entities/alarmsLogsPaginatedDTO"
import { LoadingSpinner } from "../../../components/LoadingSpinner"

export const AlarmsLogsView = () => {
  const {
    loading,
    handleFilterSensorOrServiceDropdown,
    filterSensorOrServiceSelected,
    openFilterSensorOrServiceSelection,
    FILTER_BY_SENSOR_OR_SERVICE_OPTIONS,
    handleFilterSensorOrServiceSelection,
    handleFilterDropdown,
    filterSelected,
    openFilterSelection,
    sensorsProdSerialNumber,
    SERVICES,
    handleFilterSelection,
    clearFilter,
    handlePaginationDropdown,
    pagination,
    openPaginationSelection,
    quantityOfPagesArrayRef,
    handlePaginationSelection,
    alarmsLogsPaginated,
    configuredSensors,
  } = useAlarmsLogsController()

  return (
    <>
      {loading ? (
        <CenterLoading>
          <LoadingSpinner />
        </CenterLoading>
      ) : (
        <Main>
          <Header />
          <UserSelectionContainer>
            <UserSelectionText>Filtrar por: </UserSelectionText>

            <UserSelectionDropDownAndIcon
              onClick={handleFilterSensorOrServiceDropdown}
            >
              <UserSelectionDropdown>
                <UserSelectionDropDownText>
                  {filterSensorOrServiceSelected}
                </UserSelectionDropDownText>
                {openFilterSensorOrServiceSelection && (
                  <UserSelectionDropdownList>
                    {FILTER_BY_SENSOR_OR_SERVICE_OPTIONS.map(
                      (option: string, index: number) => (
                        <UserSelectionDropdownListItem
                          key={index}
                          index={index}
                          onClick={(e) =>
                            handleFilterSensorOrServiceSelection(e, option)
                          }
                        >
                          {option}
                        </UserSelectionDropdownListItem>
                      )
                    )}
                  </UserSelectionDropdownList>
                )}
              </UserSelectionDropdown>

              <MdKeyboardArrowDown size={22} />
            </UserSelectionDropDownAndIcon>

            <UserSelectionDropDownAndIcon onClick={handleFilterDropdown}>
              <UserSelectionDropdown>
                <UserSelectionDropDownText>
                  {filterSelected}
                </UserSelectionDropDownText>
                {openFilterSelection && (
                  <UserSelectionDropdownList>
                    {filterSensorOrServiceSelected === "Sensor"
                      ? sensorsProdSerialNumber.map(
                          (option: string, index: number) => (
                            <UserSelectionDropdownListItem
                              key={index}
                              index={index}
                              onClick={(e) => handleFilterSelection(e, option)}
                            >
                              {option}
                            </UserSelectionDropdownListItem>
                          )
                        )
                      : SERVICES.map((option: string, index: number) => (
                          <UserSelectionDropdownListItem
                            key={index}
                            index={index}
                            onClick={(e) => handleFilterSelection(e, option)}
                          >
                            {option}
                          </UserSelectionDropdownListItem>
                        ))}
                  </UserSelectionDropdownList>
                )}
              </UserSelectionDropdown>

              <MdKeyboardArrowDown size={22} />
            </UserSelectionDropDownAndIcon>

            <ClearFilterBTN onClick={clearFilter}>Limpar Filtro</ClearFilterBTN>
          </UserSelectionContainer>

          <UserSelectionContainer>
            <UserSelectionText>Página: </UserSelectionText>

            <UserSelectionDropDownAndIcon onClick={handlePaginationDropdown}>
              <UserSelectionDropdown>
                <UserSelectionDropDownText>
                  {pagination}
                </UserSelectionDropDownText>
                {openPaginationSelection && (
                  <UserSelectionDropdownList>
                    {quantityOfPagesArrayRef.current.map(
                      (item: number, index: number) => (
                        <UserSelectionDropdownListItem
                          key={index}
                          index={index}
                          onClick={(e) => handlePaginationSelection(e, item)}
                        >
                          {item}
                        </UserSelectionDropdownListItem>
                      )
                    )}
                  </UserSelectionDropdownList>
                )}
              </UserSelectionDropdown>

              <MdKeyboardArrowDown size={22} />
            </UserSelectionDropDownAndIcon>
          </UserSelectionContainer>

          <Container>
            {alarmsLogsPaginated.length > 0 ? (
              alarmsLogsPaginated.map((alarmLog: AlarmsLogsPaginated) =>
                AlarmsInfo({ alarmLog, configuredSensors })
              )
            ) : (
              <p style={{ display: "flex", justifyContent: "center" }}>
                Não há logs de alarmes para essa seleção/usuário.
              </p>
            )}
          </Container>
        </Main>
      )}
    </>
  )
}
