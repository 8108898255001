import React, { useContext, useEffect, useRef } from "react"
import { HistoricalAnalysisContext } from "../../../../context/modelContext"
import { SensorGroupProps } from "../../../../entities/SensorGroupDTO"
import CheckboxItem from "../CheckboxItem"

import {
  CheckBoxButtonGroup,
  EmptySensorMessage,
  SensorAndServicesGroup,
  SensorsContainer,
} from "./styles"
import { ContextAnaliseHistorica } from "../../../../entities/contextDTO"

const SensorsGroup = ({
  sensors,
  availablesServices,
  graphicType,
  onChangeSelectedServices,
  onChangeCheckedService,
}: SensorGroupProps) => {
  if (graphicType === 5) {
    graphicType = 3
  }
  const { isLoadingProvisionedSensors } = useContext(
    HistoricalAnalysisContext
  ) as ContextAnaliseHistorica

  const warningMessage = useRef(
    <EmptySensorMessage>
      <h1>Carregando Sensores...</h1>
    </EmptySensorMessage>
  )

  useEffect(() => {
    if (!isLoadingProvisionedSensors) {
      warningMessage.current = (
        <EmptySensorMessage>
          <h1>Não há sensores provisionados</h1>
        </EmptySensorMessage>
      )
    }
  }, [isLoadingProvisionedSensors])
  return (
    <SensorsContainer>
      {availablesServices.length === 0
        ? warningMessage.current
        : availablesServices.map((services, index) => {
            if (services.services.length === 0)
              return (
                <React.Fragment key={Math.random() * 1000}>
                  Não há Sensores Configurados
                </React.Fragment>
              )
            else
              return (
                <SensorAndServicesGroup
                  key={`SensorsGroup${Math.random() * 1000}`}
                >
                  <span>{`${services.name ?? `S${services.serialNumber}`}`}</span>
                  <CheckBoxButtonGroup>
                    {services.services.map((service) => {
                      if (service.type?.includes(graphicType)) {
                        return (
                          <CheckboxItem
                            lastData={service.lastData}
                            key={`${services.mac}${service.nameService}`}
                            text={
                              graphicType !== 4
                                ? service.nameService
                                : `Horimetro: Dados de ${service.nameService}`
                            }
                            value={service.nameService}
                            disable={!service.hasData}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(value: any) => {
                              onChangeSelectedServices(
                                value.target.checked,
                                service.nameService,
                                services.name,
                                services.serialNumber,
                                services.mac
                              )
                            }}
                            checked={onChangeCheckedService(
                              service.nameService,
                              services.mac
                            )}
                          />
                        )
                      } else
                        return <React.Fragment key={Math.random() * 1000} />
                    })}
                  </CheckBoxButtonGroup>
                </SensorAndServicesGroup>
              )
          })}
    </SensorsContainer>
  )
}

export default SensorsGroup
