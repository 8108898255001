import { useEffect } from "react"
import { useAuth } from "react-oidc-context"
import { useNavigate } from "react-router-dom"
import {
  fetchSensorsConfig,
  fetchSensorsProv,
} from "../../../store/features/sensors/sensors.api"
import { fetchDashboards } from "../../../store/features/dashboard/dashboard.api"
import { setUserProfile } from "../../../store/features/user/user.slice"
import { useAppDispatch } from "../../../store/hooks"
import { Profile } from "../../../store/features/user/user.interfaces"
import { setInitialDashboard } from "../../../store/features/dashboard/dashboard.slice"
import { fetchGateways } from "../../../store/features/gateways/gateways.api"
import {
  fetchProvisionedSensorsRequest,
  fetchSensorWithServices,
} from "../../../store/features/reports/reports.api"
import { resetLastConfig } from "../../../store/features/historicalAnalysis/historicalAnalysis.slice"
import { GetPayloadFromAccessToken } from "../../../utils/authentication/GetPayloadFromAccessToken"

export const useLoginController = () => {
  const auth = useAuth()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const getUserProfile = () => {
    const { user } = auth

    if (!user || typeof user.profile !== "object") {
      return null
    }
    const profile = GetPayloadFromAccessToken(user.access_token)
    if (profile.isLeft()) {
      return null
    }
    return profile.value as Profile
  }

  useEffect(() => {
    if (auth.isLoading) return
    if (!auth.isAuthenticated) {
      auth.signinRedirect()
    }
    const loadData = async () => {
      //dispatch some actions to configure system
      await dispatch(fetchProvisionedSensorsRequest())
      await dispatch(fetchSensorWithServices())
      await dispatch(fetchGateways())
      await dispatch(fetchSensorsConfig())
      await dispatch(fetchSensorsProv())
      await dispatch(fetchDashboards())
      dispatch(resetLastConfig())

      const profile = getUserProfile()
      if (!profile) {
        console.error("Erro ao salvar dados do usuário")
        return
      }

      dispatch(setUserProfile(profile))

      dispatch(
        setInitialDashboard({
          userProfile: profile,
        })
      )

      navigate("/dashboard")
    }

    if (auth.isAuthenticated) {
      loadData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated])
}
