import React from "react"
import * as S from "./styles"
import { useServiceConfigController } from "./controllers/useServiceConfigController"
import { InstallButton } from "../../../Buttons/InstallButton"
import { cleanAndPadSerialNumber } from "../../../../utils/cleanAndPadSerialNumber"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ExtraInformationComponent } from "./components"

interface PropsModalServiceConfig {}

export const ServiceConfig: React.FC<PropsModalServiceConfig> = () => {
  const {
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    GET_TEXT_FROM_CONFIGS,
    configServiceSelected,
    typeOfServiceSelected,
    GET_DROPDOWN_OPTIONS_BY_INFO_TYPE,
    transformHowTextWillBePresented,
    onNSampleInputChange,
    onDropOptionClick,
    handleChange,
    onConfigureReconfigureButtonClick,
    btnStatus,
    handleOpenDropdownCopyConfig,
    sensorsToBeDisplayed,
    bleHdrMac,
  } = useServiceConfigController()

  const { sensorsConfig } = useAppTranslate()

  const renderInputContainer = (
    config:
      | "axis"
      | "res"
      | "sens"
      | "freqHigh"
      | "freqLow"
      | "nsCalc"
      | "nse"
      | "tsa"
      | "freq"
      | "channel"
  ): JSX.Element => (
    <S.NSampleContainer>
      <S.NSampleInput
        disabled={btnStatus === "configuring"}
        type='number'
        value={configServiceSelected?.current?.[config] || ""}
        onChange={(e) => onNSampleInputChange(e, config, e.target.value)}
        min='1'
        placeholder='Select'
      />
    </S.NSampleContainer>
  )

  const renderDropdownContainer = (
    key: number,
    config:
      | "axis"
      | "res"
      | "sens"
      | "freqHigh"
      | "freqLow"
      | "nsCalc"
      | "tsa"
      | "freq"
      | "channel"
      | "nse",
    value: number | string | undefined
  ): JSX.Element => {
    const defaultValue =
      transformHowTextWillBePresented(
        config,
        configServiceSelected?.current?.[
          config as keyof typeof configServiceSelected.current
        ]
      ).toString() ?? transformHowTextWillBePresented(config, value).toString()

    const dropdownOptions = GET_DROPDOWN_OPTIONS_BY_INFO_TYPE[config]
    return (
      <S.SelectContainer
        $width='13rem'
        value={defaultValue}
        onChange={(e) => handleChange(e, config, e.target.value)}
        disabled={btnStatus === "configuring"}
      >
        {config !== "nse" &&
          Array.isArray(dropdownOptions) &&
          dropdownOptions.map((dropOption, index: number) => (
            <S.DropDownListItem
              key={index}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                onDropOptionClick(e, config, dropOption as string)
              }
              value={dropOption?.toString()}
            >
              {dropOption?.toString()}
            </S.DropDownListItem>
          ))}
        <option value=' ' disabled hidden>
          Select
        </option>
      </S.SelectContainer>
    )
  }

  const copyConfig = () => {
    const defaultValue = !sensorsToBeDisplayed.some((element) =>
      Object.keys(element).includes(typeOfServiceSelected.current as string)
    )
      ? `Nenhum sensor com ${typeOfServiceSelected.current} configurado`
      : "Select"

    return (
      <S.CopyConfig>
        <S.CopyConfigTitle>
          {sensorsConfig.copy_configuration_from}
        </S.CopyConfigTitle>
        <S.ServiceAndOptionsContainerCopyConfig>
          <S.DropdownAndInfoStyled>
            <S.SelectContainer
              $width='18rem'
              onChange={(e) =>
                handleOpenDropdownCopyConfig(e, 5, e.target.value)
              }
              defaultValue={" "}
              disabled={btnStatus === "configuring"}
            >
              <option value=' ' disabled>
                {defaultValue}
              </option>

              {sensorsToBeDisplayed.map((element, index) => {
                return (
                  typeOfServiceSelected.current !== null &&
                  Object.keys(element).includes(
                    typeOfServiceSelected.current
                  ) && (
                    <S.DropDownListItem key={index} value={element.bleHdrMac}>
                      {element.name === null || element?.name?.trim() === ""
                        ? `S${cleanAndPadSerialNumber(
                            element.sensor.productionSerialNumber,
                            element.sensor.hardwareVersion
                          )}`
                        : element.name}
                    </S.DropDownListItem>
                  )
                )
              })}
            </S.SelectContainer>
          </S.DropdownAndInfoStyled>
        </S.ServiceAndOptionsContainerCopyConfig>
      </S.CopyConfig>
    )
  }

  return (
    <S.Container>
      {RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS[
        typeOfServiceSelected?.current as keyof typeof RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS
      ].map((config, index) => (
        <S.ServiceAndOptionsContainer key={index}>
          <S.ServiceStyled>{`${GET_TEXT_FROM_CONFIGS[config]}: `}</S.ServiceStyled>
          <S.DropdownAndInfoStyled>
            {config === "nse"
              ? renderInputContainer(config)
              : renderDropdownContainer(
                  index,
                  config,
                  configServiceSelected?.current?.[
                    config as keyof typeof configServiceSelected.current
                  ]
                )}
          </S.DropdownAndInfoStyled>
        </S.ServiceAndOptionsContainer>
      ))}

      {(typeOfServiceSelected?.current === "pot" ||
        typeOfServiceSelected?.current === "_4t20") && (
        <ExtraInformationComponent
          limits={["upperLimit", "inferiorLimit"]}
          btnStatus={btnStatus}
          serviceName={typeOfServiceSelected?.current}
          mac={bleHdrMac as string}
        />
      )}

      <S.FooterContainer>
        {copyConfig()}

        <InstallButton
          status={btnStatus}
          onClick={() => onConfigureReconfigureButtonClick()}
        />
      </S.FooterContainer>
    </S.Container>
  )
}
