export class Left<Error, Success> {
  readonly value: Error

  constructor(value: Error) {
    this.value = value
  }

  isLeft(): this is Left<Error, Success> {
    return true
  }

  isRight(): this is Right<Error, Success> {
    return false
  }
}

export class Right<Error, Success> {
  readonly value: Success

  constructor(value: Success) {
    this.value = value
  }

  isLeft(): this is Left<Error, Success> {
    return false
  }

  isRight(): this is Right<Error, Success> {
    return true
  }
}

export type Result<Error, Success> =
  | Left<Error, Success>
  | Right<Error, Success>

export const left = <Error, Success>(l: Error): Result<Error, Success> => {
  return new Left<Error, Success>(l)
}

export const right = <Error, Success>(a: Success): Result<Error, Success> => {
  return new Right<Error, Success>(a)
}
