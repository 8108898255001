import {
  SensorsProvContainer,
  IconAndDescriptionContainer,
  TextContainer,
  InfoContainer,
  ResetBtn,
  ThresholdInputDiv,
  ComponentEdit,
  EmailDiv,
} from "./styles"

import { GetInput } from "../../components/Input"
import { GetDropdown } from "../../components/Dropdown"
import { MdCancel } from "react-icons/md"
import { useContext, useState } from "react"
import { ConfigsDTO } from "../../entities/dataFromApiDTO"
import { AlarmsConfigContext } from "../../context/modelContext"
import { ContextAlarmsConfig } from "../../entities/contextDTO"
import { ModalConfirmDelet } from "../../components/ModalConfirmDelet"
import IconButton from "../../components/IconButton"
import { Tooltip } from "../../components/Tooltip"

export const AlarmsInfo = ({
  productionSerialNumber,
  config,
  sensorName,
}: {
  productionSerialNumber: string
  config: ConfigsDTO
  sensorName: string
}) => {
  const { dropdownSelectedRef, THRESHOLD_OPTIONS } = useContext(
    AlarmsConfigContext
  ) as ContextAlarmsConfig

  const [openModalConfirm, setOpenModalConfirm] = useState(false)

  const getThresholdInputDiv = (): JSX.Element => {
    const options = config.optionLines[0]
    const { thresholds, ...dropdown } = options
    const dropdownOpts = {
      ...dropdown,
      width: "140px",
    }
    return (
      <ThresholdInputDiv>
        {GetDropdown(dropdownOpts)}
        {dropdownSelectedRef.current[options.name as string] ===
        THRESHOLD_OPTIONS[4] ? (
          <>
            <div>{GetInput(thresholds![0])}</div>
            <div>{GetInput(thresholds![1])}</div>
          </>
        ) : (
          <>
            <div>{GetInput(thresholds![2])}</div>
            <div style={{ display: "none" }}>{GetInput({})}</div>
          </>
        )}
      </ThresholdInputDiv>
    )
  }

  const getEmailDiv = (): JSX.Element => {
    const options = config.optionLines[2]
    const width = "200px"
    return (
      <Tooltip
        title={"Para colocar mais de um email, separe-os por ponto e vírgula."}
      >
        <EmailDiv>
          <span style={{ fontWeight: "bold" }}>Email(s):</span>
          {GetInput({ width, ...options })}
        </EmailDiv>
      </Tooltip>
    )
  }

  const { onUpdateClickFn } = config

  return (
    <>
      <SensorsProvContainer>
        <InfoContainer>
          <Tooltip title='Variável monitorada'>
            <TextContainer>{config.monitoredVariableTitle}</TextContainer>
          </Tooltip>
        </InfoContainer>

        <ComponentEdit>{getThresholdInputDiv()}</ComponentEdit>
        <ComponentEdit>{GetDropdown(config.optionLines[1])}</ComponentEdit>
        <ComponentEdit>{getEmailDiv()}</ComponentEdit>

        <IconButton
          name='atualizar'
          widthButton='100px'
          heightButton='30px'
          fontSize='0.8em'
          onClick={(
            e,
            name = `${productionSerialNumber}-${config.monitoredVariableTitle}`
          ) => {
            onUpdateClickFn(e, name)
          }}
        />

        <InfoContainer>
          <Tooltip title='Excluir Alarme'>
            <IconAndDescriptionContainer>
              <ResetBtn onClick={() => setOpenModalConfirm(true)}>
                <MdCancel size={20} />
              </ResetBtn>
            </IconAndDescriptionContainer>
          </Tooltip>
        </InfoContainer>
      </SensorsProvContainer>
      {openModalConfirm === true ? (
        <ModalConfirmDelet
          nameSensor={sensorName}
          monitoredVariableTitle={config.monitoredVariableTitle}
          handleModalClose={() => setOpenModalConfirm(false)}
          onClickBtn={(e) =>
            config.onDeleteClickFn(
              e,
              productionSerialNumber,
              config.monitoredVariableTitle
            )
          }
        />
      ) : (
        <></>
      )}
    </>
  )
}
