import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { darken } from "polished"
import { FiDownload } from "react-icons/fi"

interface IconButtonProps {
  onClick: () => {}
  disabled: boolean
}

export default function IconButton({ onClick, disabled }: IconButtonProps) {
  const useStyles = makeStyles({
    button: {
      background: "#65bcda",
      display: "flex",
      justifyContent: "space-around",
      height: "42px",
      fontSize: "16px",

      "&,span": {
        color: `white !important`,
      },

      "&:hover": {
        background: darken(0.1, "#65bcda"),
      },
    },
  })

  const classes = useStyles()

  return (
    <div style={{ width: "180px" }}>
      <Button
        onClick={onClick}
        className={classes.button}
        fullWidth={true}
        variant='contained'
        disabled={disabled}
      >
        Download
        <FiDownload size={15} />
      </Button>
    </div>
  )
}
