import { FC } from "react"

import * as S from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { useModalDisableOrResetController } from "./controllers/useModalDisableOrResetController"
import { InstallButton } from "../../../Buttons/InstallButton"
import { BsExclamationSquare } from "react-icons/bs"
import theme from "../../../../styles/theme"

export const DisableOrReset: FC = () => {
  const { sensorsConfig } = useAppTranslate()

  const {
    _getModalContentText,
    onYesClick,
    btnStatusDisable,
    btnStatusReset,
    resetOnlyAlgorithm,
  } = useModalDisableOrResetController()

  const statusBtn = resetOnlyAlgorithm ? btnStatusDisable : btnStatusReset
  return (
    <S.Container>
      <S.ModalTitle>{sensorsConfig.are_you_sure}</S.ModalTitle>
      <BsExclamationSquare color={theme.colors.red} size={120} />
      <S.Message>{_getModalContentText()}</S.Message>
      <S.Buttons>
        <InstallButton status={statusBtn} onClick={() => onYesClick()} />
      </S.Buttons>
    </S.Container>
  )
}
