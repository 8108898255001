export interface Profile {
  user_data: {
    x1: number
    x2: string
    y1: number
    y2: string
  }
  email: string
  exp: number
  name: string
  permissions: String[]
}

export enum PermissionsProteu {
  // Manager
  /** All functionality available on the manager page */
  ManagerAll = "proteu::manager::all",
  /** Manager page visible to the user */
  ManagerEnable = "proteu::manager::enable",
  /** Allowed to see all manager sales */
  ManagerSalesEnable = "proteu::managersales::enable",
  /** Allowed to create manager campanies */
  ManagerCompaniesEnable = "proteu::managercompanies::enable",
  /** Manager user page visible to the user  */
  ManagerUsersEnable = "proteu::manageruser::enable",

  // User
  UserAll = "proteu::user::all",

  // Proteu
  /** All proteu pages apart from the manager pages available with all their resources */
  ProteuAll = "proteu::all",

  // Sensor Configuration
  /** All functionality available on the sensors configuration page */
  SensorsConfigAll = "proteu::sensorsconfig::all",
  /** Sensor settings page visible to the user */
  SensorsConfigEnable = "proteu::sensorsconfig::enable",
  /** Reads data from configured sensors*/
  SensorsConfigRead = "proteu::sensorsconfig::read",
  /** */
  SensorsConfigWrite = "proteu::sensorsconfig::write",
  /** Allowed to edit configured sensor services */
  SensorsConfigUpdate = "proteu::sensorsconfig::update",
  /** Remove the configured sensor */
  SensorsConfigDelete = "proteu::sensorsconfig::delete",

  // Sensor Provisioning
  /** All functionality available on the sensors configuration page */
  SensorsProvAll = "proteu::sensorsprov::all",
  /** Provisioning page available */
  SensorsProvEnable = "proteu::sensorsprov::enable",
  /** View data from sensors available to be provisioned*/
  SensorsProvRead = "proteu::sensorsprov::read",
  /** installation of new sensors available */
  SensorsProvWrite = "proteu::sensorsprov::write",
  /**  */
  SensorsProvUpdate = "proteu::sensorsprov::update",

  // Dashboard
  /** All functionality available on the dashboard page */
  DashboardAll = "proteu::dashboard::all",
  /** Dashboard page visible to the user */
  DashboardEnable = "proteu::dashboard::enable",
  /** Allowed to see all dashboards along with their analytics */
  DashboardRead = "proteu::dashboard::read",
  /** Allowed to create both dashboards and analytics */
  DashboardWrite = "proteu::dashboard::write",
  /** Allowed to edit dashboards and analytics */
  DashboardUpdate = "proteu::dashboard::update",
  /** Allowed to delete dashboards and analytics */
  DashboardDelete = "proteu::dashboard::delete",

  // Historical Analysis
  /** All functionality available on the historical analysis page */
  HistoricalAnalysisAll = "proteu::historicalanalysis::all",
  /** Historical analysis page visible to the user */
  HistoricalAnalysisEnable = "proteu::historicalanalysis::enable",
  /** Allowed to see all historical analysis */
  HistoricalAnalysisRead = "proteu::historicalanalysis::read",
  /** Allowed to create historical analysis */
  HistoricalAnalysisWrite = "proteu::historicalanalysis::write",
  /**  */
  HistoricalAnalysisUpdate = "proteu::historicalanalysis::update",
  /**  */
  HistoricalAnalysisDelete = "proteu::historicalanalysis::delete",

  // Reports
  /** All functionality available on the reports page */
  ReportsAll = "proteu::report::all",
  /** Reports page visible to the user */
  ReportsEnable = "proteu::report::enable",
  /** Allowed to see all reports */
  ReportsRead = "proteu::report::read",
  /** Allowed to create reports */
  ReportsWrite = "proteu::report::write",
  /**  */
  ReportsUpdate = "proteu::report::update",
  /**  */
  ReportsDelete = "proteu::report::delete",

  // Alarms Configuration
  /** All functionality available on the alarms configuration page */
  AlarmsConfigAll = "proteu::alarmsconfig::all",
  /** Alarms configuration page visible to the user */
  AlarmsConfigEnable = "proteu::alarmsconfig::enable",
  /** Allowed to see all alarms */
  AlarmsConfigRead = "proteu::alarmsconfig::read",
  /** Allowed to create alarms  */
  AlarmsConfigWrite = "proteu::alarmsconfig::write",
  /** Allowed to update alarms */
  AlarmsConfigUpdate = "proteu::alarmsconfig::update",
  /** Allowed to delete alarms */
  AlarmsConfigDelete = "proteu::alarmsconfig::delete",

  // Gateways
  /** All functionality available on the Gateways page */
  GatewaysAll = "proteu::gateway::all",
  /** Gateways page visible to the user */
  GatewaysEnable = "proteu::gateway::enable",
  /** Allowed to see all gateways */
  GatewaysRead = "proteu::gateway::read",
  /** Allowed to create gateways */
  GatewaysWrite = "proteu::gateway::write",
  /**  */
  GatewaysUpdate = "proteu::gateway::update",
  /**  */
  GatewaysDelete = "proteu::gateway::delete",

  // Alarms Logs
  /** All functionality available on the alarms logs page */
  AlarmsLogsAll = "proteu::alarmslogs::all",
  /** Alarms logs page visible to the user */
  AlarmsLogsEnable = "proteu::alarmslogs::enable",
  /** Allowed to see all alarms logs */
  AlarmsLogsRead = "proteu::alarmslogs::read",
  /**  */
  AlarmsLogsWrite = "proteu::alarmslogs::write",
  /**  */
  AlarmsLogsUpdate = "proteu::alarmslogs::update",
  /**  */
  AlarmsLogsDelete = "proteu::alarmslogs::delete",
}
