import { FC } from "react"
import * as S from "./styles"

interface AnalyticGridOptionProps {
  analyticsPerLine: 1 | 2 | 3
}

export const AnalyticGridOption: FC<AnalyticGridOptionProps> = ({
  analyticsPerLine,
}) => {
  const Analytics = ({ analytics }: { analytics: number }) => {
    return (
      <>
        {[...Array(analytics)].map((_, index) => (
          <S.AnalyticRepresentation key={index} />
        ))}
      </>
    )
  }

  const Content = () => {
    switch (analyticsPerLine) {
      case 1:
        return <Analytics analytics={2} />
      case 2:
        return <Analytics analytics={4} />
      case 3:
        return <Analytics analytics={6} />
    }
  }

  return (
    <S.Container $analyticsperline={analyticsPerLine}>
      <Content />
    </S.Container>
  )
}
