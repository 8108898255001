import Icon from "@phosphor-icons/react"

interface IconProps {
  icon: Icon.IconProps
  className?: string
}

export function IconComponent(props: IconProps) {
  return (
    <div className={props.className}>
      <>{props.icon}</>
    </div>
  )
}
