import styled from "styled-components"

export const Button = styled.button`
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 90px;
  height: 40px;

  color: ${(props) => props.theme.colors.white};

  position: absolute;
  right: 10px;
  top: 10px;

  border-radius: ${(props) => props.theme.border.radius};

  background-color: ${(props) => props.theme.colors.red};

  &:hover {
    background-color: ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
`
