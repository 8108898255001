import { FC } from "react"
import { BackButton } from "../../../Buttons"
import { Title } from "../../../Title"

import * as S from "./styles"

interface ErrorProps {
  errorMessage: string
  labelBackButton: string
  onBack: () => void
}

export const Error: FC<ErrorProps> = ({
  errorMessage,
  labelBackButton,
  onBack,
}) => {
  return (
    <S.Container>
      <Title size={"medium"} label={errorMessage} />
      <BackButton label={labelBackButton} onClick={onBack} />
    </S.Container>
  )
}
