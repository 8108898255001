import * as S from "./styles"
import { ReactComponent as SensorIdSVG } from "../../../../assets/sensor_id.svg"
import { ReactComponent as RssiSVG } from "../../../../assets/rssi_icon.svg"
import { ReactComponent as TempSvg } from "../../../../assets/temp_icon.svg"
import { ReactComponent as DateSVG } from "../../../../assets/date_icon.svg"
import { ReactComponent as HardwareVersionSVG } from "../../../../assets/hardware.svg"
import { ReactComponent as AplicationVersionSVG } from "../../../../assets/aplicationVersion.svg"
import { ReactComponent as ProtocolSVG } from "../../../../assets/protocol.svg"
import { InstallButton } from "../../../../components/Buttons/InstallButton"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { FC } from "react"
import { SensorDataToProvisionDTO } from "../../entities/SensorDataToProvisionDTO"
import useSensorsInfoController from "../../controller/useSensorsInfoController"
import { VoltageIcon } from "../../../../components/VoltageIcon"
import { cleanAndPadSerialNumber } from "../../../../utils/cleanAndPadSerialNumber"

interface SensorInfoProps {
  serialNumber?: string
  sensorTemperature?: number
  rssi: number
  protocolVersion: string
  hardwareVersion?: string | null
  applicationVersion: string
  dateAndTimeOfLastMsg?: string
  openModal: ({ mac, codeDate, adv }: SensorDataToProvisionDTO) => void
  mac: string
  codeDate?: string
  voltage?: number
  adv: string
}

export const SensorInfo: FC<SensorInfoProps> = ({
  serialNumber,
  sensorTemperature,
  rssi,
  protocolVersion,
  hardwareVersion,
  applicationVersion,
  dateAndTimeOfLastMsg,
  mac,
  codeDate,
  adv,
  voltage,
  openModal,
}) => {
  const { converterDateTime, getStatusButton } = useSensorsInfoController()

  const { sensorsProv } = useAppTranslate()
  const statusBtn = getStatusButton(mac)
  const codeDateFormatted = codeDate ?? ""
  return (
    <S.SensorsInfoWrapper>
      <S.TextAndIcon title={sensorsProv.serial_number}>
        <SensorIdSVG width={"20px"} height={"18px"} />
        {serialNumber && (
          <S.Text>
            {cleanAndPadSerialNumber(serialNumber, hardwareVersion)}
          </S.Text>
        )}
      </S.TextAndIcon>

      <S.TextAndIcon title={sensorsProv.sensor_temperature}>
        <TempSvg width={"20px"} stroke={"black"} height={"18px"} />
        <S.Text>{sensorTemperature} °C</S.Text>
      </S.TextAndIcon>

      <S.TextAndIcon title={sensorsProv.voltage}>
        <VoltageIcon voltage={Number(voltage)} />
        <S.Text>{voltage} V</S.Text>
      </S.TextAndIcon>

      <S.TextAndIcon title={sensorsProv.rssi}>
        <RssiSVG width={"20px"} />
        <S.Text>{rssi} dBm</S.Text>
      </S.TextAndIcon>

      <S.TextAndIcon title={sensorsProv.protocol_version}>
        <ProtocolSVG width={"20px"} height={"18px"} />
        <S.Text>{protocolVersion}</S.Text>
      </S.TextAndIcon>

      <S.TextAndIcon title={sensorsProv.hardware_version}>
        <HardwareVersionSVG width={"20px"} height={"18px"} />
        <S.Text>{hardwareVersion}</S.Text>
      </S.TextAndIcon>

      <S.TextAndIcon
        title={sensorsProv.application_version + codeDateFormatted}
      >
        <AplicationVersionSVG width={"20px"} height={"18px"} stroke={"black"} />
        <S.Text>{applicationVersion}</S.Text>
      </S.TextAndIcon>

      <S.TextAndIcon title={sensorsProv.date_and_time_of_last_msg}>
        <DateSVG width={"20px"} height={"18px"} />
        <S.Text>{converterDateTime(dateAndTimeOfLastMsg)}</S.Text>
      </S.TextAndIcon>

      <InstallButton
        status={statusBtn}
        onClick={() => openModal({ mac, codeDate, adv })}
      />
    </S.SensorsInfoWrapper>
  )
}
