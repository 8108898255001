import { ProductApiDTO } from "../../entities/productApiDTO"
import { CompanyApiDTO } from "../../services/callApiToGetAllCompanies"
import { PermissionLevelDTO } from "../FormUser"
import { Container } from "./styles"

interface SelectProps {
  items: CompanyApiDTO[] | ProductApiDTO[] | PermissionLevelDTO[]
  type: "company" | "product" | "permissionLevel"
  change: (selectedIndex: number) => void
  defaultValue?: string | number
  width?: string
}

export default function Select({
  items,
  type,
  change,
  defaultValue = "default",
  width = "400px",
}: SelectProps) {
  let Options: JSX.Element[] = []
  let label = ""

  if (type === "permissionLevel") {
    label = "Selecionar Nível de Permissão"

    Options = (items as PermissionLevelDTO[]).map((item, index) => (
      <option key={index} value={item.value}>
        {item.name}
      </option>
    ))

    Options.push(
      <option disabled value='default' key='default'>
        {label}
      </option>
    )
  }

  if (type === "company") {
    label = "Selecionar Empresa"

    Options = (items as CompanyApiDTO[]).map((item, index) => (
      <option key={index} value={item.id}>
        {item.name}
      </option>
    ))

    Options.push(
      <option disabled value='default' key='default'>
        {label}
      </option>
    )
  }

  if (type === "product") {
    label = "Selecionar Produto"

    Options = (items as ProductApiDTO[]).map((item, index) => (
      <option key={index} value={index}>
        {item.name}
      </option>
    ))

    Options.push(
      <option disabled value='default' key='default'>
        {label}
      </option>
    )
  }

  return (
    <Container $width={width}>
      <select
        onChange={(value) => change(value.target.selectedIndex)}
        defaultValue={defaultValue}
      >
        {Options}
      </select>
    </Container>
  )
}
