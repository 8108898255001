/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react"
import {
  DropdownContainer,
  DropdownTitle,
  DropDownAndIcon,
  StyledDropdown,
  DropdownTextSelected,
  DropdownList,
  DropdownListItem,
} from "./styles"

import { MdKeyboardArrowDown } from "react-icons/md"
import { AlarmsConfigContext } from "../../context/modelContext"
import { ContextAlarmsConfig } from "../../entities/contextDTO"
import { cleanAndPadSerialNumber } from "../../../../utils/cleanAndPadSerialNumber"

export const GetDropdown = ({
  hasTitle = false,
  title = "",
  openDropdown = false,
  handleDropdownOpenClick = (e: any, name: any) => console.log(),
  optionSelected = "",
  handleDropdownOptionSelect = (e: any, name: any, option: any) =>
    console.log(),
  dropdownOptions = [],
  width = "100%",
  fontWeight = "bold",
  name = "",
  isSensor = false,
}: {
  hasTitle?: boolean
  title?: string
  openDropdown?: boolean
  handleDropdownOpenClick?: any
  optionSelected?: string
  handleDropdownOptionSelect?: any
  dropdownOptions?: string[]
  width?: string
  fontWeight?: string
  name?: string
  isSensor?: boolean
}) => {
  const { configuredSensorsData } = useContext(
    AlarmsConfigContext
  ) as ContextAlarmsConfig

  const getName = (option: string) => {
    if (!isSensor || !option) return option

    const serial = option.split("-")[0]

    const find = configuredSensorsData.find((sensor) =>
      sensor.productionSerialNumber.includes(serial)
    )

    if (!find) return option

    return find.name
      ? find.name
      : `S${cleanAndPadSerialNumber(find.productionSerialNumber, find.hardwareVersion)}`
  }

  return (
    <DropdownContainer width={width} fontWeight={fontWeight}>
      {hasTitle && <DropdownTitle>{title}: </DropdownTitle>}

      <DropDownAndIcon onClick={(e) => handleDropdownOpenClick(e, name)}>
        <StyledDropdown>
          <DropdownTextSelected>{getName(optionSelected)}</DropdownTextSelected>
          {openDropdown && (
            <DropdownList>
              {dropdownOptions.map((option, index) => (
                <DropdownListItem
                  key={index}
                  index={index}
                  onClick={(e) => handleDropdownOptionSelect(e, name, option)}
                >
                  {getName(option)}
                </DropdownListItem>
              ))}
            </DropdownList>
          )}
        </StyledDropdown>

        <MdKeyboardArrowDown size={22} />
      </DropDownAndIcon>
    </DropdownContainer>
  )
}
