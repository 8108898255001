import { Container, Border } from "./styles"
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai"
interface TimeSelectorProps {
  next: () => void
  previus: () => void
  value: string
}

const TimeSelector = ({ next, previus, value }: TimeSelectorProps) => {
  return (
    <Container>
      <Border>
        <AiOutlineArrowLeft size={18} onClick={() => previus()} />
        <p>{value}</p>
        <AiOutlineArrowRight size={18} onClick={() => next()} />
      </Border>
    </Container>
  )
}
export default TimeSelector
