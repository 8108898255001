import styled from "styled-components"

export const Container = styled.div`
  border: 1px solid #cacaca;
  border-radius: 10px;

  margin-bottom: 6px;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  &:hover {
    border-color: #07bc0c;
  }
`

export const Header = styled.div`
  min-height: 62px;

  flex-wrap: wrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-right: 10px;
`

export const Info = styled.div`
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  border: 1px solid #cacaca;
  border-radius: 10px;

  min-width: 300px;
  max-width: 500px;

  padding: 16px;
  margin-bottom: 10px;
`

export const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }

  span {
    font-size: 14px;
  }
`

export const IconAndDescriptionContainer = styled.div`
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  min-width: 200px;
  max-width: 200px;
  width: 200px;

  margin-top: 5px;
  margin-bottom: 5px;

  /* padding-left: 40px; */

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 10px;
    font-weight: bold;
    font-size: 14px;
  }
`

export const Button = styled.button`
  height: 30px;
  width: 70px;

  border: none;
  border-radius: 5px;

  background-color: #2f90d6;
  color: white;
`
