import styled from "styled-components"

export const CompleteMessage = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  h1 {
    margin-bottom: 10px;
  }
`

export const Container = styled.div`
  font-size: 14px;
`

export const ContainerTable = styled.div`
  margin: auto;
  padding-top: 20px;
`
