import { AlarmsConfigView } from "./views"
import { AlarmsConfigContextProvider } from "./context/modelContext"
import Container from "../../components/ui/Container"
import { NewHeader } from "../../components/NewHeader"
import Row from "../../components/ui/Row"
import styled from "styled-components"
import Column from "../../components/ui/Column"
import { useAppTranslate } from "../../translate/useAppTranslate"

const Card = styled.div`
  height: auto;
  border: solid 1px ${(props) => props.theme.palette.gray._400};
  border-radius: 6px 6px 6px 6px;
  background-color: ${(props) => props.theme.palette.white};
  padding: 20px 16px;
`

export const AlarmsConfig = () => {
  const { alarms } = useAppTranslate()
  const objPage = {
    page: {
      title: `${alarms.pageName}`,
      subTitle: `${alarms.subPageConfiguration}`,
    },
  }

  return (
    <AlarmsConfigContextProvider>
      <Container>
        <Row>
          <NewHeader page={objPage.page} />
        </Row>
        <Row>
          <Column md='24' lg='24' xl='24'>
            <Card>
              <AlarmsConfigView />
            </Card>
          </Column>
        </Row>
      </Container>
    </AlarmsConfigContextProvider>
  )
}
