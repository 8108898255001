import { useContext } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { DashboardContext } from "../../../../../pages/Dashboard/context/DashboardContext"
import { DashboardContextDTO } from "../../../../../pages/Dashboard/entities/DashboardContextDTO"
import {
  deleteAnalytics,
  deleteDashboards,
} from "../../../../../store/features/dashboard/dashboard.api"
import { resetDashboardError } from "../../../../../store/features/dashboard/dashboard.slice"
import { ModalsType } from "../../../../../store/features/modal/modal.interfaces"
import { closeModal } from "../../../../../store/features/modal/modal.slice"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useModalDeleteController = ({
  type,
}: {
  type: "DashboardDelete" | "AnalyticDelete"
}) => {
  const dispatch = useAppDispatch()

  const { analyticIdToDelete, dashboardIdToDelete } = useContext(
    DashboardContext
  ) as DashboardContextDTO

  const dashboard = useAppSelector((state) => state.persistedReducer.dashboard)

  const { modalDeleteAnalytic, modalDeleteDash } = useAppTranslate()

  const isLoading = dashboard.status === "loading"
  const hasError = dashboard.status === "failed"

  const timeToActivateTheButton = type === "DashboardDelete" ? 5 : 3

  const subtitle =
    type === "DashboardDelete"
      ? modalDeleteDash.subtitle
      : modalDeleteAnalytic.subtitle

  const deleteMessage =
    type === "DashboardDelete" ? modalDeleteDash.info : modalDeleteAnalytic.info

  const loadingMessage =
    type === "DashboardDelete"
      ? modalDeleteDash.loading
      : modalDeleteAnalytic.loading

  const errorMessage =
    type === "DashboardDelete"
      ? modalDeleteDash.error
      : modalDeleteAnalytic.error

  const onClose = () => {
    dispatch(closeModal())
    dispatch(resetDashboardError())
    analyticIdToDelete.current = null
    dashboardIdToDelete.current = null
  }

  const onDelete = (type: ModalsType) => {
    if (type === "AnalyticDelete") {
      if (!analyticIdToDelete.current || !dashboardIdToDelete.current) return

      dispatch(
        deleteAnalytics({
          analyticId: analyticIdToDelete.current,
          dashboardId: dashboardIdToDelete.current,
        })
      ).then((e) => {
        if (e.meta.requestStatus === "fulfilled") onClose()
      })
    } else {
      if (!dashboardIdToDelete.current!) return

      dispatch(
        deleteDashboards({
          dashboardId: dashboardIdToDelete.current,
        })
      ).then((e) => {
        if (e.meta.requestStatus === "fulfilled") onClose()
      })
    }
  }

  const onBack = () => {
    dispatch(resetDashboardError())
  }

  return {
    isLoading,
    hasError,
    timeToActivateTheButton,
    subtitle,
    loadingMessage,
    errorMessage,
    deleteMessage,
    onClose,
    onDelete,
    onBack,
  }
}
