import { useContext, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { openModal } from "../../../../../store/features/modal/modal.slice"
import { DashboardContext } from "../../../../../pages/Dashboard/context/DashboardContext"
import { DashboardContextDTO } from "../../../../../pages/Dashboard/entities/DashboardContextDTO"

export const useDashToolsController = () => {
  const { dashboardIdToDelete } = useContext(
    DashboardContext
  ) as DashboardContextDTO

  const dispatch = useAppDispatch()

  const openDashboard = useAppSelector(
    (state) => state.persistedReducer.dashboard.openDashboard
  )

  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleEditClick = () => {
    setIsOpen(false)
    dispatch(
      openModal({
        modal: "DashboardEdit",
      })
    )
  }

  const handleDeleteClick = () => {
    setIsOpen(false)

    if (!openDashboard) return

    dashboardIdToDelete.current = openDashboard.id

    dispatch(
      openModal({
        modal: "DashboardDelete",
      })
    )
  }

  return {
    toggleDropdown,
    isOpen,
    dropdownRef,
    handleEditClick,
    handleDeleteClick,
  }
}
