import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react"
import { SelectedServices } from "../entities/allSensorSelected"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { reportRequest } from "../../../store/features/reports/reports.api"
import { format } from "date-fns"
import { sensorRightJson } from "../entities/sensorRightJson"

export interface ReportsContextProps {
  submitReport: () => Promise<void>
  startDate: Date
  endDate: Date
  setStartDate: Dispatch<SetStateAction<Date>>
  setEndDate: Dispatch<SetStateAction<Date>>
  disabledSubmitButton: boolean
  selectedServices: SelectedServices
  setSelectedServices: Dispatch<SetStateAction<SelectedServices>>
}

export const ReportsContext = createContext<ReportsContextProps | null>(null)

export const ReportsContextProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch()

  const sensorsData = useAppSelector(
    (state) => state.persistedReducer.reports.provisionedSensorsWithDataObject
  )

  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())

  const [selectedServices, setSelectedServices] = useState<SelectedServices>({})

  async function submitReport(): Promise<void> {
    const sensors = transformSensorsIntoTheRightConfigJson()

    await dispatch(
      reportRequest({
        startDate: format(startDate, "yyy-MM-dd"),
        endDate: format(endDate, "yyy-MM-dd"),
        sensors,
      })
    )
  }

  const transformSensorsIntoTheRightConfigJson = () => {
    let sensors: sensorRightJson = {}

    const macs = Object.keys(selectedServices)

    for (let index = 0; index < macs.length; index++) {
      const mac = macs[index]

      const info = sensorsData.find((sensor) => sensor.mac === mac)

      if (!info) continue

      const services = Object.keys(selectedServices[mac])

      for (let j = 0; j < services.length; j++) {
        const service = services[j]

        if (!selectedServices[mac][service]) continue

        if (!sensors[mac]) {
          sensors = {
            ...sensors,
            [mac]: {
              id: info.id.toString(),
              name: info.name ?? "",
              serialNumber: info.serialNumber,
              services: [service],
            },
          }

          continue
        }

        sensors = {
          ...sensors,
          [mac]: {
            ...sensors[mac],
            services: [...sensors[mac].services, service],
          },
        }
      }
    }

    return sensors
  }

  const disabledSubmitButton = useMemo(() => {
    let hasSelectedService = false

    const macs = Object.keys(selectedServices)

    macs.forEach((mac) => {
      const services = Object.keys(selectedServices[mac])

      services.forEach(
        (service) =>
          (hasSelectedService =
            hasSelectedService || selectedServices[mac][service])
      )
    })

    return !hasSelectedService
  }, [selectedServices])

  const defaultContext: ReportsContextProps = {
    submitReport,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    disabledSubmitButton,
    selectedServices,
    setSelectedServices,
  }

  return (
    <ReportsContext.Provider value={defaultContext}>
      {children}
    </ReportsContext.Provider>
  )
}
