import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  padding: 5px; /* this */

  div {
    text-align: center;
    margin: 5px; /* and that, will result in a 10px gap */

    input {
      margin: 5px;
    }
  }
`
