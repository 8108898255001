import { Container } from "./styles"

import { useLineChartController } from "../../../controllers/useLineChart"

import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import Boost from "highcharts/modules/boost"
import Indicators from "highcharts/indicators/indicators-all.js"
import DragPanes from "highcharts/modules/drag-panes.js"
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js"
import PriceIndicator from "highcharts/modules/price-indicator.js"
import FullScreen from "highcharts/modules/full-screen.js"
import StockTools from "highcharts/modules/stock-tools.js"
import "./style.css"
import HFullScreen from "highcharts/modules/full-screen"
import HStockTools from "highcharts/modules/stock-tools"

Indicators(Highcharts)
DragPanes(Highcharts)
AnnotationsAdvanced(Highcharts)
PriceIndicator(Highcharts)
FullScreen(Highcharts)
StockTools(Highcharts)
HFullScreen(Highcharts)
HStockTools(Highcharts)
Boost(Highcharts)

const LineChart = () => {
  const { chartRef, graphicOptions } = useLineChartController()

  return (
    <Container>
      <HighchartsReact
        constructorType={"stockChart"}
        highcharts={Highcharts}
        ref={chartRef}
        options={{
          ...graphicOptions,
        }}
      />
    </Container>
  )
}

export default LineChart
