import Draggable from "react-draggable"
import { CloseModalButton } from "../Buttons"
import { AnalyticConfig } from "./components/AnalyticConfig"
import { AnalyticEdit } from "./components/AnalyticEdit"
import { Confirmation } from "./components/Confirmation"
import { DashboardConfig } from "./components/DashboardConfig"
import { Delete } from "./components/Delete"
import { DisableOrReset } from "./components/DisableOrReset"
import { SensorAndServices } from "./components/SensorAndServices"
import { ServiceConfig } from "./components/ServiceConfig"
import { useModalController } from "./controller/useModalController"
import { HistoricalAnalysisConfig } from "./components/HistoricalAnalysisConfig"
import * as S from "./styles"
import { FC } from "react"

const componentMap = {
  AnalyticConfig: <AnalyticConfig />,
  AnalyticEdit: <AnalyticEdit />,
  DashboardCreate: <DashboardConfig type={"DashboardCreate"} />,
  DashboardEdit: <DashboardConfig type={"DashboardEdit"} />,
  SelectSensorsAndServices: <SensorAndServices />,
  AnalyticDelete: <Delete type={"AnalyticDelete"} />,
  DashboardDelete: <Delete type={"DashboardDelete"} />,
  Confirmation: <Confirmation />,
  ServiceConfig: <ServiceConfig />,
  DisableOrReset: <DisableOrReset />,
  HistoricalAnalysisConfig: <HistoricalAnalysisConfig />,
}

export const Root: FC = () => {
  const {
    onClose,
    activeModal,
    title,
    width,
    height,
    minWidth,
    minHeight,
    maxWidthOverflow,
    maxHeight,
    maxWidth,
    draggable,
  } = useModalController()

  if (!activeModal) return <></>

  const componentToRender = componentMap[activeModal]

  return (
    <S.Container open={true}>
      <Draggable disabled={window.innerWidth > 800 ? !draggable : true}>
        <S.Content
          $widthModal={width}
          $heightModal={height}
          $minWidthModal={minWidth}
          $minHeightModal={minHeight}
          $maxWidthOverflow={maxWidthOverflow}
          $maxHeight={maxHeight}
          $maxWidth={maxWidth}
        >
          <S.ModalHeader>
            <S.TitleModal>{title}</S.TitleModal>
            <CloseModalButton onClick={onClose} />
          </S.ModalHeader>

          <S.ModalBody>{componentToRender}</S.ModalBody>
        </S.Content>
      </Draggable>
    </S.Container>
  )
}
