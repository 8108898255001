import { OrbitItem } from "../../../../entities/Orbit"
import { Container } from "./styles"

interface SelecOrbitProps {
  items: OrbitItem[]
  onChange: (name: string) => void
}

const SelectOrbit = ({ items, onChange }: SelecOrbitProps) => {
  return (
    <Container>
      {items.map((item) => {
        return (
          <div className='checkbox' key={item.name}>
            <label>
              <input
                type='checkbox'
                value={item.name}
                disabled={false}
                checked={item.checked}
                onChange={() => {
                  onChange(item.name)
                }}
              />

              <span>{item.name}</span>
            </label>
          </div>
        )
      })}
    </Container>
  )
}

export default SelectOrbit
