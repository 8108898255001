import { useContext, useEffect } from "react"
import { AlarmsLogsContext } from "../context/modelContext"
import { UpdateAllAlarmsLogsUseCase } from "../useCases/updateAllAlarmsLogsUsecase"
import { GetAlarmsLogsUseCase } from "../useCases/getAlarmsLogsUsecase"
import { useDispatch } from "react-redux"
import { AlarmsLogsPaginated } from "../entities/alarmsLogsPaginatedDTO"
import { ContextAlarmsLogs } from "../entities/contextDTO"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { GetSensorNameFromProductionSerialNumberUseCase } from "../useCases/GetSensorNameFromProductionSerialNumber"
import { alarmLogResetCount } from "../../../store/features/alarms_logs/alarms_logs.slice"
import { useAppSelector } from "../../../store/hooks"

export const useAlarmsLogsController = () => {
  const {
    pagination,
    alarmsLogsFilteredRef,
    setLoading,
    setAlarmsLogsFiltered,
    setAlarmsLogs,
    setSensorsProdSerialNumber,
    alarmsLogsCount,
    alarmsLogs,
    setPagination,
    PAGINATION_OFFSET,
    setAlarmsLogsPaginated,
    quantityOfPagesArrayRef,
    setOpenFilterSelection,
    setOpenFilterSensorOrServiceSelection,
    setOpenPaginationSelection,
    setFilterSelected,
    setFilterSensorOrServiceSelected,
    filterSensorOrServiceSelected,
    FILTER_BY_SENSOR_OR_SERVICE_OPTIONS,
    SERVICES,
    sensorsProdSerialNumber,
    openFilterSensorOrServiceSelection,
    openFilterSelection,
    alarmsLogsFiltered,
    loading,
    openPaginationSelection,
    filterSelected,
    alarmsLogsPaginated,
  } = useContext(AlarmsLogsContext) as ContextAlarmsLogs

  const configuredSensors = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const dispatchActions = useDispatch()

  useEffect(() => {
    getAllAlarmsLogs()
    if (alarmsLogsCount > 0) updateAllAlarmsLogsToBeSeenAt()
    // eslint-disable-next-line
  }, [])

  const getAlarmsLogsUseCase = new GetAlarmsLogsUseCase()
  const updateAllAlarmsLogsUseCase = new UpdateAllAlarmsLogsUseCase()
  const getSensorNameFromProductionSerialNumberUseCase =
    new GetSensorNameFromProductionSerialNumberUseCase()

  useEffect(() => {
    _setAlarmsLogsPaginated()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const getAllAlarmsLogs = async (): Promise<void> => {
    const alarmsLogsData: AlarmsLogsPaginated[] =
      await getAlarmsLogsUseCase.getAll()

    const {
      prodSerialNumberArray,
      alarmsLogsWithName,
    }: {
      prodSerialNumberArray: string[]
      alarmsLogsWithName: AlarmsLogsPaginated[]
    } =
      await getProdSerialNumberArrayAndSensorsNameFromAlarmsLogs(alarmsLogsData)
    _verifyQuantityOfPages(alarmsLogsWithName)
    setSensorsProdSerialNumber(prodSerialNumberArray)
    setAlarmsLogs(alarmsLogsWithName)
    setAlarmsLogsFiltered(alarmsLogsWithName)
    alarmsLogsFilteredRef.current = alarmsLogsWithName
    _setAlarmsLogsPaginated()
    setLoading(false)
  }

  const updateAllAlarmsLogsToBeSeenAt = async (): Promise<void> => {
    await updateAllAlarmsLogsUseCase.updateAll()
    dispatchActions(alarmLogResetCount())
  }

  const getProdSerialNumberArrayAndSensorsNameFromAlarmsLogs = async (
    alarmsLogsData: AlarmsLogsPaginated[]
  ): Promise<{
    prodSerialNumberArray: string[]
    alarmsLogsWithName: AlarmsLogsPaginated[]
  }> => {
    const prodSerialNumberArray: string[] = []
    const alarmsLogsWithName: AlarmsLogsPaginated[] = Object.assign(
      [],
      alarmsLogsData
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const prodSerialNumberAndName: any = {}

    await Promise.all(
      alarmsLogsData.map(async (alarmLog) => {
        if (
          !prodSerialNumberArray.find(
            (item) => item === alarmLog.productionSerialNumber
          )
        ) {
          prodSerialNumberArray.push(alarmLog.productionSerialNumber)
          const name =
            await getSensorNameFromProductionSerialNumberUseCase.getSensorByProdSerialNumber(
              alarmLog.productionSerialNumber
            )
          prodSerialNumberAndName[alarmLog.productionSerialNumber] = name
        }
      })
    )

    alarmsLogsData.forEach((alarmLog, index) => {
      alarmsLogsWithName[index] = {
        ...alarmLog,
        name: prodSerialNumberAndName[alarmLog.productionSerialNumber],
      }
    })

    return {
      prodSerialNumberArray,
      alarmsLogsWithName,
    }
  }

  const handleFilterSensorOrServiceDropdown = (
    e: React.MouseEvent<HTMLElement>
  ): void => {
    e.stopPropagation()
    setOpenFilterSelection(false)
    setOpenFilterSensorOrServiceSelection(true)
    setOpenPaginationSelection(false)
  }

  const handleFilterSensorOrServiceSelection = (
    e: React.MouseEvent<HTMLElement>,
    option: string
  ): void => {
    e.stopPropagation()

    // sets one selection and deselects the another
    setFilterSensorOrServiceSelected(option)
    setFilterSelected("")

    setOpenFilterSensorOrServiceSelection(false)
  }

  const handleFilterDropdown = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setOpenFilterSensorOrServiceSelection(false)
    setOpenFilterSelection(true)
    setOpenPaginationSelection(false)
  }

  const handleFilterSelection = (
    e: React.MouseEvent<HTMLElement>,
    option: string
  ): void => {
    e.stopPropagation()
    setFilterSelected(option)
    setOpenFilterSelection(false)

    _filterData(option)
  }

  const clearFilter = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setAlarmsLogsFiltered(alarmsLogs)
    alarmsLogsFilteredRef.current = alarmsLogs
    setOpenFilterSelection(false)
    setOpenFilterSensorOrServiceSelection(false)
    setFilterSelected("")
    setFilterSensorOrServiceSelected("")
    setPagination(1)
    _setAlarmsLogsPaginated()
  }

  const handlePaginationDropdown = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setOpenPaginationSelection(true)
  }

  const handlePaginationSelection = (
    e: React.MouseEvent<HTMLElement>,
    option: number
  ): void => {
    e.stopPropagation()
    setPagination(option)
    setOpenPaginationSelection(false)
  }

  const _filterData = (option: string): void => {
    switch (filterSensorOrServiceSelected) {
      case "Sensor":
        _filterBySensor(option)
        break
      case "Serviço":
        _filterByService(option)
        break
      default:
        break
    }
  }

  const _filterBySensor = (option: string): void => {
    const alarmsLogsFilteredBySensor = alarmsLogs.filter(
      (item) => item.productionSerialNumber === option
    )
    setAlarmsLogsFiltered(alarmsLogsFilteredBySensor)
    alarmsLogsFilteredRef.current = alarmsLogsFilteredBySensor
    setPagination(1)
    _setAlarmsLogsPaginated()
  }

  const _filterByService = (option: string): void => {
    const serviceType = _getServiceTypeNumberFromServiceTypeText(option)
    const alarmsLogsFilteredByService = alarmsLogs.filter(
      (item) => item.serviceType === serviceType
    )
    setAlarmsLogsFiltered(alarmsLogsFilteredByService)
    alarmsLogsFilteredRef.current = alarmsLogsFilteredByService
    setPagination(1)
    _setAlarmsLogsPaginated()
  }

  const _getServiceTypeNumberFromServiceTypeText = (
    serviceType: string
  ):
    | string
    | HDR_SERVICES_TYPE.tilt
    | HDR_SERVICES_TYPE.accRaw
    | HDR_SERVICES_TYPE._4t20 => {
    switch (serviceType) {
      case "INCLINÔMETRO":
        return HDR_SERVICES_TYPE.tilt
      case "ACC. PURA":
        return HDR_SERVICES_TYPE.accRaw
      case "4T20":
        return HDR_SERVICES_TYPE._4t20
      default:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (HDR_SERVICES_TYPE as any)[serviceType.toLowerCase()]
    }
  }

  const _setAlarmsLogsPaginated = (): void => {
    const array: AlarmsLogsPaginated[] = alarmsLogsFilteredRef.current.slice(
      (pagination - 1) * PAGINATION_OFFSET,
      pagination * PAGINATION_OFFSET
    )
    setAlarmsLogsPaginated(array)
    _verifyQuantityOfPages()
  }

  const _verifyQuantityOfPages = (
    data = alarmsLogsFilteredRef.current
  ): void => {
    const quantityOfPagesForPagination =
      Math.floor(data.length / PAGINATION_OFFSET) +
      (data.length % PAGINATION_OFFSET !== 0 ? 1 : 0)
    const array: number[] = []
    for (let index = 1; index <= quantityOfPagesForPagination; index++) {
      array.push(index)
    }
    quantityOfPagesArrayRef.current = array
  }

  return {
    FILTER_BY_SENSOR_OR_SERVICE_OPTIONS,
    SERVICES,
    sensorsProdSerialNumber,
    handleFilterSensorOrServiceDropdown,
    openFilterSensorOrServiceSelection,
    filterSensorOrServiceSelected,
    handleFilterSensorOrServiceSelection,
    handleFilterDropdown,
    openFilterSelection,
    handleFilterSelection,
    filterSelected,
    clearFilter,
    alarmsLogsFiltered,
    loading,
    pagination,
    alarmsLogsPaginated,
    handlePaginationDropdown,
    openPaginationSelection,
    handlePaginationSelection,
    quantityOfPagesArrayRef,
    configuredSensors,
  }
}
