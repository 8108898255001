import { useEffect, useState } from "react"
import { FftType } from "../../../../../store/features/dashboard/dashboard.interfaces"
import { ChartType } from "../../../../../components/Chart/entities/ChartData"

export const useChartContainerController = ({
  id,
  type,
}: {
  id: number
  type: ChartType
}) => {
  const [fftType, setFftType] = useState<FftType>("Acceleration")

  useEffect(() => {
    if (type === ChartType.TWO) {
      localStorage.setItem(`FFT-TYPE-${id}`, "Acceleration")
    }
  }, [id, type])

  const handleToggleFFT = () => {
    if (fftType === "Velocity") {
      changeType("Acceleration")
    } else {
      changeType("Velocity")
    }
  }

  const changeType = (type: FftType) => {
    localStorage.setItem(`FFT-TYPE-${id}`, type)

    setFftType(type)
  }

  return {
    fftType,
    handleToggleFFT,
  }
}
