import { ReactNode } from "react"
import { AuthProvider } from "react-oidc-context"
import {
  oidcClientSettingsAuth0,
  oidcClientSettingsKeycloak,
} from "../oidcClientSettings"

interface PropsAuthenticationProvider {
  children: ReactNode
}

export const AuthenticationProvider = ({
  children,
}: PropsAuthenticationProvider) => {
  const auth = process.env.REACT_APP_AUTH

  if (auth === "keycloak") {
    return (
      <AuthProvider {...oidcClientSettingsKeycloak}>{children}</AuthProvider>
    )
  }

  return (
    <AuthProvider
      {...oidcClientSettingsAuth0}
      extraQueryParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
      }}
    >
      {children}
    </AuthProvider>
  )
}
