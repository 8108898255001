import { apiCall } from "../../../utils/axios"

export class DeleteAlarmUsecase {
  async delete(id: number) {
    const api = apiCall()
    const response = await api.delete(
      `${process.env.REACT_APP_API_ALARMS}/${id}`
    )

    if (response.status === 200) {
      return true
    }

    return false
  }
}
