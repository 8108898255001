import styled from "styled-components"

export const TextConfig = styled.span<{ disabled: boolean }>`
  font-size: small;
  margin-bottom: 5px;
  display: flex;
  justify-items: center;
  color: ${(props) => (props.disabled ? "#aeaeae" : "black")};
`

export const TitleReference = styled.span<{ disabled: boolean }>`
  font-size: small;
  display: flex;
  justify-items: center;
  flex-direction: column;
  color: ${(props) => (props.disabled ? "#aeaeae" : "black")};
`

export const Container = styled.div`
  margin-top: 5px;
  border: 1px solid #aeaeae;
  border-radius: 2px;
  display: flex;
  padding: 5px;
  width: 200px;
  display: flex;
  align-items: center;
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: x-small;
  margin-right: 0px;
  justify-content: end;
  width: 100%;
`

export const Button = styled.button<{ disabled: boolean }>`
  border: 1px solid #2f90d6;
  background: #2f90d6;
  outline: none;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: x-small;

  ${(props) =>
    props.disabled && {
      cursor: "not-allowed",
      border: "1px solid #DCDCDC",
      background: "#DCDCDC",
    }}
`
export const ResetButton = styled.button<{ disabled: boolean }>`
  border: none;
  border-radius: 5px;
  background: #ff7b7b21;
  color: #ff7b7b;
  text-transform: uppercase;
  padding: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-size: x-small;

  &:hover {
    color: white;
    background: ${(props) => (props.disabled ? "#DCDCDC" : "#ff7b7b")};
  }

  ${(props) =>
    props.disabled && {
      cursor: "not-allowed",
      border: "1px solid #DCDCDC",
      background: "#DCDCDC",
      color: "white",
    }}
`
