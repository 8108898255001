import { SelectHTMLAttributes, forwardRef } from "react"
import { Container, DefaultOption } from "./styles"

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  values: string[]
  placeholder: string
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ values, placeholder, ...props }, ref) => {
    const DEFAULT_VALUE = 0

    return (
      <Container ref={ref} {...props}>
        <DefaultOption value={DEFAULT_VALUE} disabled={true}>
          {placeholder}
        </DefaultOption>

        {values.map((value, index) => (
          <option key={index} value={index + 1}>
            {value}
          </option>
        ))}
      </Container>
    )
  }
)

Select.displayName = "Select"
