import styled from "styled-components"

const ALARMS_CONFIG_WIDTH = 320
const ALARMS_CONFIG_PADDING = 10
const COMPONENTS_INSIDE_ALARMS_CONFIG_WIDTH =
  ALARMS_CONFIG_WIDTH - 2 * ALARMS_CONFIG_PADDING

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
`

export const Main = styled.main`
  padding: 0 10px;
  font-size: 14px;

  h1 {
    font-size: 14px;
  }
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  padding: 0px 0 10px 0;

  strong {
    font-size: 32px;
    letter-spacing: 1.15px;
    font-weight: bold;
  }
  @media (max-width: 736px) {
    padding: 60px 0 10px 0;
  }
`

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000;

  margin-bottom: 5px;
  display: flex;
  justify-content: center;
`

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000;

  margin-bottom: 2%;
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`

export const AlarmsConfigsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  padding: ${ALARMS_CONFIG_PADDING}px;
  width: ${ALARMS_CONFIG_WIDTH}px;

  background: #eaeaea;
  border-radius: 5px;
`

export const EachConfigDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px;
  margin-bottom: 10px;

  align-content: center;
  justify-content: center;
  align-items: center;

  width: ${COMPONENTS_INSIDE_ALARMS_CONFIG_WIDTH}px;

  background: #ffffff;
  border-radius: 5px;

  gap: 8px;
`

export const ConfigAndCloseLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: ${COMPONENTS_INSIDE_ALARMS_CONFIG_WIDTH}px;
`

export const ConfigTitleText = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;

  width: 200px;
`

export const CloseButton = styled.button`
  outline: none;
  border: none;
  background: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ThresholdInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  width: 100%;
`

export const EmailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;

  width: 100%;
`

export const FloatingAddButton = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
`
