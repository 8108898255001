import styled from "styled-components"

interface PropsInstallBTN {
  $background: string
}

export const InstallBTN = styled.button<PropsInstallBTN>`
  width: 170px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props.color};
  border-radius: 5px;
  background: ${(props) => props.$background};
`

export const TitleAndIcon = styled.div<{ letterSpacing: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  text-transform: uppercase;

  p {
    font-weight: 600;
    letter-spacing: ${(props) => props.letterSpacing};
  }
`
