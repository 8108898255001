import styled from "styled-components"

export const SensorsProvContainer = styled.div`
  background: none;
  outline: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 5%;
  border: 1px solid #cacaca;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
`

export const IconAndDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`
export const TextContainer = styled.span`
  margin-left: 10px;
  font-weight: bold;
`

export const InfoContainer = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;

  @media (min-width: 800px) {
    width: 10%;
  }
`
export const ResetBtn = styled.button`
  border: none;
  border-radius: 5px;
  background: #ff7b7b21;
  // outline: 1px solid #ff7b7b !important;
  color: #ff7b7b;
  text-transform: uppercase;

  padding: 6px;

  &:hover {
    color: white;
    background: #ff7b7b;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`
export const ThresholdInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  width: 100%;
`

export const ComponentEdit = styled.div`
  /* overflow-x: hidden; */
  text-overflow: ellipsis;
  // white-space: nowrap;

  @media (min-width: 800px) {
    width: 10%;
  }
`
export const EmailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;

  width: 100%;
`
