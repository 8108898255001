import { MdCancel } from "react-icons/md"
import * as S from "./styles"
import { useReferencePOTController } from "../../controllers/useReferencePOTController"

interface CardReferencePOTProps {
  setReferencePOT: (mac: string, value: number | number[][] | null) => void
  referencePOT: string | null
  resetReferencePOT: () => void
  mac: string
  serviceName: "pot" | "_4t20"
  getLastDataPOTSalved: (mac: string) => number | number[][] | null
}

export const ExtraInformationInLocalStorage = ({
  setReferencePOT,
  referencePOT,
  resetReferencePOT,
  mac,
  serviceName,
  getLastDataPOTSalved,
}: CardReferencePOTProps) => {
  const {
    getExtraInformationFromLS,
    referenceDisabled,
    TRANSFORM_VARIABLE_INTO_TITLE,
  } = useReferencePOTController()

  const informationFromLS = getExtraInformationFromLS(serviceName, mac)

  const referennceIsDisabled = referenceDisabled(serviceName, mac)
  const isDisabledInformations = !informationFromLS
  const lastPOTData = getLastDataPOTSalved(mac)

  const CardReferencePOT = () => {
    return (
      <S.Container>
        <S.TitleReference disabled={referennceIsDisabled}>
          <strong>Referência</strong>
          <span>{`${referencePOT || "-"}`}</span>
        </S.TitleReference>
        <S.ButtonsContainer>
          <S.Button
            disabled={lastPOTData === null || referennceIsDisabled}
            onClick={() => setReferencePOT(mac, lastPOTData)}
          >
            SET
          </S.Button>
          <S.ResetButton
            onClick={() => resetReferencePOT()}
            disabled={referennceIsDisabled}
          >
            &nbsp;RESET &nbsp;
            <MdCancel size={20} />
          </S.ResetButton>
        </S.ButtonsContainer>
      </S.Container>
    )
  }
  return (
    <>
      {["upperLimit", "inferiorLimit", "unitOfMeasurement"].map(
        (config, index: number) => (
          <S.TextConfig key={index} disabled={isDisabledInformations}>
            <strong>
              {`
               ${
                 TRANSFORM_VARIABLE_INTO_TITLE[
                   config as
                     | "upperLimit"
                     | "inferiorLimit"
                     | "unitOfMeasurement"
                 ]
               }:
                
               `}
            </strong>
            {informationFromLS ? informationFromLS[config] : " -"}
          </S.TextConfig>
        )
      )}

      {serviceName === "pot" && <CardReferencePOT />}
    </>
  )
}
