import styled from "styled-components"

export const Period = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  text-align: center;
`

export const SelectPeriod = styled.select`
  margin-left: 10px;
  width: 250px;
  padding: 10px 5px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  display: flex;
  font-size: small;
  cursor: pointer;
  text-transform: uppercase;

  option {
    text-align: center;
    font-size: 1.1em;
    display: flex;
    text-transform: uppercase;
  }
`

export const LabelTitleAnalytic = styled.label`
  margin-top: 7px;
`
