import { createSlice } from "@reduxjs/toolkit"
import { alarmLogRequest, fetchConfiguredSensors } from "./alarms_logs.api"
import { SensorConfiguredDTO } from "./alarms_logs.interfaces"

interface InitialState {
  loading: boolean
  count: number
  sensorsConfig: SensorConfiguredDTO[]
}

const initialState: InitialState = {
  loading: false,
  count: 0,
  sensorsConfig: [],
}

export const alarmsLogsSlice = createSlice({
  name: "alarmsLogs",
  initialState,
  reducers: {
    alarmLogResetCount: (state) => {
      state.count = 0
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(alarmLogRequest.pending, (state) => {
      state.loading = true
    })
    builder.addCase(alarmLogRequest.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(alarmLogRequest.fulfilled, (state, action) => {
      state.loading = false
      state.count = action.payload
    })
    builder.addCase(fetchConfiguredSensors.pending, (state) => {})
    builder.addCase(fetchConfiguredSensors.rejected, (state, action) => {})
    builder.addCase(fetchConfiguredSensors.fulfilled, (state, action) => {
      state.sensorsConfig = action.payload
    })
  },
})

export const { alarmLogResetCount } = alarmsLogsSlice.actions

export default alarmsLogsSlice.reducer
