import { configureStore } from "@reduxjs/toolkit"
import modalReducer from "./features/modal/modal.slice"

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist"

import { persistedReducer } from "./persister"

export const store = configureStore({
  reducer: {
    persistedReducer,
    modalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      WebSocket: false,
    }),
})

export const persisted = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
