import styled, { keyframes } from "styled-components"

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const RotateAnimation = styled.div`
  animation: ${spin} 1s linear infinite;

  svg {
    display: block;
  }
`

export const Container = styled.div<{ size: number }>`
  height: ${(props) => props.size + "px"};
  width: ${(props) => props.size + "px"};
`
