import { Container, ContainerHourMeter, ContainerHourMeterRMS2 } from "./styles"

import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import Boost from "highcharts/modules/boost"
import { useHourMeterChartController } from "../../../controllers/useHourMeterChart"
import LineChart from "../Linechart"
import { HourmeterController } from "../../../entities/hourMeterDTO"
import { HourMeterTableComponent } from "./components/HourMeterTable"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/no-data-to-display")(Highcharts)
require("highcharts/modules/heatmap")(Highcharts)
require("highcharts/modules/draggable-points")(Highcharts)
require("highcharts/modules/annotations")(Highcharts)
Boost(Highcharts)

export const HourMeterChart = () => {
  const {
    chartHourMeterRef,
    graphicOptionsHourMeter,
    thresholdOffHourMeter,
    thresholdOperingHourMeter,
    startDate,
    endDate,
    dataToPlot,
    generalData,
  } = useHourMeterChartController() as HourmeterController

  return (
    <Container>
      <ContainerHourMeterRMS2>
        <LineChart />
      </ContainerHourMeterRMS2>
      <ContainerHourMeter>
        <HighchartsReact
          highcharts={Highcharts}
          ref={chartHourMeterRef as React.Ref<HighchartsReact.RefObject>}
          options={{
            ...graphicOptionsHourMeter,
          }}
        />

        <HourMeterTableComponent
          thresholdOffHourMeter={thresholdOffHourMeter}
          thresholdOperingHourMeter={thresholdOperingHourMeter}
          startDate={startDate}
          endDate={endDate}
          dataToPlot={dataToPlot}
          generalData={generalData}
        />
      </ContainerHourMeter>
    </Container>
  )
}
