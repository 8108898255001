import axios from "axios"
import { apiCall } from "../../../utils/axios"
import { Result, left, right } from "../../../utils/either/result"

export interface CompanyApiDTO {
  id: number
  addressId: number
  guid: string
  name: string
  documentNumber: string
  documentType: number
  occupationArea: string
  stateRegistration: string
  avatarUrl: string | null
  brokerUsername: string | null
  brokerPass: string | null
  brokerHost: string | null
  brokerPort: string | null
  brokerProtocol: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export const callApiGetAllCompanies = async (): Promise<
  Result<Error, CompanyApiDTO[]>
> => {
  const URL = `${process.env.REACT_APP_API_COMPANIES}`

  const api = apiCall()

  try {
    const result = await api.get<CompanyApiDTO[]>(URL)
    return right(result.data)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400)
        return left(Error("Erro ao buscar empresas!"))

      if (error.response?.status === 401)
        return left(Error("Você não tem permissão para completar essa ação!"))

      if (error.response?.status === 404)
        return left(Error("O recurso não foi encontrado!"))

      return left(Error("Erro ao buscar empresas!"))
    }

    return left(Error("Erro ao buscar empresas!"))
  }
}
