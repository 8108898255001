import { FC } from "react"
import * as S from "./styles"
import { useTableSensorsController } from "./controller/useTableSensorsController"

export const TableSensors: FC = () => {
  const { sensors, getCheckboxState, handleChangeCheckbox, getNameSensor } =
    useTableSensorsController()

  return (
    <S.Table>
      <S.TableHeader>
        <S.HeaderRow>
          <S.SensorHeader>Sensor</S.SensorHeader>
          <S.ServiceHeader colSpan={50}>Serviços</S.ServiceHeader>
        </S.HeaderRow>
      </S.TableHeader>

      <S.TableBody>
        {sensors.map(
          (sensor) =>
            sensor.services.length > 0 && (
              <S.Row key={sensor.mac}>
                <S.SensorName>
                  {getNameSensor(sensor.name, sensor.serialNumber)}
                </S.SensorName>
                {sensor.services.map((service) => {
                  if (!service.axis) {
                    return (
                      <S.ContainerService key={service.name}>
                        <S.Label>
                          <S.CheckboxService
                            type='checkbox'
                            value={service.name}
                            checked={getCheckboxState(
                              sensor.mac,
                              service.name,
                              undefined
                            )}
                            onChange={() =>
                              handleChangeCheckbox(
                                sensor.mac,
                                service.name,
                                undefined
                              )
                            }
                          />
                          <span>{service.name.toUpperCase()}</span>
                        </S.Label>
                      </S.ContainerService>
                    )
                  }

                  return service.axis.map((ax) => (
                    <S.ContainerService key={service.name + ax}>
                      <S.Label>
                        <S.CheckboxService
                          type='checkbox'
                          value={service.name}
                          checked={getCheckboxState(
                            sensor.mac,
                            service.name,
                            ax
                          )}
                          onChange={() =>
                            handleChangeCheckbox(sensor.mac, service.name, ax)
                          }
                        />
                        <span>{`${service.name.toUpperCase()}/${ax.toUpperCase()}`}</span>
                      </S.Label>
                    </S.ContainerService>
                  ))
                })}
              </S.Row>
            )
        )}
      </S.TableBody>
    </S.Table>
  )
}
