import { createSlice } from "@reduxjs/toolkit"
import { InitialState } from "./dashboard.interfaces"
import {
  deleteAnalytics,
  fetchDashboards,
  postAnalytics,
  postDashboard,
  deleteDashboards,
  editDashboards,
} from "./dashboard.api"
import { Profile } from "../user/user.interfaces"
import { createChartDataFromAnalytics } from "./dashboard.utilities"

const initialState: InitialState = {
  data: [],
  error: null,
  status: "not started",
  openDashboard: null,
  charts: [],
  userProfile: null,
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboardState: (state) => {
      state.data = []
      state.status = "not started"
      state.error = null
      state.openDashboard = null
      state.charts = []
    },
    resetDashboardAction: (state) => {
      state.status = "idle"
    },
    resetDashboardError: (state) => {
      state.status = "idle"
      state.error = null
    },
    setInitialDashboard: (
      state,
      action: {
        type: string
        payload: {
          userProfile: Profile
        }
      }
    ) => {
      state.userProfile = action.payload.userProfile

      if (state.data.length <= 0) {
        state.openDashboard = null
        return
      }

      state.openDashboard = state.data[0]

      state.charts = createChartDataFromAnalytics(
        state.openDashboard.analytics,
        state.userProfile
      )
    },
    updateOpenDashboard: (
      state,
      action: {
        type: string
        payload: {
          dashboardIndex: number
        }
      }
    ) => {
      const dashboard = state.data[action.payload.dashboardIndex]

      if (dashboard === undefined) {
        console.error("Invalid dashboard index!")
        return
      }

      state.openDashboard = dashboard

      const analytics = state.openDashboard.analytics
      const profile = state.userProfile

      if (!profile) {
        console.error("Not found user profile data!")
        return
      }

      state.charts = createChartDataFromAnalytics(analytics, profile)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDashboard.pending, (state) => {
        state.status = "loading"
      })
      .addCase(postDashboard.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
      .addCase(postDashboard.fulfilled, (state, action) => {
        state.status = "created"
        state.data.push(action.payload)
        state.openDashboard = action.payload

        state.openDashboard.analytics = []
        state.charts = []
      })
      .addCase(fetchDashboards.pending, (state) => {
        state.status = "loading"
        state.data = []
        state.error = null
      })
      .addCase(fetchDashboards.fulfilled, (state, action) => {
        state.status = "fetched"
        state.data = action.payload
        state.error = null

        if (action.payload.length <= 0) return
        if (!state.userProfile) return

        state.openDashboard = action.payload[0]
        state.charts = createChartDataFromAnalytics(
          action.payload[0].analytics,
          state.userProfile
        )
      })
      .addCase(fetchDashboards.rejected, (state, action) => {
        state.status = "failed"
        state.data = []
        state.error = action.error
      })
      .addCase(postAnalytics.fulfilled, (state, action) => {
        state.status = "created"

        const dashboardIndex = state.data.findIndex(
          (dashboard) => dashboard.id === action.payload.dashboardId
        )

        if (dashboardIndex === -1) {
          console.error("Dashboard not found.")
          return
        }

        const dashboard = state.data[dashboardIndex]

        dashboard.analytics.push(action.payload)

        state.openDashboard = state.data[dashboardIndex]

        const analytic = action.payload
        const profile = state.userProfile

        if (!profile) {
          console.error("Not user profile found!")
          return
        }

        state.charts.push(...createChartDataFromAnalytics([analytic], profile))
      })
      .addCase(postAnalytics.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteAnalytics.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteAnalytics.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
      .addCase(deleteAnalytics.fulfilled, (state, action) => {
        state.status = "deleted"

        const dashboardIndex = state.data.findIndex(
          (dashboard) => dashboard.id === action.payload.dashboardId
        )

        if (dashboardIndex === -1) {
          console.error("Dashboard not found.")
          return
        }

        const analyticIndex = state.data[dashboardIndex].analytics.findIndex(
          (analytic) => analytic.id === action.payload.analyticId
        )

        if (analyticIndex === -1) {
          console.error("Analytic not found.")
          return
        }

        state.data[dashboardIndex].analytics.splice(analyticIndex, 1)
        state.openDashboard = state.data[dashboardIndex]

        state.charts = state.charts.filter(
          (chart) => chart.id !== action.payload.analyticId
        )
      })
      .addCase(deleteDashboards.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteDashboards.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
      .addCase(deleteDashboards.fulfilled, (state, action) => {
        state.status = "deleted"

        const dashboardIndex = state.data.findIndex(
          (dashboard) => dashboard.id === action.payload.dashboardId
        )

        if (dashboardIndex === -1) {
          console.error("Dashboard not found.")
        }

        state.data.splice(dashboardIndex, 1)

        if (state.data.length <= 0) {
          state.openDashboard = null
          state.charts = []
          return
        }

        state.openDashboard = state.data[0]

        const analytics = state.openDashboard.analytics
        const profile = state.userProfile

        if (!profile) {
          console.error("Not found user profile data!")
          return
        }

        state.charts = createChartDataFromAnalytics(analytics, profile)
      })
      .addCase(editDashboards.pending, (state) => {
        state.status = "loading"
      })
      .addCase(editDashboards.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
      .addCase(editDashboards.fulfilled, (state, action) => {
        state.status = "edited"
        const dashboardIndex = state.data.findIndex(
          (dashboard) => dashboard.id === action.payload.id
        )

        if (dashboardIndex !== -1) {
          state.data[dashboardIndex].layout = action.payload.layout
          state.data[dashboardIndex].name = action.payload.name

          state.openDashboard!.layout = action.payload.layout
          state.openDashboard!.name = action.payload.name
        } else {
          console.error("Dashboard not found.")
        }
      })
  },
})

export const {
  setInitialDashboard,
  resetDashboardState,
  resetDashboardAction,
  resetDashboardError,
  updateOpenDashboard,
} = dashboardSlice.actions

export default dashboardSlice.reducer
