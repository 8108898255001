import styled from "styled-components"

export const Container = styled.select`
  position: relative;
  font-family: Arial;

  width: 400px;
  height: 56px;
  border: 1px solid #757575;
  border-radius: 4px;

  padding-left: 10px;
  padding-right: 10px;

  margin-top: 6px;
  margin-bottom: 2px;

  select {
    font-size: 16px;

    width: 100%;
    height: 56px;

    outline: none;
    border: none;
    background-color: transparent;
  }

  &:focus {
    outline-color: #3498db;
  }
`
