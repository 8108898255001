import { Dialog } from "@mui/material"
import { Sale } from "../../hooks/entities/sale"
import {
  Container,
  Description,
  Product,
  Info,
  Buttons,
  ButtonRed,
  ButtonGreen,
} from "./styles"

interface ModalConfirmSaleProps {
  open: boolean
  data: Sale
  disabled: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ModalConfirmSale = ({
  open,
  disabled,
  onClose,
  data,
  onConfirm,
}: ModalConfirmSaleProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Container>
        <h3>Confirmar Venda?</h3>

        <Info>
          <h4>{data.company}</h4>
          <Description>{data.description}</Description>

          <Product>
            {data.amount + " un - "}
            {data.product}
          </Product>
        </Info>

        <Buttons>
          <ButtonRed onClick={onClose}>Não</ButtonRed>

          {disabled ? (
            <ButtonGreen onClick={onConfirm} disabled={disabled}>
              Carregando...
            </ButtonGreen>
          ) : (
            <ButtonGreen onClick={onConfirm} disabled={disabled}>
              Sim
            </ButtonGreen>
          )}
        </Buttons>
      </Container>
    </Dialog>
  )
}
