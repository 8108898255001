import styled from "styled-components"

export const Container = styled.select`
  position: relative;

  font-size: 14px;
  font-family: Arial;

  background-color: white;

  height: 42px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;

  padding-left: 10px;
  padding-right: 10px;

  color: ${(props) => (props.value === 0 ? "#8d8d8d" : "black")};

  option {
    color: black;
  }
`

export const DefaultOption = styled.option`
  color: #a9a9ac;
`
