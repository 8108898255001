import { Tooltip } from "../../Tooltip"
import { IconAndDescriptionContainer } from "../styles"
import { BsClock } from "react-icons/bs"

const TimeActive = ({
  formatedLifetime,
  iconSize,
}: {
  formatedLifetime: string
  iconSize: number
}): JSX.Element => {
  if (!formatedLifetime) return <IconAndDescriptionContainer />

  return (
    <Tooltip title={"Tempo Ativo"}>
      <IconAndDescriptionContainer>
        <BsClock size={iconSize} />
        <p>{formatedLifetime}</p>
      </IconAndDescriptionContainer>
    </Tooltip>
  )
}

export default TimeActive
