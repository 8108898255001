import { FC } from "react"
import * as S from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { Button } from "../../../Buttons"
import { SelectPeriod } from "../../../SelectPeriod"
import { TitleInputText } from "../../../TitleInputText"

export const AnalyticEdit: FC = () => {
  const { modalAnalyticConfig, buttons } = useAppTranslate()

  const handleSelectPeriod = (value: number) => {}

  return (
    <>
      <S.ModalBody>
        <TitleInputText
          title={modalAnalyticConfig.graphic_title}
          placeholder={modalAnalyticConfig.graphic_title_text}
          type='text'
          name='titleAnalytic'
        />

        <SelectPeriod handleOnChange={handleSelectPeriod} />
      </S.ModalBody>

      <S.ModalFooter>
        <Button label={buttons.save} onClick={() => console.log("sensors")} />
      </S.ModalFooter>
    </>
  )
}
