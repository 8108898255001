import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 300px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FieldTitle = styled.div<{ disabled: boolean }>`
  font-weight: bold;
  color: ${(props) => (props.disabled ? "grey" : "unset")};
`

export const FieldAndInputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 300px;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 14px;
`

export const CentralizeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`
