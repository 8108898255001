import CircularProgress from "@material-ui/core/CircularProgress"

export const ButtonConfirmController = () => {
  const returnStatus = (
    laoding: boolean,
    permission: string
  ): {
    cursor: string
    disabled: boolean
    icon?: JSX.Element
  } => {
    if (laoding) {
      return {
        cursor: "progress",
        disabled: true,
        icon: <CircularProgress size={17} />,
      }
    } else {
      return {
        cursor: "pointer",
        disabled: permission === "",
      }
    }
  }

  return {
    returnStatus,
  }
}
