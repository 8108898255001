import { FC } from "react"
import * as S from "./styles"

import { MdClose } from "react-icons/md"

interface CloseModalButtonProps {
  onClick: () => void
}

export const CloseModalButton: FC<CloseModalButtonProps> = ({ onClick }) => {
  return (
    <S.Button onClick={onClick}>
      <MdClose />
    </S.Button>
  )
}
