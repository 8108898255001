export const accumulatedChart = () => ({
  credits: {
    enabled: false,
  },
  lang: {
    noData: "Não há dados para serem mostrados para os sensores selecionados.",
    loading: "Carregando...",
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "30px",
      color: "#303030",
    },
  },
  chart: {
    height: "650px",
    type: "column",
  },
  title: {
    text: "",
  },

  tooltip: {
    pointFormat:
      '<span style="color:black">{series.name}</span>: <b> {point.hours} Horas</b> ({point.percentage:.0f} %)<br/>',
    shared: true,
    style: {
      fontSize: "12px",
    },
  },
  plotOptions: {
    column: {
      stacking: "percent",
    },
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  accessibility: {
    enabled: false,
  },
  stockTools: {
    gui: {
      enabled: false,
    },
  },
  legend: {
    enabled: true,
    align: "left",
    verticalAlign: "bottom",
    itemStyle: {
      lineHeight: "14px",
      fontSize: "10px",
      fontWeight: "bold",
    },
  },

  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "printChart",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
})
