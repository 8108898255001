import styled from "styled-components"

const BaseIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px;
  border-style: solid;
  border-radius: 4px;

  min-width: 44px;
  min-height: 44px;

`

export const PrimaryIconButton = styled(BaseIconButton)`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.gray._50};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.darker};
    border-color: ${(props) => props.theme.palette.primary.darker};
  }

  // On click (mobile)
  &:active {
    background-color: ${(props) => props.theme.palette.sucess.darker};
    border-color: ${(props) => props.theme.palette.sucess.darker};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.primary.darkest};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray._400};
    border-color: ${(props) => props.theme.palette.gray._400};
    color: ${(props) => props.theme.palette.gray._500};
  }
`

export const SecondaryIconButton = styled(BaseIconButton)`
  background-color: ${(props) => props.theme.palette.gray._50};
  border-color: ${(props) => props.theme.palette.gray._300};
  color: ${(props) => props.theme.palette.gray._800};

  &:hover {
    background-color: ${(props) => props.theme.palette.gray._100};
    border-color: ${(props) => props.theme.palette.gray._300};
  }

  // On click (mobile)
  &:active {
    background-color: ${(props) => props.theme.palette.gray._300};
    border-color: ${(props) => props.theme.palette.gray._300};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.gray._100};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray._50};
    border-color: ${(props) => props.theme.palette.gray._300};
    color: ${(props) => props.theme.palette.gray._500};
  }
`

export const DestructiveIconButton = styled(BaseIconButton)`
  background-color: ${(props) => props.theme.palette.error.main};
  border-color: ${(props) => props.theme.palette.error.main};
  color: ${(props) => props.theme.palette.gray._50};

  &:hover {
    background-color: ${(props) => props.theme.palette.error.darker};
    border-color: ${(props) => props.theme.palette.error.darker};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.error.main};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray._400};
    color: ${(props) => props.theme.palette.gray._500};
    border-color: ${(props) => props.theme.palette.gray._400};
  }
`
