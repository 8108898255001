import { FC } from "react"
import { Error } from "../Error"
import { Loading } from "../Loading"
import { useSensorAndServicesController } from "./controller/useSensorAndServicesController"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { BackButton, Button } from "../../../Buttons"
import { TabBarAnalyticModal } from "../../../TabBarAnalyticModal"
import { TableSensors } from "./components/TableSensors"

import * as S from "./styles"

export const SensorAndServices: FC = () => {
  const {
    isLoading,
    hasError,
    sensorsConfig,
    handleCloseModal,
    handleBackModal,
    handleCreateAnalytic,
    buttonIsDisabled,
  } = useSensorAndServicesController()

  const { modalAnalyticConfig, buttons } = useAppTranslate()

  if (hasError) {
    return (
      <Error
        errorMessage={modalAnalyticConfig.error}
        labelBackButton={buttons.back}
        onBack={handleCloseModal}
      />
    )
  }

  if (isLoading) {
    return <Loading loadingMessage={modalAnalyticConfig.loading} />
  }

  return (
    <>
      <TabBarAnalyticModal selected='TableServices' />

      {sensorsConfig.length !== 0 ? (
        <S.Container>
          <TableSensors />
        </S.Container>
      ) : (
        <S.Message>
          {modalAnalyticConfig.no_sensor_with_this_algorithm_available}
        </S.Message>
      )}

      <S.Buttons>
        <BackButton label={buttons.back} onClick={handleBackModal} />
        <Button
          label={buttons.finish}
          onClick={handleCreateAnalytic}
          disabled={buttonIsDisabled()}
        />
      </S.Buttons>
    </>
  )
}
