import { createSlice } from "@reduxjs/toolkit"
import { Product } from "./products.interfaces"
import { fetchProducts } from "./produtcs.api"

interface InitialState {
  loading: boolean
  error: string | null
  products: Product[]
}

const initialState: InitialState = {
  loading: false,
  error: null,
  products: [],
}

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? ""
    })
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.loading = false
      state.products = action.payload
    })
  },
})

export default productsSlice.reducer
