/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { darken } from "polished"
import PropTypes from "prop-types"

export default function IconButton({
  name,
  icon,
  widthButton,
  backgroundColor,
  justifyButton,
  textColor,
  heightButton,
  onClick,
  fullWidth,
  fontSize,
  disabled,
}: {
  name: any
  icon: any
  widthButton: any
  backgroundColor: any
  justifyButton: any
  textColor: any
  heightButton: any
  onClick: any
  fullWidth: any
  fontSize: any
  disabled: any
}) {
  const useStyles = makeStyles({
    button: {
      background: backgroundColor,
      margin: "0px",
      marginTop: "5px",
      marginBottom: "5px",
      display: "flex",
      justifyContent: justifyButton,
      height: heightButton,
      fontSize: "12px",

      "&,span": {
        color: `${textColor} !important`,
      },

      "&:hover": {
        background: darken(0.1, backgroundColor),
      },
    },
  })

  const classes = useStyles()

  return (
    <div style={{ width: widthButton }}>
      <Button
        onClick={onClick}
        className={classes.button}
        fullWidth={fullWidth}
        variant='contained'
        disabled={disabled}
      >
        {name}
        {icon}
      </Button>
    </div>
  )
}

IconButton.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
  widthButton: PropTypes.string,
  backgroundColor: PropTypes.string,
  justifyButton: PropTypes.string,
  textColor: PropTypes.string,
  heightButton: PropTypes.string,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
}

IconButton.defaultProps = {
  name: "IconButton",
  icon: <></>,
  widthButton: "180px",
  backgroundColor: "#65bcda",
  justifyButton: "spece-around",
  textColor: "#fff",
  heightButton: "60px",
  fullWidth: true,
  fontSize: "16px",
  disabled: false,
}
