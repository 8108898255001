import styled from "styled-components"

export const Header = styled.header`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  @media (max-width: 736px) {
    padding: 60px 0 10px 0;
  }
`

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const SearchInput = styled.input`
  height: 40px;

  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;

  padding-left: 10px;
`

export const Collectors = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;

  h1 {
    margin-top: 50px;
  }

  span,
  td,
  tr {
    z-index: 0;
    font-size: 16px;
  }

  th {
    z-index: 1;
    font-size: 16px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  #selectAll {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  #download {
    display: flex;
    color: #fff;
    background: #65bcda;
    height: 30px;
    width: 120px;
    border: none;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  ul {
    width: 100%;
    height: 600px;
    border: 1px solid silver;
    border-radius: 6px;
    list-style-type: none;
    overflow: auto;

    .checkboxContainer {
      width: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;

      @media (max-width: 500px) {
        width: 80px;
      }
    }

    li {
      padding: 10px;
      border-bottom: 1px solid silver;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      size: 16px;
      @media (max-width: 500px) {
        font-size: 13px;
      }

      &:hover {
        background: #e0e0e0;
      }
      span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      strong {
        color: #65bcda;
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: #2b8ca8;
        }
      }
    }
  }
`

export const SelectButton = styled.button<{ selected: boolean }>`
  font-size: 18px;
  font-weight: 500;
  text-align: right;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${(props) => (props.selected ? "#65bcda" : "#9e9e9e")};
  background: none;
  border: none;

  border-bottom: 3px solid
    ${(props) => (props.selected ? "#65bcda" : "#9e9e9e")};

  padding: 7px;

  margin-bottom: 5px;

  transition: 0.2s ease-in-out;

  &:hover {
    color: #65bcda;
    border-bottom: 3px solid #65bcda;
  }
`

export const DatesPicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  z-index: 2;

  input {
    width: 110px;
    display: flex;
    align-items: center;
    height: 40px;
    text-align: center;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 8%;
    outline: none;
    transition: 0.2s ease-in-out;
    background: none;
  }

  .react-datepicker {
    font-size: 13px;
  }

  .react-datepicker__header {
    padding-top: 0.8em;
  }

  .react-datepicker__month {
    margin: 0.4em 13px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }

  .react-datepicker__current-month {
    font-size: 13px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 13px;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 13px;
  }
`

export const Selects = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 12px;
  margin-bottom: 12px;

  @media (max-width: 1250px) {
    display: flex;
    flex-wrap: wrap;
  }

  #disconsider {
    display: flex;
    color: #9e9e9e;
    background: none;
    border: none;
    text-align: right;
    align-items: center;
    border-bottom: 3px solid #9e9e9e;
    padding: 7px;
    justify-content: space-between;
    transition: 0.2s ease-in-out;
    &:hover {
      border-bottom: 2px solid black;
      color: black;
    }
  }
`

export const Active = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`

export const Selected = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ul {
    width: 100%;
    height: 300px;
  }
`

export const ClearFilterBTN = styled.button`
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #2196f3;
  color: white;
  font-weight: bold;

  height: 42px;
  width: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #1273c1;
  }

  &:focus {
    outline: none;
    border: 2px solid black;
  }
`

export const UserSelectionContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;

  align-items: center;

  @media (max-width: 700px) {
    /* display: flex; */
    flex-wrap: wrap;
  }
`
