import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Border = styled.div`
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`
