import styled from "styled-components"

export const FloatingButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 56px;
  height: 56px;
  background-color: #2f9bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  &:hover {
    transition: background-color 0.4s ease;
    background-color: #d4d4d4;
  }
`
