import { createSlice } from "@reduxjs/toolkit"
import { LastConfigDTO } from "./historicalAnalysis.interaface"

interface InitialState {
  analyticsMetadata: {}
  series: []
  lastConfig: LastConfigDTO | null
}

const initialState: InitialState = {
  analyticsMetadata: {},
  series: [],
  lastConfig: null,
}

export const historicalAnalysisSlice = createSlice({
  name: "historicalAnalysis",
  initialState,
  reducers: {
    setAnalyticsMetadata: (state, action) => {
      state.analyticsMetadata = action.payload
    },
    setSeries: (state, action) => {
      state.series = action.payload
    },
    setLastConfig: (state, action) => {
      state.lastConfig = action.payload
    },
    resetLastConfig: (state) => {
      state.lastConfig = null
    },
  },
})

export const {
  setAnalyticsMetadata,
  setSeries,
  setLastConfig,
  resetLastConfig,
} = historicalAnalysisSlice.actions

export default historicalAnalysisSlice.reducer
