/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { GatewayInfoDTO } from "../entities/gatewayInfoDTO"
import { GatewayLogDTO } from "../entities/gatewaysLogDTO"
import { useAppSelector } from "../../../store/hooks"

interface GatewaysListControllerDTO {
  gateways: GatewayInfoDTO[]
}

interface GatewayInfoByMacDTO {
  [mac: string]: GatewayInfoDTO
}

export const useGatewaysListController = (): GatewaysListControllerDTO => {
  const [gateways, setGateways] = useState<GatewayInfoDTO[]>([])

  const gatewaysByMac = useRef<GatewayInfoByMacDTO>({})

  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)

  const provisionedGateways = useAppSelector(
    (state) => state.persistedReducer.gateways.gateways
  )

  const getWebsocketEventName = (bleHdrMac: string) => {
    return `WS2CLIENT/GATEWAY_HEALTH/${bleHdrMac}`
  }

  useEffect(() => {
    if (provisionedGateways.length === 0) return

    updateGatewaysListWithProvisioned()
    setGateways(gatewaysInfoArray())
  }, [provisionedGateways])

  useEffect(() => {
    if (!socket) return

    if (provisionedGateways.length === 0) return

    for (let index = 0; index < provisionedGateways.length; index++) {
      const gateway = provisionedGateways[index]

      const webSocketGatewaysLogEvent = getWebsocketEventName(gateway.bleHdrMac)

      socket.on(webSocketGatewaysLogEvent, (data: GatewayLogDTO) => {
        updateGatewaysListWithWebSocketLog(data)
        setGateways(gatewaysInfoArray())
      })
    }

    return function cleanup() {
      for (let index = 0; index < provisionedGateways.length; index++) {
        const collector = provisionedGateways[index]
        const webSocketGatewaysLogEvent = getWebsocketEventName(
          collector.bleHdrMac
        )

        socket.removeListener(webSocketGatewaysLogEvent)
      }
    }
  }, [socket])

  const updateGatewaysListWithProvisioned = (): void => {
    for (const provisionedGateway of provisionedGateways) {
      const mac = provisionedGateway.bleHdrMac

      if (gatewaysByMac.current[mac] === undefined) {
        gatewaysByMac.current[mac] = {
          name: provisionedGateway.name,
          mac: provisionedGateway.bleHdrMac,
          ewMac: provisionedGateway.ewMac,
          id_mqqt: provisionedGateway.id,
          rssi: null,
          ssid: null,
          ip_wifi: null,
          ip_eth: null,
          version: null,
          dataRate: null,
          time: null,
          lifetime: null,
        }
      } else {
        gatewaysByMac.current[mac].name = provisionedGateway.name
        gatewaysByMac.current[mac].mac = provisionedGateway.bleHdrMac
        gatewaysByMac.current[mac].ewMac = provisionedGateway.ewMac
        gatewaysByMac.current[mac].id_mqqt = provisionedGateway.id
      }
    }
  }

  const updateGatewaysListWithWebSocketLog = (data: GatewayLogDTO): void => {
    const mac = data.mac

    if (gatewaysByMac.current[mac] === undefined) return

    gatewaysByMac.current[mac].rssi = data.rssi
    gatewaysByMac.current[mac].ssid = data.ssid
    gatewaysByMac.current[mac].ip_wifi = data.ip_wifi
    gatewaysByMac.current[mac].ip_eth = data.ip_eth
    gatewaysByMac.current[mac].version = data.version
    gatewaysByMac.current[mac].dataRate = data.dataRate
    gatewaysByMac.current[mac].lifetime = data.lifetime
    gatewaysByMac.current[mac].time = data.time
  }

  const gatewaysInfoArray = (): GatewayInfoDTO[] => {
    const macs = Object.keys(gatewaysByMac.current)

    const gateways: GatewayInfoDTO[] = []

    for (const mac of macs) gateways.push(gatewaysByMac.current[mac])

    return gateways
  }

  return {
    gateways,
  }
}
