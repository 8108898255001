import theme from "../../../styles/theme"
import { formatedDate } from "../../../utils/date"

const getHeader = (title: string) => {
  return `<b>${title}</b> </br>`
}

const getDate = (date: number | undefined) => {
  if (!date) return ""
  return `${formatedDate(date)} </br>`
}

export function barChartTooltipFormater(
  this: Highcharts.TooltipFormatterContextObject,
  tooltip: Highcharts.Tooltip
) {
  const header = getHeader(this.point.name)

  const custom = (this.point as unknown as { custom: { time: number } }).custom

  if (!custom) {
    return header
  }

  const date = getDate(custom.time)

  return `
    ${header}
    ${date}
  `
}

export const barChartOptions = ({
  noDataMessage,
  loadingMessage,
}: {
  noDataMessage: string
  loadingMessage: string
}): Highcharts.Options => {
  return {
    title: {
      text: "",
    },

    lang: {
      noData: noDataMessage,
      loading: loadingMessage,
    },

    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
        color: theme.colors.black,
      },
    },

    chart: {
      type: "column",
      events: {
        load: function () {
          this.showLoading()
        },
      },
      panning: {
        enabled: true,
        type: "xy",
      },
      panKey: "shift",
      style: {
        fontFamily: "Roboto",
        fontSize: "12px",
      },
    },

    time: {
      useUTC: false,
    },

    accessibility: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: "Sensores",
        style: {
          fontSize: "12px",
        },
      },
      categories: [
        "Sensor1",
        "Sensor2",
        "Sensor3",
        "Sensor4",
        "Sensor5",
        "Sensor6",
      ],
    },

    yAxis: {
      title: {
        text: "Alarmes",
        style: {
          fontSize: "12px",
        },
      },
      stackLabels: {
        enabled: false,
      },
    },

    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: "transparent",
            color: theme.colors.black,
          },
        },
      },
    },

    legend: {
      enabled: true,
      itemStyle: {
        lineHeight: "16px",
        fontSize: "12px",
        fontWeight: "bold",
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  }
}
