import { ReactNode, createContext, useRef, useState } from "react"
import { ContextAlarmsLogs } from "../entities/contextDTO"
import { AlarmsLogsPaginated } from "../entities/alarmsLogsPaginatedDTO"
import { useAppSelector } from "../../../store/hooks"

export const AlarmsLogsContext = createContext<ContextAlarmsLogs | null>(null)

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [
    openFilterSensorOrServiceSelection,
    setOpenFilterSensorOrServiceSelection,
  ] = useState<boolean>(false)
  const [filterSensorOrServiceSelected, setFilterSensorOrServiceSelected] =
    useState<string>("")
  const [openFilterSelection, setOpenFilterSelection] = useState<boolean>(false)
  const [filterSelected, setFilterSelected] = useState<string>("")

  const FILTER_BY_SENSOR_OR_SERVICE_OPTIONS = ["Sensor", "Serviço"]
  const SERVICES = [
    "HEALTH",
    "TEMP",
    "RMS2",
    "RMMS",
    "INCLINÔMETRO",
    "FFT",
    "ACC. PURA",
    "4T20",
    "NTC",
    "POT",
  ]

  const [alarmsLogs, setAlarmsLogs] = useState<AlarmsLogsPaginated[]>([])
  const [alarmsLogsFiltered, setAlarmsLogsFiltered] = useState<
    AlarmsLogsPaginated[]
  >([])
  const alarmsLogsFilteredRef = useRef([])
  const [sensorsProdSerialNumber, setSensorsProdSerialNumber] = useState<
    string[]
  >([])
  const alarmsLogsCount: number = useAppSelector(
    (state) => state.persistedReducer.alarmsLogs.count
  )
  const [loading, setLoading] = useState<boolean>(true)

  // pagination
  const [pagination, setPagination] = useState<number>(1)
  const PAGINATION_OFFSET: number = 20
  const [alarmsLogsPaginated, setAlarmsLogsPaginated] = useState<
    AlarmsLogsPaginated[]
  >(
    alarmsLogsFiltered.slice(
      (pagination - 1) * PAGINATION_OFFSET,
      pagination * PAGINATION_OFFSET
    )
  )
  const [openPaginationSelection, setOpenPaginationSelection] =
    useState<boolean>(false)
  const quantityOfPagesArrayRef = useRef([1])

  const defaultContext: ContextAlarmsLogs = {
    FILTER_BY_SENSOR_OR_SERVICE_OPTIONS,
    SERVICES,
    sensorsProdSerialNumber,
    setSensorsProdSerialNumber,
    openFilterSensorOrServiceSelection,
    setOpenFilterSensorOrServiceSelection,
    filterSensorOrServiceSelected,
    setFilterSensorOrServiceSelected,
    openFilterSelection,
    setOpenFilterSelection,
    filterSelected,
    setFilterSelected,
    alarmsLogsFiltered,
    setAlarmsLogsFiltered,
    alarmsLogsFilteredRef,
    alarmsLogs,
    setAlarmsLogs,
    loading,
    setLoading,
    pagination,
    setPagination,
    alarmsLogsPaginated,
    setAlarmsLogsPaginated,
    openPaginationSelection,
    setOpenPaginationSelection,
    quantityOfPagesArrayRef,
    alarmsLogsCount,
    PAGINATION_OFFSET,
  }

  return (
    <AlarmsLogsContext.Provider value={defaultContext}>
      {children}
    </AlarmsLogsContext.Provider>
  )
}
