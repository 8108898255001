import styled from "styled-components"

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
`

const BorderButton = styled(BaseButton)`
  border: 1px;
  border-style: solid;
  border-radius: 4px;

  min-width: 166px;
  min-height: 40px;

  padding-left: 16px;
  padding-right: 16px;
`

export const PrimaryButton = styled(BorderButton)`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.gray._50};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.darker};
    border-color: ${(props) => props.theme.palette.primary.darker};
  }

  // On click (mobile)
  &:active {
    background-color: ${(props) => props.theme.palette.sucess.darker};
    border-color: ${(props) => props.theme.palette.sucess.darker};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.primary.darkest};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray._400};
    border-color: ${(props) => props.theme.palette.gray._400};
    color: ${(props) => props.theme.palette.gray._500};
  }
`

export const SecondaryButton = styled(BorderButton)`
  background-color: ${(props) => props.theme.palette.gray._50};
  border-color: ${(props) => props.theme.palette.gray._300};
  color: ${(props) => props.theme.palette.gray._800};

  &:hover {
    background-color: ${(props) => props.theme.palette.gray._100};
    border-color: ${(props) => props.theme.palette.gray._300};
  }

  // On click (mobile)
  &:active {
    background-color: ${(props) => props.theme.palette.gray._300};
    border-color: ${(props) => props.theme.palette.gray._300};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.gray._100};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray._50};
    border-color: ${(props) => props.theme.palette.gray._300};
    color: ${(props) => props.theme.palette.gray._500};
  }
`

export const LinkButton = styled(BaseButton)`
  color: ${(props) => props.theme.palette.primary.main};

  border: none;
  background: none;

  &:hover {
    color: ${(props) => props.theme.palette.primary.darker};
  }

  &:focus {
    color: ${(props) => props.theme.palette.primary.darker};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.gray._500};
  }
`

export const DestructiveButton = styled(BorderButton)`
  background-color: ${(props) => props.theme.palette.error.main};
  border-color: ${(props) => props.theme.palette.error.main};
  color: ${(props) => props.theme.palette.gray._50};

  &:hover {
    background-color: ${(props) => props.theme.palette.error.darker};
    border-color: ${(props) => props.theme.palette.error.darker};
  }

  &:focus {
    background-color: ${(props) => props.theme.palette.error.main};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray._400};
    color: ${(props) => props.theme.palette.gray._500};
    border-color: ${(props) => props.theme.palette.gray._400};
  }
`
