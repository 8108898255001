/**
 * @file hdr_rqm_ack.ts
 * @description Define as constantes de ack de requisição de dados
 * RQM_ACK_NOACK: RQM nenhum ack
 * RQM_ACK_RQACPT: RQM requisição aceita pelo nRF
 * RQM_ACK_PROVOK: Dispositivo alvo provisionado
 * RQM_ACK_CFGOK: Dispositivo alvo recebeu a configuração, que talvez foi recusada
 * RQM_ACK_RSTOK: Dispositivo alvo foi resetado (Apenas reset total)
 * RQM_ACK_TIMEOUT: Dispositivo alvo não respondeu dentro do tempo limite
 */
export const HDR_RQM_ACK = {
  RQM_ACK_NOACK: 0,
  RQM_ACK_RQACPT: 1,
  RQM_ACK_PROVOK: 2,
  RQM_ACK_CFGOK: 3,
  RQM_ACK_RSTOK: 4,
  RQM_ACK_TIMEOUT: 5,
}
