import { SerializedError, createSlice } from "@reduxjs/toolkit"
import { fetchData } from "./data.api"

interface InitialState {
  status: "not started" | "idle" | "loading" | "completed" | string[]
  error: string | null | undefined | SerializedError
}

const initialState: InitialState = {
  error: null,
  status: "not started",
}

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle"
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "completed"
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

export const dataActions = dataSlice.actions

export default dataSlice.reducer
