import { createAsyncThunk } from "@reduxjs/toolkit"
import { SensorConfig, SensorProv } from "./sensors.interfaces"
import { apiCall } from "../../../utils/axios"
import { buildSensorsConfig } from "./sensors.utilities"

const ApiRoutes = {
  SensorsProv: "v1/proteu/sensors/prov/external",
  SensorsConfig: "/v1/proteu/sensors/config/external",
  UpdateSensorName: "/v1/proteu/sensors/prov/external",
}

export const fetchSensorsConfig = createAsyncThunk(
  "sensors/Config",
  async (): Promise<SensorConfig[]> => {
    const api = apiCall()
    const response = await api.get(ApiRoutes.SensorsConfig)
    const sensorsConfigured = buildSensorsConfig(response.data)
    return sensorsConfigured
  }
)

export const fetchSensorsProv = createAsyncThunk(
  "sensors/Prov",
  async (): Promise<SensorProv[]> => {
    const api = apiCall()
    const response = await api.get(ApiRoutes.SensorsProv)
    return response.data
  }
)

export const updateSensorName = createAsyncThunk(
  "sensors/updateName",
  async ({
    id,
    sensorName,
  }: {
    id: number
    sensorName: string
  }): Promise<SensorProv> => {
    const api = apiCall()
    const response = await api.put(`${ApiRoutes.UpdateSensorName}/${id}`, {
      name: sensorName,
    })
    return response.data
  }
)
