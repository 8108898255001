import { Container } from "./styles"
import { useLineChartController } from "../../../controllers/useLineChart"

import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import Boost from "highcharts/modules/boost"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/no-data-to-display")(Highcharts)
Boost(Highcharts)

const FftChart = () => {
  const { chartRef, graphicOptions } = useLineChartController()

  return (
    <Container>
      <HighchartsReact
        highcharts={Highcharts}
        ref={chartRef}
        options={{
          ...graphicOptions,
        }}
      />
    </Container>
  )
}

export default FftChart
