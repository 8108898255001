import { FC } from "react"
import { useLoginController } from "./hooks/controller"
import { LoadingSpinner } from "../../components/LoadingSpinner"

import * as S from "./styles"

export const Login: FC = () => {
  useLoginController()

  return (
    <S.Container>
      <LoadingSpinner />
    </S.Container>
  )
}
