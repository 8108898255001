import styled from "styled-components"

interface PropsContainer {
  $background: string
  $color: string
  $border: string
  $bordercolor: string
}

export const Container = styled.button<PropsContainer>`
  border: 1px solid #2f90d6;
  background: #2f90d6;
  outline: none;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  min-width: 108px;
  padding: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  &:hover {
    background: ${(props) => props.$background};
    color: ${(props) => props.$color};
    border: ${(props) => props.$border};
    border-color: ${(props) => props.$bordercolor};
  }
`
