import styled from "styled-components"

export const TextLine = styled.div`
  display: flex;
  flex-direction: row;

  p {
    font-weight: bold;
    margin-right: 4px;
  }

  p,
  span {
    font-size: 16px;
  }
`
