import { FC } from "react"
import * as S from "./styles"
import { PiCaretLeftBold } from "react-icons/pi"

interface BackButtonProps {
  label: string
  onClick: () => void
  disabled?: boolean
}

export const BackButton: FC<BackButtonProps> = ({
  onClick,
  disabled = false,
  label,
}) => {
  return (
    <S.Button onClick={onClick} disabled={disabled}>
      <PiCaretLeftBold color='white' size={20} />
      {label}
    </S.Button>
  )
}
