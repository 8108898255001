import { createAsyncThunk } from "@reduxjs/toolkit"
import { SaleRequest } from "./sales.interfaces"
import { apiCall } from "../../../utils/axios"
import axios from "axios"
import { Result, left, right } from "../../../utils/either/result"

export const createSale = createAsyncThunk(
  "sales/fetchSales",
  async (data: SaleRequest): Promise<Result<Error, null>> => {
    const api = apiCall()

    try {
      const URL = `${process.env.REACT_APP_API_SALES}`
      await api.post<SaleRequest>(URL, data)
      return right(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400)
          return left(Error("Erro ao cadastrar venda!"))

        if (error.response?.status === 401)
          return left(Error("Você não tem permissão para completar essa ação!"))

        if (error.response?.status === 404)
          return left(Error("O recurso não foi encontrado!"))

        return left(Error("Erro ao cadastrar venda!"))
      }

      return left(Error("Erro ao cadastrar venda!"))
    }
  }
)
