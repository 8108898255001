import { Tooltip } from "../../Tooltip"
import { IconAndDescriptionContainer } from "../styles"
import { BsHouseFill } from "react-icons/bs"

const Ssid = ({
  ssid,
  iconSize,
}: {
  ssid: string | null
  iconSize: number
}): JSX.Element => {
  if (!ssid) return <IconAndDescriptionContainer />

  return (
    <Tooltip title={"SSID"}>
      <IconAndDescriptionContainer>
        <BsHouseFill size={iconSize} />
        <p>{ssid}</p>
      </IconAndDescriptionContainer>
    </Tooltip>
  )
}

export default Ssid
