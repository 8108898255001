import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
`

export const InputTitleAnalytic = styled.input`
  display: flex;
  width: 100%;
  @media (max-width: 1100px) {
    width: 100%;
  }

  font-family: Verdana, sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 15px 0 20px;
  padding: 12px 0px 12px 0px;

  text-indent: 10px;
`
