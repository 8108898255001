import { FC } from "react"

import * as S from "./styles"

interface ToggleFFTProps {
  checked: boolean
  onChange: () => void
}

export const ToggleFFT: FC<ToggleFFTProps> = ({ onChange, checked }) => {
  return (
    <S.SwitchLabel>
      <S.StyledSwitch type='checkbox' checked={checked} onChange={onChange} />
      <S.Slider />
      <S.SliderThumb />
    </S.SwitchLabel>
  )
}
