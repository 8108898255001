import { apiCall } from "../../../utils/axios"

export class UpdateAllAlarmsLogsUseCase {
  async updateAll() {
    try {
      const api = apiCall()

      const URL = `${process.env.REACT_APP_API_ALARMS_LOGS_UPDATE_ALL}`
      await api.put(URL, { seenAt: new Date().toISOString() })
    } catch (error) {
      console.error("Error at GetAlarmsUsecase: " + error)
    }
  }
}
