import styled from "styled-components"

export const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  h2 {
    margin-top: 14px;
  }
`
