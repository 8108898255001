export const useReferencePOTController = () => {
  const getExtraInformationFromLS = (serviceName: string, mac: string) => {
    const extraInformationFromLS = localStorage.getItem(
      `${mac} - ${serviceName} - ExtraConfig`
    )
    if (extraInformationFromLS) {
      return JSON.parse(extraInformationFromLS)
    }
    return null
  }

  const referenceDisabled = (serviceName: string, mac: string) => {
    const extraInformationFromLS = localStorage.getItem(
      `${mac} - ${serviceName} - ExtraConfig`
    )

    if (!extraInformationFromLS) {
      return true
    }
    return false
  }

  const TRANSFORM_VARIABLE_INTO_TITLE = {
    upperLimit: "Limite superior",
    inferiorLimit: "Limite inferior",
    unitOfMeasurement: "Unidade de medida",
  }

  return {
    getExtraInformationFromLS,
    referenceDisabled,
    TRANSFORM_VARIABLE_INTO_TITLE,
  }
}
