import React from "react"
import { formatedDate } from "../../../../../../utils/date"

interface CheckboxItemProps {
  value: string
  disable: boolean
  checked: boolean
  text: string
  onChange: React.ChangeEventHandler
  lastData: string
}

const _returnLastDataDate = (lastData: string): string => {
  if (lastData !== "Sem dados" && lastData !== "") {
    return formatedDate(lastData)
  }

  return lastData
}

const CheckboxItem = ({
  lastData,
  value,
  disable,
  checked,
  text,
  onChange,
}: CheckboxItemProps) => {
  return (
    <div className='checkbox'>
      <label title={_returnLastDataDate(lastData)}>
        <input
          type='checkbox'
          value={value}
          disabled={disable}
          checked={checked}
          onChange={onChange}
        />
        <span>{text}</span>
      </label>
    </div>
  )
}

export default CheckboxItem
