import React, { useState } from "react"
import {
  Table,
  ActionIcons,
  EditIcon,
  PermissionsIcon,
  Pagination,
} from "./styles"
import useManagerUserController from "../../controllers/useManagerUser"
import { BsFileEarmarkPerson, BsFileEarmarkLock2 } from "react-icons/bs"
import { UsersApiDTO } from "../../entities/usersApiDTO"

interface UserTableProps {
  handleOpenFormEditUser: (user: UsersApiDTO) => void
  filteredUsers: UsersApiDTO[]
}

export const UserTable: React.FC<UserTableProps> = ({
  handleOpenFormEditUser,
  filteredUsers,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const { getCompanyNameById, handleOpenModalEditPermissionUser, getProfile } =
    useManagerUserController()

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem)

  const renderTable = (usersToRender: UsersApiDTO[]) => {
    return (
      <>
        {usersToRender.map((user, index) => (
          <tr key={index}>
            <td>
              <ActionIcons>
                <EditIcon
                  onClick={() => handleOpenFormEditUser(user)}
                  title='Editar dados do usuário'
                >
                  <BsFileEarmarkPerson size={22} />
                </EditIcon>

                <PermissionsIcon
                  onClick={() => handleOpenModalEditPermissionUser(user)}
                >
                  <BsFileEarmarkLock2
                    size={22}
                    title='Editar permissão do usuário'
                  />
                </PermissionsIcon>
              </ActionIcons>
            </td>
            <td>{user.firstName}</td>
            <td>{user?.lastName}</td>
            <td>{getCompanyNameById(user?.companyId)}</td>
            <td>{user?.email}</td>
            <td>{getProfile(user?.permissionLevel)}</td>
          </tr>
        ))}
      </>
    )
  }

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const changeItemsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(e.target.value))
    setCurrentPage(1)
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th />
            <th>Nome</th>
            <th>Sobrenome</th>
            <th>Empresa</th>
            <th>Email</th>
            <th>Perfil</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            renderTable(currentItems)
          ) : (
            <tr>
              <td colSpan={6}>Nenhum usuário encontrado</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination>
        <div>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <select onChange={changeItemsPerPage} defaultValue={10}>
            <option value={5}>5 por página</option>
            <option value={10}>10 por página</option>
            <option value={20}>20 por página</option>
          </select>
        </div>
        <div>
          <button
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <button
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === totalPages || totalPages === 0}
          >
            Próxima
          </button>
        </div>
      </Pagination>
    </>
  )
}
