import { useNavigate } from "react-router-dom"

export const useErrorController = () => {
  const navigate = useNavigate()

  const handleTryAgain = () => {
    navigate("/")
    window.location.reload()
  }

  return {
    handleTryAgain,
  }
}
