import { OidcClientSettings } from "oidc-client-ts"

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REDIRECT_URI,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_AUTH0_ISSUER,
  REACT_APP_AUTH0_AUTHORIZE,
  REACT_APP_AUTH0_TOKEN,
  REACT_APP_AUTH0_JWKS_URI,
  REACT_APP_AUTH0_LOGOUT,
  REACT_APP_REDIRECT_URI,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env

export const oidcClientSettingsAuth0: OidcClientSettings = {
  authority: REACT_APP_AUTH0_DOMAIN as string,
  client_id: REACT_APP_AUTH0_CLIENT_ID as string,
  redirect_uri: REACT_APP_AUTH0_REDIRECT_URI as string,
  scope: REACT_APP_AUTH0_SCOPE as string,
  metadata: {
    issuer: REACT_APP_AUTH0_ISSUER as string,
    authorization_endpoint: REACT_APP_AUTH0_AUTHORIZE as string,
    token_endpoint: REACT_APP_AUTH0_TOKEN as string,
    jwks_uri: REACT_APP_AUTH0_JWKS_URI as string,
    end_session_endpoint: REACT_APP_AUTH0_LOGOUT as string,
  },
  post_logout_redirect_uri: REACT_APP_REDIRECT_URI as string,
  extraQueryParams: {
    audience: REACT_APP_AUTH0_AUDIENCE as string,
  },
}

export const oidcClientSettingsKeycloak: OidcClientSettings = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY as string,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI as string,
}
