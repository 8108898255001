import {
  SensorsProvContainer,
  IconAndDescriptionContainer,
  TextContainer,
  InfoContainer,
} from "./styles"

import SensorId_svg from "../../../../assets/SensorID_icon.svg"

import { AccordionComponentAlarm } from "../../components/AcordionComponentAlarm"
import React, { useContext, useState } from "react"
import { DataFromApiDTO } from "../../entities/dataFromApiDTO"

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { useAlarmConfigViewModel } from "../../controllers/useAlarmConfigController"
import IconButton from "../../components/IconButton"
import { Tooltip } from "../../components/Tooltip"
import { AlarmsConfigContext } from "../../context/modelContext"
import { ContextAlarmsConfig } from "../../entities/contextDTO"
import { cleanAndPadSerialNumber } from "../../../../utils/cleanAndPadSerialNumber"

const SensorsWithAlarms = ({ options }: { options: DataFromApiDTO }) => {
  const [openAccordion, setOpenAccordion] = useState(false)
  const { handleOpenModal } = useAlarmConfigViewModel()

  const { configuredSensorsData } = useContext(
    AlarmsConfigContext
  ) as ContextAlarmsConfig

  const getSerial = (serial: string) => {
    const find = configuredSensorsData.find((sensor) =>
      sensor.productionSerialNumber.includes(serial)
    )

    if (!find) return serial

    return `S${cleanAndPadSerialNumber(find.productionSerialNumber, find.hardwareVersion)}`
  }

  return (
    <React.Fragment key={`SensorsGroup${Math.random() * 1000}`}>
      <SensorsProvContainer
        onClick={() => {
          setOpenAccordion(!openAccordion)
        }}
        openAccordion={openAccordion}
      >
        <InfoContainer>
          <Tooltip title='Nome ou Nº de série'>
            <TextContainer>{options.sensorName}</TextContainer>
          </Tooltip>
        </InfoContainer>

        <InfoContainer>
          <Tooltip title='Nº de série'>
            <IconAndDescriptionContainer>
              <img
                src={SensorId_svg}
                alt='sensor id icon'
                style={{ width: "20px" }}
              />
              <TextContainer>
                {getSerial(options.productionSerialNumber)}
              </TextContainer>
            </IconAndDescriptionContainer>
          </Tooltip>
        </InfoContainer>

        <IconButton
          name='novo alarme'
          widthButton='120px'
          heightButton='30px'
          fontSize='0.8em'
          onClick={() => {
            handleOpenModal(
              false,
              `${options.productionSerialNumber}-${options.sensorName}`
            )
          }}
        />

        <InfoContainer>
          {openAccordion ? (
            <MdKeyboardArrowUp size={22} />
          ) : (
            <MdKeyboardArrowDown size={22} />
          )}
        </InfoContainer>
      </SensorsProvContainer>
      {openAccordion ? <AccordionComponentAlarm options={options} /> : <></>}
    </React.Fragment>
  )
}

export default SensorsWithAlarms
