import * as S from "./styles"
import { FC } from "react"

import { useModalAnalyticConfigController } from "./hooks/useModalAnalyticConfigController"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { TabBarAnalyticModal } from "../../../TabBarAnalyticModal"
import { TitleInputText } from "../../../TitleInputText"
import { Title } from "../../../Title"
import { SelectPeriod } from "../../../SelectPeriod"
import { Button } from "../../../Buttons/Button"
import { AnalyticPeriod } from "../../../../pages/Dashboard/entities/DashboardContextDTO"

export const AnalyticConfig: FC = () => {
  const {
    handleUpdatePeriod,
    handleUpdateOrientation,
    handleUpdateName,
    handleUpdateType,
    handleNextModal,
    selectedPeriod,
    orientedBy,
    graphicName,
    selectedTypeChart,
    types,
  } = useModalAnalyticConfigController()

  const { modalAnalyticConfig, buttons } = useAppTranslate()

  return (
    <>
      <TabBarAnalyticModal selected='Preferences' />

      <S.Content>
        <TitleInputText
          name='titleAnalytic'
          type='text'
          title={modalAnalyticConfig.graphic_title}
          placeholder={modalAnalyticConfig.graphic_title_text}
          onChange={handleUpdateName}
        />

        <S.LabelTitleAnalytic>
          <Title label={modalAnalyticConfig.type_graphic} size='small' />
        </S.LabelTitleAnalytic>

        <S.SelectAnalyticContainer>
          {types.map((analyticType) => {
            return (
              <S.CardContainer
                key={analyticType.type}
                selected={analyticType.type === selectedTypeChart}
                onClick={() => {}}
              >
                <S.CheckboxContainer>
                  <S.InputCheckbox
                    type='radio'
                    name='select-type'
                    checked={analyticType.type === selectedTypeChart}
                    onChange={() =>
                      handleUpdateType(analyticType.type as 1 | 2 | 3)
                    }
                  />
                  <S.CardContentTitle>{analyticType.title}</S.CardContentTitle>
                </S.CheckboxContainer>

                <S.CardContent>
                  <h4>{analyticType.description}</h4>
                </S.CardContent>
              </S.CardContainer>
            )
          })}
        </S.SelectAnalyticContainer>

        <SelectPeriod
          disabled={selectedTypeChart !== 1}
          handleOnChange={handleUpdatePeriod}
        />

        <S.ContentSensorOrEntity>
          <S.LabelTitleAnalytic>
            <Title
              label={`${modalAnalyticConfig.oriented_graph} :`}
              size='small'
            />
          </S.LabelTitleAnalytic>

          <S.SelectSensorOrEntity>
            <input
              type='radio'
              name='oriented-select'
              value={"Sensor"}
              checked={orientedBy === "sensor"}
              onChange={() => handleUpdateOrientation("sensor")}
            />
            <h3>{modalAnalyticConfig.sensor}</h3>
          </S.SelectSensorOrEntity>

          <S.SelectSensorOrEntity>
            <input
              type='radio'
              name='oriented-select'
              value={"Entidade"}
              checked={orientedBy === "entity"}
              onChange={() => handleUpdateOrientation("entity")}
            />
            <h3>{modalAnalyticConfig.entity}</h3>
          </S.SelectSensorOrEntity>
        </S.ContentSensorOrEntity>

        <S.ModalFooter>
          <Button
            label={buttons.next}
            disabled={
              graphicName === "" ||
              (selectedTypeChart === 1 &&
                selectedPeriod === AnalyticPeriod.NO_SELECTED)
            }
            onClick={handleNextModal}
          />
        </S.ModalFooter>
      </S.Content>
    </>
  )
}
