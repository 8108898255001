import styled from "styled-components"

export const StyledButton = styled.button<{
  background: string
  width: string
  padding: string
  marginBottom: string
  fontSize: string
}>`
  outline: none;
  border: none;
  border-radius: 5px;

  color: #fff;

  font-weight: bold;

  background: ${(props) =>
    props.disabled ? "#8d8d8f" : props.background ?? "#3AA508"};
  width: ${(props) => props.width ?? "250px"};
  padding: ${(props) => props.padding ?? "6px"};
  margin-bottom: ${(props) => props.marginBottom ?? "10px"};
  font-size: ${(props) => props.fontSize ?? "14px"};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const TextInsideBtn = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  width: "90%";
`
