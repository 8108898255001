import styled from "styled-components"

export const SensorsProvContainer = styled.div<{
  openAccordion: boolean
}>`
  background: none;
  outline: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 5%;
  border: 1px solid #cacaca;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;

  ${(props) =>
    props.openAccordion &&
    `
    margin-bottom: 0px;
    border-bottom: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  `};
`

export const IconAndDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`
export const TextContainer = styled.span`
  margin-left: 10px;
  font-weight: bold;
`

export const InfoContainer = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;

  @media (min-width: 800px) {
    width: 10%;
  }
`
export const ResetBtn = styled.button`
  border: none;
  border-radius: 5px;
  background: #ff7b7b21;
  color: #ff7b7b;
  text-transform: uppercase;
  padding: 6px;
  &:hover {
    color: white;
    background: #ff7b7b;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`
