import { Tooltip } from "../../Tooltip"
import { IconAndDescriptionContainer } from "../styles"
import { BsArrowUp } from "react-icons/bs"

const DataRate = ({
  dataRate,
  iconSize,
}: {
  dataRate: number | null
  iconSize: number
}): JSX.Element => {
  if (!dataRate) return <IconAndDescriptionContainer />

  return (
    <Tooltip title={"Data rate"}>
      <IconAndDescriptionContainer>
        <BsArrowUp size={iconSize} />
        <p>{dataRate + " bytes/s"}</p>
      </IconAndDescriptionContainer>
    </Tooltip>
  )
}

export default DataRate
