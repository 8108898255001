import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const Title = styled.div`
  width: 400px;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    margin-top: 6px;
  }
`

export const ClearButton = styled.button`
  padding-top: 10px;

  text-decoration: none;
  border: none;
  background-color: transparent;
`
