/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from "react"
import { AlarmsConfigContext } from "../context/modelContext"
import { toast } from "react-toastify"
import { CreateAlarmUsecase } from "../useCases/createAlarmUsecase"
import { UpdateAlarmUsecase } from "../useCases/updateAlarmUsecase"
import { ContextAlarmsConfig } from "../entities/contextDTO"
import { SensorObjDTO, ServiceDTO } from "../entities/sensorObjDTO"
import { useAlarmConfigViewModel } from "./useAlarmConfigController"

export const useAlarmConfigModalViewModel = () => {
  const {
    openAlarmsConfigModal,
    setOpenAlarmsConfigModal,
    THRESHOLD_OPTIONS,
    TIME_BETWEEN_ALARMS,
    VARIABLE_TO_BE_MONITORED,
    VARIABLE_MONITORED_MAPPED_TO_API,
    VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS,
    API_MAPPED_TO_VARIABLE_MONITORED,
    CONVERT_TSAMPLE_TO_SECONDS,
    productionSerialNumbersOfConfiguredAlarms,
    DROPDOWN_NAMES,
    INPUT_NAMES,
    allProdSerialNumbersAndSensorsNameFromConfiguredSensors,
    alarmsFromUserRef,
    wasModalOpenedFromMain,
    prodSerialNumberPlusAlarm,
    validateEmail,
    configuredSensorsData,
    dropdownOpen,
    setDropdownOpen,
    dropdownSelected,
    setDropdownSelected,
    inputSelected,
    setInputSelected,
    loadingModal,
    setLoadingModal,
    prodSerialNumberToDropdown,
    setProdSerialNumberToDropdown,
    variableToBeMonitoredDropdown,
    setVariableToBeMonitoredDropdown,
  } = useContext(AlarmsConfigContext) as ContextAlarmsConfig

  const createAlarmUsecase = new CreateAlarmUsecase()
  const updateAlarmUsecase = new UpdateAlarmUsecase()
  const { getAlarm } = useAlarmConfigViewModel()
  useEffect(() => {
    const dropSelectedCopy = Object.assign({}, dropdownSelected)
    ;(dropSelectedCopy as any)[DROPDOWN_NAMES[0]] = ""
    ;(dropSelectedCopy as any)[DROPDOWN_NAMES[3]] = ""
    setDropdownSelected(dropSelectedCopy)

    const dropOpenCopy = Object.assign({}, dropdownOpen)
    dropOpenCopy[DROPDOWN_NAMES[0]] = false
    dropOpenCopy[DROPDOWN_NAMES[1]] = false
    dropOpenCopy[DROPDOWN_NAMES[3]] = false
    setDropdownOpen(dropOpenCopy)

    _populateProdSerialNumberDropdown()

    // eslint-disable-next-line
  }, [wasModalOpenedFromMain, prodSerialNumberPlusAlarm, openAlarmsConfigModal])

  useEffect(() => {
    const dropOpenCopy = Object.assign({}, dropdownOpen)
    dropOpenCopy[DROPDOWN_NAMES[3]] = false
    setDropdownOpen(dropOpenCopy)

    const dropSelectedCopy = Object.assign({}, dropdownSelected)

    ;(dropSelectedCopy as any)[DROPDOWN_NAMES[3]] = ""
    setDropdownSelected(dropSelectedCopy)

    _populateVariableMonitoredDropdown()

    // eslint-disable-next-line
  }, [(dropdownSelected as any)[DROPDOWN_NAMES[0]]])

  const handleCloseModal = (): void => {
    setOpenAlarmsConfigModal(false)
  }

  const handleDropdownOpenClick = (
    e: React.MouseEvent<HTMLElement>,
    dropName: string
  ): void => {
    e.preventDefault()
    const negateDropOpen = !dropdownOpen[dropName]
    const dropdownOpenOpts = { ...dropdownOpen, [dropName]: negateDropOpen }
    setDropdownOpen(dropdownOpenOpts)
  }

  const handleDropdownOptionSelect = (
    e: React.MouseEvent<HTMLElement>,
    dropName: string,
    value: any
  ): void => {
    e.preventDefault()
    const negateDropOpen = !dropdownOpen[dropName]
    const dropdownOpenOpts = { ...dropdownOpen, [dropName]: negateDropOpen }
    setDropdownOpen(dropdownOpenOpts)

    const dropdownSelectionOpts = { ...dropdownSelected, [dropName]: value }
    setDropdownSelected(dropdownSelectionOpts)
  }

  const handleInputOnChange = (e: any, inputName: string): void => {
    const value = e.target.value
    const inputSelectionOpts = { ...inputSelected, [inputName]: value }
    setInputSelected(inputSelectionOpts)
  }

  const onClickSaveBtn = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    setLoadingModal(true)

    const { error, message, monitoredVariable } = _buildSensorsObj()
    if (error) {
      setLoadingModal(false)
      return
    }

    _createOrUpdateAlarm(message, monitoredVariable as string)
  }

  const _buildSensorsObj = ():
    | {
        error: boolean
        message: SensorObjDTO
        monitoredVariable?: undefined
      }
    | {
        error: boolean
        message: SensorObjDTO
        monitoredVariable: string
      } => {
    const productionSerialNumberWithSensorsName: string = (
      dropdownSelected as any
    )[DROPDOWN_NAMES[0]]

    if (productionSerialNumberWithSensorsName.length === 0) {
      toast.warn("O campo de sensor precisa ser preenchido!", {
        autoClose: 2500,
      })
      return {
        error: true,
        message: {} as SensorObjDTO,
      }
    }
    const thresholdCondition: string = (dropdownSelected as any)[
      DROPDOWN_NAMES[1]
    ]
    const timeBetweenAlarms: string = (dropdownSelected as any)[
      DROPDOWN_NAMES[2]
    ]
    const variableToBeMonitored: string = (dropdownSelected as any)[
      DROPDOWN_NAMES[3]
    ]
    if (variableToBeMonitored.length === 0) {
      toast.warn("A variável a ser monitorada precisa ser preenchida!", {
        autoClose: 2500,
      })
      return {
        error: true,
        message: {} as SensorObjDTO,
      }
    }
    const firstThreshold: number = parseFloat(
      (inputSelected as any)[INPUT_NAMES[0]]
    )
    const threshold: number = parseFloat((inputSelected as any)[INPUT_NAMES[1]])
    const secondThreshold: number = parseFloat(
      (inputSelected as any)[INPUT_NAMES[2]]
    )
    const email: string = (inputSelected as any)[INPUT_NAMES[3]]

    const obj = {} as SensorObjDTO

    obj["productionSerialNumber"] =
      productionSerialNumberWithSensorsName.split("-")[0]

    const monitoringVariableMapped =
      VARIABLE_MONITORED_MAPPED_TO_API[variableToBeMonitored]

    ;(obj as any)[monitoringVariableMapped] = {}
    ;(obj as any)[monitoringVariableMapped]["threshold"] = threshold
    ;(obj as any)[monitoringVariableMapped]["higher"] =
      thresholdCondition === THRESHOLD_OPTIONS[0]
    ;(obj as any)[monitoringVariableMapped]["lower"] =
      thresholdCondition === THRESHOLD_OPTIONS[1]
    ;(obj as any)[monitoringVariableMapped]["tSample"] =
      CONVERT_TSAMPLE_TO_SECONDS[timeBetweenAlarms]
    ;(obj as any)[monitoringVariableMapped]["border"] =
      thresholdCondition === THRESHOLD_OPTIONS[2] ||
      thresholdCondition === THRESHOLD_OPTIONS[3]
    ;(obj as any)[monitoringVariableMapped]["risingEdge"] =
      thresholdCondition === THRESHOLD_OPTIONS[2]
    ;(obj as any)[monitoringVariableMapped]["fallingEdge"] =
      thresholdCondition === THRESHOLD_OPTIONS[3]
    ;(obj as any)[monitoringVariableMapped]["between"] =
      thresholdCondition === THRESHOLD_OPTIONS[4]
    ;(obj as any)[monitoringVariableMapped]["firstThreshold"] = firstThreshold
    ;(obj as any)[monitoringVariableMapped]["secondThreshold"] = secondThreshold
    ;(obj as any)[monitoringVariableMapped]["type"] = 1 // email
    ;(obj as any)[monitoringVariableMapped]["socialContact"] = email

    const { isValid, errorMsg } = _validateFields(
      (obj as any)[monitoringVariableMapped]
    )
    if (!isValid) {
      toast.warn(errorMsg, { autoClose: 2500 })
      return {
        error: true,
        message: {} as SensorObjDTO,
      }
    }

    return {
      error: false,
      message: obj,
      monitoredVariable: monitoringVariableMapped,
    }
  }

  const _validateFields = (obj: ServiceDTO) => {
    if (
      typeof obj.threshold !== "number" &&
      (obj.border || obj.higher || obj.lower)
    ) {
      return {
        isValid: false,
        errorMsg: "Todos os campos precisam ser preenchidos!",
      }
    }

    if (obj.border && !obj.risingEdge && !obj.fallingEdge) {
      return {
        isValid: false,
        errorMsg: "Todos os campos precisam ser preenchidos!",
      }
    }

    if (
      obj.between &&
      (typeof obj.firstThreshold !== "number" ||
        typeof obj.secondThreshold !== "number")
    ) {
      return {
        isValid: false,
        errorMsg: "Todos os campos precisam ser preenchidos!",
      }
    }

    if (!obj.type || !obj.socialContact || !obj.tSample) {
      return {
        isValid: false,
        errorMsg: "Todos os campos precisam ser preenchidos!",
      }
    }

    if (
      obj.between &&
      obj.firstThreshold !== undefined &&
      obj.secondThreshold !== undefined &&
      obj.firstThreshold > obj.secondThreshold
    ) {
      return {
        isValid: false,
        errorMsg:
          "Primeiro threshold precisa ser menor que o segundo threshold!",
      }
    }

    const emailsSplitted = obj.socialContact.split(";")
    for (const email of emailsSplitted) {
      const validated = validateEmail(email)
      if (!validated) {
        return {
          isValid: false,
          errorMsg: "Emails não estão escritos da maneira correta!",
        }
      }
    }

    return {
      isValid: true,
      errorMsg: "",
    }
  }

  const _createOrUpdateAlarm = (
    message: SensorObjDTO,
    monitoredVariable: string
  ) => {
    const alarmExists = productionSerialNumbersOfConfiguredAlarms.length > 0
    if (alarmExists) {
      _updateAlarm(message, monitoredVariable)
    } else {
      _createAlarm(message)
    }
  }

  const _createAlarm = async (message: SensorObjDTO) => {
    const created = await createAlarmUsecase.create({
      sensors: JSON.stringify([message]),
    })
    if (created) {
      toast.success("Alarme criado!", { autoClose: 2500 })
      handleCloseModal()
      getAlarm()
    } else {
      toast.error("Algo de errado aconteceu. Tente novamente mais tarde.", {
        autoClose: 2500,
      })
    }
    setLoadingModal(false)
  }

  const _updateAlarm = async (
    message: SensorObjDTO,
    monitoredVariable: string
  ): Promise<void> => {
    const id = alarmsFromUserRef.current.id
    const sensors = alarmsFromUserRef.current.sensors
    const sensorsJSON = JSON.parse(sensors)

    const productionSerialNumber = message["productionSerialNumber"]

    const sensorsFiltered = sensorsJSON.filter(
      (item: any) => item.productionSerialNumber === productionSerialNumber
    )
    const sensorAlreadyHasSomeAlarm = sensorsFiltered.length > 0

    if (sensorAlreadyHasSomeAlarm) {
      const sensorsUpdated = {
        ...sensorsFiltered[0],
        [monitoredVariable]: (message as any)[monitoredVariable],
      }

      const index = sensorsJSON.findIndex(
        (item: any) => item.productionSerialNumber === productionSerialNumber
      )

      sensorsJSON[index] = sensorsUpdated
    } else {
      sensorsJSON.push(message)
    }

    const updated = await updateAlarmUsecase.update(id, {
      sensors: JSON.stringify(sensorsJSON),
    })
    if (updated) {
      toast.success("Alarme adicionado!", { autoClose: 2500 })
      handleCloseModal()
      getAlarm()
    } else {
      toast.error("Algo de errado aconteceu. Tente novamente mais tarde.", {
        autoClose: 2500,
      })
    }
    setLoadingModal(false)
  }

  const _populateProdSerialNumberDropdown = (): void => {
    if (wasModalOpenedFromMain.current === true) {
      const prodSerialNumberAndSensorNameWithoutConfiguredAlarms =
        allProdSerialNumbersAndSensorsNameFromConfiguredSensors.filter(
          (item) => {
            if (
              !productionSerialNumbersOfConfiguredAlarms.includes(
                item!.split("-")[0]
              )
            ) {
              return item
            }
            return null
          }
        )
      setProdSerialNumberToDropdown(
        prodSerialNumberAndSensorNameWithoutConfiguredAlarms
      )
    } else {
      const prodSerialNumArray: string[] = []
      prodSerialNumArray.push(prodSerialNumberPlusAlarm.current)
      const dropSelectedCopy = Object.assign({}, dropdownSelected)
      ;(dropSelectedCopy as any)[DROPDOWN_NAMES[0]] =
        prodSerialNumberPlusAlarm.current
      setDropdownSelected(dropSelectedCopy)
      setProdSerialNumberToDropdown(prodSerialNumArray)
    }
  }

  const _populateVariableMonitoredDropdown = (): void => {
    const prodSerialNumberWithSensorNameSelected = (dropdownSelected as any)[
      DROPDOWN_NAMES[0]
    ]

    // get services configured from sensor (from sensors config)
    const servicesConfiguredFromSensor = configuredSensorsData
      .filter((item) => {
        if (
          item.productionSerialNumber &&
          (item.productionSerialNumber ===
            prodSerialNumberWithSensorNameSelected.split("-")[0] ||
            item.productionSerialNumber.slice(
              item.productionSerialNumber.length - 5
            ) === prodSerialNumberWithSensorNameSelected.split("-")[0])
        ) {
          return item
        }

        return null
      })
      .map((item) => {
        const services = Object.assign([], item.services)

        // add services with channels
        const channelServices = ["_4t20", "ntc", "pot"]
        channelServices.forEach((service) => {
          const index = services.indexOf(service)
          if (index !== -1) {
            services.splice(index, 1)

            const channel = (item as any)[service].channel
            if (channel === "ab") {
              services.push(`${service}_a`)
              services.push(`${service}_b`)
            } else {
              services.push(`${service}_${channel}`)
            }
          }
        })

        return services
      })
      .map((item) => {
        const newItem = Object.assign([], item)
        newItem.push("rssi")
        newItem.push("voltage")
        return newItem
      })

    if (!servicesConfiguredFromSensor[0]) {
      return
    }

    const variablesNotYetConfigured: string[] = []

    // If user has not a single alarm configured, fill everything
    if (Object.keys(alarmsFromUserRef.current).length === 0) {
      VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS.forEach((variable) => {
        if (servicesConfiguredFromSensor[0].includes(variable)) {
          variablesNotYetConfigured.push(
            (API_MAPPED_TO_VARIABLE_MONITORED as any)[variable]
          )
        }
      })
      setVariableToBeMonitoredDropdown(variablesNotYetConfigured)
      return
    }

    const sensors = JSON.parse(alarmsFromUserRef.current.sensors)

    sensors.forEach((item: any) => {
      if (
        item.productionSerialNumber ===
        prodSerialNumberWithSensorNameSelected.split("-")[0]
      ) {
        VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS.forEach((variable) => {
          if (
            !item[variable] &&
            servicesConfiguredFromSensor[0].includes(variable)
          ) {
            variablesNotYetConfigured.push(
              (API_MAPPED_TO_VARIABLE_MONITORED as any)[variable]
            )
          }
        })
        return
      }
    })

    if (variablesNotYetConfigured.length === 0) {
      VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS.forEach((variable) => {
        if (servicesConfiguredFromSensor[0].includes(variable)) {
          variablesNotYetConfigured.push(
            (API_MAPPED_TO_VARIABLE_MONITORED as any)[variable]
          )
        }
      })
    }

    setVariableToBeMonitoredDropdown(variablesNotYetConfigured)
  }

  return {
    openAlarmsConfigModal,
    handleCloseModal,
    dropdownOpen,
    dropdownSelected,
    inputSelected,
    onClickSaveBtn,
    handleDropdownOpenClick,
    handleDropdownOptionSelect,
    handleInputOnChange,
    THRESHOLD_OPTIONS,
    TIME_BETWEEN_ALARMS,
    VARIABLE_TO_BE_MONITORED,
    DROPDOWN_NAMES,
    INPUT_NAMES,
    loadingModal,
    prodSerialNumberToDropdown,
    variableToBeMonitoredDropdown,
  }
}
