import { FC } from "react"
import * as S from "./styles"
import { useAppTranslate } from "../../translate/useAppTranslate"

interface TabBarAnalyticModalProps {
  selected: "Preferences" | "TableServices"
}

export const TabBarAnalyticModal: FC<TabBarAnalyticModalProps> = ({
  selected,
}) => {
  const { modalAnalyticConfig } = useAppTranslate()

  return (
    <>
      <S.TopNavBar>
        <S.ItemTopNavBar selected={selected === "Preferences"}>
          {modalAnalyticConfig.preference}
        </S.ItemTopNavBar>
        <S.ItemTopNavBar selected={selected === "TableServices"}>
          {modalAnalyticConfig.sensor_services}
        </S.ItemTopNavBar>
      </S.TopNavBar>
    </>
  )
}
