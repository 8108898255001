/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiCall } from "../../../utils/axios"

export class UpdateAlarmUsecase {
  async update(id: number, sensorsObj: any) {
    const api = apiCall()
    const response = await api.put(
      `${process.env.REACT_APP_API_ALARMS}/${id}`,
      sensorsObj
    )

    if (response.status === 200) {
      return true
    }

    return false
  }
}
