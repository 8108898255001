import {
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material"
import styled from "styled-components"

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
  },
}))
