import * as z from "zod"

export const salesSchema = z.object({
  company: z.number().min(1, "Selecione uma empresa!"),
  product: z.number().min(1, "Selecione um produto!"),
  description: z
    .string({
      required_error: "Obrigatório adicionar uma descrição!",
    })
    .min(1, "Adicione uma descrição válida!"),
  quantity: z.coerce.number().positive("Selecione uma quantidade valida!"),
})

export type SalesSchema = z.infer<typeof salesSchema>

export const defaultValues: SalesSchema = {
  company: 0,
  product: 0,
  description: "",
  quantity: 0,
}
