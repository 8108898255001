import styled from "styled-components"

export const Config = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
`

export const Page = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  align-items: center;
`
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Chart = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`

export const Tools = styled.div`
  width: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Chart3Dtype = styled.button`
  position: fixed;
  width: 60px;
  height: 60px;
  top: 145px;
  right: 20px;
  background-color: #0c9;
  border-radius: 50px;
  text-align: center;
`

export const ChartFFTController = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

export const AxisLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const AxisContainerChoice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 18px;
  justify-content: end;
  align-items: end;
  text-align: end;

  input {
    height: 20px;
    width: 20px;
  }
`

export const Chart3DAxisChoice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`

export const ToggleDivHourMeter = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 20%;
  font-size: small;
  gap: 5px;
`

export const ToggleLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  padding: 5px;
  gap: 5px;
  border-radius: 5px;
`

export const ContainerOptnsFFT = styled.div`
  justify-content: center;
  flex-direction: row;
  display: flex;
  font-size: small;
  gap: 20px;
  @media (max-width: 570px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`

export const Footer = styled.footer`
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const FooterText = styled.p<{ $isbold: boolean }>`
  font-weight: ${(props) => (props.$isbold ? "bold" : "normal")};
`
