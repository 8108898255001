/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from "react"
import { AlarmsConfigContext } from "../context/modelContext"
import { toast } from "react-toastify"
import { GetAlarmUsecase } from "../useCases/getAlarmUsecase"
import { UpdateAlarmUsecase } from "../useCases/updateAlarmUsecase"
import { DeleteAlarmUsecase } from "../useCases/deleteAlarmUsecase"
import { GetAlarmDataDTO } from "../entities/getAlarmDTO"
import { SensorNameWithProdSerialNumberDTO } from "../entities/sensorNameWithProdSerialDTO"
import {
  ConfigsDTO,
  DataFromApiDTO,
  OptionsLinesDTO,
  ThresholdsDTO,
} from "../entities/dataFromApiDTO"
import { SensorObjDTO } from "../entities/sensorObjDTO"
import { ContextAlarmsConfig } from "../entities/contextDTO"
import { ConfiguredSensors } from "../entities/configuredSensorsDTO"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"

export const useAlarmConfigViewModel = () => {
  const {
    setOpenAlarmsConfigModal,
    setProductionSerialNumbersOfConfiguredAlarms,
    sensors,
    setSensors,
    THRESHOLD_OPTIONS,
    TIME_BETWEEN_ALARMS,
    VARIABLE_TO_BE_MONITORED,
    API_MAPPED_TO_VARIABLE_MONITORED,
    VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS,
    VARIABLE_MONITORED_MAPPED_TO_API,
    CONVERT_SECONDS_TO_TSAMPLE,
    CONVERT_TSAMPLE_TO_SECONDS,
    DROPDOWN_NAMES,
    INPUT_NAMES,
    alarmsFromUserRef,
    wasModalOpenedFromMain,
    prodSerialNumberPlusAlarm,
    validateEmail,
    dropdownOpenRef,
    dropdownSelectedRef,
    inputSelectedRef,
    sensorsToBeUpdatedRef,
    dataFromAPI,
    setDataFromAPI,
    sensorsNameWithProdSerialNumber,
    setSensorsNameWithProdSerialNumber,
    updateData,
    setUpdateData,
    loading,
    setLoading,
    updateBtnDisabled,
    setUpdateBtnDisabled,
    openAccordionAlarm,
    setOpenAccordionAlarm,
    configuredSensorsData,
  } = useContext(AlarmsConfigContext) as ContextAlarmsConfig

  const getAlarmUsecase = new GetAlarmUsecase()
  const updateAlarmUsecase = new UpdateAlarmUsecase()
  const deleteAlarmUsecase = new DeleteAlarmUsecase()

  useEffect(() => {
    // setLoading(true);
    getAlarm()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (sensors.length > 0) {
      updateStates()
    }

    // eslint-disable-next-line
  }, [updateData])

  const getAlarm = async () => {
    const { error, data }: { error: boolean; data: GetAlarmDataDTO } =
      await getAlarmUsecase.get()
    if (error) {
      setLoading(false)
      return
    }

    if (Object.keys(data).length === 0) {
      _resetVariables()
      return
    }

    alarmsFromUserRef.current = data
    const sensorsFromAPI = JSON.parse(data.sensors)
    const prodSerialNumbers: string[] = sensorsFromAPI.map(
      (item: any) => item.productionSerialNumber
    )
    // get names
    const sensorsNameWithProdSerialNumberObj: SensorNameWithProdSerialNumberDTO =
      {}
    for (const serialNum of prodSerialNumbers) {
      const sensorFind = configuredSensorsData.find(
        (element) =>
          element.productionSerialNumber?.slice(
            element.productionSerialNumber.length - 5
          ) === serialNum
      ) as ConfiguredSensors
      if (sensorFind)
        sensorsNameWithProdSerialNumberObj[serialNum] =
          sensorFind.name ??
          `S${cleanAndPadSerialNumber(sensorFind.productionSerialNumber, sensorFind.hardwareVersion)}`
    }

    setSensorsNameWithProdSerialNumber(sensorsNameWithProdSerialNumberObj)
    setProductionSerialNumbersOfConfiguredAlarms(prodSerialNumbers)
    updateStates(sensorsFromAPI, sensorsNameWithProdSerialNumberObj)
    setLoading(false)
  }

  const updateStates = (
    sensorsJSON = undefined,
    sensorsNameWithProdSerialNumberObject:
      | SensorNameWithProdSerialNumberDTO
      | undefined = undefined
  ): void => {
    const sensorsObj: SensorObjDTO[] = sensorsJSON ?? sensors
    const sensorsNameWithProdSerialNumberObj =
      sensorsNameWithProdSerialNumberObject ?? sensorsNameWithProdSerialNumber
    _setStatesFromSensors(sensorsObj, sensorsNameWithProdSerialNumberObj)
    setSensors(sensorsObj)
  }

  const _setStatesFromSensors = (
    sensors: any,
    sensorsNameWithProdSerialNumberObj: any
  ) => {
    const data: DataFromApiDTO[] = []
    sensors.forEach((item: any, index: any) => {
      const sensorName =
        sensorsNameWithProdSerialNumberObj[item.productionSerialNumber]
      const obj = _buildObj(item, index, sensorName)
      data.push(obj)
    })
    setDataFromAPI(data)
  }

  const _buildObj = (
    item: any,
    index: number,
    sensorName: any
  ): DataFromApiDTO => {
    const obj = {} as DataFromApiDTO
    sensorsToBeUpdatedRef.current[index] = {} as SensorObjDTO

    obj["productionSerialNumber"] = item.productionSerialNumber
    obj["sensorName"] = sensorName
    obj["onDeleteAlarmCompletelyClick"] = handleOnDeleteAlarmCompletelyClick
    obj["configs"] = []

    sensorsToBeUpdatedRef.current[index]["productionSerialNumber"] =
      item.productionSerialNumber
    VARIABLE_TO_BE_MONITORED_FROM_API_WITH_CHANNELS.forEach((variable) => {
      const itemObj = item[variable]
      if (itemObj) {
        const temp = {} as ConfigsDTO
        const name = `${index}-${variable}`

        ;(sensorsToBeUpdatedRef.current as any)[index][variable] = {}

        temp["monitoredVariableTitle"] = (
          API_MAPPED_TO_VARIABLE_MONITORED as any
        )[variable]
        temp["onDeleteClickFn"] = handleOnDeleteConfigClick
        temp["onUpdateClickFn"] = handleOnUpdateClick
        temp["optionLines"] = []

        const thresholdObj: OptionsLinesDTO = {}
        _buildThresholdObj(thresholdObj, name, itemObj)
        temp["optionLines"].push(thresholdObj)

        const tSampleObj: OptionsLinesDTO = {}
        _buildTSampleObj(tSampleObj, name, itemObj)
        temp["optionLines"].push(tSampleObj)

        const emailObj = {}
        _buildEmailObj(emailObj, name, itemObj)
        temp["optionLines"].push(emailObj)

        _buildSensorToBeUpdatedRef(
          index,
          variable,
          thresholdObj,
          tSampleObj,
          emailObj
        )
        obj["configs"].push(temp)
      }
    })

    return obj
  }

  const _buildThresholdObj = (
    temp: OptionsLinesDTO,
    name: string,
    defaultValueObj: any
  ) => {
    const thresholdName = `${name}-threshold`
    const thresholdOptionSelected =
      _getThresholdOptionSelectedFromAPI(defaultValueObj)

    // set refs
    dropdownOpenRef.current = {
      ...dropdownOpenRef.current,
      [thresholdName]: dropdownOpenRef.current[thresholdName] ?? false,
    }
    dropdownSelectedRef.current = {
      ...dropdownSelectedRef.current,
      [thresholdName]:
        dropdownSelectedRef.current[thresholdName] ?? thresholdOptionSelected,
    }

    temp["isDropdown"] = true
    temp["fontWeight"] = "unset"
    temp["name"] = thresholdName
    temp["openDropdown"] = dropdownOpenRef.current[thresholdName] ?? false
    temp["optionSelected"] =
      dropdownSelectedRef.current[thresholdName] ?? thresholdOptionSelected
    temp["handleDropdownOpenClick"] = handleDropdownOpenClick
    temp["handleDropdownOptionSelect"] = handleDropdownOptionSelect
    temp["dropdownOptions"] = THRESHOLD_OPTIONS
    temp["thresholds"] = []

    {
      const firstThreshold = {} as ThresholdsDTO
      let defaultValue = defaultValueObj["firstThreshold"]
      _buildThresholdInsideThresholdObj(
        firstThreshold,
        thresholdName,
        "firstThreshold",
        defaultValue
      )
      temp["thresholds"].push(firstThreshold)
    }

    {
      const secondThreshold = {} as ThresholdsDTO
      let defaultValue = defaultValueObj["secondThreshold"]
      _buildThresholdInsideThresholdObj(
        secondThreshold,
        thresholdName,
        "secondThreshold",
        defaultValue
      )
      temp["thresholds"].push(secondThreshold)
    }

    {
      const threshold = {} as ThresholdsDTO
      let defaultValue = defaultValueObj["threshold"]
      const disabled =
        dropdownSelectedRef.current[thresholdName] === THRESHOLD_OPTIONS[4]
      _buildThresholdInsideThresholdObj(
        threshold,
        thresholdName,
        "threshold",
        defaultValue,
        disabled
      )
      temp["thresholds"].push(threshold)
    }
  }

  const _buildThresholdInsideThresholdObj = (
    temp: any,
    name: string,
    title: string,
    defaultValue: any,
    disabled: null | boolean = null
  ) => {
    const thresholdInsideThresholdName = `${name}-${title}`

    // set ref
    inputSelectedRef.current = {
      ...inputSelectedRef.current,
      [thresholdInsideThresholdName]:
        inputSelectedRef.current[thresholdInsideThresholdName] ?? defaultValue,
    }

    temp["isDropdown"] = false
    temp["inputType"] = "number"
    temp["disabled"] =
      disabled ?? dropdownSelectedRef.current[name] !== THRESHOLD_OPTIONS[4]
    temp["title"] = title
    temp["name"] = thresholdInsideThresholdName
    temp["onChangeFn"] = handleInputOnChange
    temp["defaultValue"] = defaultValue
    temp["value"] = inputSelectedRef.current[thresholdInsideThresholdName]
  }

  const _buildTSampleObj = (temp: any, name: any, defaultValueObj: any) => {
    const tSampleName = `${name}-tSample`
    const optionSelected = (CONVERT_SECONDS_TO_TSAMPLE as any)[
      defaultValueObj["tSample"]
    ]

    // set refs
    dropdownOpenRef.current = {
      ...dropdownOpenRef.current,
      [tSampleName]: dropdownOpenRef.current[tSampleName] ?? false,
    }

    dropdownSelectedRef.current = {
      ...dropdownSelectedRef.current,
      [tSampleName]: dropdownSelectedRef.current[tSampleName] ?? optionSelected,
    }

    temp["hasTitle"] = true
    temp["isDropdown"] = true
    temp["fontWeight"] = "unset"
    temp["title"] = "Tempo entre alarmes"
    temp["name"] = tSampleName
    temp["openDropdown"] = dropdownOpenRef.current[tSampleName] ?? false
    temp["optionSelected"] =
      dropdownSelectedRef.current[tSampleName] ?? optionSelected
    temp["handleDropdownOpenClick"] = handleDropdownOpenClick
    temp["handleDropdownOptionSelect"] = handleDropdownOptionSelect
    temp["dropdownOptions"] = TIME_BETWEEN_ALARMS
  }

  const _buildEmailObj = (temp: any, name: any, defaultValueObj: any) => {
    const emailName = `${name}-email`

    // set ref
    inputSelectedRef.current = {
      ...inputSelectedRef.current,
      [emailName]:
        inputSelectedRef.current[emailName] ?? defaultValueObj["socialContact"],
    }
    temp["isDropdown"] = false
    temp["inputType"] = "email"
    temp["title"] = "Email para notificação"
    temp["name"] = emailName
    temp["onChangeFn"] = handleInputOnChange
    temp["value"] = inputSelectedRef.current[emailName]
    temp["defaultValue"] = defaultValueObj["socialContact"]
  }

  const _getThresholdOptionSelectedFromAPI = (defaultValueObj: any) => {
    let optionsSelected = "Acima"

    if (defaultValueObj["border"]) {
      if (defaultValueObj["risingEdge"]) {
        return "Borda de subida"
      }
      return "Borda de descida"
    } else if (defaultValueObj["between"]) {
      return "Entre valores"
    } else if (defaultValueObj["lower"]) {
      return "Abaixo"
    }

    return optionsSelected
  }

  const _buildSensorToBeUpdatedRef = (
    index: number,
    variable: string,
    thresholdObj: OptionsLinesDTO,
    tSampleObj: OptionsLinesDTO,
    emailObj: any
  ): void => {
    const thresholdObjVariable = thresholdObj["thresholds"] as ThresholdsDTO[]

    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["threshold"] =
      parseFloat(thresholdObjVariable[2]["value"] as string).toFixed(2)
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["higher"] =
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[0]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["lower"] =
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[1]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["tSample"] =
      CONVERT_TSAMPLE_TO_SECONDS[tSampleObj["optionSelected"] as string]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["border"] =
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[2] ||
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[3]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["risingEdge"] =
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[2]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["fallingEdge"] =
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[3]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["between"] =
      thresholdObj["optionSelected"] === (THRESHOLD_OPTIONS as any)[4]
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["firstThreshold"] =
      parseFloat(thresholdObjVariable[0]["value"] as string).toFixed(2)
    ;(sensorsToBeUpdatedRef.current as any)[index][variable][
      "secondThreshold"
    ] = parseFloat(thresholdObjVariable[1]["value"] as string).toFixed(2)
    ;(sensorsToBeUpdatedRef.current as any)[index][variable]["type"] =
      // email
      (sensorsToBeUpdatedRef.current as any)[index][variable]["socialContact"] =
        emailObj["value"]
  }

  const handleOpenModal = (
    fromMain: boolean = false,
    productionSerialNumber: string = ""
  ): void => {
    wasModalOpenedFromMain.current = fromMain
    prodSerialNumberPlusAlarm.current = productionSerialNumber
    setOpenAlarmsConfigModal(true)
  }

  const handleDropdownOpenClick = (
    e: React.MouseEvent<HTMLElement>,
    dropName: string
  ): void => {
    e.preventDefault()

    const negateDropOpen = !dropdownOpenRef.current[dropName]
    dropdownOpenRef.current = {
      ...dropdownOpenRef.current,
      [dropName]: negateDropOpen,
    }

    setUpdateData(!updateData)
  }

  const handleDropdownOptionSelect = (
    e: React.MouseEvent<HTMLElement>,
    dropName: string,
    value: any
  ): void => {
    e.preventDefault()

    // Set the same threshold for firstThreshold (bug)
    const inputThreshold = `${dropName}-threshold`
    const inputFirstThreshold = `${dropName}-firstThreshold`

    inputSelectedRef.current = {
      ...inputSelectedRef.current,
      [inputFirstThreshold]: inputSelectedRef.current[inputThreshold],
    }

    dropdownSelectedRef.current = {
      ...dropdownSelectedRef.current,
      [dropName]: value,
    }

    setUpdateData(!updateData)
  }

  const handleInputOnChange = (e: any, inputName: string): void => {
    const value = e.target.value

    inputSelectedRef.current = {
      ...inputSelectedRef.current,
      [inputName]: value,
    }

    setUpdateData(!updateData)
  }

  const handleOnDeleteAlarmCompletelyClick = async (
    e: React.MouseEvent<HTMLElement>,
    productionSerialNumber: string
  ): Promise<void> => {
    e.preventDefault()

    const sensors = alarmsFromUserRef.current.sensors
    const sensorsJSON = JSON.parse(sensors)

    if (sensorsJSON.length === 1) {
      await _delete()
    } else {
      const index = sensorsJSON.findIndex(
        (item: any) => item.productionSerialNumber === productionSerialNumber
      )
      sensorsJSON.splice(index, 1)

      const sensorsString = JSON.stringify(sensorsJSON)
      const successMessage = "Alarme do sensor excluída!"

      await _update({ sensorsString, successMessage, updateScreenData: true })
    }
  }

  const handleOnDeleteConfigClick = async (
    e: React.MouseEvent<HTMLElement>,
    productionSerialNumber: string,
    monitoredVariableTitle: string
  ): Promise<void> => {
    e.preventDefault()

    const sensors = alarmsFromUserRef.current.sensors
    const sensorsJSON = JSON.parse(sensors)
    const monitoredVariableToAPI =
      VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariableTitle]

    const sensorsFiltered = sensorsJSON.filter(
      (item: any) => item.productionSerialNumber === productionSerialNumber
    )
    delete sensorsFiltered[0][monitoredVariableToAPI]

    const index = sensorsJSON.findIndex(
      (item: any) => item.productionSerialNumber === productionSerialNumber
    )

    if (Object.keys(sensorsFiltered[0]).length === 1) {
      handleOnDeleteAlarmCompletelyClick(e, productionSerialNumber)
      return
    }

    sensorsJSON[index] = sensorsFiltered[0]

    const sensorsString = JSON.stringify(sensorsJSON)
    const successMessage = "Configuração de alarme excluída!"

    await _update({ sensorsString, successMessage, updateScreenData: true })
  }

  const handleOnUpdateClick = async (
    e: React.MouseEvent<HTMLElement>,
    buttonName: string
  ): Promise<void> => {
    e.preventDefault()

    if (sensorsToBeUpdatedRef.current.length === 0) {
      return
    }
    setUpdateBtnDisabled(true)

    const [productionSerialNumber, monitoredVariable] = buttonName.split("-")

    const originalSensorsObject = JSON.parse(alarmsFromUserRef.current.sensors)

    const indexOfOriginal = originalSensorsObject.findIndex(
      (item: any) => item.productionSerialNumber === productionSerialNumber
    )
    const indexOfChanged = sensorsToBeUpdatedRef.current.findIndex(
      (item) => item.productionSerialNumber === productionSerialNumber
    )

    originalSensorsObject[indexOfOriginal][
      VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
    ] = (sensorsToBeUpdatedRef.current as any)[indexOfChanged][
      VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
    ]

    // validate email fields
    const emailsSplitted =
      originalSensorsObject[indexOfOriginal][
        VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
      ].socialContact.split(";")
    for (const email of emailsSplitted) {
      const validated = validateEmail(email)
      if (!validated) {
        toast.warn("Emails não estão escritos da maneira correta!", {
          autoClose: 2500,
        })
        setUpdateBtnDisabled(false)
        return
      }
    }

    // validate if all fields are fulfilled
    const thresholdInput =
      originalSensorsObject[indexOfOriginal][
        VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
      ].threshold
    const firstThresholdInput =
      originalSensorsObject[indexOfOriginal][
        VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
      ].firstThreshold
    const secondThresholdInput =
      originalSensorsObject[indexOfOriginal][
        VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
      ].secondThreshold
    const betweenDropdown =
      originalSensorsObject[indexOfOriginal][
        VARIABLE_MONITORED_MAPPED_TO_API[monitoredVariable]
      ].between

    if (betweenDropdown) {
      if (isNaN(firstThresholdInput) || isNaN(secondThresholdInput)) {
        toast.warn("Todos os campos precisam ser preenchidos!", {
          autoClose: 2500,
        })
        setUpdateBtnDisabled(false)
        return
      } else if (firstThresholdInput > secondThresholdInput) {
        toast.warn(
          "Primeiro threshold precisa ser menor que o segundo threshold!",
          { autoClose: 2500 }
        )
        setUpdateBtnDisabled(false)
        return
      }
    } else {
      if (isNaN(thresholdInput)) {
        toast.warn("Todos os campos precisam ser preenchidos!", {
          autoClose: 2500,
        })
        setUpdateBtnDisabled(false)
        return
      }
    }

    const updatedAlarmsRef = {
      ...alarmsFromUserRef.current,
      sensors: JSON.stringify(originalSensorsObject),
    }

    const sensorsString = updatedAlarmsRef.sensors
    const successMessage = "Alarme atualizado!"

    await _update({ sensorsString, successMessage })

    alarmsFromUserRef.current = updatedAlarmsRef

    setUpdateBtnDisabled(false)
  }

  const _update = async ({
    sensorsString,
    successMessage,
    updateScreenData = false,
  }: {
    sensorsString: any
    successMessage: any
    updateScreenData?: boolean
  }): Promise<void> => {
    const id = alarmsFromUserRef.current.id

    const updated = await updateAlarmUsecase.update(id, {
      sensors: sensorsString,
    })
    if (updated) {
      toast.success(successMessage, { autoClose: 2500 })
      if (updateScreenData) getAlarm()
    } else {
      toast.error("Algo de errado aconteceu. Tente novamente mais tarde.", {
        autoClose: 2500,
      })
    }
  }

  const _delete = async (): Promise<void> => {
    const id = alarmsFromUserRef.current.id

    const deleted = await deleteAlarmUsecase.delete(id)
    if (deleted) {
      toast.success("Alarmes deletados!", { autoClose: 2500 })
      getAlarm()
    } else {
      toast.error("Algo de errado aconteceu. Tente novamente mais tarde.", {
        autoClose: 2500,
      })
    }
  }

  const _resetVariables = (): void => {
    dropdownOpenRef.current = {}
    dropdownSelectedRef.current = {}
    inputSelectedRef.current = {}
    sensorsToBeUpdatedRef.current = []

    setDataFromAPI([])
    setSensorsNameWithProdSerialNumber({})

    setUpdateData(false)
    setLoading(false)
    setUpdateBtnDisabled(false)

    setProductionSerialNumbersOfConfiguredAlarms([])
    setSensors([])

    alarmsFromUserRef.current = {} as GetAlarmDataDTO
  }

  return {
    handleOpenModal,
    THRESHOLD_OPTIONS,
    TIME_BETWEEN_ALARMS,
    VARIABLE_TO_BE_MONITORED,
    handleDropdownOpenClick,
    handleDropdownOptionSelect,
    handleInputOnChange,
    handleOnUpdateClick,
    DROPDOWN_NAMES,
    INPUT_NAMES,
    dropdownSelectedRef,
    dataFromAPI,
    loading,
    updateBtnDisabled,
    openAccordionAlarm,
    setOpenAccordionAlarm,
    getAlarm,
  }
}
