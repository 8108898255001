import { Tooltip } from "../../Tooltip"
import { IconAndDescriptionContainer } from "../styles"
import { BsWifi } from "react-icons/bs"

const Rssi = ({
  rssi,
  iconSize,
}: {
  rssi: number | null
  iconSize: number
}): JSX.Element => {
  if (!rssi) return <IconAndDescriptionContainer />

  return (
    <Tooltip title={"RSSI"}>
      <IconAndDescriptionContainer>
        <BsWifi size={iconSize} />
        <p>{rssi !== 0 ? rssi + " dBm" : "-"}</p>
      </IconAndDescriptionContainer>
    </Tooltip>
  )
}

export default Rssi
