/* eslint-disable react/prop-types */
import { Container } from "./styles"
import { FormButton } from "../../components/FormButton"
import { useNavigate } from "react-router-dom"

interface Props {
  page: "company" | "user" | "sale"
  onNewRegister: () => void
}

const ConfirmationMessage: React.FC<Props> = ({ page, onNewRegister }) => {
  const navigator = useNavigate()

  return (
    <Container>
      <h1>Cadastrado completado com sucesso!</h1>
      <FormButton
        text='Cadastrar Empresa'
        callBack={
          page === "company"
            ? () => onNewRegister()
            : () => navigator("/managercompany")
        }
      />
      <FormButton
        text='Cadastrar usuário'
        callBack={
          page === "user"
            ? () => onNewRegister()
            : () => navigator("/manageruser")
        }
      />
      <FormButton
        text='Cadastrar Venda'
        callBack={
          page === "sale"
            ? () => onNewRegister()
            : () => navigator("/managersale")
        }
      />
    </Container>
  )
}

export default ConfirmationMessage
