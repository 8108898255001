import { Bell, SignOut } from "phosphor-react"
import {
  CustomLink,
  NavTitle,
  BoxUserInfo,
  AppVersion,
  NotificationButton,
  Badge,
  NotificationBadge,
} from "../../styles/Navbar.styled"
import { Button } from "../../../ui/Button"
import { useMenuController } from "../../../Menu/controller/useMenuController"
import { useAppSelector } from "../../../../store/hooks"

export const NavFooter = () => {
  const { logout, userProfile } = useMenuController()

  const alarmLogCount = useAppSelector(
    (state) => state.persistedReducer.alarmsLogs.count
  )

  return (
    <>
      <NotificationButton>
        {alarmLogCount > 0 && (
          <Badge>
            <NotificationBadge>
              {alarmLogCount >= 100 ? "+99" : alarmLogCount}
            </NotificationBadge>
          </Badge>
        )}
        <CustomLink
          to='/alarmslogs'
          className={(isActive) =>
            "nav-link" + (!isActive ? " unselected" : "")
          }
        >
          <Bell className='menu-icon' size={24} />
          <NavTitle>Notificações</NavTitle>
        </CustomLink>
      </NotificationButton>

      {/* <CustomLink
        to='/faq'
        className={(isActive) => "nav-link" + (!isActive ? " unselected" : "")}
      >
        <GearSix color={theme.palette.gray._500} size={24} />
        <NavTitle>Configurações</NavTitle>
      </CustomLink> */}
      <BoxUserInfo>
        <span className='user'>{userProfile?.email}</span>
        <span className='company' />
        <Button
          variant='link'
          leftIcon={<SignOut size={24} />}
          onClick={() => logout()}
        >
          Sair da conta
        </Button>
      </BoxUserInfo>
      <AppVersion>V 1.0</AppVersion>
    </>
  )
}
