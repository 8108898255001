import {
  getTemp,
  getRMMS,
  getRMS2,
  getFFT,
  getAccRaw,
  getPot,
  get4T20,
  getNtc,
  getHealth,
} from "hdr-process-data"

import { ServiceAndMac } from "../entities/serviceAndMacDTO"
import { ProcessData } from "../entities/processDataWithMacDTO"
import { getSensorDataFromApi } from "../../../store/features/historicalAnalysis/historicalAnalysis.request"

export const useProcessApiData = () => {
  async function getProcessData(
    service: string,
    serviceArray: ServiceAndMac[],
    analyticID: number | undefined,
    startDate: Date,
    endDate: Date
  ): Promise<ProcessData[] | undefined> {
    let macs: string[] = []

    if (serviceArray[0]?.mac) {
      macs = serviceArray.map((item) => item.mac.toString())
    } else {
      return
    }

    const data = await getSensorDataFromApi(
      startDate ? startDate : null,
      endDate ? endDate : null,
      macs,
      service
    )
    const processData: ProcessData[] = []

    switch (service) {
      case "TEMP":
        data.forEach((element) => {
          const result = getTemp(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "RMS2":
        data.forEach((element) => {
          const result = getRMS2(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "RMMS":
        data.forEach((element) => {
          const result = getRMMS(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "FFT":
        data.forEach((element) => {
          const result = getFFT(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "ACCRAW":
        data.forEach((element) => {
          const result = getAccRaw(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "POT":
        data.forEach((element) => {
          const result = getPot(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "_4T20":
        data.forEach((element) => {
          const result = get4T20(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "NTC":
        data.forEach((element) => {
          const result = getNtc(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })

          if (result.isRight()) {
            processData.push({
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      case "HEALTH":
        data.forEach((element) => {
          const result = getHealth(element.raw, element.time.toFixed(0), {
            applicationVersion: element.applicationVersion,
            protocolVersion: element.protocolVersion,
          })
          if (result.isRight()) {
            processData.push({
              rssi: element.rssi,
              mac: element.mac,
              ...result.value,
            })
          }
        })
        break

      default:
        break
    }

    return processData
  }

  return {
    getProcessData,
    getSensorDataFromApi,
  }
}
