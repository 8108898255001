import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { fetchCompanies } from "../../../store/features/companies/companies.api"
import { fetchProducts } from "../../../store/features/products/produtcs.api"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "react-toastify"
import { Sale } from "./entities/sale"
import { createSale } from "../../../store/features/sales/sales.api"
import { SalesSchema, defaultValues, salesSchema } from "../schemas/sales"

export const useRegisterSale = () => {
  const dispatch = useAppDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const profile = useAppSelector((state) => state.persistedReducer.user.profile)

  const companies = useAppSelector((state) => state.persistedReducer.companies)
  const products = useAppSelector((state) => state.persistedReducer.products)
  const sales = useAppSelector((state) => state.persistedReducer.sales)

  const sale = useRef<null | Sale>(null)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<SalesSchema>({
    resolver: zodResolver(salesSchema),
    defaultValues,
  })

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])

  useEffect(() => {
    for (let key in errors) {
      const error = errors[key as keyof SalesSchema]

      if (!error) continue
      if (!error.message) continue

      toast.error(error.message)

      break
    }
  }, [errors])

  const onSubmit: SubmitHandler<SalesSchema> = (data) => {
    const company_index = data.company - 1
    const product_index = data.product - 1

    if (company_index >= companies.companies.length) return
    if (product_index >= products.products.length) return

    const company = companies.companies[company_index]
    const product = products.products[product_index]

    sale.current = {
      amount: data.quantity,
      company: company.name,
      description: data.description,
      product: product.name,
    }

    openModal()
  }

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const registerSale = async () => {
    const company_index = getValues("company") - 1
    const product_index = getValues("product") - 1

    if (company_index >= companies.companies.length) return
    if (product_index >= products.products.length) return

    if (!profile) return

    const result = await dispatch(
      createSale({
        companyId: companies.companies[company_index].id,
        description: getValues("description"),
        productId: products.products[product_index].id,
        quantity: getValues("quantity"),
        userId: profile.user_data.y1,
      })
    ).unwrap()

    if (result.isLeft()) {
      toast.error(result.value.message)
      return
    }

    toast.success("Venda cadastrada com sucesso!")

    setTimeout(() => {
      closeModal()
      reset()
    }, 5000)
  }

  return {
    companies: companies.companies,
    products: products.products,
    register,
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isOpen,
    openModal,
    closeModal,
    sale: sale.current,
    registerSale,
    loadingPage: companies.loading || products.loading,
    loadingRequest: sales.loading,
  }
}
