import { SerializedError, createSlice } from "@reduxjs/toolkit"
import {
  fetchSensorsConfig,
  fetchSensorsProv,
  updateSensorName,
} from "./sensors.api"
import { SensorConfig, SensorProv } from "./sensors.interfaces"

interface InitialState {
  config: SensorConfig[]
  prov: SensorProv[]
  status:
    | "not started"
    | "idle"
    | "loading"
    | "failed"
    | "success"
    | "created"
    | "deleted"
  error: string | null | undefined | SerializedError
}

const initialState: InitialState = {
  config: [],
  prov: [],
  error: null,
  status: "not started",
}

export const sensorsConfigSlice = createSlice({
  name: "sensorsConfig",
  initialState,
  reducers: {
    resetSensorsConfigState: (state) => {
      state.config = []
      state.prov = []
      state.error = null
      state.status = "not started"
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSensorsConfig.pending, (state) => {
        state.status = "loading"
        state.error = null
      })
      .addCase(fetchSensorsConfig.fulfilled, (state, action) => {
        state.status = "success"
        state.config = action.payload
        state.error = null
      })
      .addCase(fetchSensorsConfig.rejected, (state, action) => {
        state.status = "failed"
        state.config = []
        state.error = action.error
      })
      .addCase(fetchSensorsProv.pending, (state) => {
        state.status = "loading"
        state.error = null
      })
      .addCase(fetchSensorsProv.fulfilled, (state, action) => {
        state.status = "success"
        state.prov = action.payload
        state.error = null
      })
      .addCase(fetchSensorsProv.rejected, (state, action) => {
        state.status = "failed"
        state.prov = []
        state.error = action.error
      })
      .addCase(updateSensorName.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
      .addCase(updateSensorName.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateSensorName.fulfilled, (state, action) => {
        state.status = "success"

        const indexInProvSensors = state.prov.findIndex(
          (sensor) => sensor.bleHdrMac === action.payload.bleHdrMac
        )
        const indexInConfigSensors = state.config.findIndex(
          (sensor) => sensor.mac === action.payload.bleHdrMac
        )

        if (indexInProvSensors === -1) return
        state.prov[indexInProvSensors].name = action.payload.name

        if (indexInConfigSensors === -1) return
        state.config[indexInConfigSensors].name = action.payload.name as string
      })
  },
})

export const { resetSensorsConfigState } = sensorsConfigSlice.actions

export default sensorsConfigSlice.reducer
