import { styled } from "styled-components"

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
`
