import { FC } from "react"
import * as S from "./styles"
import { LoadingSpinner } from "../../components/LoadingSpinner"

export const LoadingPage: FC = () => {
  return (
    <S.Container>
      <LoadingSpinner />
    </S.Container>
  )
}
