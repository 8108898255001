import { Controller } from "react-hook-form"
import { Input } from "../../components/Input"
import { Select } from "../../components/Select"
import { ModalConfirmSale } from "./components/ModalConfirmSale"
import { useRegisterSale } from "./hooks/useRegisterSale"
import { NewHeader } from "../../components/NewHeader"
import { ContainerWraper, LoadingContainer, Title } from "./styles"
import FormButton from "../../components/FormButton"
import Container from "../../components/ui/Container"

import Row from "../../components/ui/Row"
import { useAppTranslate } from "../../translate/useAppTranslate"
import Column from "../../components/ui/Column"
import styled from "styled-components"

const Card = styled.div`
  height: auto;
  border: solid 1px ${(props) => props.theme.palette.gray._400};
  border-radius: 6px 6px 6px 6px;
  background-color: ${(props) => props.theme.palette.white};
  padding: 20px 16px;
`

export const RegisterSale = (): JSX.Element => {
  const {
    companies,
    products,
    handleSubmit,
    control,
    isOpen,
    closeModal,
    sale,
    registerSale,
    loadingPage,
    loadingRequest,
  } = useRegisterSale()

  const { register } = useAppTranslate()

  const objPage = {
    page: {
      title: register.salesPageName,
    },
  }

  if (loadingPage) {
    return (
      <LoadingContainer>
        <h1>Carregando...</h1>
      </LoadingContainer>
    )
  }

  return (
    <Container>
      <Row>
        <NewHeader page={objPage.page} />
      </Row>
      <Row>
        <Column md='24' lg='24' xl='24'>
          <Card>
            {sale && (
              <ModalConfirmSale
                open={isOpen}
                data={sale}
                disabled={loadingRequest}
                onClose={closeModal}
                onConfirm={() => registerSale()}
              />
            )}

            <ContainerWraper onSubmit={handleSubmit}>
              <Title>
                <h1>Informações</h1>
              </Title>

              <Controller
                name='company'
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Select
                      values={companies.map((company) => company.name)}
                      placeholder='Selecione uma empresa'
                      onChange={(value) => onChange(Number(value.target.value))}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  )
                }}
              />

              <Controller
                name='product'
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Select
                      values={products.map((product) => product.name)}
                      placeholder='Selecione um produto'
                      onChange={(value) => onChange(Number(value.target.value))}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  )
                }}
              />

              <Controller
                name='description'
                control={control}
                render={({ field }) => {
                  return <Input label='Descrição' placeholder='' {...field} />
                }}
              />

              <Controller
                name='quantity'
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label='Quantidade'
                      type='number'
                      placeholder=''
                      {...field}
                    />
                  )
                }}
              />

              <FormButton text='ADICIONAR VENDA' type='submit' />
            </ContainerWraper>
          </Card>
        </Column>
      </Row>
    </Container>
  )
}
