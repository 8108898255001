import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiCall } from "../../../utils/axios"
import { buildConfiguredSensors } from "./alarms_logs.utilities"
import { SensorConfiguredDTO } from "./alarms_logs.interfaces"

export const alarmLogRequest = createAsyncThunk(
  "alarmsLogs/alarmLogRequest",
  async (): Promise<number> => {
    const api = apiCall()

    const url = process.env.REACT_APP_API_ALARMS_LOGS_SEEN_AT_NULL

    if (!url) {
      throw Error("Invalid url!")
    }

    const alarmsLogs = await api.get(url)

    return alarmsLogs.data.length
  }
)

export const fetchConfiguredSensors = createAsyncThunk(
  "alarmsLogs/fetchConfiguredSensors",
  async (): Promise<SensorConfiguredDTO[]> => {
    const api = apiCall()

    const url = process.env.REACT_APP_API_SENSORS_CONFIGURATIONS_INDEX

    if (!url) {
      throw Error("Invalid url!")
    }

    const response = await api.get(url)

    return buildConfiguredSensors(response.data)
  }
)
