import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  RequestCreateCompany,
  ResponseCompanyApi,
  ResponseCreateCompany,
} from "./companies.interface"
import { apiCall } from "../../../utils/axios"
import axios, { isAxiosError } from "axios"
import { Result, left, right } from "../../../utils/either/result"

export const fetchCompanies = createAsyncThunk(
  "companies/fetchCompanies",
  async (): Promise<ResponseCompanyApi[]> => {
    const api = apiCall()

    const URL = process.env.REACT_APP_API_COMPANIES

    if (!URL) throw Error("Erro ao buscar empresas!")

    try {
      const response = await api.get<ResponseCompanyApi[]>(URL)
      return response.data
    } catch (error) {
      if (!isAxiosError(error)) throw Error("Erro ao buscar empresas!")

      if (error.response?.status === 400)
        throw Error("Erro ao buscar empresas!")

      if (error.response?.status === 401)
        throw Error("Você não tem permissão para completar essa ação!")

      if (error.response?.status === 404)
        throw Error("O recurso não foi encontrado!")

      throw Error("Erro ao buscar empresas!")
    }
  }
)

export const createCompany = createAsyncThunk(
  "companies/createCompany",
  async (
    data: RequestCreateCompany
  ): Promise<Result<Error, ResponseCreateCompany>> => {
    const URL = process.env.REACT_APP_API_COMPANIES

    if (URL === undefined)
      return left(Error("Erro ao buscar rota de cadastro!"))

    const api = apiCall()

    try {
      const result = await api.post<ResponseCreateCompany>(URL, data)
      return right(result.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400)
          return left(Error("Erro ao cadastrar empresa!"))

        if (error.response?.status === 401)
          return left(Error("Você não tem permissão para completar essa ação!"))

        if (error.response?.status === 404)
          return left(Error("O recurso não foi encontrado!"))

        if (error.response?.status === 409)
          return left(Error("Já existe uma empresa com esse CNPJ!"))

        return left(Error("Erro ao cadastrar empresa!"))
      }

      return left(Error("Erro ao cadastrar empresa!"))
    }
  }
)
