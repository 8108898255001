/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiCall } from "../../../utils/axios"
import {
  SensorAvailablesServices,
  ApiDataDTO,
  services,
} from "./reports.interfaces"

const _checkSensorsWithData = async (
  macs: any,
  service: string
): Promise<ApiDataDTO[]> => {
  const CONVERT_SENSOR_SERVICE_TO_API_CALL: {
    [key: string]: any
  } = {
    temp: "/v1/temps",
    health: "/v1/healths",
    tempsMMM: "/v1/tempsmmm",
    rms2: "/v1/rms2s",
    rmms: "/v1/rmms",
    tilt: "/v1/tilts",
    fft: "/v1/ffts",
    accRaw: "/v1/accRaws",
    _4t20: "/v1/_4t20",
    ntc: "/v1/ntcs",
    pot: "/v1/pots",
  }

  const api = apiCall()

  const url: string | undefined = CONVERT_SENSOR_SERVICE_TO_API_CALL[service]

  const data = await api.get(url!, {
    headers: {
      macs: macs,
    },
  })
  return data.data
}

async function getAvailablesServiceForEachSensor(
  macs: string[],
  availableServices: SensorAvailablesServices[]
) {
  const allServices = [
    "accRaw",
    "fft",
    "ntc",
    "rmms",
    "rms2",
    "temp",
    "tilt",
    "_4t20",
    "pot",
    "health",
  ]
  for (let j = 0; j < allServices.length; j++) {
    const service = allServices[j]
    const data: ApiDataDTO[] = await _checkSensorsWithData(macs, service)
    data.forEach((element: ApiDataDTO) => {
      let index = availableServices.findIndex((mac) => mac.mac === element.mac)
      if (index !== -1) {
        const newService: services = {
          nameService: service,
          lastData: new Date(element.time).toISOString(),
          hasData: true,
        }
        availableServices[index].services = [
          ...availableServices[index].services,
          newService,
        ]
      }
    })
  }
  return availableServices
}

export const getLastDataFromSensor = async (
  mac: string,
  service:
    | "accRaw"
    | "fft"
    | "ntc"
    | "rmms"
    | "rms2"
    | "temp"
    | "tilt"
    | "_4t20"
    | "pot"
    | "health"
) => {
  const data: ApiDataDTO[] = await _checkSensorsWithData(mac, service)

  if (data.length === 0) return null
  return data[0]
}

export function buildSensorsProvObject(provs: any) {
  const obj: any = {}
  try {
    for (const prov of provs) {
      if (prov) {
        if (!obj[prov.bleHdrMac]) {
          obj[prov.bleHdrMac] = {
            id: prov.id,
            name: prov.name,
            firmware: prov.sensor.firmwareVersion,
            applicationVersion: prov.sensor.applicationVersion,
            hardwareVersion: prov.sensor.hardwareVersion,
            protocolVersion: prov.sensor.protocolVersion,
            network: prov.bleNetwork,
            bleHdrMac: prov.bleHdrMac,
            bleOriginalMac: prov.bleOriginalMac,
            productionSerialNumber: prov.sensor?.productionSerialNumber,
            algorithms: prov.sensor?.algorithms,
          }
        }
      }
    }
  } catch (err) {
    //
  }
  return obj
}

export async function buildSensorWithDataObject(provs: any): Promise<any> {
  const obj = buildSensorsProvObject(provs)
  const allMacFromStore: string[] = Object.keys(obj)
  let availableServices = [] as SensorAvailablesServices[]

  const sensorsLength = Object.keys(obj).length
  if (sensorsLength === 0) return []

  for (let index = 0; index < sensorsLength; index++) {
    const mac = allMacFromStore[index]
    const { productionSerialNumber, id, name } = obj[mac]

    const newMac = {
      serialNumber: productionSerialNumber,
      id,
      name,
      mac,
      services: [],
    }

    availableServices.push(newMac)
  }
  const formatService = await getAvailablesServiceForEachSensor(
    allMacFromStore,
    availableServices
  )
  return formatService
}
