import { createSlice } from "@reduxjs/toolkit"
import { Action, InitialState } from "./modal.interfaces"

const initialState: InitialState = {
  activeModal: null,
  configService: undefined,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: Action) => {
      state.activeModal = action.payload.modal
      if (action.payload.configService)
        state.configService = action.payload.configService
    },
    closeModal: (state) => {
      state.activeModal = null
    },
  },
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
