import styled from "styled-components"

export const Container = styled.div`
  /* width: 400px;
    height: 240px; */

  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`

export const Info = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;
  flex-direction: column;

  height: 100px;
  width: 100%;

  margin-top: 10px;

  padding-left: 10px;
  padding-right: 10px;
`

export const Buttons = styled.div`
  width: 100%;

  margin-top: 10px;

  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const ButtonRed = styled.button`
  width: 118px;
  height: 34px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #ff0000;
`

export const ButtonGreen = styled.button`
  width: 118px;
  height: 34px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #3cd62f;
`

export const Description = styled.p`
  max-lines: 3;
  word-break: break-all;
  font-size: 16px;
`

export const Product = styled.p`
  font-size: 16px;
`
