import { useContext } from "react"
import { DashboardContext } from "../../../../../pages/Dashboard/context/DashboardContext"
import { DashboardContextDTO } from "../../../../../pages/Dashboard/entities/DashboardContextDTO"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import {
  closeModal,
  openModal,
} from "../../../../../store/features/modal/modal.slice"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useModalAnalyticConfigController = () => {
  const {
    setSelectedPeriod,
    setOrientedBy,
    selectedTypeChart,
    setSelectedTypeChart,
    orientedBy,
    graphicName,
    setGraphicName,
    selectedPeriod,
    analyticToPlot,
  } = useContext(DashboardContext) as DashboardContextDTO

  const dispatch = useAppDispatch()

  const dashboards = useAppSelector((state) => state.persistedReducer.dashboard)

  const handleUpdatePeriod = (period: number) => {
    setSelectedPeriod(period)
  }

  const handleUpdateOrientation = (orientation: "sensor" | "entity") => {
    setOrientedBy(orientation)
  }

  const handleUpdateName = (name: string): void => {
    setGraphicName(name)
  }

  const handleUpdateType = (type: 1 | 2 | 3) => {
    setSelectedTypeChart(type)
  }

  const handleNextModal = (): void => {
    dispatch(closeModal())
    dispatch(
      openModal({
        modal: "SelectSensorsAndServices",
      })
    )
    handleCreateAnalytic(selectedPeriod, selectedTypeChart, graphicName)
  }

  const handleCreateAnalytic = (
    selectedPeriod: number,
    selectedTypeChart: number | null,
    graphicName: string
  ) => {
    if (selectedTypeChart === null) return

    if (selectedTypeChart === 1) {
      analyticToPlot.current = {
        period: selectedPeriod,
        type: selectedTypeChart,
        tag: graphicName,
        sensors: {},
        dashboardId: dashboards.openDashboard?.id as number,
      }
    } else {
      analyticToPlot.current = {
        period: 0,
        type: selectedTypeChart,
        tag: graphicName,
        sensors: {},
        dashboardId: dashboards.openDashboard?.id as number,
      }
    }
  }

  const { modalAnalyticConfig } = useAppTranslate()

  const types = [
    {
      title: modalAnalyticConfig.amplitude_time_s,
      description: modalAnalyticConfig.amplitude_time_s_text,
      type: 1,
    },
    {
      title: modalAnalyticConfig.amplitude_frequency,
      description: modalAnalyticConfig.amplitude_frequency_text,
      type: 2,
    },
    {
      title: modalAnalyticConfig.amplitude_time_ms,
      description: modalAnalyticConfig.amplitude_time_ms_text,
      type: 3,
    },
  ]

  return {
    handleUpdatePeriod,
    handleUpdateOrientation,
    handleUpdateName,
    handleUpdateType,
    handleNextModal,
    selectedPeriod,
    orientedBy,
    graphicName,
    selectedTypeChart,
    types,
  }
}
