/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react"
import { HistoricalAnalysisContext } from "../context/modelContext"

import * as Highchart from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { OrbitItem } from "../entities/Orbit"
import useWindowDimensions from "../controllers/useWindowDimensions"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { formatedDate } from "../../../utils/date"

export const linechart = (width: number, height: number) => {
  const chart: Highchart.Options = {
    rangeSelector: {
      selected: 1,
    },
    title: {
      text: "",
    },
    boost: { useGPUTranslations: false, enabled: true },
    plotOptions: { series: { boostThreshold: 2000 } },
    chart: {
      type: "line",
      panning: {
        enabled: true,
        type: "xy",
      },
      panKey: "shift", // key to alternate to pan
      height: height,
      width: width,
    },
    time: {
      useUTC: false,
    },
    xAxis: {
      gridLineWidth: 1,
      title: { text: "" },
      type: "linear",
      tickPixelInterval: 150,
      crosshair: true,
      labels: { enabled: true },
      min: -10,
      max: 10,
    },
    yAxis: {
      gridLineWidth: 1,
      title: { text: "Amplitude" },
      min: -10,
      max: 10,
    },
    stockTools: {
      gui: {
        buttons: [],
      },
    },
    lang: {
      noData: "Não Há Dados para a Seleção de Sensores",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#0f0e0e4",
      },
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "bottom",
      itemStyle: {
        lineHeight: "14px",
        fontSize: "13px",
      },
    },
    tooltip: {
      style: {
        fontSize: "13px",
      },
    },
  }
  return chart
}

export const useOrbitChartController = () => {
  const {
    selectedOrbitItems,
    selectorIndex,
    setSelectorMaxIndex,
    selectorMaxIndex,
    setSelectorText,
    dataToPlot,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica

  const { height, width } = useWindowDimensions(50)

  const chartRef = useRef<HighchartsReact.RefObject>(null)

  const graphicOptions: Highchart.Options = {
    ...linechart(width, height),
  }

  useEffect(() => {
    if (Object.keys(dataToPlot).length === 0) return
    if (!chartRef.current) return

    while (chartRef.current.chart.series.length !== 0) {
      chartRef.current.chart.series[0].remove()
    }

    if (dataToPlot.length) setSelectorMaxIndex(dataToPlot.length)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const time: string = (dataToPlot as any)[selectorIndex]["time"]

    setSelectorText(formatedDate(time))

    const orbits = getSelectedsOrbits(selectedOrbitItems)

    orbits.forEach((orbitItem) => {
      const { xAxis, yAxis } = getXandYAxes(orbitItem.axis)
      const graphicData = getGraphicData("mac", selectorIndex, xAxis, yAxis)

      addSeries(chartRef, orbitItem.name, graphicData)
    })
  }, [dataToPlot, selectorIndex, selectorMaxIndex])

  const getSelectedsOrbits = (selectedOrbitItems: OrbitItem[]) => {
    const checked: OrbitItem[] = []

    selectedOrbitItems.forEach((orbitItem: OrbitItem) => {
      if (orbitItem.checked) checked.push(orbitItem)
    })

    return checked
  }

  const getXandYAxes = (axis: string[]) => {
    let xAxis = ""
    let yAxis = ""

    if (axis.includes("X") && axis.includes("Y")) {
      xAxis = "accRawx"
      yAxis = "accRawy"
    } else if (axis.includes("X") && axis.includes("Z")) {
      xAxis = "accRawx"
      yAxis = "accRawz"
    } else if (axis.includes("Y") && axis.includes("Z")) {
      xAxis = "accRawy"
      yAxis = "accRawz"
    }

    return { xAxis, yAxis }
  }

  const getGraphicData = (
    mac: string,
    indexData: number,
    xAxis: string,
    yAxis: string
  ) => {
    let graphicData: number[][] = []

    for (
      let index = 0;
      // @ts-ignore
      index < dataToPlot[indexData]["accRawx"].length;
      index++
    ) {
      // @ts-ignore
      const x = dataToPlot[indexData][xAxis][index]
      // @ts-ignore
      const y = dataToPlot[indexData][yAxis][index]

      if (x && y) graphicData.push([x, y])
    }

    return graphicData
  }
  // @ts-ignore
  const addSeries = (chartRef, seriesName: string, graphicData: number[][]) => {
    chartRef.current.chart.addSeries({
      type: "line",
      name: seriesName,
      data: graphicData,
    })
  }

  return { chartRef, graphicOptions }
}
